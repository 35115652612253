import React, { useRef, useEffect } from 'react';
import useBreakpoints from '../../hooks/Responsive';
import {
  Button,
  Row
} from "reactstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import "./HorizontalMapScroller.css";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { COLOR } from 'constants/color';
import { PATH } from 'constants/link';
import { HorizontalMapScrollerCard } from './HorizontalMapScrollerCard';

export const HorizontalMapScroller = ({ challengePackages }) => {
  const { isTabletOrMobile } = useBreakpoints();
  const [isShown, setIsShown] = React.useState(false);


  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);

  if (!challengePackages || challengePackages?.length < 2) {
    return null;
  }

  return (
    <div className={`${`horizontalMapScrollerRoot${isShown ? ' isPanelShown' : ''}${isTabletOrMobile ? ' showUnderMap' : ''}`}`}>
      <div className={`horizontalMapScrollerViewMoreRoot`} onClick={() => { setIsShown(!isShown) }}>
        <h3 className={'horizontalMapScrollerViewMoreText'}>{`${!isShown ? "Show More Events" : "Hide Events"}`}</h3>
      </div>
      <div ref={elRef} className={`horizontalMapScrollerInner hideHorizontalScroll`}>
        {
          challengePackages.map((cp) => { return <HorizontalMapScrollerCard challengePackage={cp} /> })
        }
      </div>
    </div>
  );
}