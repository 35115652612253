import React from 'react';
import { GenCommunityStandardsLink, GenPrivacyPolicyLink, GenTermsOfUseLink } from '../../helpers/Links';
import { COLOR } from '../../constants';

export const LegalFooter = (props) => {
  return <div style={styles.slideFooterRoot}>
    <span style={styles.legalText}>{"By clicking " + props.buttonText + ", you agree to our "}</span>
    {GenCommunityStandardsLink(<span style={styles.legalTextUnderline}>Community Standards</span>)}
    <span style={styles.legalText}>, </span>
    {GenTermsOfUseLink(<span style={styles.legalTextUnderline}>Terms</span>)}
    <span style={styles.legalText}>, and </span>
    {GenPrivacyPolicyLink(<span style={styles.legalTextUnderline}>Privacy Policy</span>)}
    <span style={styles.legalText}>.</span>
  </div>;
}

const styles = {
  slideFooterRoot: {
    width: "100%",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 20,
    paddingTop: 5,
    flexWrap: 'wrap',
  },

  legalText: {
    fontSize: 12,
  },

  legalTextUnderline: {
    textDecorationLine: 'underline',
    fontSize: 12,
  },
}