const CHALLENGE_REVIEW_STATUS = {
	IN_REVIEW: 0,
	APPROVED: 1,
	DECLINED_INCORRECT: 2,
	DECLINED_DUPLICATE: 3,
	DECLINED_UNSAFE: 4,
	DECLINED_INAPPROPRIATE: 5,
}

const CHALLENGE_REVIEW_STATUS_DISPLAY = {
	"0": "In Review",
	"1": "Approved",
	"2": "Declined: Incorrect",
	"3": "Declined: Duplicate",
	"4": "Declined: Unsafe",
	"5": "Declined: Inappropriate",
}

export { CHALLENGE_REVIEW_STATUS, CHALLENGE_REVIEW_STATUS_DISPLAY } 