import { CHALLENGE_ANSWER_STATUS, IMAGE } from '../constants';

export const GetStatuses = (challengePackageAccess, challengePackage) => {
  let statuses = {
    toDo: 0,
    inReview: 0,
    approved: 0,
    declined: 0,
    devicesUsed: {},
  };

  if (challengePackageAccess === null || challengePackage === null) {
    return statuses;
  }

  const challenges = challengePackage.challenges;
  const submissions = challengePackageAccess.submittedChallengeAnswers;
  let submissionsMap = {}
  let remainingChallenges = [];
  let pointsEarned = null;
  if (pointsEarned == null) {
    challenges.forEach((challenge) => {
      if (challenge.packagePoints !== null) {
        pointsEarned = 0;
      }
    })
  }

  submissions.forEach((submission) => {
    if (!(submission.deviceId in statuses.devicesUsed)) {
      statuses.devicesUsed[submission.deviceId] = 1;
    } else {
      statuses.devicesUsed[submission.deviceId] += 1;
    }
    let cpacChal = challenges.filter((challenge) => { return challenge.challengeId === submission.challengeId })[0];
    submissionsMap[submission.challengeId] = submission;
    switch (submission.status) {
      case CHALLENGE_ANSWER_STATUS.IN_REVIEW:
        statuses.inReview += 1;
        break;
      case CHALLENGE_ANSWER_STATUS.APPROVED:
        statuses.approved += 1;
        if (cpacChal && cpacChal.packagePoints) {
          pointsEarned += cpacChal.packagePoints;
        }
        break;
      case CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT:
      case CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE:
        statuses.declined += 1;
        break;
    }
  })

  challenges.forEach((packageChallenge) => {
    if (!submissionsMap[packageChallenge.challengeId]) {
      remainingChallenges.push(packageChallenge);
    }
  })

  statuses.toDo = remainingChallenges.length;

  if (pointsEarned !== null) {
    statuses.pointsEarned = pointsEarned;
  }

  return statuses;
}

export const GetStatusIcon = (status) => {
  let icon = null;
  switch (status) {
    case CHALLENGE_ANSWER_STATUS.IN_REVIEW:
      icon = IMAGE.REVIEW_ICON.default;
      break;
    case CHALLENGE_ANSWER_STATUS.APPROVED:
      icon = IMAGE.YES_ICON.default;
      break;
    case CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT:
    case CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE:
      icon = IMAGE.NO_ICON.default;
      break;
    default:
      icon = IMAGE.TO_DO_ICON;
      break;
  }

  return icon;
}

export const GetSubmissionsByChallengeIDMap = (challengePackageAccess) => {
  const submissions = challengePackageAccess?.submittedChallengeAnswers;
  let submissionsMap = {};

  submissions?.forEach((submission) => {
    submissionsMap[submission?.challengeId] = submission;
  })

  return submissionsMap;
}