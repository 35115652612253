// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Form,
  Button,
  Col
} from "reactstrap";
import { CHALLENGE_TYPE_DETAILS, COLOR } from "../../constants";
import { GetFilterNameFromNumber } from "../../helpers/Filters";
import { SanitizeInternalString } from "../../helpers/Strings";
import ChallengeTypeWithIcon from "./ChallengeTypeWithIcon";
import './ChallengeType.css';

const ChallengeTypeSelectionRow = (props) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const details = CHALLENGE_TYPE_DETAILS[GetFilterNameFromNumber(props.challengeType)]
  const isRequired = props.isRequired;
  const isSelected = props.isSelected;
  return (
    <Col
      className="challengeTypeSelectionRowRoot">
      <div className="pl-2 pr-2 challengeTypeSelectionRowMain"
        style={
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: props.isRequired ? COLOR.ROAMLI_NAVY_HEX : props.isSelected ? "#11cdef" : "transparent",
            color: isSelected || isRequired ? "white" : ""
          }
        }>
        <div
          style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center' }}
          onClick={() => { props.onSelectHandler(props.challengeType, !props.isSelected) }}
        >
          <ChallengeTypeWithIcon challengeType={props.challengeType} isSelected={isSelected} isRequired={isRequired} />
        </div>
        <div
          className="challengeTypeSelectionRowInfoCircleContainer"
          onClick={() => { setDetailsOpen(!detailsOpen) }}
          style={{ height: 50, width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="fa fa-info-circle challengeTypeSelectionRowInfoCircle" style={isSelected || isRequired ? { color: "white" } : {}} />
        </div>
      </div>
      {
        detailsOpen ?
          <ul className="challengeTypeSelectionRowDetails">
            <li className="challengeTypeSelectionRowDetailsText">{details.details}</li>
          </ul>
          : null
      }
    </Col>
  );
}

ChallengeTypeSelectionRow.propTypes = {
  challengeType: PropTypes.number,
  isSelected: PropTypes.bool,
  isRequired: PropTypes.bool,
  isPrimary: PropTypes.bool,
  onSelectHandler: PropTypes.func
};

export default ChallengeTypeSelectionRow;
