import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Button
} from "reactstrap";
import "./ViewPackageDetailModal.css";
import ViewPackageYouTubeVideo from './ViewPackageYouTubeVideo';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import { IsChallengeSubmittedInPackage } from 'helpers/ChallengePackage';
import { IsChallengeTextAnswer } from 'helpers/Challenge';
import { useAuth0 } from '@auth0/auth0-react';
import { COLOR } from 'constants/color';
import { GetSubmissionForChallenge } from 'helpers/ChallengePackage';
import { CHALLENGE_ANSWER_STATUS } from 'constants/challengeAnswerStatus';
import useBreakpoints from 'hooks/Responsive';
import { IsValidTime } from 'helpers/ChallengePackage';

const ViewPackageDetailModal = (props) => {
  const { loginWithPopup, isAuthenticated } = useAuth0();
  const { challenge, challenges } = props;
  const currentChallengeIndex = challenge?.order ?? -1;
  const { challengePackageAccess, challengePackage } = React.useContext(ViewPackageContext)
  const { isTabletOrMobile } = useBreakpoints();
  const isValidForSubmission = IsValidTime(challengePackage);

  const createLinks = () => {
    const links = [];
    challenge?.challenge?.externalLinks?.forEach((link) => {
      links.push(
        <div className={"viewPackageDetailModalBody linkRow"}>
          <p className={"viewPackageDetailModalBody linkLabel"}>{`${link.label}: `}</p>
          <a href={link.url} target="_blank" className={"viewPackageDetailModalBody link"}>
            <p className={"viewPackageDetailModalBody linkLabel"}>{link.url}</p>
          </a>
        </div>
      )
    })
    return (
      <div className={"viewPackageDetailModalBody linkContainer"}>
        {links}
      </div>
    );
  }

  const isSubmitted = React.useMemo(() => {
    return IsChallengeSubmittedInPackage(challenge?.challenge, challengePackageAccess)
  }, [challenge, challengePackageAccess])

  const getSubmissionButtonText = () => {
    if (isSubmitted) return "Submitted!";
    if (IsChallengeTextAnswer(challenge?.challenge)) return "Submit Answer";
    return "Submit Photo";
  }

  const GetStatusText = () => {
    const submission = GetSubmissionForChallenge(challenge.challenge, challengePackageAccess);
    if (!submission) return null;

    let overlayText = { color: COLOR.ROAMLI_YELLOW_HEX, text: "Submitted!" };
    if (submission.status === CHALLENGE_ANSWER_STATUS.IN_REVIEW) {
      overlayText = { color: COLOR.ROAMLI_PURPLE_HEX, text: "In Review" };
    } else if (submission.status === CHALLENGE_ANSWER_STATUS.APPROVED) {
      overlayText = { color: COLOR.SUCCESS_GREEN_HEX, text: "Correct!" };
    } else if (submission.status === CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT) {
      overlayText = { color: COLOR.ALERT_RED_HEX, text: "Declined" };
    } else if (submission.status === CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE) {
      overlayText = { color: COLOR.ALERT_RED_HEX, text: "Declined" };
    }
    return overlayText;
  }

  const GetSubmissionContent = () => {
    if (!isSubmitted) {
      return isValidForSubmission ? <Button
        className="my-2"
        style={{ marginRight: 0 }}
        color="warning"
        type="button"
        disabled={isSubmitted}
        onClick={props.openSubmissionModalHandler}>
        {getSubmissionButtonText()}
      </Button> : null;
    }

    return <div style={
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: "5px 15px",
        marginRight: "10px",
        borderWidth: 2,
        borderRadius: 5,
        borderColor: GetStatusText()?.color,
        fontWeight: 600,
        fontSize: 18,
        color: GetStatusText()?.color,
        borderStyle: "solid",
        transform: "rotate(-15deg)"
      }
    }>
      {GetStatusText()?.text}
    </div>
  }
  
  return (
    <Modal
      show={props.show}
      onHide={() => { props.showHandler(false) }}
      dialogClassName="viewPackageDetailModalRoot"
      aria-labelledby="example-custom-modal-styling-title"
      fade={"true"}
    >
      <Modal.Body className={"viewPackageDetailModalBody"}>
        <div className="viewPackageDetailModalBannerContainer">
          <img src={challenge?.challenge?.picChallengeUrl} className="viewPackageDetailModalBanner" />
        </div>
        <Button size={"sm"} className={"viewPackageDetailModalCloseButton"} onClick={() => { props.showHandler(false) }}>
          <span className="btn-inner--icon">
            <i className="fas fa-times" />
          </span>
        </Button>
        <div className={"viewPackageDetailModalBody content"}>
          <p className={"viewPackageDetailModalBody title"}>{challenge?.title}</p>
          {
            challenge?.challenge?.address?.displayedAddress &&
            <p className={"viewPackageDetailModalBody address"}>{challenge?.challenge?.address?.displayedAddress}</p>
          }
          <p className={"viewPackageDetailModalBody description"}>
            {challenge?.challenge?.description}
          </p>
          {
            challenge?.extraDescription && <p className={"viewPackageDetailModalBody description"}>
              {challenge?.extraDescription}
            </p>
          }
          {createLinks()}
          {
            challenge?.overrideYouTubeLink || challenge?.challenge?.youTubeLink ? <div style={{ width: '100%', height: 1, backgroundColor: "#00000030", marginBottom: 15 }} /> : null
          }
          <ViewPackageYouTubeVideo youTubeLink={challenge?.overrideYouTubeLink ?? challenge?.challenge?.youTubeLink} softFont />
        </div>
      </Modal.Body>
      <Modal.Footer className={"viewPackageDetailModalFooter"}>
        {
          challenges.length > 1 ?
            <div className={"paginationContainer"}>
              <Button
                className="previousButton"
                color="info"
                type="button"
                disabled={currentChallengeIndex === 0}
                onClick={() => { props.activeChallengeHandler(challenges[currentChallengeIndex - 1]) }}>
                <i className="buttonIcon fas fa-angle-left" />
              </Button>
              <span style={{ margin: '0 10px', fontSize: 14 }}>{`${currentChallengeIndex + 1} of ${challenges.length}`}</span>
              <Button
                className="nextButton"
                color="info"
                type="button"
                disabled={currentChallengeIndex === challenges?.length - 1}
                onClick={() => { props.activeChallengeHandler(challenges[currentChallengeIndex + 1]) }}>
                <i className="buttonIcon fas fa-angle-right" />
              </Button>
            </div>
            : <div />
        }
        <div style={
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }>
          {isAuthenticated ? GetSubmissionContent() :
            (isValidForSubmission && <Button
              className="my-2"
              style={{ marginRight: 0 }}
              color="success"
              type="button"
              onClick={loginWithPopup}>
              Log In to Submit
            </Button>)
          }
          {!isTabletOrMobile && <Button
            className="my-2"
            style={{ marginRight: 0 }}
            color="info"
            type="button"
            onClick={() => { props.showHandler(false) }}>
            Close
          </Button>}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewPackageDetailModal;