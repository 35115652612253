import React from 'react';
import useBreakpoints from '../../hooks/Responsive';
import {
  Button,
  Row
} from "reactstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import "./HorizontalMapScroller.css";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { COLOR } from 'constants/color';
import { PATH, LINK } from 'constants/link';

export const HorizontalMapScrollerCard = ({ challengePackage }) => {
  const { lookUpCode } = useParams();
  const navigateToNewEvent = () => {
    const newCode = challengePackage.lookUpCode;
    const newPath = `${LINK.VIEW_BASE}/${newCode}`;
    window.location.href = newPath;
  }

  if (lookUpCode === challengePackage.lookUpCode) {
    return null;
  }

  return (
    <div className={"horizontalMapScrollerCardRoot"} onClick={navigateToNewEvent}>
      <div className="horizontalMapScrollerCardOverlay" />
      <img src={challengePackage.anchorImage} className="horizontalMapScrollerCardBackground" />
      <div className="horizontalMapScrollerCardTitleInner">
        <h1 className="display-4 mb-1 horizontalMapScrollerCardTitle" >
          {challengePackage?.title?.toUpperCase()}
        </h1>
        <h3 style={{ color: "white" }}>
          {challengePackage.lookUpCode}
        </h3>
      </div>
    </div>
  );
}