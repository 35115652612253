import React from "react";
import { Button } from 'reactstrap';

export default function PrimaryButton(props) {
  return (
    <Button
      aria-controls="simple-menu"
      aria-haspopup="true"
      color="primary"
      onClick={props.onClickHandler}
      style={{ display: 'flex', alignItems: 'center' }}>
      <i className={props.icon + " mr-2"} />
      {props.label}
    </Button>
  );
}