/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { PATH } from "constants/link";

function AdminFooter() {
  const location = useLocation();
  if (location.pathname.indexOf(PATH.PACKAGE_DETAILS) > -1) return null;
  if (location.pathname.indexOf(PATH.MAIN_CREATE_PACKAGE) > -1) return null;
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.roamli.com"
                  target="_blank"
                >
                  Roamli
                </a>
              </div>
            </Col>
            <Col lg="6">
              {/* <Nav className="nav-footer justify-content-center justify-content-lg-end">
                <NavItem>
                  <NavLink
                    href="https://www.creative-tim.com?ref=adpr-admin-footer"
                    target="_blank"
                  >
                    Creative Tim
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://www.creative-tim.com/presentation?ref=adpr-admin-footer"
                    target="_blank"
                  >
                    About Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="http://blog.creative-tim.com?ref=adpr-admin-footer"
                    target="_blank"
                  >
                    Blog
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://www.creative-tim.com/license?ref=adpr-admin-footer"
                    target="_blank"
                  >
                    License
                  </NavLink>
                </NavItem>
              </Nav> */}
              <Nav className="nav-footer justify-content-center justify-content-lg-end" >
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://www.facebook.com/RoamliApp"
                    id="tooltip601201423"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square" />
                    <span className="nav-link-inner--text d-lg-none">
                      Facebook
                  </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip601201423">
                    Like us on Facebook
                </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="http://www.instagram.com/roamli/"
                    id="tooltip871243015"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                    <span className="nav-link-inner--text d-lg-none">
                      Instagram
                  </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip871243015">
                    Follow us on Instagram
                </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="http://www.twitter.com/Roamli"
                    id="tooltip366258619"
                    target="_blank"
                  >
                    <i className="fab fa-twitter-square" />
                    <span className="nav-link-inner--text d-lg-none">
                      Twitter
                  </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip366258619">
                    Follow us on Twitter
                </UncontrolledTooltip>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default AdminFooter;
