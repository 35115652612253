/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Input,
  Col,
  Button
} from "reactstrap";

const ChallengeImageField = (props) => {

  return (
    <div className="imagePreviewRoot">
      <div className="imagePreviewContainer mb-3">
        <img src={props.source} className="imagePreview" />
      </div>
      <div className="form-row mb-4" style={{ alignItems: 'center'}}>
        {
          props.isResetDisabled ?
            null
            : <Button
              className="btn-icon-only"
              color="primary"
              type="button"
              disabled={props.isResetDisabled}
              style={{ height: 46, width: 46 }}
              onClick={props.resetHandler}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-undo-alt" />
              </span>
            </Button>
        }
        <input
          type="file"
          onChange={(event) => (props.onChangeImageHandler(event))}
          accept="image/png, image/jpeg"
          className="filetype" />
      </div>
    </div>
  );
}

ChallengeImageField.propTypes = {
  source: PropTypes.string,
  onImageChangeHandler: PropTypes.func,
  resetHandler: PropTypes.func,
  isResetDisabled: PropTypes.bool,
};

export default ChallengeImageField;
