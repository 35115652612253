/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from "reactstrap";
import ChallengeTableRow from "./ChallengeTableRow";
import Download from '../../Utility/Download';
import { GetFilterNameFromNumber } from '../../../helpers/Filters';
import { SanitizeInternalString } from '../../../helpers/Strings';
import { GetCurrentPointValue } from '../../../helpers/Challenge';
import { PaginationControls } from '../../Pagination/PaginationControls';
import { TABLE_PAGE_COUNT } from '../../../constants';

const PAGE_COUNT = TABLE_PAGE_COUNT;

const CHALLENGE_SORT_TYPE = {
  NONE: "none",
  ORDER: "order",
  TITLE: "title",
  SUBMISSIONS: "submissions",
  POINTS: "points",
  TYPE: "type",
  ADDRESS: "address",
  PERSONAL: "personal",
  LOCATION_BASED: "locationBased",
  CREATED_ON: "createdOn",
  MODIFIED_ON: "modifiedOn",
}

const ChallengeTable = (props) => {
  const firstListRef = React.useRef(null);
  const [pagination, setPagination] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState(-1);
  const [sortType, setSortType] = React.useState(CHALLENGE_SORT_TYPE.CREATED_ON);

  const challengesData = props.challenges;

  const createRows = (challenges) => {
    let rows = [];
    challenges.forEach((challenge) => {
      let points = GetCurrentPointValue(challenge);
      rows.push(
        <ChallengeTableRow
          key={challenge.id}
          challenge={challenge}
          title={challenge.title}
          image={challenge.picChallengeUrl}
          points={points}
          type={challenge.challengeType}
          address={challenge.address !== null ? challenge.address.displayedAddress : ""}
          isPersonal={challenge.isPersonal}
          noLocation={challenge.noLocation}
          createdOn={challenge.createdOn}
          modifiedOn={challenge.modifiedOn}
        />)
    })

    return rows;
  }

  const getSearchResults = (challenges) => {
    if (props.searchQuery === "" || props.searchQuery === null) {
      return challenges;
    }

    const filtered = challenges.filter(
      (challenge) => {
        const type = SanitizeInternalString(GetFilterNameFromNumber(challenge.challengeType))
        return (challenge.title !== null && challenge.title.toLowerCase().includes(props.searchQuery.toLowerCase()))
          || (challenge.address?.displayedAddress !== null && challenge.address?.displayedAddress.toLowerCase().includes(props.searchQuery.toLowerCase()))
          || (type !== null && type.toLowerCase().includes(props.searchQuery.toLowerCase()))
      }
    )

    return filtered
  }

  const getPaginatedData = (noPagination) => {
    let challenges = props.challenges;
    if (sortType === CHALLENGE_SORT_TYPE.TITLE) {
      challenges = sortByTitle();
    } else if (sortType === CHALLENGE_SORT_TYPE.POINTS) {
      challenges = sortByPoints();
    } else if (sortType === CHALLENGE_SORT_TYPE.TYPE) {
      challenges = sortByType();
    } else if (sortType === CHALLENGE_SORT_TYPE.ADDRESS) {
      challenges = sortByAddress();
    } else if (sortType === CHALLENGE_SORT_TYPE.PERSONAL) {
      challenges = sortByIsPersonal();
    } else if (sortType === CHALLENGE_SORT_TYPE.LOCATION_BASED) {
      challenges = sortByIsLocationBased();
    } else if (sortType === CHALLENGE_SORT_TYPE.CREATED_ON) {
      challenges = sortByCreatedOn();
    } else if (sortType === CHALLENGE_SORT_TYPE.MODIFIED_ON) {
      challenges = sortByModifiedOn();
    }

    challenges = getSearchResults(challenges);

    if (noPagination) {
      return challenges;
    }

    return challenges.slice(PAGE_COUNT * pagination, PAGE_COUNT * pagination + PAGE_COUNT);
  }

  const sortByTitle = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let titleA = a.title == null ? "" : a.title.toUpperCase();
        let titleB = b.title == null ? "" : b.title.toUpperCase();
        return (titleA < titleB ? -1 : (titleA > titleB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByPoints = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = GetCurrentPointValue(a);
        let compareB = GetCurrentPointValue(b);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByType = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challengeType;
        let compareB = b.challengeType;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByAddress = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.address ? a.address.displayedAddress : "";
        let compareB = b.address ? b.address.displayedAddress : "";
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsPersonal = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.isPersonal;
        let compareB = b.isPersonal;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsLocationBased = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = !a.noLocation;
        let compareB = !b.noLocation;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByCreatedOn = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = new Date(a.createdOn);
        let compareB = new Date(b.createdOn);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByModifiedOn = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = new Date(a.modifiedOn);
        let compareB = new Date(b.modifiedOn);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const paginatedData = getPaginatedData();

  const getDownloadButton = (challenges) => {
    let downloadData = [];
    challenges.forEach(
      (challenge) => {
        let points = GetCurrentPointValue(challenge);
        let dataObject = {};

        dataObject.Title = challenge.title;
        dataObject.Points = points;
        dataObject.Type = SanitizeInternalString(GetFilterNameFromNumber(challenge.challengeType));
        dataObject.Address = challenge.address !== null ? challenge.address.displayedAddress : "";
        dataObject.IsPersonal = challenge.isPersonal;
        dataObject.NoLocation = challenge.noLocation;
        dataObject.CreatedOn = challenge.createdOn;

        downloadData.push(dataObject);
      }
    )

    return <Download
      filename={"Challenges"}
      data={downloadData} />;
  }

  const onClickHandler = (newSortType) => {
    setSortDirection(newSortType === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1);
    setSortType(newSortType);
  }

  // Reset pagination when searchquery gets updated 
  React.useEffect(() => {
    setPagination(0);
    props.setDownloadButtonHandler(getDownloadButton(getPaginatedData(true)));
  }, [sortType, sortDirection, props.searchQuery])

  return (
    <>
      <div className="table-responsive" ref={firstListRef}>
        <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              {/* <th data-sort="order" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ORDER) }}>Order</th> */}
              <th data-sort="title" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TITLE) }}>Title</th>
              {/* <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.SUBMISSIONS) }}># of Submissions</th> */}
              <th data-sort="points" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.POINTS) }}>Point Value</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TYPE) }}>Type</th>
              <th data-sort="address" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ADDRESS) }}>Address</th>
              <th data-sort="personal" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.PERSONAL) }}>Personal</th>
              <th data-sort="locationBased" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.LOCATION_BASED) }}>Location Based</th>
              <th data-sort="modifiedOn" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(CHALLENGE_SORT_TYPE.MODIFIED_ON === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(CHALLENGE_SORT_TYPE.MODIFIED_ON); }}>Last Updated</th>
              <th data-sort="createdOn" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(CHALLENGE_SORT_TYPE.CREATED_ON === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(CHALLENGE_SORT_TYPE.CREATED_ON); }}>Created On</th>
              {/* <th scope="col">Actions</th> */}
            </tr>
          </thead>
          <tbody className="list">
            {createRows(paginatedData)}
          </tbody>
        </Table>
      </div >
      <PaginationControls
        dataSet={getSearchResults(challengesData)}
        currentPage={pagination}
        setPaginationHandler={setPagination} />
    </>
  );
}

export default ChallengeTable;
