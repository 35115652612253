import * as React from 'react';
import Masonry from 'react-masonry-component';
import "./MasonryGallery.css";

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const IMAGE_SIZE = {
  LARGE: "large",
  SMALL: "small",
}

const MasonryGallery = (props) => {
  const childElements = props.urls.map((url) => {
    return (
      <div
        className="masonry-image-root"
        style={
          {
            width: props.imageSize === IMAGE_SIZE.LARGE ? 350 : 200,
            height: props.imageSize === IMAGE_SIZE.LARGE ? 350 : 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 2.5,
            position: 'relative',
            overflow: 'hidden',
            borderRadius: 15
          }
        }>
        <img src={url.url} style={{ minHeight: '100%', maxHeight: "130%", objectFit: 'cover' }} />
        {
          url.hoverText ?
            <div className="masonry-image-overlay">
              {
                props.imageSize === IMAGE_SIZE.LARGE ?
                  <h3 className="masonry-image-overlay-text">{url.hoverText}</h3>
                  : <h5 className="masonry-image-overlay-text">{url.hoverText}</h5>
              }
            </div>
            : null
        }
      </div>

    );
  });

  return (
    <Masonry
      className={'my-gallery-class'} // default ''
      elementType={'div'} // default 'div'
      options={masonryOptions} // default {}
      disableImagesLoaded={false} // default false
      updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
      imagesLoadedOptions={imagesLoadedOptions} // default {}
    >
      {
        props.urls.length !== 0 ?
          childElements
          : <h3>No Images to Display</h3>
      }
    </Masonry>
  );
}

export default MasonryGallery;