/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from "reactstrap";
import ChallengePackageChallengeTableRow from "./ChallengePackageChallengeTableRow";
import Download from '../../Utility/Download';
import { GetFilterNameFromNumber } from '../../../helpers/Filters';
import { SanitizeInternalString } from '../../../helpers/Strings';
import { PaginationControls } from '../../Pagination/PaginationControls';
import {TABLE_PAGE_COUNT} from '../../../constants';

const PAGE_COUNT = TABLE_PAGE_COUNT;

const CHALLENGE_SORT_TYPE = {
  NONE: "none",
  ORDER: "order",
  TITLE: "title",
  SUBMISSIONS: "submissions",
  POINTS: "points",
  TYPE: "type",
  ADDRESS: "address",
  PERSONAL: "personal",
  LOCATION_BASED: "locationBased",
}

const ChallengePackageChallengeTable = (props) => {
  const firstListRef = React.useRef(null);
  const [pagination, setPagination] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState(1);
  const [sortType, setSortType] = React.useState(CHALLENGE_SORT_TYPE.ORDER);

  const challengesData = props.challengePackage.challenges;

  const packageAccesses = props.packageAccesses;

  let challengeSubmissions = {};

  packageAccesses.forEach((pa) => {
    let access = pa.packageAccess;
    let userInfo = pa.userInfo;

    access.submittedChallengeAnswers.forEach((ca) => {
      ca.userInfo = userInfo;
      if (challengeSubmissions[ca.challengeId]) {
        let newList = challengeSubmissions[ca.challengeId];
        newList.push(ca);
        challengeSubmissions[ca.challengeId] = newList;
      } else {
        challengeSubmissions[ca.challengeId] = [ca];
      }
    })
  })

  const createRows = (packageChallenges) => {
    let rows = [];
    packageChallenges.forEach((packageChallenge) => {
      let challenge = packageChallenge.challenge;
      let points = packageChallenge.packagePoints ? packageChallenge.packagePoints : 10;
      let submissions = challengeSubmissions[packageChallenge.challengeId] ? challengeSubmissions[packageChallenge.challengeId] : [];
      rows.push(
        <ChallengePackageChallengeTableRow
          key={packageChallenge?.challengeId}
          order={packageChallenge.order + 1}
          title={packageChallenge.title}
          image={challenge?.picChallengeUrl}
          points={points}
          submissions={submissions}
          type={packageChallenge.challengeType}
          address={challenge?.address ? challenge.address.displayedAddress : ""}
          isPersonal={challenge?.isPersonal}
          noLocation={challenge?.noLocation}
          isTextAnswer={challenge?.textAnswer && challenge?.textAnswer.length > 0}
        />)
    })

    return rows;
  }

  const getSearchResults = (challenges) => {
    if (props.searchQuery === "" || props.searchQuery === null) {
      return challenges;
    }

    const filtered = challenges.filter(
      (cp) => {
        return (cp.title !== null && cp.title.toLowerCase().includes(props.searchQuery.toLowerCase()))
      }
    )

    return filtered
  }

  const getPaginatedData = (noPagination) => {
    let challenges = props.challengePackage.challenges;
    if (sortType === CHALLENGE_SORT_TYPE.ORDER) {
      challenges = sortByOrder();
    } else if (sortType === CHALLENGE_SORT_TYPE.TITLE) {
      challenges = sortByTitle();
    } else if (sortType === CHALLENGE_SORT_TYPE.POINTS) {
      challenges = sortByPoints();
    } else if (sortType === CHALLENGE_SORT_TYPE.TYPE) {
      challenges = sortByType();
    } else if (sortType === CHALLENGE_SORT_TYPE.ADDRESS) {
      challenges = sortByAddress();
    } else if (sortType === CHALLENGE_SORT_TYPE.PERSONAL) {
      challenges = sortByIsPersonal();
    } else if (sortType === CHALLENGE_SORT_TYPE.LOCATION_BASED) {
      challenges = sortByIsLocationBased();
    } else if (sortType === CHALLENGE_SORT_TYPE.SUBMISSIONS) {
      challenges = sortByNumberOfSubmissions();
    }

    challenges = getSearchResults(challenges);

    if (noPagination) {
      return challenges;
    }

    return challenges.slice(PAGE_COUNT * pagination, PAGE_COUNT * pagination + PAGE_COUNT);
  }

  const sortByTitle = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let titleA = a.title == null ? "" : a.title.toUpperCase();
        let titleB = b.title == null ? "" : b.title.toUpperCase();
        return (titleA < titleB ? -1 : (titleA > titleB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByNumberOfSubmissions = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = challengeSubmissions[a.challengeId] ? challengeSubmissions[a.challengeId].length : 0;
        let compareB = challengeSubmissions[b.challengeId] ? challengeSubmissions[b.challengeId].length : 0;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByOrder = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.order;
        let compareB = b.order;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByPoints = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.packagePoints !== null ? a.packagePoints : 10;
        let compareB = b.packagePoints !== null ? b.packagePoints : 10;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByType = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challengeType;
        let compareB = b.challengeType;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByAddress = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challenge.address ? a.challenge.address.displayedAddress : "";
        let compareB = b.challenge.address ? b.challenge.address.displayedAddress : "";
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsPersonal = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challenge.isPersonal;
        let compareB = b.challenge.isPersonal;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsLocationBased = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = !a.challenge.noLocation;
        let compareB = !b.challenge.noLocation;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const paginatedData = getPaginatedData();

  const getDownloadButton = (packageChallenges) => {
    let downloadData = [];
    packageChallenges.forEach(
      (packageChallenge) => {
        let challenge = packageChallenge.challenge;
        let points = packageChallenge.packagePoints ? packageChallenge.packagePoints : 10;
        let submissions = challengeSubmissions[packageChallenge.challengeId] ? challengeSubmissions[packageChallenge.challengeId] : [];
        let dataObject = {};

        dataObject.Order = packageChallenge.order + 1;
        dataObject.Title = packageChallenge.title;
        dataObject.Points = points;
        dataObject.TotalSubmissions = submissions.length;
        dataObject.Type = SanitizeInternalString(GetFilterNameFromNumber(packageChallenge.challengeType));
        dataObject.Address = challenge?.address ? challenge?.address?.displayedAddress : "";
        dataObject.IsPersonal = challenge?.isPersonal;
        dataObject.NoLocation = challenge?.noLocation;

        downloadData.push(dataObject);
      }
    )

    return <Download
      filename={props.challengePackage.title + "-Challenges"}
      data={downloadData} />;
  }

  React.useEffect(() => {
    setPagination(0);
    props.setDownloadButtonHandler(getDownloadButton(getPaginatedData(true)));
  }, [sortType, sortDirection, props.searchQuery])

  const onClickHandler = (newSortType) => {
    setSortDirection(newSortType === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1);
    setSortType(newSortType);
  }

  return (
    <>
      <div className="table-responsive" ref={firstListRef}>
        <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              <th data-sort="order" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ORDER) }}>#</th>
              <th data-sort="title" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TITLE) }}>Title</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.SUBMISSIONS) }}># of Submissions</th>
              <th data-sort="points" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.POINTS) }}>Point Value</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TYPE) }}>Type</th>
              <th data-sort="address" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ADDRESS) }}>Address</th>
              <th data-sort="personal" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.PERSONAL) }}>Personal</th>
              <th data-sort="locationBased" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.LOCATION_BASED) }}>Location Based</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody className="list">
            {createRows(paginatedData)}
          </tbody>
        </Table>
      </div >
      <PaginationControls
        dataSet={getSearchResults(challengesData)}
        currentPage={pagination}
        setPaginationHandler={setPagination} />
    </>
  );
}

export default ChallengePackageChallengeTable;
