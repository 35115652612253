import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'constants/color';
import { Spinner } from 'react-bootstrap';

export const NoUserSubmissionsCTA = (props) => {
  return (
    <NoAnswersContainer>
      <NoAnswersText>{props.cta}</NoAnswersText>
      {!props.loading && (
        <StyledImg src={require('assets/roamli-logo.png').default} />
      )}
      {props.loading && <Spinner />}
    </NoAnswersContainer>
  );
};

const NoAnswersContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: "100%",
});

const StyledImg = styled.img({
  height: 100,
})

const NoAnswersText = styled.p({
  fontSize: `24px`,
  margin: 30,
  textAlign: 'center'
});
