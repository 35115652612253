import { COLOR, CHALLENGE_TYPE, CHALLENGE_TYPE_ICON } from '../constants';

export function GetIconColorPackage(challengeType, timeSubmittable = true) {
	let name;
	let color;
	let backgroundColor;
	switch (challengeType) {
		case CHALLENGE_TYPE.ARCHITECTURE:
			name = CHALLENGE_TYPE_ICON.ARCHITECTURE;
			color = COLOR.CHAL_ARCHITECTURE_COLOR;
			backgroundColor = COLOR.CHAL_ARCHITECTURE_BG;
			break;
		case CHALLENGE_TYPE.BUILDING:
			name = CHALLENGE_TYPE_ICON.BUILDING;
			color = COLOR.CHAL_BUILDING_COLOR;
			backgroundColor = COLOR.CHAL_BUILDING_BG;
			break;
		case CHALLENGE_TYPE.HISTORIC:
			name = CHALLENGE_TYPE_ICON.HISTORIC;
			color = COLOR.CHAL_HISTORIC_COLOR;
			backgroundColor = COLOR.CHAL_HISTORIC_BG;
			break;
		case CHALLENGE_TYPE.MODERN:
			name = CHALLENGE_TYPE_ICON.MODERN;
			color = COLOR.CHAL_MODERN_COLOR;
			backgroundColor = COLOR.CHAL_MODERN_BG;
			break;
		case CHALLENGE_TYPE.HOUSE:
			name = CHALLENGE_TYPE_ICON.HOUSE;
			color = COLOR.CHAL_HOUSE_COLOR;
			backgroundColor = COLOR.CHAL_HOUSE_BG;
			break;
		case CHALLENGE_TYPE.MURAL:
			name = CHALLENGE_TYPE_ICON.MURAL;
			color = COLOR.CHAL_MURAL_COLOR;
			backgroundColor = COLOR.CHAL_MURAL_BG;
			break;
		case CHALLENGE_TYPE.STREET_ART:
			name = CHALLENGE_TYPE_ICON.STREET_ART;
			color = COLOR.CHAL_STREET_ART_COLOR;
			backgroundColor = COLOR.CHAL_STREET_ART_BG;
			break;
		case CHALLENGE_TYPE.STATUE:
			name = CHALLENGE_TYPE_ICON.STATUE;
			color = COLOR.CHAL_STATUE_COLOR;
			backgroundColor = COLOR.CHAL_STATUE_BG;
			break;
		case CHALLENGE_TYPE.FOUNTAIN:
			name = CHALLENGE_TYPE_ICON.FOUNTAIN;
			color = COLOR.CHAL_FOUNTAIN_COLOR;
			backgroundColor = COLOR.CHAL_FOUNTAIN_BG;
			break;
		case CHALLENGE_TYPE.LANDMARK:
			name = CHALLENGE_TYPE_ICON.LANDMARK;
			color = COLOR.CHAL_LANDMARK_COLOR;
			backgroundColor = COLOR.CHAL_LANDMARK_BG;
			break;
		case CHALLENGE_TYPE.ART:
			name = CHALLENGE_TYPE_ICON.ART;
			color = COLOR.CHAL_ART_COLOR;
			backgroundColor = COLOR.CHAL_ART_BG;
			break;
		case CHALLENGE_TYPE.MONUMENT:
			name = CHALLENGE_TYPE_ICON.MONUMENT;
			color = COLOR.CHAL_MONUMENT_COLOR;
			backgroundColor = COLOR.CHAL_MONUMENT_BG;
			break;
		case CHALLENGE_TYPE.NATURE:
			name = CHALLENGE_TYPE_ICON.NATURE;
			color = COLOR.CHAL_NATURE_COLOR;
			backgroundColor = COLOR.CHAL_NATURE_BG;
			break;
		case CHALLENGE_TYPE.VIEW:
			name = CHALLENGE_TYPE_ICON.VIEW;
			color = COLOR.CHAL_VIEW_COLOR;
			backgroundColor = COLOR.CHAL_VIEW_BG;
			break;
		case CHALLENGE_TYPE.WATERFRONT:
			name = CHALLENGE_TYPE_ICON.WATERFRONT;
			color = COLOR.CHAL_WATERFRONT_COLOR;
			backgroundColor = COLOR.CHAL_WATERFRONT_BG;
			break;
		case CHALLENGE_TYPE.TRAIL:
			name = CHALLENGE_TYPE_ICON.TRAIL;
			color = COLOR.CHAL_TRAIL_COLOR;
			backgroundColor = COLOR.CHAL_TRAIL_BG;
			break;
		case CHALLENGE_TYPE.WATERFALL:
			name = CHALLENGE_TYPE_ICON.WATERFALL;
			color = COLOR.CHAL_WATERFALL_COLOR;
			backgroundColor = COLOR.CHAL_WATERFALL_BG;
			break;
		case CHALLENGE_TYPE.BEACH:
			name = CHALLENGE_TYPE_ICON.BEACH;
			color = COLOR.CHAL_BEACH_COLOR;
			backgroundColor = COLOR.CHAL_BEACH_BG;
			break;
		case CHALLENGE_TYPE.PARK:
			name = CHALLENGE_TYPE_ICON.PARK;
			color = COLOR.CHAL_PARK_COLOR;
			backgroundColor = COLOR.CHAL_PARK_BG;
			break;
		case CHALLENGE_TYPE.RIVER:
			name = CHALLENGE_TYPE_ICON.RIVER;
			color = COLOR.CHAL_RIVER_COLOR;
			backgroundColor = COLOR.CHAL_RIVER_BG;
			break;
		case CHALLENGE_TYPE.ACTIVE:
			name = CHALLENGE_TYPE_ICON.ACTIVE;
			color = COLOR.CHAL_ACTIVE_COLOR;
			backgroundColor = COLOR.CHAL_ACTIVE_BG;
			break;
		case CHALLENGE_TYPE.MUSEUM:
			name = CHALLENGE_TYPE_ICON.MUSEUM;
			color = COLOR.CHAL_MUSEUM_COLOR;
			backgroundColor = COLOR.CHAL_MUSEUM_BG;
			break;
		case CHALLENGE_TYPE.ZOO:
			name = CHALLENGE_TYPE_ICON.ZOO;
			color = COLOR.CHAL_ZOO_COLOR;
			backgroundColor = COLOR.CHAL_ZOO_BG;
			break;
		case CHALLENGE_TYPE.CONSERVATORY:
			name = CHALLENGE_TYPE_ICON.MUSEUM;
			color = COLOR.ROAMLI_NAVY_HEX;
			backgroundColor = COLOR.ROAMLI_YELLOW_HEX;
			break;
		case CHALLENGE_TYPE.BRIDGE:
			name = CHALLENGE_TYPE_ICON.BRIDGE;
			color = COLOR.CHAL_BRIDGE_COLOR;
			backgroundColor = COLOR.CHAL_BRIDGE_BG;
			break;
		case CHALLENGE_TYPE.MYSTERY:
			name = CHALLENGE_TYPE_ICON.MYSTERY;
			color = COLOR.CHAL_MYSTERY_COLOR;
			backgroundColor = COLOR.CHAL_MYSTERY_BG;
			break;
		case CHALLENGE_TYPE.NOOK:
			name = CHALLENGE_TYPE_ICON.NOOK;
			color = COLOR.CHAL_NOOK_COLOR;
			backgroundColor = COLOR.CHAL_NOOK_BG;
			break;
		case CHALLENGE_TYPE.STADIUM:
			name = CHALLENGE_TYPE_ICON.STADIUM;
			color = COLOR.CHAL_STADIUM_COLOR;
			backgroundColor = COLOR.CHAL_STADIUM_BG;
			break;
		case CHALLENGE_TYPE.FOOD:
			name = CHALLENGE_TYPE_ICON.FOOD;
			color = COLOR.CHAL_FOOD_COLOR;
			backgroundColor = COLOR.CHAL_FOOD_BG;
			break;
		case CHALLENGE_TYPE.MUSIC:
			name = CHALLENGE_TYPE_ICON.MUSIC;
			color = COLOR.CHAL_MUSIC_COLOR;
			backgroundColor = COLOR.CHAL_MUSIC_BG;
			break;
		case CHALLENGE_TYPE.THEATER:
			name = CHALLENGE_TYPE_ICON.THEATER;
			color = COLOR.CHAL_THEATER_COLOR;
			backgroundColor = COLOR.CHAL_THEATER_BG;
			break;
		default:
			name = CHALLENGE_TYPE_ICON.MYSTERY;
			color = COLOR.CHAL_MYSTERY_COLOR;
			backgroundColor = COLOR.CHAL_MYSTERY_BG;
			break;
	}

	// switch (color) {
	// 	case COLOR.ROAMLI_NAVY_HEX:
	// 		backgroundColor = COLOR.ROAMLI_YELLOW_HEX;
	// 		break;
	// 	case COLOR.ROAMLI_PURPLE_HEX:
	// 		backgroundColor = COLOR.ROAMLI_NAVY_HEX;
	// 		break;
	// 	case COLOR.ROAMLI_ORANGE_HEX:
	// 		backgroundColor = COLOR.ROAMLI_TEAL_HEX;
	// 		break;
	// 	case COLOR.ROAMLI_YELLOW_HEX:
	// 		backgroundColor = COLOR.ROAMLI_BLUE_HEX;
	// 		break;
	// }

	if (!timeSubmittable) {
		color = 'black';
		backgroundColor = 'gray';
	}

	return { name: name, color: color, backgroundColor: backgroundColor };
}