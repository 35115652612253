import React from 'react';
import useBreakpoints from '../../../hooks/Responsive';
import {
  Button,
  Col,
  Row
} from "reactstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import "./ViewPackageSidebar.css";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import ViewPackageImageHeader from './ViewPackageImageHeader';
import ViewChallengePackageList from './ViewPackageChallengeList';
import ViewPackageUserInfoRow from './ViewPackageUserInfoRow';
import ViewPackageDescription from './ViewPackageDescription';
import ViewPackageAppButtonSection from './ViewPackageAppButtonSection';
import { COLOR } from 'constants/color';
import { PATH } from 'constants/link';
import ViewPackageYouTubeVideo from './ViewPackageYouTubeVideo';
import { ListGroupItem } from 'react-bootstrap';

const ViewPackageSidebar = (
  {
    challengePackage,
    onSideMenuClickHandler,
    userData,
    updatePackageHandler
  }
) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const { isAuthenticated, loginWithPopup } = useAuth0();
  const { isTabletOrMobile } = useBreakpoints();

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  if (isTabletOrMobile) {
    return null;
  }

  const shouldShowChallenges = () => {
    return !challengePackage.startTime || new Date().getTime() >= new Date(challengePackage.startTime).getTime();
  }

  return (
    <div className={"viewPackageSidebarRoot"}>
      <ProSidebar
        collapsed={collapsed}
        collapsedWidth={60}
        transitionDuration={100}
        width={425}
      >
        <SidebarHeader style={{ backgroundColor: "white" }}>
          <div
            className="viewPackageSidebarCollapseSection"
            onClick={collapsed ? () => { setCollapsed(!collapsed) } : undefined}
            style={collapsed ? { cursor: "pointer" } : undefined}>
            {
              !collapsed ?
                isAuthenticated ?
                  <Menu iconShape="square">
                    <MenuItem
                      icon={<i className="fas fa-arrow-left" />}
                      onClick={() => { routeChange(PATH.MAIN) }}
                    >
                      My Dashboard
                    </MenuItem>
                  </Menu>
                  : <Row className="my-2 ml-2">
                    <Button
                      color="success"
                      type="button"
                      onClick={() => {
                        loginWithPopup()
                      }}>
                      Log In
                    </Button>
                    <Button
                      className="ml-1"
                      color="info"
                      type="button"
                      onClick={() => {
                        routeChange(PATH.HOME)
                      }
                      }>
                      Sign Up
                    </Button>
                  </Row>
                : null
            }
            {
              !collapsed &&
              <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                <img
                  className="navbar-brand-img"
                  style={{ height: 40, width: 40 }}
                  src={require("assets/roamli-logo.png").default}
                />
                <h1 className="mb-0 ml-1" style={{ color: COLOR.ROAMLI_NAVY_HEX, textTransform: 'capitalize' }}>Roamli</h1>
              </Row>
            }
            <div
              onClick={!collapsed ? () => { setCollapsed(!collapsed) } : undefined}
              className="sidebarHeaderCollapse">
              <div
                className={classnames("sidenav-toggler d-none d-xl-block", {
                  active: !collapsed,
                })}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line collapseIcon" />
                  <i className="sidenav-toggler-line collapseIcon" />
                  <i className="sidenav-toggler-line collapseIcon" />
                </div>
              </div>
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent
          style={{ backgroundColor: "white", position: "relative" }}>
          <div
            onClick={() => { setCollapsed(false) }}
            className={`viewPackageSidebarCollapseCover${collapsed ? " show" : ""}`} />
          <ViewPackageImageHeader
            challengePackage={challengePackage}
            showArrow={false} />
          <ViewPackageUserInfoRow
            challengePackage={challengePackage}
            userData={userData}
          />
          <ViewPackageDescription
            challengePackage={challengePackage}
            updatePackageHandler={updatePackageHandler}
          />
          {
            challengePackage?.youTubeLink && <ListGroupItem className="px-0 viewPackageUserRow" id="viewPackageUserRow">
              <Col className="col-auto">
                <ViewPackageYouTubeVideo youTubeLink={challengePackage?.youTubeLink} />
              </Col>
            </ListGroupItem>
          }
          <ViewPackageAppButtonSection />
          {
            shouldShowChallenges() &&
            <ViewChallengePackageList
              challengePackage={challengePackage}
              challengeClickHandler={onSideMenuClickHandler} />
          }
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}

export default ViewPackageSidebar;