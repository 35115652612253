/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import useBreakpoints from "hooks/Responsive";

import Breadcrumbs from './Breadcrumbs';

const LoadingCard = ({ spinnerColor }) => {
  const { isTabletOrMobile } = useBreakpoints();
  return <Col md="6" xl="3">
    <Card className="card-stats">
      <CardBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: !isTabletOrMobile ? 123 : 80 }}>
        <Spinner color={spinnerColor} />
      </CardBody>
    </Card>
  </Col>
}

const LoadingCardsHeader = ({ name, parentName, subName }) => {
  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Breadcrumbs name={name} parentName={parentName} subName={subName ?? name} />
            </Row>
            <Row>
              <LoadingCard spinnerColor="primary" />
              <LoadingCard spinnerColor="success" />
              <LoadingCard spinnerColor="warning" />
              <LoadingCard spinnerColor="info" />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

LoadingCardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  subName: PropTypes.string,
};

export default LoadingCardsHeader;
