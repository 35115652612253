import React, { useState, useEffect } from "react";
// import Config from "react-native-config";
// import { UserInfoContext } from "../UserInfo/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfoContext } from '../UserInfoContext';
import GetProtectedAPIClient from '../../api/protectedAPIClient';
import { ANSWER_TYPE, ASYNC_STORAGE, CHALLENGE_ANSWER_STATUS, COLOR } from '../../constants';
import { ActivePackageContext } from "./ActivePackageContext";
// import { ChallengesContext } from "../Challenges/ChallengesContext";
// import { BottomSheetContext } from "../BottomSheet/BottomSheetContext";
// import { CreateChallengeAnalyticsObject, logCustomEvent } from "../../../helpers/analytics";
import { USER_ENDPOINT, CHALLENGE_ENDPOINT } from "constants/api";
import { IsEmpty } from "helpers/Strings";
import { ViewPackageContext } from "contexts/ViewPackageContext/ViewPackageContext";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";

export const ActivePackageProvider = ({ children }) => {
  // const { userInfo } = React.useContext(UserInfoContext);
  // const {
  //   UpdateChallengePackageAccesses,
  //   UpdateChallengePackages
  // } = React.useContext(ChallengesContext)
  // const { closeBottomSheetHandler } = React.useContext(BottomSheetContext);

  const { setChallengePackageAccess, setChallengePackage } = React.useContext(ViewPackageContext)
  const { setReactBSAlert, CallSuccessAlert } = React.useContext(NotificationContext)
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const data = React.useContext(UserInfoContext);
  const [activePackage, setActivePackage] = React.useState(null);
  const [activatingPackage, setActivatingPackage] = React.useState(false);
  const [submittingChallengeAnswer, setSubmittingChallengeAnswer] = React.useState({
    challengePackage: null,
    challenge: null,
    submissionProgress: 0,
  });

  const isPackageActivePackage = (challengePackage) => {
    if (!challengePackage) { return false };
    return activePackage?.challengePackage?.id === challengePackage?.id;
  }

  const isChallengeBeingSubmitted = (challenge) => {
    return submittingChallengeAnswer.challenge?.id === challenge?.id;
  }

  useEffect(() => {
    if (data?.user?.id) {
      PullActivePackage();
    } else {
      setActivePackage(null);
    }
  }, [data?.user?.id])

  const PullActivePackage = async () => {
    if (!isAuthenticated) { setActivePackage(null); return; }
    const identifier = "roamli.back.auth";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${identifier}`,
      scope: "read:current_user openid access:users",
    });
    const challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
    challengeAPIClient.get('Package/ActivePackage?userId=' + data?.user?.id)
      .then(async (res) => {
        if (!!res.data) {
          // res.data.challengePackage.packageArea = GetScavengerHuntArea(res.data.challengePackage);
        }
        await HandlePackageActivation(res.data, !!res.data);
        setActivePackage(res.data);
      })
      .catch((error) => {
        setActivePackage(null);
      });
  }

  const ActivatePackage = async (challengePackage, activating, successCallback, errorCallback) => {
    if (!isAuthenticated) { setActivePackage(null); if (errorCallback) { errorCallback() }; return; }
    setActivatingPackage(true);
    const activateObject = {
      challengePackageId: challengePackage.id,
      userId: data?.user?.id,
      isActivated: activating,
    }

    const identifier = "roamli.back.auth";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${identifier}`,
      scope: "read:current_user openid access:users",
    });
    const challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
    challengeAPIClient
      .put('Package/Activate', activateObject)
      .then(async (res) => {
        if (res.data && activating) {
          if (!res?.data?.challengePackageAccess?.packageIsActiveForUser) {
            setActivePackage(null);
            await HandlePackageActivation(null, false);
            setActivatingPackage(false);
            if (successCallback) {
              successCallback()
            }
          }
          setActivePackage(res.data);
          await HandlePackageActivation(res.data, res?.data?.challengePackageAccess?.packageIsActiveForUser);
          if (successCallback) {
            successCallback()
          }
          setActivatingPackage(false);
          // closeBottomSheetHandler();
        } else {
          setActivePackage(null);
          setActivatingPackage(false);
          // closeBottomSheetHandler();
        }
      })
      .catch((error) => {
        setActivePackage(null);
        setActivatingPackage(false);
        // closeBottomSheetHandler();
        if (errorCallback) {
          errorCallback()
        }
      });
  }

  const HandlePackageActivation = async (challengePackageBundle, activationStatus) => {
    if (challengePackageBundle) {
      // UpdateChallengePackageAccesses(challengePackageBundle?.challengePackageAccess);
      // UpdateChallengePackages(challengePackageBundle?.challengePackage);
    }
    if (activationStatus) {
      // AsyncStorage.setItem(
      //   ASYNC_STORAGE.CHALLENGE_PACKAGE_ACTIVE,
      //   JSON.stringify(challengePackageBundle),
      // );
    } else {
      // AsyncStorage.removeItem(
      //   ASYNC_STORAGE.CHALLENGE_PACKAGE_ACTIVE
      // );
    }
  }

  const GetCompleteChallengeFullObject = (challenge, photoData, textAnswer) => {
    return {
      mediaType: photoData ? ANSWER_TYPE.PICTURE : ANSWER_TYPE.TEXT,
      mediaBytes: photoData ? photoData : [],
      challengeId: challenge.id,
      mediaName: "appSubmitted",
      userId: data?.user?.id,
      deviceId: null,
      textAnswer: textAnswer ? textAnswer : null
    }
  }

  const SubmitChallengeAnswer = async (
    challengePackage,
    challenge,
    challengePackageAccess,
    photoData,
    textAnswer,
    successCallback,
    incorrectCallback,
    errorCallback
  ) => {
    if (!isAuthenticated || !challengePackageAccess || !challengePackage || !challenge) {
      if (errorCallback) errorCallback();
      return;
    }
    const identifier = "roamli.back.auth";
    const accessToken = await getAccessTokenSilently({
      audience: `https://${identifier}`,
      scope: "read:current_user openid access:users",
    });
    const challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
    const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
    // const analyticsObject = CreateChallengeAnalyticsObject(challenge, location);
    // logCustomEvent('submitChallengeAnswerStarted', analyticsObject);

    setSubmittingChallengeAnswer({
      challengePackage: challengePackage,
      challenge: challenge,
      submissionProgress: 0.1,
    });

    if (!activePackage || (activePackage && activePackage?.challengePackage?.id !== challengePackage?.id)) {
      await ActivatePackage(challengePackage, true, () => { }, () => { });
    }

    const userId = data?.user?.id;

    if (!challengePackageAccess?.packageIsActiveForUser) {

    }

    const completeChallengeFull = GetCompleteChallengeFullObject(challenge, photoData, textAnswer);

    const completeChallengeFullBase = "/CompleteChallenge";

    const userChallenge = {
      challengeId: challenge?.id,
      userId: userId,
    };

    setSubmittingChallengeAnswer({
      challengePackage: challengePackage,
      challenge: challenge,
      submissionProgress: 0.17,
    });
    // Make sure a UserChallenge exists before submitting the answer
    userAPIClient
      .post("/Challenge/CreateUserChallenge", userChallenge)
      .then((res) => {
        setSubmittingChallengeAnswer({
          challengePackage: challengePackage,
          challenge: challenge,
          submissionProgress: 0.33,
        });
        challengeAPIClient
          .post(completeChallengeFullBase, completeChallengeFull)
          .then(async (res) => {
            if (res.status === 200) {
              //If TextAnswer, check if it was successful. 
              //If successful, continue. Else exit and return error callback
              if (
                !IsEmpty(textAnswer)
                && (!res?.data?.challengeAnswer || res?.data?.challengeAnswer?.status === CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT)
              ) {
                if (incorrectCallback) {
                  incorrectCallback();
                }
                setSubmittingChallengeAnswer({
                  challengePackage: null,
                  challenge: null,
                  submissionProgress: 0,
                });
              } else {
                // Update existing packages and challenge answers with resetBundle Info
                if (res?.data?.challengePackage && res?.data?.challengePackageAccess) {
                  setChallengePackageAccess(res?.data?.challengePackageAccess);
                  setChallengePackage(res?.data?.challengePackage);
                  if (res?.data?.challengePackageAccess?.packageHasBeenCompleted) {
                    CallSuccessAlert(
                      "Congratulations!",
                      () => { setReactBSAlert(null) },
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      "Close",
                      true,
                      false,
                      "",
                      "You've completed everything in this event!"
                    )
                  }
                }
                await PullActivePackage();


                // Finish submission trigger
                setSubmittingChallengeAnswer({
                  challengePackage: null,
                  challenge: null,
                  submissionProgress: 0,
                });

                // Set submission trigger to 0
                // setTimeout(() => {
                //   setSubmittingChallengeAnswer({
                //     challengePackage: null,
                //     challenge: null,
                //     submissionProgress: 0,
                //   });
                // }, 1000);

                //Call successCallback()
                if (successCallback) {
                  successCallback();
                }

                // const analyticsObject = CreateChallengeAnalyticsObject(challenge, location);
                // logCustomEvent('submitChallengeAnswerCompleted', analyticsObject);
              }



            } else {
              // Finish Submission Trigger
              setSubmittingChallengeAnswer({
                challengePackage: null,
                challenge: null,
                submissionProgress: 0,
              });
              // Alert Failure
              if (errorCallback) {
                errorCallback();
              }
            }
          })
          .catch((error) => {
            console.log(error);
            // Finish Submission Trigger
            setSubmittingChallengeAnswer({
              challengePackage: null,
              challenge: null,
              submissionProgress: 0,
            });
            // Alert Failure
            if (errorCallback) {
              errorCallback();
            }
          });
      })
      .catch((error) => {
        console.log(error);
        // Finish Submission Trigger
        setSubmittingChallengeAnswer({
          challengePackage: null,
          challenge: null,
          submissionProgress: 0,
        });
        // Alert Failure
        if (errorCallback) {
          errorCallback();
        }
      })
  }

  return (
    <ActivePackageContext.Provider value={
      {
        activePackage,
        setActivePackage,
        submittingChallengeAnswer,
        setSubmittingChallengeAnswer,
        activatingPackage,
        setActivatingPackage,
        isPackageActivePackage,
        ActivatePackage,
        SubmitChallengeAnswer,
        isChallengeBeingSubmitted,
      }
    }>
      {children}
    </ActivePackageContext.Provider>
  );
}
