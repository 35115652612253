import React from 'react';
import GoogleApiWrapper from 'components/GoogleMap/ViewPackageMap/ViewPackageMap';
import useWindowDimensions from 'hooks/Dimensions';
import useBreakpoints from 'hooks/Responsive';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';

const ViewPackage = (props) => {
  const { height, width } = useWindowDimensions();
  const { isTabletOrMobile } = useBreakpoints();
  const { challengePackage,
    createdChallengePackages,
    createdByUser,
    GetPackage,
    userLocation,
    GetFilteredChallenges,
  } = React.useContext(ViewPackageContext);

  const getHeight = React.useCallback((isFullscreen) => {
    if (isFullscreen) return height * .95;
    return isTabletOrMobile ? height * .6 : height
  }, [])

  return (
    <GoogleApiWrapper
      createdChallengePackages={createdChallengePackages}
      updatePackageHandler={GetPackage}
      isTabletOrMobile={isTabletOrMobile}
      userLocation={userLocation}
      userData={createdByUser}
      lat={challengePackage?.packageArea?.coordinate?.latitude ?? 41.895081}
      lng={challengePackage?.packageArea?.coordinate?.longitude ?? -87.629879}
      hideLine={challengePackage?.hideLine ?? false}
      type={challengePackage.type}
      challengePackage={challengePackage}
      containerStyle={
        { position: "relative", height: getHeight(), width: "100%", maxWidth: "100%" }}
      fullscreenStyle={
        { position: "relative", height: getHeight(true), width: "100%", maxWidth: "100%" }
      }
      challenges={GetFilteredChallenges()}
      removeChallengeHandler={undefined}
    />
  );
}

export default ViewPackage;