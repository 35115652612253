import React, { Component } from 'react';
import ReactDOM from "react-dom";
import {
  Map,
  GoogleApiWrapper,
  InfoWindow,
  Marker,
  Circle,
  Polyline
} from 'google-maps-react';
import {
  Button,
  Row
} from "reactstrap";
import '../GoogleMap.css';
import { COLOR, DISTANCE, CHALLENGE_PACKAGE_TYPE } from '../../../constants';
import { GetIconColorPackage } from '../../../helpers/Category';
import { getMarkerIcon } from 'helpers/Marker';

class MarkersList extends React.Component {
  constructor(props) {
    super(props);
    this.markersRendered = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.hideLine !== nextProps.hideLine) {
      return true;
    }

    if (this.props.type !== nextProps.type) {
      return true;
    }

    if (JSON.stringify(this.props.challenges) === JSON.stringify(nextProps.challenges) && this.markersRendered) {
      return false;
    }
    this.markersRendered = true;
    return true;
  }
  getScavengerHuntMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starOrange.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );
        const marker =
          <Marker
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon()}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                fontSize: "12px",
                className: "markerLabel",
              }} />

        // const circle = <Circle
        //   map={this.props.map}
        //   google={this.props.google}
        //   onClick={() => { }}
        //   center={centerPosition}
        //   radius={DISTANCE.SCAVENGER_HUNT_RADIUS}
        //   strokeColor={colorPackage.color}
        //   fillColor={colorPackage.backgroundColor} />
        // challengeMarkers.push(circle);
        challengeMarkers.push(marker);
      })
    }
    return challengeMarkers;
  };

  getChallengeTrackMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starPurple.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );

        const marker =
          <Marker
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId + "markers"}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon()}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                fontSize: "12px",
                className: "markerLabel",
              }} />
        challengeMarkers.push(marker);
      })
    }
    if (!this.props.hideLine) {
      challengeMarkers.push(<Polyline
        map={this.props.map}
        google={this.props.google} Î
        onClick={() => { }}
        path={
          this.props.challenges.map(
            (c) => { return { lat: c.centerLat, lng: c.centerLong } })
        }
        options={{ strokeColor: COLOR.ROAMLI_PURPLE_HEX, strokeWeight: 5 }} />);
    }

    return challengeMarkers;
  }

  render() {
    const challengeMarkers =
      this.props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
        this.getScavengerHuntMarkers()
        : this.getChallengeTrackMarkers();

    return (
      <>
        {challengeMarkers}
      </>
    )
  }
}

class OptionMarkersList extends React.Component {
  constructor(props) {
    super(props);
    this.markersRendered = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props.challenges) === JSON.stringify(nextProps.challenges) && this.markersRendered) {
      return false;
    }
    this.markersRendered = true;
    return true;
  }

  getOptionMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        const centerPosition = {
          lat: challenge.lat,
          lng: challenge.long
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/orange-dot.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(5, 5), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(10, 10)
        );

        const marker =
          <Marker
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.id + "optionsMarkers"}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={iconMarker} />

        challengeMarkers.push(marker);
      })
    }

    return challengeMarkers;
  }

  render() {
    const challengeMarkers = this.getOptionMarkers()

    return (
      <>
        {challengeMarkers}
      </>
    )
  }
}

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoWindow: false,
      activeMarker: {},
      activeChallenge: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.lat !== nextProps.lat || this.props.lng !== nextProps.lng) {
      return false;
    } else {
      return true;
    }
  }

  handleCenterChanged = (map) => {
    if (!map?.center || !this.props.updateCoordinatesHandler) { return false };
    this.props.updateCoordinatesHandler({ latitude: map.center.lat(), longitude: map.center.lng() })
  };

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        activeChallenge: null,
      });
    }
  };

  onMarkerClick = (props, marker, e, challenge) => {
    if (challenge?.id !== this.state.activeChallenge?.id) {
      this.setState({
        activeMarker: marker,
        showingInfoWindow: true,
        activeChallenge: challenge,
      });
    } else {
      this.onClose()
    }
  }

  render() {
    const position = { lat: this.props.lat, lng: this.props.lng };
    return (
      <div className="map-container">
        <Map
          className={"googleMap"}
          google={this.props.google}
          containerStyle={this.props.containerStyle}
          zoom={14}
          initialCenter={position}
          streetViewControl={false}
          mapTypeControl={false}
          onCenterChanged={(t, map, coord) => { this.handleCenterChanged(map) }}
          styles={
            [
              {
                "featureType": "poi.business",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              }
            ]
          }
        >
          <OptionMarkersList
            google={this.props.google}
            onClick={this.onMarkerClick}
            challengePackage={this.props.challengePackage}
            challenges={this.props.optionChallenges} />
          <MarkersList
            google={this.props.google}
            onClick={this.onMarkerClick}
            challengePackage={this.props.challengePackage}
            type={this.props.type}
            challenges={this.props.challenges}
            hideLine={this.props.hideLine} />
          <InfoWindowEx
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onClose}>
            <div style={
              {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                minHeight: 200,
                minWidth: 200
              }
            }>
              <div style={
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: "column"
                }
              }>
                <div style={{ height: 150, width: 250, overflow: 'hidden' }}>
                  {
                    this.state.activeChallenge?.picChallengeUrl ?
                      <img src={this.state.activeChallenge?.picChallengeUrl} style={{ width: '100%' }} />
                      : <h6 style={{ textAlign: 'center' }}>No Image</h6>
                  }
                </div>
                <h4 style={{ textAlign: 'center' }}>{this.state.activeChallenge?.title}</h4>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  {
                    this.props.showDetailModalHandler &&
                    <Button
                      style={{ width: '50%', marginBottom: 0, marginTop: 10, marginRight: 0, marginLeft: 0, borderRadius: 0 }}
                      color="info"
                      type="button"
                      onClick={
                        () => {
                          this.props.showDetailModalHandler(this.state.activeChallenge);
                        }
                      }>Details</Button>
                  }
                  {
                    !this.props.challenges.find(x => x.id === this.state.activeChallenge?.id) ?
                      this.props.addChallengeHandler ?
                        <Button
                          style={{ width: this.props.showDetailModalHandler ? '50%' : '100%', marginBottom: 0, marginTop: 10, marginRight: 0, marginLeft: 0, borderRadius: 0 }}
                          color="success"
                          type="button"
                          onClick={
                            () => {
                              if (this.props.addChallengeHandler) {
                                this.props.addChallengeHandler(this.state.activeChallenge);
                              }
                              this.onClose()
                            }
                          }>Add</Button>
                        : null
                      :
                      this.props.removeChallengeHandler ?
                        <Button
                          style={{ width: this.props.showDetailModalHandler ? '50%' : '100%', marginBottom: 0, marginTop: 10, marginRight: 0, marginLeft: 0, borderRadius: 0 }}
                          color="danger"
                          type="button"
                          onClick={
                            () => {
                              if (this.props.removeChallengeHandler) {
                                this.props.removeChallengeHandler(this.state.activeChallenge);
                              }
                              this.onClose()
                            }
                          }>Remove</Button>
                        : null
                  }
                </div>
              </div>
            </div>
          </InfoWindowEx>
        </Map>
      </div>
    );
  }
}

class InfoWindowEx extends Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement(`div`);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      ReactDOM.render(
        React.Children.only(this.props.children),
        this.contentElement
      );
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBVA1mMwDKj4H4Vr1xtyeGrYDFXrkZfBeo'
})(MapContainer);