/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, Route, Switch } from "react-router-dom";
// core components
import ViewPackageWrapper from "views/pages/viewPackage/ViewPackageWrapper";
import { PATH } from "constants/link";

function View(props) {
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <div className="main-content" ref={mainContentRef}>
      <Switch>
        <Route path={PATH.VIEW_EVENT + "/:lookUpCode"} component={ViewPackageWrapper} />
        <Route path={PATH.VIEW_EVENT} component={ViewPackageWrapper} />
      </Switch>
    </div>
  );
}

export default View;
