import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { PATH } from 'constants/link';

const CreatorCard = () => {
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }
  return <Col className='pl-0 pr-0'>
    <Card
      className="bg-gradient-success border-0"
      tag="h5"
      style={{ cursor: "pointer", marginBottom: 0 }}
      onClick={() => { routeChange(PATH.MAIN) }}>
      <CardBody>
        <Row>
          <div className="col" style={{ paddingRight: 0 }}>
            <CardTitle
              className="text-uppercase text-muted mb-0 text-white"
              tag="h5"
            >
              You're a Creator!
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">
              Manage Events in the Dashboard
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
              <i className="ni ni-active-40" />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span className="text-white mr-2">
            Go to Dashboard
            <i className="fa fa-arrow-right ml-2" />
          </span>
        </p>
      </CardBody>
    </Card>
  </Col>
}

export default CreatorCard;