import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { PATH } from 'constants/link';

const ExplorerCard = ({ ctaText }) => {
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  return <Col className='pl-0 pr-0'>
    <Card
      className="bg-gradient-info border-0"
      tag="h5"
      style={{ cursor: "pointer", marginBottom: 0 }}
      onClick={() => { routeChange(PATH.PRICING) }}>
      <CardBody>
        <Row>
          <div className="col" style={{ paddingRight: 0 }}>
            <CardTitle className="text-uppercase text-muted mb-0 text-white">
              Upgrade And
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">
              {ctaText ? ctaText : "Create Your Own Events!"}
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
              <i className="ni ni-spaceship" />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span className="text-white mr-2">
            Become a Roamli Creator
            <i className="fa fa-arrow-right ml-2" />
          </span>
        </p>
      </CardBody>
    </Card>
  </Col>
}

export default ExplorerCard;