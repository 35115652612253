/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { useParams, Redirect } from "react-router-dom";

// core components
import ChallengePackageCardsHeader from "components/Headers/ChallengePackageCardsHeader.js";
import { UserInfoContext } from "contexts/UserInfoContext";
import { ChallengeContext } from "contexts/ChallengeContext/ChallengeContext";
import ChallengePackageChallengeTable from "components/Tables/ChallengePackageChallengeTable/ChallengePackageChallengeTable";
import GetProtectedAPIClient from '../../../api/protectedAPIClient';
import { useAuth0 } from "@auth0/auth0-react";
import LoadingCardsHeader from "components/Headers/LoadingCardsHeader";
import ParticipantTable from "components/Tables/ParticipantTable/ParticipantTable";
import { CHALLENGE_ANSWER_STATUS, VIEW_MODE } from '../../../constants';
import { CHALLENGE_ENDPOINT, USER_ENDPOINT } from '../../../constants/api';
import { STRIPE_PRICES } from '../../../constants/stripe';
import UpdateChallengePackageDetailsWrapper from "../../../components/Forms/ChallengePackageForm/UpdateChallengePackageDetailsWrapper";
import ReviewSection from "components/Section/ReviewSection";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { PaymentContext } from "contexts/PaymentContext";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import { useHistory } from "react-router-dom";
import useBreakpoints from "hooks/Responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { PATH } from "constants/link";
import DownloadImagesButton from "components/Utility/DownloadImagesButton";

const defaultAnalyticsDict = {
  challengeSubmissions: {},
  totalLookups: 0,
  uniqueLookups: 0,
  totalSubmissions: 0,
  submissionsInReview: 0,
  totalLikes: 0,
  totalActive: 0,
  challengeCoverage: { submitted: 0, total: 1 },
  mostSubmissions: { users: null, count: null },
};

function ChallengePackage() {
  const { isTabletOrMobile } = useBreakpoints();
  const { lookUpCode } = useParams();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [searchQuery, setSearchQuery] = React.useState("");
  const { challengePackages, isLoaded } = React.useContext(UserInfoContext);
  const { notify } = React.useContext(NotificationContext);
  const {
    activeStatusLoading,
    updateChallengePackageActiveStatus
  } = React.useContext(ChallengeContext);
  const { isActiveSubscription, isCreatedBeforeSubscriptionOriginDate } = React.useContext(PaymentContext);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [paListInfo, setPaListInfo] = useState([]);
  const [analytics, setAnalytics] = React.useState(defaultAnalyticsDict);
  const [viewMode, setViewMode] = React.useState(VIEW_MODE.CHALLENGES);
  const [downloadButton, setDownloadButton] = React.useState(null);
  const [alert, setalert] = React.useState(false);
  const isCreator = isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY);
  const [cp, setCp] = React.useState(challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode }));

  const location = useLocation();
  const history = useHistory();
  const routeChange = () => {
    let path = PATH.PRICING;
    history.push(path);
  }

  const detailsRouteChange = (excludeDetails) => {
    let path = PATH.MAIN_PACKAGE + "/" + cp.lookUpCode
    if (!excludeDetails) {
      path += PATH.PACKAGE_DETAILS
    };
    history.push(path);
  }

  React.useEffect(() => {
    setCp(challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode }));
  }, [challengePackages])

  React.useEffect(() => {
    if (isLoaded) {
      const cPackage = challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode });
      setCp(cPackage)
      if (!cPackage) {
        return <Redirect to={PATH.MAIN} />
      }
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (cp) {
      getPackageAccessInformation();
    }
  }, [cp])

  React.useEffect(() => {
    if (location.pathname.indexOf(PATH.PACKAGE_DETAILS) === -1) {
      setViewMode(VIEW_MODE.CHALLENGES)
    };
  }, [location])

  const setChallengePackageActiveAlert = () => {
    setalert(
      <ReactBSAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title="Activate Event"
        onConfirm={async () => { setalert(null); await updateChallengePackageActiveStatus(cp.id, true) }}
        onCancel={() => setalert(null)}
        cancelBtnBsStyle="info"
        cancelBtnText="Close"
        disabled={activeStatusLoading}
        confirmBtnBsStyle="success"
        confirmBtnText={activeStatusLoading ? <Spinner /> : "Activate"}
        btnSize=""
        showCancel
      >
        {"Users will be able to access your event online."}
      </ReactBSAlert>
    );
  };

  const setChallengePackageInactiveAlert = () => {
    setalert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Deactivate Event"
        onConfirm={async () => { setalert(null); await updateChallengePackageActiveStatus(cp.id, false) }}
        onCancel={() => setalert(null)}
        cancelBtnBsStyle="info"
        cancelBtnText="Close"
        confirmBtnBsStyle="danger"
        confirmBtnText={activeStatusLoading ? <Spinner /> : "Deactivate"}
        btnSize=""
        showCancel
      >
        {"Users won't be able to access your event. You can reactivate at any time."}
      </ReactBSAlert>
    );
  };

  const getPackageAccessInformation = async (successCallback) => {
    if (!isAuthenticated || !cp) {
      return;
    }
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      var challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
      await challengeAPIClient.get("/Package/CreatedAccessesV2?id=" + cp.id)
        .then(res => {
          let pas = res.data;

          let filteredPas = pas.filter((item) => {
            const itemCPA = item?.challengePackageAccess;
            return itemCPA?.userId !== null
              && itemCPA?.auth0Id !== null
              && itemCPA?.userId !== undefined
              && itemCPA?.auth0Id !== undefined
              && item?.userInfo
              && item?.userInfo?.id
          }
          );
          const analyticsDict = JSON.parse(JSON.stringify(defaultAnalyticsDict));
          analyticsDict.totalLookups = cp.lookUpCount + cp.webLookUpCount + cp.iFrameLookUpCount;
          analyticsDict.uniqueLookups = filteredPas.length;
          analyticsDict.challengeCoverage = { submitted: 0, total: cp.challenges.length };

          filteredPas.forEach((fpa) => {
            const access = fpa.challengePackageAccess;
            analyticsDict.totalSubmissions += access.submittedChallengeAnswers.length;
            if (access.packageIsLiked) {
              analyticsDict.totalLikes++;
            }

            if ((cp.endTime === null || new Date(cp.endTime) > new Date()) && access.packageIsActiveForUser) {
              analyticsDict.totalActive++;
            }

            const approvedOrReview = access.submittedChallengeAnswers.filter(
              (sca) => {
                return sca.status == CHALLENGE_ANSWER_STATUS.IN_REVIEW || sca.status == CHALLENGE_ANSWER_STATUS.APPROVED
              }
            );

            if (approvedOrReview.length > 0) {
              if (analyticsDict.mostSubmissions.users === null || analyticsDict.mostSubmissions.count < approvedOrReview.length) {
                analyticsDict.mostSubmissions.users = 1;
                analyticsDict.mostSubmissions.count = approvedOrReview.length;
              } else if (analyticsDict.mostSubmissions.count === approvedOrReview.length) {
                analyticsDict.mostSubmissions.users++;
              }
            }

            access.submittedChallengeAnswers.forEach((ca) => {
              if (analyticsDict.challengeSubmissions[ca.challengeId]) {
                analyticsDict.challengeSubmissions[ca.challengeId] += 1
              } else {
                analyticsDict.challengeSubmissions[ca.challengeId] = 1;
              }

              if (ca.status == CHALLENGE_ANSWER_STATUS.IN_REVIEW) {
                analyticsDict.submissionsInReview++;
              }
            })
          })

          analyticsDict.challengeCoverage.submitted = Object.keys(analyticsDict.challengeSubmissions).length;

          setAnalytics(analyticsDict);
          setPaListInfo(filteredPas.map((fpa) => { return { packageAccess: fpa.challengePackageAccess, userInfo: fpa.userInfo } }));
          setIsPageLoaded(true);
          if (successCallback) {
            successCallback();
          }
        })
        .catch((err) => {
          console.log(err)
          setPaListInfo([]);
          setIsPageLoaded(true);
          notify("danger", "Error", "Couldn't get participant information. Please reload the page or contact support. (ERR: 8001)");
        })
    } catch (e) {
      console.log(e.message);
      setPaListInfo([]);
      setIsPageLoaded(true);
      notify("danger", "Error", "Couldn't get participant information. Please reload the page or contact support. (ERR: 8002)")
    }
  };

  useEffect(() => {
    if (cp) {
      getPackageAccessInformation();
    }
  }, []);

  const getSubName = () => {
    if (viewMode === VIEW_MODE.CHALLENGES) {
      return "Challenges"
    }
    if (viewMode === VIEW_MODE.PARTICIPANTS) {
      return "Participants"
    }
    if (viewMode === VIEW_MODE.CHALLENGE_PACKAGE_INFO) {
      return "Edit/Details"
    }
    if (viewMode === VIEW_MODE.REVIEW_CHALLENGES) {
      return "Review Challenges"
    }
    return ""
  }

  const isSubscriptionActive = () => {
    return isCreator || isCreatedBeforeSubscriptionOriginDate(new Date(cp?.createdOn));
  }

  if (!isLoaded) {
    return <Container className="mt-2" fluid>
      <Card>
        <CardBody>
          <Row style={{ alignItems: 'center' }}>
            <h3 className="mb-0 ml-3 mr-3">{"Loading Event"}</h3>
            <Spinner color="primary" />
          </Row>
        </CardBody>
      </Card>
    </Container>
  }

  if (viewMode === VIEW_MODE.CHALLENGE_PACKAGE_INFO) {
    return <UpdateChallengePackageDetailsWrapper
      challengePackageRefreshHandler={setCp}
      setViewMode={() => {
        setViewMode(VIEW_MODE.CHALLENGES);
        detailsRouteChange(true);
      }}
    />
  }

  return (
    <>
      {alert}
      {isPageLoaded ?
        <ChallengePackageCardsHeader
          name="Event"
          parentName="Home"
          subName={getSubName()}
          analytics={analytics}
          viewMode={viewMode}
          challengePackage={cp}
          challengePackageInfoHandler={() => {
            setViewMode(VIEW_MODE.CHALLENGE_PACKAGE_INFO);
            setSearchQuery("");
            detailsRouteChange();
          }}
          challengesHandler={() => { setViewMode(VIEW_MODE.CHALLENGES); setSearchQuery(""); }}
          participantsHandler={() => { setViewMode(VIEW_MODE.PARTICIPANTS); setSearchQuery(""); }}
          reviewHandler={() => { setViewMode(VIEW_MODE.REVIEW_CHALLENGES); setSearchQuery(""); }} />
        : <LoadingCardsHeader name="Event" parentName="Main" subName={getSubName()} />
      }
      <Container className="mt--6" fluid style={isTabletOrMobile ? { padding: 0 } : null}>
        <Card>
          <CardHeader className="border-0">
            <Row style={{ alignItems: 'center' }}>
              <Col md="6" xl="6">
                <div className={"form-row"} style={{ display: 'flex', alignItems: 'center' }}>
                  <h3 className="mb-0">{cp.title ?? cp.lookUpCode}</h3>
                  <h4 className={"mb-0 ml-1" + (cp.active && isSubscriptionActive() ? " text-success" : " text-danger")} style={{ display: 'flex', alignItems: 'center' }}>
                    {!isSubscriptionActive() ? <Button
                      onClick={routeChange}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      size="md"
                      color={"success"}
                      style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                      {"Upgrade to Activate Event"}
                    </Button>
                      : <>
                        <div style={{ height: 10, width: 10, borderRadius: 15, backgroundColor: cp.active && isSubscriptionActive() ? "#2dce89" : "#f5365c" }} className={"mb-0 ml-2 mr-1"} />
                        {cp.active ? "Active" : "Inactive"}
                      </>
                    }
                    {
                      isSubscriptionActive() ?
                        <Button
                          onClick={!cp.active && isSubscriptionActive() ? setChallengePackageActiveAlert : setChallengePackageInactiveAlert}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          size="sm"
                          disabled={activeStatusLoading}
                          color={cp.active ? "danger" : "success"}
                          style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                          {activeStatusLoading ? <Spinner /> : (cp.active ? "Deactivate" : "Activate")}
                        </Button>
                        : null
                    }
                  </h4>
                </div>
              </Col>
              {
                (viewMode === VIEW_MODE.CHALLENGE_PACKAGE_INFO || viewMode === VIEW_MODE.REVIEW_CHALLENGES) ?
                  null
                  : <Col className="text-right" xl="6" xs="12">
                    <Row
                      className={`text-right${isTabletOrMobile ? " mt-3" : " mr-1"}`}
                      style={{ display: "flex", justifyContent: isTabletOrMobile ? "center" : 'flex-end' }}>
                      <Form
                        onSubmit={e => { e.preventDefault(); }}
                        style={{ justifyContent: 'flex-end', display: 'flex', transform: "translateX(0%)" }}
                        className={classnames(
                          "navbar-search form-inline mr-sm-3",
                          "navbar-search-light"
                        )}
                      >
                        <FormGroup className="mb-0">
                          <InputGroup className="input-group-alternative input-group-merge">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-search" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              key={viewMode}
                              placeholder={"Search " + (viewMode === VIEW_MODE.CHALLENGES ? "Title" : "Name and Username")}
                              type="text"
                              onChange={(e) => { setSearchQuery(e.target.value) }} />
                          </InputGroup>
                        </FormGroup>
                      </Form>
                      {!isTabletOrMobile && downloadButton}
                      {/* <DownloadImagesButton images={getAllImages()} /> */}
                    </Row>
                  </Col>
              }
            </Row>
          </CardHeader>
          {
            viewMode === VIEW_MODE.PARTICIPANTS ?
              <ParticipantTable
                searchQuery={searchQuery}
                challengePackage={cp}
                packageAccesses={paListInfo}
                setDownloadButtonHandler={setDownloadButton} />
              : null
          }
          {
            viewMode === VIEW_MODE.CHALLENGES ?
              <ChallengePackageChallengeTable
                searchQuery={searchQuery}
                challengePackage={cp}
                packageAccesses={paListInfo}
                setDownloadButtonHandler={setDownloadButton} />
              : null
          }
          {
            viewMode === VIEW_MODE.REVIEW_CHALLENGES ?
              <ReviewSection
                challengePackage={cp}
                packageAccesses={paListInfo}
                reloadPackageAccessesHandler={getPackageAccessInformation}
              />
              : null
          }
        </Card>
      </Container>
    </>
  );
}

export default ChallengePackage;
