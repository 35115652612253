import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'reactstrap';
import ReviewSectionGalleryInfoModalImage from './ReviewSectionGalleryInfoModalImage';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ReviewSectionInfoModal = ({
  challengePackage,
  inspectedSubmission,
  packageAccesses,
  closeHandler }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUserSubmissions = () => {
    return packageAccesses.find((pa) => { return pa?.packageAccess?.userId === inspectedSubmission?.userId })?.packageAccess?.submittedChallengeAnswers ?? [];
  }

  const getInspectedChallenge = () => {
    console.log(challengePackage.challenges.find((chal) => { return chal.challengeId === inspectedSubmission?.challengeId }));
    return challengePackage.challenges.find((chal) => { return chal.challengeId === inspectedSubmission?.challengeId });
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={inspectedSubmission}
      toggle={() => { closeHandler(); setValue(0); }}
    >
      <div className="modal-header" style={{ paddingBottom: 0 }} >
        <h6 className="modal-title" id="modal-title-default">
          {
            value === 0 ?
              "#" + (inspectedSubmission?.challenge?.order + 1) + ": " + inspectedSubmission?.challenge.title
              : `User: ${inspectedSubmission?.userInfo?.username}`
          }
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => { closeHandler(); setValue(0); }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body" style={{ paddingTop: 0 }} >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab label="Submission" {...a11yProps(0)} />
              <Tab label="Challenge Info" {...a11yProps(1)} />
              <Tab label="All User's Submissions" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img
                src={inspectedSubmission?.picUrl}
                style={{ minWidth: 300, maxHeight: 500 }}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <img
                src={getInspectedChallenge()?.picChallengeUrl}
                style={{ minWidth: 300, maxHeight: 300, borderRadius: 30 }}
              />
              <div style={{ width: 400, display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}>
                <div>
                  <p style={{ fontWeight: 400, color: "black", maxWidth: 400, marginTop: 30 }}>{`Title: ${getInspectedChallenge()?.challenge?.title}`}</p>
                </div>
                <div>
                  <p style={{ fontWeight: 400, color: "black", maxWidth: 400, marginTop: 20 }}>
                    {`Description: ${getInspectedChallenge()?.challenge?.description} ${getInspectedChallenge()?.extraDescription}`}
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap" }}>
              {
                getUserSubmissions().map((userSubmission) => {
                  const challenge = challengePackage.challenges.find((chal) => { return chal.challengeId === userSubmission?.challengeId });
                  return <ReviewSectionGalleryInfoModalImage
                    challenge={challenge}
                    submission={userSubmission} />;
                })
              }
            </div>
          </TabPanel>
        </Box>
      </div>

    </Modal>
  );
}

export default ReviewSectionInfoModal;
