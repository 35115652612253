export function SanitizeInternalString(string) {
  const words = string.replace("_", " ").split(" ");
  let upperWords = [];
  words.forEach((word) => { upperWords.push(word.charAt(0).toUpperCase() + word.slice(1)) });
  return upperWords.join(" ");
}

export function IsEmpty(string) {
  return !string || string === null || string === "" || string === undefined;
}

export function Truncate(string, wordMax) {
  const wordArray = string.split(" ");
  if (wordArray.length <= wordMax) {
    return string;
  }

  return wordArray.slice(0, wordMax).join(" ") + "...";
}

export const SanitizeUrl = (url) => {
  var encodedUrl = encodeURIComponent(url);
  encodedUrl = encodedUrl.replace(/'/g, '%27');
  return encodedUrl;
}