/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  UncontrolledTooltip,
  Modal,
  Button
} from "reactstrap";
import { GetFilterNameFromNumber } from '../../../helpers/Filters';
import { SanitizeInternalString } from '../../../helpers/Strings';
import MasonryModal from '../../Modals/MasonryModal/MasonryModal';
import Avatar from "../../../components/Images/RoundedImages/Avatar";

const ChallengePackageChallengeTableRow = (props) => {
  const [imageModalOpen, setImageModalOpen] = React.useState(false);
  const formatDate = (timestamp) => {
    if (timestamp === null) {
      return null;
    }

    let date = new Date(timestamp);

    return "" + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + formatTime(date.getMinutes());
  }

  const formatTime = (time) => {
    if (time.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  const getSubmissionUrls = () => {
    return props.submissions.map((submission) => { return { url: submission.picUrl, hoverText: submission.userInfo?.username } });
  }

  const submissions = getSubmissionUrls();

  return (
    <tr>
      <td>
        <span className="order text-muted">
          {props.order}
        </span>
      </td>
      <td className="table-user" style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar imageUrl={props.image} />
        <b className="title">{props.title}</b>
      </td>
      <td>
        <span className="submissions text-muted">
          {props.submissions.length}
        </span>
      </td>
      <td>
        <span className="points text-muted">
          {props.points}
        </span>
      </td>
      <td>
        <span className="type text-muted">
          {SanitizeInternalString(GetFilterNameFromNumber(props.type))}
        </span>
      </td>
      <td>
        <span className="address text-muted">
          {props.address}
        </span>
      </td>
      <td>
        <span className="personal text-muted">
          {props.isPersonal ? <i className="fa fa-check" /> : null}
        </span>
      </td>
      <td>
        <span className="locationBased text-muted">
          {!props.noLocation ? <i className="fa fa-check" /> : null}
        </span>
      </td>
      <td className="table-actions">
        {/* <a
          className="table-action"
          href="#pablo"
          id="tooltip209424781"
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-edit" />
        </a>
        <UncontrolledTooltip delay={0} target="tooltip209424781">
          Edit Challenge
        </UncontrolledTooltip> */}
        {!props.isTextAnswer &&
          <>
            <a
              className="table-action table-action-delete"
              href="#pablo"
              id="tooltip12475020"
              onClick={() => { setImageModalOpen(true) }}
            >
              <i className="fas fa-camera" />
            </a>
            <UncontrolledTooltip delay={0} target="tooltip12475020">
              View Submissions
            </UncontrolledTooltip>
          </>
        }
      </td>
      <MasonryModal
        isOpen={imageModalOpen}
        closeHandler={() => { setImageModalOpen(false) }}
        urls={submissions}
        title={props.title + " Photo Submissions"} />
    </tr>
  );
}

export default ChallengePackageChallengeTableRow;
