import {CHALLENGE_TYPE} from '../constants'

export function MapActiveFilterNamesToNumbers(filters) {
	const filterKeys = Object.keys(filters);
	let filtersNumberArray = [];
	filterKeys.forEach((filterKey)=>{
		if (filters[filterKey] === true) {
			filtersNumberArray.push(CHALLENGE_TYPE[filterKey.toUpperCase()])
		}
	})
	return filtersNumberArray;
}

export function GetFilterNameFromNumber(number) {
	return Object.keys(CHALLENGE_TYPE).find(key => CHALLENGE_TYPE[key] === number);
}

export function GetFilters(active) {
	return {
		active: active,
		architecture: active,
		art: active,
		beach: active,
		bridge: active,
		building: active,
		// conservatory: active,
		fountain: active,
		historic: active,
		house: active,
		landmark: active,
		modern: active,
		monument: active,
		mural: active,
		museum: active,
		mystery: active,
		nature: active,
		park: active,
		river: active,
		statue: active,
		street_art: active,
		trail: active,
		view: active,
		waterfall: active,
		waterfront: active,
		zoo: active,
		nook: active,
		stadium: active,
		food: active,
		music: active,
		theater: active,
	}
}