/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Button,
  Col,
  Row
} from "reactstrap";
import { GetFilterNameFromNumber } from '../../../../helpers/Filters';
import { SanitizeInternalString } from '../../../../helpers/Strings';
import { COLOR } from "constants/color";
import { useHistory } from "react-router-dom";
import Avatar from "../../../../components/Images/RoundedImages/Avatar";
import useBreakpoints from "hooks/Responsive";

const ChallengeAddTableRow = (props) => {
  const [imageModalOpen, setImageModalOpen] = React.useState(false);
  const { isTabletOrMobile } = useBreakpoints();
  const getCardSize = () => {
    if (isTabletOrMobile) return "sm";
    return "md";
  }
  const formatDate = (timestamp) => {
    if (timestamp === null) {
      return null;
    }

    let date = new Date(timestamp);

    return "" + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + formatTime(date.getMinutes());
  }

  const formatTime = (time) => {
    if (time.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  // const getSubmissionUrls = () => {
  //   return props.submissions.map((submission) => { return { url: submission.picUrl, hoverText: submission.userInfo.username } });
  // }

  // const submissions = getSubmissionUrls();

  return (
    <Col style={{
      width: "100%",
      paddingTop: 20,
      paddingBottom: 20,
      backgroundColor: props.index % 2 !== 0 ? "#F5F5F5" : "white",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {/* <td>
        <span className="order text-muted">
          {props.order}
        </span>
      </td> */}
      <Col>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
          {/* <Avatar imageUrl={props.image} /> */}
          <b className="title" style={{ color: COLOR.ROAMLI_PURPLE_HEX, maxWidth: isTabletOrMobile ? 150 : 300 }}>{props.title}</b>
          <Row>
            <Button
              color={"info"}
              size={getCardSize()}
              onClick={(e) => { props.showDetailModalHandler() }}>
              Details
            </Button>
            <Button
              color={props.isSelected ? "danger" : "success"}
              size={getCardSize()}
              disabled={props.disableAddChallengesHandler && !props.isSelected}
              onClick={(e) => { props.isSelected ? props.removeChallengeHandler() : props.addChallengeHandler() }}>
              {props.isSelected ? "Remove" : "Add"}
            </Button>
          </Row>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <span className="address text-muted">
            Address: {props.address}
          </span>
        </Row>
      </Col>
      {/* <td>
        <span className="submissions text-muted">
          {props.submissions.length}
        </span>
      </td> */}
      {/* <td>
        <span className="points text-muted">
          {props.points}
        </span>
      </td> */}
      {/* <td>
        <span className="type text-muted">
          {SanitizeInternalString(GetFilterNameFromNumber(props.type))}
        </span>
      </td> */}
      {/* <td>
        <span className="personal text-muted">
          {props.isPersonal ? <i className="fa fa-check" /> : null}
        </span>
      </td>
      <td>
        <span className="locationBased text-muted">
          {!props.noLocation ? <i className="fa fa-check" /> : null}
        </span>
      </td> */}
      {/* <td>
        <span className="createdOn text-muted">
          {formatDate(props.createdOn)}
        </span>
      </td> */}
    </Col>
  );
}

export default ChallengeAddTableRow;
