import React from 'react';
import {
  Button,
  Row,
  Col,
  ListGroupItem,
} from "reactstrap";
import 'react-pro-sidebar/dist/css/styles.css';
import "./ViewPackageSidebar.css";
import { AvatarV2 } from 'components/Images/RoundedImages/Avatar';
import ViewPackageChallengeSubmitButton from './ViewPackageChallengeSubmitButton';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import { CHALLENGE_ANSWER_STATUS } from 'constants/challengeAnswerStatus';
import { GetSubmissionForChallenge } from 'helpers/ChallengePackage';
import { COLOR } from 'constants/color';
import { HEX_OPACITY } from 'constants/hexOpacity';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

const ViewChallengePackageListRow = (props) => {
  const { isAuthenticated } = useAuth0();
  const { challenge, challengeClickHandler } = props;
  const { challengePackageAccess } = React.useContext(ViewPackageContext);

  const GetOverlay = React.useCallback(() => {
    const submission = GetSubmissionForChallenge(challenge.challenge, challengePackageAccess);
    if (!submission) return null;
    let overlayInfo = null;
    if (submission.status === CHALLENGE_ANSWER_STATUS.IN_REVIEW) {
      overlayInfo = { color: COLOR.ROAMLI_YELLOW_HEX, image: <i className='fa fa-gavel' style={{ color: "white", fontSize: 24 }} /> };
    } else if (submission.status === CHALLENGE_ANSWER_STATUS.APPROVED) {
      overlayInfo = { color: COLOR.SUCCESS_GREEN_HEX, image: <i className='fa fa-check' style={{ color: "white", fontSize: 24 }} /> };
    } else if (submission.status === CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT) {
      overlayInfo = { color: COLOR.ALERT_RED_HEX, image: <i className='fa fa-ban' style={{ color: "white", fontSize: 24 }} /> };
    } else if (submission.status === CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE) {
      overlayInfo = { color: COLOR.ALERT_RED_HEX, image: <i className='fa fa-ban' style={{ color: "white", fontSize: 24 }} /> };
    }

    if (!overlayInfo) return null;

    return (
      <div
        className="challenge-package-list-row-overlay-root"
        style={{ background: overlayInfo.color + HEX_OPACITY["100"] }}>
        {overlayInfo.image}
      </div>
    )
  }, [challenge, challengePackageAccess])

  return (
    <ListGroupItem className="px-0">
      <Row className="align-items-center">
        <Col
          className="col-auto"
          style={{
            position: "relative",
            overflow: "hidden",
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
          }}>
          {/* <AvatarV2 src={challenge.picChallengeUrl} alt={challenge.title} /> : */}
          <OrderRoot isSubmitted={!!GetOverlay()}>
            {!GetOverlay() && (challenge.order + 1)}
          </OrderRoot>
          {GetOverlay()}
        </Col>
        {
          !challenge.noLocation && <div className="col ml--2">
            <h4 className="mb-0">
              <div>
                {challenge.title}
              </div>
            </h4>
            <small>{`${challenge.packagePoints ?? challenge?.challenge?.points} Points`}</small>{" "}
            <span className="text-success">●</span>{" "}
            <small>{challenge.challenge.address.displayedAddress}</small>
          </div>
        }
        <Col className="col-auto">
          {/* <Row>
            <ViewPackageChallengeSubmitButton
              challenge={challenge}
              challengePackageAccess={challengePackageAccess} />
          </Row> */}
          <Button color="primary" size="sm" type="button" onClick={() => { challengeClickHandler(challenge) }}>
            View
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

const OrderRoot = styled.div((props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  width: '48px',
  borderRadius: '100px',
  backgroundColor: props.isSubmitted ? "transparent" : COLOR.ROAMLI_BLUE_HEX,
  color: "white",
})));

export default ViewChallengePackageListRow;