import moment from 'moment';

export function FormatDate(dateTimeString, short = false) {
  const formatString = short ? "MM/DD/YY" : "MM/DD/YYYY";
  return moment(dateTimeString).format(formatString);
}

export function FormatTime(dateTimeString) {
  const date = new Date(dateTimeString);
  
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ampm;
  return strTime;
}

export function FormatDateTime(dateTimeString) {
  return FormatDate(dateTimeString, true) + " " + FormatTime(dateTimeString);
}

export function CurrentLocaleTime() {
  return moment().format();
}

export function MsToTime(s) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
}