import React, { useState, useContext, useRef, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Button
} from "reactstrap";
import './SubmitTextAnswerModal.css';
import { ActivePackageContext } from 'contexts/ActivePackageContext/ActivePackageContext';
import { COLOR } from 'constants/color';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import Dropzone from 'react-dropzone'
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import { CameraAlt } from '@material-ui/icons';
import useBreakpoints from "hooks/Responsive";

const MESSAGES = {
  INCORRECT_MESSAGE: {
    MESSAGE: "Incorrect Answer",
    COLOR: COLOR.ALERT_RED_HEX
  },
  CORRECT_MESSAGE: {
    MESSAGE: "CORRECT!",
    COLOR: COLOR.SUCCESS_GREEN_HEX
  },
  ERROR_MESSAGE: {
    MESSAGE: "There was an error submitting your answer. Please try again.",
    COLOR: COLOR.ALERT_RED_HEX
  }
}

const SubmitPhotoAnswerModal = ({
  transparent,
  visible,
  headerText,
  closeHandler,
  challenge
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusColor, setStatusColor] = useState(MESSAGES.INCORRECT_MESSAGE.COLOR);
  const _field = useRef(null);
  const {
    SubmitChallengeAnswer,
    isChallengeBeingSubmitted,
  } = useContext(ActivePackageContext);
  const { isTabletOrMobile } = useBreakpoints();

  const { notify } = React.useContext(NotificationContext);

  const {
    challengePackage,
    challengePackageAccess,
  } = useContext(ViewPackageContext);

  const closeModalHandler = () => {
    closeHandler();
  }

  React.useEffect(() => { setImageSrc(null); setIsSubmitted(false); }, [visible])

  React.useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        closeModalHandler()
      }, 3000)
    }
  }, [isSubmitted])

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      centered
      show={visible}
      onHide={closeModalHandler}
      dialogClassName="submitTextAnswerModalRoot"
      aria-labelledby="submit-text-answer-modal"
      fade={"true"}
      backdropClassName="submitTextAnswerModalOverlay"
    >
      <Modal.Header className={"submitTextAnswerModalBody"}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 20,
          paddingBottom: 0
        }}>
        <Button
          size={"sm"}
          className={"viewPackageDetailModalCloseButton"}
          onClick={closeModalHandler}>
          <span className="btn-inner--icon">
            <i className="fas fa-times" />
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body className={"submitTextAnswerModalBody"}>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <Dropzone
            disabled={isChallengeBeingSubmitted(challenge?.challenge) || isSubmitted}
            onDrop={handleDrop}
            accept={{
              'image/png': [],
              'image/jpeg': [],
              'image/webp': [],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
                <input {...getInputProps()} />
                <p>{`${isTabletOrMobile ? "C" : "Drag 'n' drop a photo here, or c"}lick to select${isTabletOrMobile ? " or take" : ""} a photo`}</p>
                {imageSrc ?
                  <div style={previewStyle}>
                    <img src={imageSrc} alt="Preview" style={imgStyle} />
                  </div>
                  : <CameraAlt style={iconStyle} />
                }
              </div>
            )
            }
          </Dropzone>
        </Row>
      </Modal.Body>
      <Modal.Footer className={"submitTextAnswerModalFooter"} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Button
          className="my-2"
          style={{ marginRight: 0, width: "100%" }}
          color="success"
          type="button"
          disabled={!imageSrc || isChallengeBeingSubmitted(challenge?.challenge) || isSubmitted}
          onClick={
            () => {
              SubmitChallengeAnswer(
                challengePackage,
                challenge?.challenge,
                challengePackageAccess,
                imageSrc.split("base64,")[1],
                undefined,
                () => {
                  notify("success", "Photo Submitted!", "Your photo has been submitted for review!")
                  setIsSubmitted(true);
                },
                () => {
                  notify("danger", "Submission Failed", "Your photo couldn't be submitted. Please refresh the page and try again or contact support. (ERR: 1001)")
                },
                () => {
                  notify("danger", "Submission Failed", "Your photo couldn't be submitted. Please refresh the page and try again or contact support. (ERR: 1002)")
                }
              )
            }
          }>
          {isChallengeBeingSubmitted(challenge?.challenge) ?
            <Spinner /> :
            isSubmitted ?
              <i className='fa fa-check' style={{ color: "white", fontSize: 24 }} /> :
              "Submit Photo"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '5px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center'
};

const previewStyle = {
  marginTop: '20px',
  textAlign: 'center',
};

const iconStyle = {
  fontSize: '48px',
  color: '#cccccc',
  marginBottom: '10px',
};

const imgStyle = {
  maxWidth: '100%',
  maxHeight: '300px',
};

export default SubmitPhotoAnswerModal;