const roamliBase = "https://www.roamli.com";
const portalBase = "https://portal.roamli.com"
const LINK = {
	REDDIT: "https://www.reddit.com/r/Roamli",
	WEBSITE: roamliBase,
	GOOGLE_PLAY_STORE: "https://play.google.com/store/apps/details?id=com.roamlimobile",
	SUPPORT_DEPRECATED: "https://roamli.zendesk.com/hc/en-us/requests/new",
	FAQ_DEPRECATED: "https://roamli.zendesk.com/hc/en-us/sections/1500000375181-FAQ",
	NOTION_FAQ: "https://roamli.notion.site/roamli/fd00f904075f4d009c49b79c920ed617",
	CONTACT_US: "https://www.roamli.com/contact-us",
	COMMUNITY_STANDARDS: roamliBase + "/community-standards",
	PRIVACY_POLICY: roamliBase + "/privacy",
	TERMS_OF_USE: roamliBase + "/terms-of-use",
	VIEW_BASE: "/view/event",
	FULL_VIEW_BASE: portalBase + "/view/event",
}

const dashboardBase = "/dashboard";
const viewBase = "/view";
const searchBase = "/search";
const PATH = {
	BASE: portalBase,
	HOME: "/",
	REDIRECT: "/redirect",
	MAIN: dashboardBase,
	MAIN_CHALLENGE: dashboardBase + "/challenge",
	MAIN_CREATE_CHALLENGE: dashboardBase + "/create/challenge",
	MAIN_CREATE_PACKAGE: dashboardBase + "/create/event",
	MAIN_PACKAGE: dashboardBase + "/package",
	PACKAGE_DETAILS: "/details",
	PRICING: "/pricing",
	PROFILE: "/profile",
	VIEW: viewBase,
	VIEW_EVENT: viewBase + "/event",
	SEARCH: searchBase,
}

export { LINK, PATH };