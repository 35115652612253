import React from 'react';
import {
  Progress
} from 'reactstrap';
import Countdown from 'react-countdown';
import { FormatDateTime } from 'helpers/Date';
import "./ViewPackage.css";

const ViewPackageTimeBar = ({ onStartHandler, challengePackage }) => {
  const getTimes = () => {
    const current = currentTime.getTime();
    const createdOn = challengePackage.createdOn ? new Date(challengePackage.createdOn).getTime() : null;
    const start = challengePackage.startTime ? new Date(challengePackage.startTime).getTime() : null;
    const end = challengePackage.endTime ? new Date(challengePackage.endTime).getTime() : null;

    return {
      current,
      createdOn,
      start,
      end
    }
  }

  const hasStarted = () => {
    const { current, start } = getTimes();
    return !start || (start && current >= start);
  }

  const [currentTime, setCurrentTime] = React.useState(new Date());
  const [eventStarted, setEventStarted] = React.useState(hasStarted());
  React.useEffect(() => {
    const updateTime = setInterval(() => { setCurrentTime(new Date()) }, 1000);
    return () => {
      clearInterval(updateTime);
    }
  }, [])

  React.useEffect(() => {
    const { current, start, end } = getTimes();
    if (!eventStarted && current >= start) {
      setEventStarted(true);
      onStartHandler();
    }
  }, [currentTime])

  const getProgress = () => {
    const { current, start, end, createdOn } = getTimes();
    let actualStart = start ?? createdOn;
    return (current - actualStart) / (end - actualStart) * 100;
  }

  const getDisplay = () => {
    const { current, start, end } = getTimes();
    if (start !== null && current < start) {
      return start - current < 86400000 ?
        <>
          <div style={{}}>{"Event starts in "}</div>
          <div style={{ fontSize: 26 }}><Countdown key="start" date={start} /></div>
        </>
        : <>
          <div style={{}}>{"Event starts on "}</div>
          <div style={{ fontSize: 26 }}>{FormatDateTime(new Date(start).toString(), true)}</div>
        </>;
    } else if (end > current) {
      return end - current < 86400000 ?
        <>
          <div style={{}}>{"Event ends in "}</div>
          <div style={{ fontSize: 26 }}><Countdown key="end" date={end} /></div>
          <Progress animated color="success" value={getProgress().toString()} className="view-package-time-bar-progress" />
        </>
        : <>
          <div style={{}}>{"Event ends on "}</div>
          <div style={{ fontSize: 26 }}>{FormatDateTime(new Date(end).toString(), true)}</div>
          <Progress animated color="success" value={getProgress().toString()} className="view-package-time-bar-progress" />
        </>
    } else {
      return <div style={{ fontSize: 26 }}>{"Event has ended"}</div>
    }
  }

  const { current, start, end } = getTimes();
  if ((start === null || current > start) && end === null) {
    return null;
  }

  return (<div style={
    {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "white",
      width: "100%",
      paddingBottom: "1.5rem",
    }
  }>
    {getDisplay()}
  </div>
  )
}

export default ViewPackageTimeBar;