import React from "react";
import PropTypes from "prop-types";
import './YouTubePlayer.css';

const YoutubeEmbed = ({ embedId, maxWidth }) => {
  const width = maxWidth ? maxWidth.toString() : "480"
  const height = maxWidth ? (maxWidth / 853 * 480).toString() : "853"
  return (
    <div className={maxWidth ? undefined : "video-responsive"}>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;