import React from 'react';
import ChallengeForm from './ChallengeForm';
import { UserInfoContext } from "contexts/UserInfoContext";
import { useParams, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Spinner,
  Row
} from "reactstrap";
import { PATH } from "constants/link";

const UpdateChallengeWrapper = () => {
  const { challenges, isLoaded } = React.useContext(UserInfoContext);
  const { challengeId } = useParams();
  const [resetCount, setResetCount] = React.useState(0);
  const [creatingDuplicate, setCreatingDuplicate] = React.useState(false);
  const [challenge, setChallenge] = React.useState(challenges.find((c) => { return c.id == challengeId }));

  const resetForm = () => {
    setResetCount(resetCount + 1);
    setCreatingDuplicate(false);
  }

  React.useEffect(() => {
    if (isLoaded) {
      const chal = challenges.find((c) => { return c.id == challengeId });
      setChallenge(chal);
      if (!chal) {
        return <Redirect to={PATH.MAIN} />
      }
    }
  }, [isLoaded])

  if (!isLoaded) {
    return <Container className="mt-2" fluid>
      <Card>
        <CardBody>
          <Row style={{ alignItems: 'center' }}>
            <h3 className="mb-0 ml-3 mr-3">{"Loading Challenge"}</h3>
            <Spinner color="primary" />
          </Row>
        </CardBody>
      </Card>
    </Container>
  }

  return !creatingDuplicate ?
    <ChallengeForm
      challenge={challenge}
      resetFormHandler={resetForm}
      creatingDuplicate={creatingDuplicate}
      duplicateHandler={() => { setCreatingDuplicate(true) }} />
    : <ChallengeForm
      key={resetCount}
      challenge={challenge}
      createMode={true}
      creatingDuplicate={creatingDuplicate}
      duplicateHandler={() => { setCreatingDuplicate(false) }}
      resetFormHandler={resetForm} />
}

export default UpdateChallengeWrapper;