/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { CHALLENGE_PACKAGE_TYPE, COLOR, LINK } from "../../../constants";
import React from "react";
import {
  UncontrolledTooltip,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Avatar from "../../../components/Images/RoundedImages/Avatar";
import { PATH } from "constants/link";
import { PanToolSharp } from "@material-ui/icons";

const ChallengePackageTableRow = (props) => {
  const formatDate = (timestamp) => {
    if (timestamp === null) {
      return null;
    }

    let date = new Date(timestamp);

    return "" + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + formatTime(date.getMinutes());
  }

  const formatTime = (time) => {
    if (time.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  const history = useHistory();
  const routeChange = () => {
    let path = PATH.MAIN_PACKAGE + "/" + props.lookUpCode;
    history.push(path);
  }

  return (
    <tr style={{ opacity: 1 }}>
      <td onClick={routeChange} className="table-user" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <Avatar imageUrl={props.image} />
        <b className="name">{props.title}</b>
      </td>
      <td>
        <a
          href={`${LINK.FULL_VIEW_BASE}/${props.lookUpCode}`}
          target="_blank"
          className="link"
          style={{ cursor: "pointer" }}>
          {`${LINK.VIEW_BASE}/${props.lookUpCode}`}
        </a>
      </td>
      <td className="active">
        <div style={{ display: 'flex', justifyContent: 'center', height: "100%", width: "100%", alignItems: 'center' }}>
          {
            props.isActive ?
              <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: COLOR.SUCCESS_GREEN_HEX }}></div>
              : <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: COLOR.ALERT_RED_HEX }}></div>
          }
        </div>
      </td>
      <td>
        <span className="lookUpCount text-muted">
          {props.lookUpCount}
        </span>
      </td>
      <td>
        {/* <a
          className="lookUpCode font-weight-bold"
          href="#pablo"
          onClick={(e) => e.preventDefault()}
        > */}
        {props.lookUpCode}
        {/* </a> */}
      </td>
      <td>
        <span className="challenges text-muted">
          {props.challenges.length}
        </span>
      </td>
      <td>
        <span className="startTime text-muted">
          {formatDate(props.startTime)}
        </span>
      </td>
      <td>
        <span className="endTime text-muted">
          {formatDate(props.endTime)}
        </span>
      </td>
      <td>
        <span className="type text-muted">
          {props.type === CHALLENGE_PACKAGE_TYPE.TRACK ? "Track" : "Hunt"}
        </span>
      </td>
      <td>
        <span className="createdOn text-muted">
          {formatDate(props.createdOn)}
        </span>
      </td>
      <td>
        <span className="modifiedOn text-muted">
          {formatDate(props.modifiedOn)}
        </span>
      </td>
      {/* <td className="table-actions">
        <a
          className="table-action"
          href="#pablo"
          id="tooltip209424781"
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-user-edit" />
        </a>
        <UncontrolledTooltip delay={0} target="tooltip209424781">
          Edit product
        </UncontrolledTooltip>
        <a
          className="table-action table-action-delete"
          href="#pablo"
          id="tooltip12475020"
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-trash" />
        </a>
        <UncontrolledTooltip delay={0} target="tooltip12475020">
          Delete product
        </UncontrolledTooltip>
      </td> */}
    </tr>
  );
}

export default ChallengePackageTableRow;
