import * as React from 'react';
import "./ReviewSection.css";

const ReviewSectionGalleryImage = ({
  isSelected,
  setSubmissionUnderInspectionHandler,
  submission,
  setSelectedSubmissionsHandler
}) => {
  return (
    <div
      className="review-section-image-root">
      <img
        onClick={() => { setSelectedSubmissionsHandler(submission) }}
        src={submission?.picUrl}
        style={{ width: '130%', objectFit: "cover", cursor: "pointer" }} />
      {
        isSelected &&
        <>
          <div className="review-section-image-overlay">
            <div className="review-section-image-overlay-selection-text">
              Selected
            </div>
          </div>
        </>
      }
      <div className="review-section-image-gallery-title-overlay">
        {submission?.challenge?.title}
      </div>
      <img
        src={require('../../assets/misc_icons/inspect.png').default}
        className="review-section-image-inspect"
        onClick={() => { setSubmissionUnderInspectionHandler(submission) }}
      />
    </div>
  )
}

export default ReviewSectionGalleryImage;