/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Form,
  Button,
  Col
} from "reactstrap";

import ExternalLinksRow from './ExternalLinksRow';

const ExternalLinks = (props) => {
  const updateLinks = (links) => {
    props.externalLinksHandler(links);
  }

  const addRow = () => {
    let newExternalLinks = [];
    props.externalLinks.forEach((el) => {
      newExternalLinks.push(el);
    })
    newExternalLinks.push({ url: null, label: null });
    updateLinks(newExternalLinks);
  }

  const deleteRow = (deleteIndex) => {
    let newExternalLinks = [];
    props.externalLinks.forEach((el, idx) => {
      if (deleteIndex !== idx) {
        newExternalLinks.push(el);
      }
    })
    updateLinks(newExternalLinks);
  }

  const updateRow = (updatedLink, updateIndex) => {
    let newExternalLinks = [];
    props.externalLinks.forEach((el, idx) => {
      if (updateIndex === idx) {
        newExternalLinks.push(updatedLink);
      } else {
        newExternalLinks.push(el);
      }
    })
    updateLinks(newExternalLinks);
  }

  const createRows = () => {
    let rows = [];
    if (props.externalLinks === null || props.externalLinks.length === 0) {
      return [];
    }

    props.externalLinks.forEach((externalLink, idx) => {
      rows.push(
        <ExternalLinksRow
          label={externalLink.label}
          url={externalLink.url}
          deleteRowHandler={deleteRow}
          index={idx}
          key={idx + props.externalLinks.length.toString()}
          updateHandler={updateRow} 
          labelMd={props.labelMd}
          urlMd={props.urlMd}
          />)
    })
    return rows;
  }
  return (
    <>
      <h3 className="mb-0 mt-3">External Links</h3>
      <Form className="needs-validation" noValidate>
        {createRows()}
      </Form>
      <div className="mt-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button
          color="primary"
          type="button"
          onClick={addRow}
          style={{ height: 46 }}
        >
          Add External Link
        </Button>
        {
          props.isResetDisabled ?
            null
            : <Button
              className="btn-icon-only ml-2"
              color="primary"
              type="button"
              disabled={props.isResetDisabled}
              style={{ height: 46, width: 46 }}
              onClick={props.resetHandler}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-undo-alt" />
              </span>
            </Button>
        }
      </div>
    </>
  );
}

ExternalLinks.propTypes = {
  externalLinks: PropTypes.array,
  externalLinksHandler: PropTypes.func,
};

export default ExternalLinks;
