const STRIPE_PRICES = {
  // TEST_CREATOR_MONTHLY: "price_1NDYZjGMbYjsc6aAQpBWShwi",
  // DEPRECATED_2999_CREATOR_MONTHLY: "price_1NDWDVGMbYjsc6aAVGw2830b",
  // CREATOR MONTHLY IS A LEGACY PRICE, 4999 is the current active price
  CREATOR_MONTHLY: "price_1NIFxWGMbYjsc6aA5tQq2XSM",
  CREATOR_MONTHLY_4999: "price_1PaEqfGMbYjsc6aA1ShCotlL",
}

const VALID_MONTHLY_PRICES = [STRIPE_PRICES.CREATOR_MONTHLY, STRIPE_PRICES.CREATOR_MONTHLY_4999];

export { STRIPE_PRICES, VALID_MONTHLY_PRICES }