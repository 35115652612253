import React from "react";
import { UpdateChallengePackageContext } from './UpdateChallengePackageContext';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useParams, useHistory } from "react-router-dom";
import { CHALLENGE_PACKAGE_TYPE, DISTANCE, ANSWER_TYPE } from '../../constants';
import GetProtectedAPIClient from '../../api/protectedAPIClient';
import { UserInfoContext } from "contexts/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import { IsValidUrl } from "../../helpers/Validations";
import { IsEmpty } from "../../helpers/Strings";
import { GetPackageTypeName, IsValidPackageLookUpCode, VALID_CHARACTERS_STRING } from "../../helpers/ChallengePackage";
import { GetRandomArbitrary } from "../../helpers/Numbers";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import { PATH } from "constants/link";

const usernameRegEx = /^[0-9a-z_.]+$/;

const UpdateChallengePackageProvider = ({ children }) => {
  const { lookUpCode } = useParams();
  const location = useLocation();
  const isCreateMode = location.pathname.indexOf(PATH.MAIN_CREATE_PACKAGE) > -1;
  const data = React.useContext(UserInfoContext);
  const userId = data.user.id;

  const defaultChallengePackage = {
    id: "0",
    type: CHALLENGE_PACKAGE_TYPE.TRACK,
    title: "",
    description: "",
    externalLinks: [],
    youTubeLink: null,
    lookUpCode: "",
    isOfficialRoamliPackage: false,
    startTime: null,
    createdBy: userId,
    endTime: null,
    requiresAccessCode: false,
    blockDeactivate: false,
    challenges: [],
    anchorChallenge: null,
    anchorImage: null,
    hideLine: false,
    autoApproveSubmissions: false,
    hideFromList: true,
  };

  const [challengePackage, setChallengePackage] = React.useState(
    isCreateMode ?
      defaultChallengePackage
      : data.challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode })
  )

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const roles = user ? user["http://www.roamli.com/roles"] : null;
  const authenticatedAdmin = isAuthenticated && (roles.includes('Admin') || roles.includes('SuperAdmin'));

  const { notify } = React.useContext(NotificationContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [title, setTitle] = React.useState(challengePackage.title);
  const [packageLookUpCode, setPackageLookUpCode] = React.useState(challengePackage.lookUpCode);
  const [challengePackageType, setChallengePackageType] = React.useState(challengePackage.type ?? CHALLENGE_PACKAGE_TYPE.TRACK);
  const [description, setDescription] = React.useState(challengePackage.description);

  const [externalLinks, setExternalLinks] = React.useState(challengePackage.externalLinks);
  const [youTubeLink, setYouTubeLink] = React.useState(challengePackage.youTubeLink);

  const [startTime, setStartTime] = React.useState(challengePackage.startTime === null ? "" : new Date(challengePackage.startTime));
  const [isStartTimeValid, setIsStartTimeValid] = React.useState(true);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = React.useState("Please select a valid Start Time");

  const [endTime, setEndTime] = React.useState(challengePackage.endTime === null ? "" : new Date(challengePackage.endTime));
  const [isEndTimeValid, setIsEndTimeValid] = React.useState(true);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = React.useState("Please select a valid End Time");

  const [hideLine, setHideLine] = React.useState(challengePackage.hideLine ?? false);
  const [hideFromList, setHideFromList] = React.useState(challengePackage.hideFromList ?? true);
  const [autoApprove, setAutoApprove] = React.useState(challengePackage.autoApprove ?? false);

  const [isActive, setIsActive] = React.useState(challengePackage.active);
  const [isOfficial, setIsOfficial] = React.useState(challengePackage.isOfficialRoamliPackage);
  const [blockDeactivate, setBlockDeactivate] = React.useState(challengePackage.blockDeactivate);


  const [image, setImage] = React.useState(null);
  const [useLocalImage, setUseLocalImage] = React.useState(false);
  const [imageKey, setImageKey] = React.useState(0);
  const [anchorImageUrl, setAnchorImageUrl] = React.useState(!IsEmpty(challengePackage?.anchorImage) ? challengePackage?.anchorImage : null);

  const [checkingLookUpCode, setCheckingLookUpCode] = React.useState(false);
  const [isCodeUnique, setIsCodeUnique] = React.useState(undefined);

  const [duplicatePackageLookUpCode, setDuplicatePackageLookUpCode] = React.useState(null);
  const [checkingDuplicateLookUpCode, setCheckingDuplicateLookUpCode] = React.useState(false);
  const [isDuplicateCodeUnique, setIsDuplicateCodeUnique] = React.useState(undefined);
  const [duplicateUsername, setDuplicateUsername] = React.useState(undefined);
  const [isDuplicateUsernameValid, setIsDuplicateUsernameValid] = React.useState(false);
  const [checkingDuplicateUsername, setCheckingDuplicateUsername] = React.useState(false);
  const [duplicateUserInfo, setDuplicateUserInfo] = React.useState(undefined);
  const [creatingDuplicate, setCreatingDuplicate] = React.useState(false);
  const [typingTimeout, setTypingTimeout] = React.useState(null);

  const [globalHide, setGlobalHide] = React.useState(isCreateMode ? false : undefined);

  const newCPACs = [];
  if (challengePackage?.challenges) {
    challengePackage.challenges.forEach((challenge) => {
      newCPACs.push({ ...challenge });
    })
  }

  const [challenges, setChallenges] = React.useState(newCPACs);
  const [addChallengesModalOpen, setAddChallengesModalOpen] = React.useState(false);

  const [selectImageModalOpen, setSelectImageModalOpen] = React.useState(false);

  const [alert, setalert] = React.useState(false);

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
    history.go();
  }

  const resetForm = () => {
    const chalPack = data.challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode });
    setChallengePackage(chalPack);
    // props.challengePackageRefreshHandler(chalPack);
  }

  const successAlert = (createdChallengePackage) => {
    setalert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={
          () => {
            setalert(null);
            routeChange(PATH.MAIN_PACKAGE + "/" + createdChallengePackage.lookUpCode)
          }
        }
        // onCancel={() => {
        //   resetForm();
        //   setalert(null);
        //   document.documentElement.scrollTop = 0;
        //   document.scrollingElement.scrollTop = 0;
        // }}
        // cancelBtnBsStyle="warning"
        // cancelBtnText="Create Another"
        confirmBtnBsStyle="info"
        confirmBtnText="View Event"
        closeOnClickOutside={false}
        // showCancel
        btnSize=""
      >
        {"Your " + GetPackageTypeName(createdChallengePackage.type) + " was created!"}
      </ReactBSAlert>
    );
  };

  const duplicationSuccessAlert = (duplicatedChallengePackage) => {
    setalert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => { setalert(null); routeChange(PATH.MAIN_PACKAGE + "/" + duplicatedChallengePackage.lookUpCode) }}
        onCancel={() => {
          setalert(null);
        }}
        cancelBtnBsStyle="warning"
        cancelBtnText="Close"
        confirmBtnBsStyle="info"
        confirmBtnText="View Duplicate"
        closeOnClickOutside={false}
        showCancel
        btnSize=""
      >
        {"Your " + GetPackageTypeName(duplicatedChallengePackage.type) + " was duplicated!"}
      </ReactBSAlert>
    );
  };

  const checkTypingTimeout = (text) => {
    if (IsValidPackageLookUpCode(text)) {
      setCheckingLookUpCode(true);
      setIsCodeUnique(false);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
        setTypingTimeout(null);
      }

      setTypingTimeout(
        setTimeout(
          async () => { await checkIfLookUpCodeIsUnique(text) },
          750
        )
      );
    }
  }

  const checkDuplicateTypingTimeout = (text) => {
    if (IsValidPackageLookUpCode(text)) {
      setCheckingDuplicateLookUpCode(true);
      setIsDuplicateCodeUnique(false);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
        setTypingTimeout(null);
      }

      setTypingTimeout(
        setTimeout(
          async () => { await checkIfDuplicateLookUpCodeIsUnique(text) },
          750
        )
      );
    }
  }

  const checkDuplicateUsernameTypingTimeout = (text) => {
    setDuplicateUserInfo(undefined);
    if (authenticatedAdmin && isValidUsername(text)) {
      setCheckingDuplicateUsername(true);
      setIsDuplicateUsernameValid(false);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
        setTypingTimeout(null);
      }

      setTypingTimeout(
        setTimeout(
          async () => { await getUserInfo(text) },
          750
        )
      );
    }
  }

  const checkIfLookUpCodeIsUnique = async (code) => {
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const prodChallenge = "https://roamlidevchallenge.azurewebsites.net/api/Challenge";
      var challengeAPIClient = await GetProtectedAPIClient(prodChallenge, accessToken);

      await challengeAPIClient.get("/Package/IsLookUpCodeUnique?lookUpCode=" + code)
        .then(res => {
          if (res.data) {
            setCheckingLookUpCode(false);
            setIsCodeUnique(true);
          } else {
            setCheckingLookUpCode(false);
            setIsCodeUnique(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCheckingLookUpCode(false);
          setIsCodeUnique(false);
        })
    } catch (error) {
      console.log(error);
      setCheckingLookUpCode(false);
      setIsCodeUnique(false);
    }
  }

  const checkIfDuplicateLookUpCodeIsUnique = async (code) => {
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const prodChallenge = "https://roamlidevchallenge.azurewebsites.net/api/Challenge";
      var challengeAPIClient = await GetProtectedAPIClient(prodChallenge, accessToken);

      await challengeAPIClient.get("/Package/IsLookUpCodeUnique?lookUpCode=" + code)
        .then(res => {
          if (res.data) {
            setCheckingDuplicateLookUpCode(false);
            setIsDuplicateCodeUnique(true);
          } else {
            setCheckingDuplicateLookUpCode(false);
            setIsDuplicateCodeUnique(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCheckingDuplicateLookUpCode(false);
          setIsDuplicateCodeUnique(false);
        })
    } catch (error) {
      console.log(error);
      setCheckingDuplicateLookUpCode(false);
      setIsDuplicateCodeUnique(false);
    }
  }

  const getUserInfo = async (username) => {
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const prodUser = "https://roamlidevusers.azurewebsites.net/api/User";
      var userAPIClient = await GetProtectedAPIClient(prodUser, accessToken);
      await userAPIClient.get('ByUsername?username=' + username)
        .then(res => {
          if (res.data) {
            setDuplicateUserInfo(res.data);
            setCheckingDuplicateUsername(false);
            setIsDuplicateUsernameValid(true);
          } else {
            setCheckingDuplicateUsername(false);
            setIsDuplicateUsernameValid(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCheckingDuplicateUsername(false);
          setIsDuplicateUsernameValid(false);
        })
    } catch (error) {
      console.log(error);
      setCheckingDuplicateUsername(false);
      setIsDuplicateUsernameValid(false);
    }
  }

  const createDuplicate = async () => {
    setCreatingDuplicate(true);
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const prodChallenge = "https://roamlidevchallenge.azurewebsites.net/api/Challenge";
      var challengeAPIClient = await GetProtectedAPIClient(prodChallenge, accessToken);

      const body = {
        userId: duplicateUserInfo.id,
        auth0Id: duplicateUserInfo.auth0Id,
        type: challengePackage.type,
        originalLookUpCode: challengePackage.lookUpCode,
        newLookUpCode: duplicatePackageLookUpCode
      }

      await challengeAPIClient.post("/Package/Admin/Duplicate", body)
        .then(res => {
          if (res.data) {
            data.challengePackages.push(res.data);
            setDuplicateUserInfo(null);
            setDuplicateUsername(null);
            setIsDuplicateUsernameValid(false);
            setIsDuplicateCodeUnique(false)
            setDuplicatePackageLookUpCode(null)
            setCreatingDuplicate(false);
            duplicationSuccessAlert(res.data);
          } else {
            setCreatingDuplicate(false);
            notify("danger", "Duplication Failed", "Your " + GetPackageTypeName(challengePackageType) + " couldn't be duplicated. Please try again or contact support. (Err: 3001)");
          }
        })
        .catch((err) => {
          console.log(err);
          setCreatingDuplicate(false);
          notify("danger", "Duplication Failed", "Your " + GetPackageTypeName(challengePackageType) + " couldn't be duplicated. Please try again or contact support. (Err: 3002)");
        })
    } catch (error) {
      console.log(error);
      setCreatingDuplicate(false);
      notify("danger", "Duplication Failed", "Your " + GetPackageTypeName(challengePackageType) + " couldn't be duplicated. Please try again or contact support. (Err: 3003)");
    }
  }

  const isValidUsername = (username) => {
    return !IsEmpty(username) && username.length > 0 && usernameRegEx.test(username) && username.length <= 30;
  }

  const formatTime = (dateTime) => {
    if (dateTime === "") { return "" };
    const date = new Date(dateTime);
    return date.getFullYear() + "-" + formatDateTimeNumber(date.getMonth() + 1) + "-" + formatDateTimeNumber(date.getDate()) + "T" + formatDateTimeNumber(date.getHours()) + ":" + formatDateTimeNumber(date.getMinutes()) + ":00";
  }

  const formatDateTimeNumber = (time) => {
    if (time.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  const formattedLinks = () => {
    if (!areLinksValid()) {
      return challengePackage.externalLinks
    }
    return externalLinks;
  }

  const areLinksValid = () => {
    let areValid = true;
    externalLinks?.forEach((link) => {
      if (!IsValidUrl(link.url) || IsEmpty(link.label)) {
        areValid = false;
      }
    })

    return areValid;
  }

  const isYouTubeLinkValid = () => {
    if (!youTubeLink) return true;
    if (youTubeLink?.url) return true;
    //Label and Description are optional
    return false;
  }

  const formattedYouTubeLink = () => {
    if (!isYouTubeLinkValid()) {
      return challengePackage?.youTubeLink
    }
    return youTubeLink;
  }

  const areStartAndEndTimeValid = () => {
    return isStartTimeValid && isEndTimeValid;
  }

  //Too lazy to clean this up in the backend
  const sanitizeCPACs = () => {
    let cleanCPACs = [];
    challenges.forEach(
      (chal) => {
        let newChal = { ...chal };
        newChal.extraDescription = newChal.extraDescription ?? "";
        newChal.challenge = null;
        cleanCPACs.push(newChal);
      }
    )
    return cleanCPACs;
  }

  const formIsValidated = () => {
    let errors = [];

    if (isCreateMode && !image && !anchorImageUrl) {
      errors.push("Select an Image");
    }

    if (title === "" || title === null || title === undefined) {
      errors.push("Enter Valid Title");
    }

    if (!IsValidPackageLookUpCode(packageLookUpCode) && isCodeUnique) {
      errors.push("Enter Valid Lookup Code");
    }

    if (description === "" || description === null || description === undefined) {
      errors.push("Enter Valid Description");
    }

    if (!areStartAndEndTimeValid()) {
      errors.push("Start Time and/or End Time are invalid");
    }

    if (!areLinksValid()) {
      errors.push("One or more External Links are invalid");
    }

    if (!isYouTubeLinkValid()) {
      errors.push("Your YouTube Link is invalid");
    }

    if (challenges.length <= 0) {
      errors.push("Must include at least One Challenge");
    }

    if (errors.length > 0) {
      notify("warning", "Fix Errors", "Please fix the following errors before submitting:", errors);
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  }

  const getCreateObject = () => {
    return {
      createdBy: userId,
      title: IsEmpty(title) ? null : title,
      description: IsEmpty(description) ? null : description,
      type: challengePackageType,
      lookUpCode: packageLookUpCode,
      challengePackageId: challengePackage.id,
      externalLinks: formattedLinks(),
      youTubeLink: formattedYouTubeLink(),
      startTime: areStartAndEndTimeValid() ? startTime === "" ? null : startTime : challengePackage.startTime,
      endTime: areStartAndEndTimeValid() ? endTime === "" ? null : endTime : challengePackage.endTime,
      hideLine: hideLine,
      hideFromList: hideFromList,
      autoApproveSubmissions: autoApprove,
      mediaType: ANSWER_TYPE.PICTURE,
      mediaBytes: image ? image?.split("base64,")[1] : [],
      mediaName: image ? (userId + "-portalSubmitted").toLowerCase() : "",
      anchorImage: anchorImageUrl,
      challenges: challengesHaveChanged() ? sanitizeCPACs(challenges) : [],
      isOfficialRoamliPackage: isOfficial,
      requiresAccessCode: false,
      active: isActive,
      blockDeactivate: false,
    };
  }

  const getUpdateObject = () => {
    return {
      userId: userId,
      type: challengePackageType,
      title: IsEmpty(title) ? null : title,
      description: IsEmpty(description) ? null : description,
      challengePackageId: challengePackage.id,
      externalLinks: formattedLinks(),
      youTubeLink: formattedYouTubeLink(),
      startTime: areStartAndEndTimeValid() ? startTime === "" ? null : startTime : challengePackage.startTime,
      endTime: areStartAndEndTimeValid() ? endTime === "" ? null : endTime : challengePackage.endTime,
      active: isActive,
      hideLine: hideLine,
      hideFromList: hideFromList,
      autoApproveSubmissions: autoApprove,
      mediaType: ANSWER_TYPE.PICTURE,
      mediaBytes: image ? image?.split("base64,")[1] : [],
      mediaName: image ? (userId + "-portalSubmitted").toLowerCase() : "",
      anchorImageUrl: anchorImageUrl,
      challenges: challengesHaveChanged() ? sanitizeCPACs(challenges) : [],
      isOfficialRoamliPackage: isOfficial,
      // blockDeactivate: blockDeactivate,
    };
  }

  const updatePackage = async () => {
    setIsLoading(true);
    if (!isAuthenticated || !challengePackage) {
      notify("danger", "Update Failed", "Your updates couldn't be applied. Please try again or contact support. (ERR: 1001)")
      return;
    }
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const prodChallenge = "https://roamlidevchallenge.azurewebsites.net/api/Challenge";
      var challengeAPIClient = await GetProtectedAPIClient(prodChallenge, accessToken);

      if (isCreateMode) {
        let body = getCreateObject();
        if (formIsValidated()) {
          await challengeAPIClient.post("/Portal/CreatePackage", body)
            .then(res => {
              if (res.data) {
                console.log("SUCCESS");
                // Update challengePackages list without needing to reload all challengepackages
                data.challengePackages.push(res.data);
                successAlert(res.data);
                setIsLoading(false);
              } else {
                console.log("FAILURE");
                notify("danger", "Create Failed", "Your " + GetPackageTypeName(challengePackageType) + " couldn't be created. Please try again or contact support. (Err: 1002)");
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              notify("danger", "Create Failed", "Your " + GetPackageTypeName(challengePackageType) + " couldn't be created. Please try again or contact support. (Err: 1003)");
              setIsLoading(false);
            })
        }
      } else {
        let body = getUpdateObject();
        if (formIsValidated()) {
          await challengeAPIClient.put("/Portal/UpdatePackage", body)
            .then(res => {
              if (res.data) {
                console.log("SUCCESS");
                notify("success", "Update Complete!", "Your updates were successful.");
                let updatedChallengePackages = data.challengePackages.map(
                  (challengePackage) => {
                    if (challengePackage.id === res.data.id) { return res.data }
                    else { return challengePackage }
                  });
                // Update challengePackages list without needing to reload all challengepackages
                data.challengePackages = updatedChallengePackages;
                setIsLoading(false);
                resetForm();
              } else {
                console.log("FAILURE");
                notify("danger", "Update Failed", "Your updates couldn't be applied. Please try again or contact support. (ERR: 1002)")
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              notify("danger", "Update Failed", "Your updates couldn't be applied. Please try again or contact support. (Err: 1003)");
              setIsLoading(false);
            })
        }
      }

    } catch (e) {
      console.log(e.message);
      notify("danger", "Update Failed", "Your updates couldn't be applied. Please try again or contact support. (Err: 1004)");
      notify("danger", (isCreateMode ? "Creation" : "Update") + " Failed", "Your " + (isCreateMode ? GetPackageTypeName(challengePackageType) : "Updates") + " couldn't be " + (isCreateMode ? "created" : "applied") + ". Please try again or contact support. (ERR: 1004)")
      setIsLoading(false);
    }
  }

  const startTimeUpdateHandler = () => {
    if (startTime === "") {
      setIsStartTimeValid(true);
      setStartTimeErrorMessage("Start Time is not valid");
      // } else if (startTime < new Date()) {
      //   setIsStartTimeValid(false);
      //   setStartTimeErrorMessage("Start Time must be after now")
    } else if (endTime !== "" && startTime >= endTime) {
      setIsStartTimeValid(false);
      setStartTimeErrorMessage("Start Time must be before End Time")
    } else {
      setIsStartTimeValid(true);
      setStartTimeErrorMessage("Start Time is not valid");
    }
  }

  const endTimeUpdateHandler = () => {
    if (endTime === "") {
      setIsEndTimeValid(true);
      setEndTimeErrorMessage("End Time is not valid");
      // } else if (endTime < new Date()) {
      //   setIsEndTimeValid(false);
      //   setEndTimeErrorMessage("End Time must be after now")
    } else if (startTime !== "" && endTime <= startTime) {
      setIsEndTimeValid(false);
      setEndTimeErrorMessage("End Time must be after Start Time");
    } else {
      setIsEndTimeValid(true);
      setEndTimeErrorMessage("End Time is not valid");
    }
  }

  const timeUpdateHandler = () => {
    startTimeUpdateHandler();
    endTimeUpdateHandler();
  }

  React.useEffect(() => {
    timeUpdateHandler();
  }, [startTime, endTime])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setAnchorImageUrl(null);
        setUseLocalImage(true);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const convertChallengesToOtherPackageType = (packageType) => {
    const isHunt = packageType === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT;
    let newChallenges = [];
    challenges.forEach((challenge) => {
      const challengePackageChallenge = {
        challengeId: challenge.challengeId,
        order: challenge.order,
        radius: isHunt ? DISTANCE.SCAVENGER_HUNT_RADIUS : challenge.challenge.radius,
        centerLat: challenge.challenge.lat,
        centerLong: challenge.challenge.long,
        centerCoordinates: null,
        extraDescription: challenge.extraDescription,
        packagePoints: challenge.packagePoints,
        overrideHidden: challenge.overrideHidden,
        title: challenge.title,
        picChallengeUrl: challenge.picChallengeUrl,
        challengeType: challenge.challengeType,
        challengeSubTypes: challenge.challengeSubTypes,
        challenge: challenge.challenge,
      }

      if (isHunt) {
        const latModifierMin = GetRandomArbitrary(-0.001, 0.001);

        const longModifierMin = GetRandomArbitrary(-0.001, 0.001);

        const newCenter = {
          latitude: challenge.challenge.lat + latModifierMin,
          longitude: challenge.challenge.long + longModifierMin,
        }

        challengePackageChallenge.centerLat = newCenter.latitude;
        challengePackageChallenge.centerLong = newCenter.longitude;
      }

      newChallenges.push({ ...challengePackageChallenge });
    })

    setChallenges(newChallenges);
  }

  const addChallengeHandler = (challenge) => {
    const challengePackageChallenge = generateChallengePackageChallenge(challenge);
    let newChallenges = [];
    challenges.forEach((challenge) => {
      newChallenges.push({ ...challenge });
    })
    newChallenges.push(challengePackageChallenge);
    setChallenges(newChallenges);
  }

  const addChallengePackageChallengesHandler = (challengePackageChallenges) => {
    let newChallenges = [];
    challenges.forEach((challenge) => {
      newChallenges.push({ ...challenge });
    })
    challengePackageChallenges.forEach((cpc) => {
      newChallenges.push({ ...cpc });
    })
    setChallenges(newChallenges);
  }

  const generateChallengePackageChallenge = (challenge, order) => {
    const isHunt = challengePackageType === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT;

    const challengePackageChallenge = {
      challengeId: challenge.id,
      order: order ?? challenges.length,
      radius: isHunt ? DISTANCE.SCAVENGER_HUNT_RADIUS : challenge.radius,
      centerLat: challenge.lat,
      centerLong: challenge.long,
      centerCoordinates: null,
      extraDescription: "",
      packagePoints: 10,
      overrideHidden: !globalHide,
      title: challenge.title,
      picChallengeUrl: challenge.picChallengeUrl,
      challengeType: challenge.challengeType,
      challengeSubTypes: challenge.challengeSubTypes,
      challenge: challenge,
    }

    if (isHunt) {
      const latModifierMin = GetRandomArbitrary(-0.001, 0.001);

      const longModifierMin = GetRandomArbitrary(-0.001, 0.001);

      const newCenter = {
        latitude: challenge.lat + latModifierMin,
        longitude: challenge.long + longModifierMin,
      }

      challengePackageChallenge.centerLat = newCenter.latitude;
      challengePackageChallenge.centerLong = newCenter.longitude;
    }
    return challengePackageChallenge
  }

  const removeChallengeHandler = (challenge) => {
    const newChallenges = [];
    challenges.forEach((chal) => {
      if (chal.challengeId !== challenge.challengeId) {
        newChallenges.push({ ...chal });
      }
    });
    setChallenges(newChallenges.map(
      (chal, idx) => {
        chal.order = idx;
        return { ...chal }
      }
    ));
  }

  const updateCPACHandler = (updateObject, successCallback, failCallback) => {
    let foundCPAC = challenges.find((x) => { return x.challengeId === updateObject.originalCPAC.challengeId });
    let originalIndex = challenges.findIndex((x) => { return x.challengeId === updateObject.originalCPAC.challengeId });

    if (!foundCPAC) {
      if (failCallback) {
        failCallback()
      }
    } else {
      let updatedCPAC = { ...foundCPAC };
      updatedCPAC.title = updateObject.title;
      updatedCPAC.extraDescription = updateObject.extraDescription ?? "";
      updatedCPAC.radius = updateObject.radius;
      updatedCPAC.packagePoints = updateObject.packagePoints;
      updatedCPAC.overrideHidden = updateObject.overrideHidden;
      updatedCPAC.order = updateObject.order;

      let newChallenges = [];
      challenges.forEach((challenge, idx) => {
        if (idx === updateObject.order && (originalIndex > updateObject.order || originalIndex === updateObject.order)) {
          newChallenges.push({ ...updatedCPAC });
        }

        if (challenge.challengeId !== updatedCPAC.challengeId) {
          newChallenges.push({ ...challenge });
        }

        if (idx === updateObject.order && originalIndex < updateObject.order) {
          newChallenges.push({ ...updatedCPAC });
        }
      })

      setChallenges(
        newChallenges.map(
          (chal, idx) => {
            chal.order = idx;
            return chal
          }
        )
      );
      if (successCallback) {
        successCallback();
      }

    }
  }

  const handleGlobalHideChange = (globalHideStatus) => {
    let newChallenges = [...challenges];

    setChallenges(
      newChallenges.map(
        (chal, idx) => {
          chal.overrideHidden = !globalHideStatus;
          return chal
        }
      )
    );
  }

  const challengesHaveChanged = () => {
    return JSON.stringify(challengePackage?.challenges ?? []) !== JSON.stringify(challenges);
  }

  React.useEffect(() => {
    if (IsValidPackageLookUpCode(packageLookUpCode) && isCreateMode) {
      checkTypingTimeout(packageLookUpCode);
    }
  }, [packageLookUpCode])

  React.useEffect(() => {
    if (IsValidPackageLookUpCode(duplicatePackageLookUpCode) && authenticatedAdmin) {
      checkDuplicateTypingTimeout(duplicatePackageLookUpCode);
    }
  }, [duplicatePackageLookUpCode])

  React.useEffect(() => {
    if (authenticatedAdmin) {
      checkDuplicateUsernameTypingTimeout(duplicateUsername);
    }
  }, [duplicateUsername])

  const getLookUpCodeErrorText = () => {
    if (IsEmpty(packageLookUpCode) || packageLookUpCode?.length < 5 || packageLookUpCode?.length > 30) {
      return "Must be between 5 and 30 characters long.";
    }

    let hasIncorrectCharacter = false;
    packageLookUpCode?.split("").forEach(
      (letter) => {
        if (!hasIncorrectCharacter && !VALID_CHARACTERS_STRING.includes(letter)) {
          hasIncorrectCharacter = true
        }
      }
    )

    if (hasIncorrectCharacter) {
      return "Must only use Numbers and Letters.";
    }

    if (isCodeUnique === false) {
      return "Code must be unique.";
    }
  }

  const getDuplicateLookUpCodeErrorText = () => {
    if (IsEmpty(duplicatePackageLookUpCode) || duplicatePackageLookUpCode?.length < 5 || duplicatePackageLookUpCode?.length > 30) {
      return "Must be between 5 and 30 characters long.";
    }

    let hasIncorrectCharacter = false;
    duplicatePackageLookUpCode?.split("").forEach(
      (letter) => {
        if (!hasIncorrectCharacter && !VALID_CHARACTERS_STRING.includes(letter)) {
          hasIncorrectCharacter = true
        }
      }
    )

    if (hasIncorrectCharacter) {
      return "Must only use Numbers and Letters.";
    }

    if (isDuplicateCodeUnique === false) {
      return "Code must be unique.";
    }
  }

  return (
    <UpdateChallengePackageContext.Provider value={{
      imageKey,
      challengePackage,
      image,
      challengePackageType,
      isCreateMode,
      lookUpCode,
      globalHide,
      title,
      packageLookUpCode,
      isCodeUnique,
      checkingLookUpCode,
      description,
      startTime,
      isStartTimeValid,
      startTimeErrorMessage,
      endTime,
      isEndTimeValid,
      endTimeErrorMessage,
      externalLinks,
      youTubeLink,
      hideLine,
      hideFromList: hideFromList,
      challenges,
      autoApprove,
      authenticatedAdmin,
      isOfficial,
      isActive,
      duplicatePackageLookUpCode,
      isDuplicateCodeUnique,
      checkingDuplicateLookUpCode,
      duplicateUsername,
      isDuplicateUsernameValid,
      checkingDuplicateUsername,
      creatingDuplicate,
      addChallengesModalOpen,


      selectImageModalOpen,
      isLoading,
      anchorImageUrl,
      useLocalImage,
      alert,
      resetForm,

      addChallengeHandler,
      updatePackage,
      createDuplicate,
      setDuplicateUsername,
      isValidUsername,
      getDuplicateLookUpCodeErrorText,
      setDuplicatePackageLookUpCode,
      setIsActive,
      setIsOfficial,
      setAutoApprove,
      setChallenges,
      updateCPACHandler,
      setAddChallengesModalOpen,
      setHideLine,
      setHideFromList,
      removeChallengeHandler,
      setExternalLinks,
      setYouTubeLink,
      setEndTime,
      setStartTime,
      formatTime,
      setDescription,
      getLookUpCodeErrorText,
      setPackageLookUpCode,
      setTitle,
      setGlobalHide,
      handleGlobalHideChange,
      setChallengePackageType,
      setSelectImageModalOpen,
      onImageChange,
      setImage,
      setUseLocalImage,
      setImageKey,
      setAnchorImageUrl,
      convertChallengesToOtherPackageType,
      generateChallengePackageChallenge,
      addChallengePackageChallengesHandler
    }}>
      {
        children
      }
    </UpdateChallengePackageContext.Provider>
  );
}

export default UpdateChallengePackageProvider;
