const CHALLENGE_TYPE_DETAILS = {
	ARCHITECTURE: {
		details: "This should be set as a Primary Type if the Challenge has some unique or important architectural qualities.\n\nFor example, the Sydney Opera House should have a Primary type of 'Architecture'.\n\nThe Page Brothers Building in Chicago should have a Primary Type of 'Building'.",
		exampleImage: "",
		subTypes: [0]
	},
	BUILDING: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a building.\n\nThis can sometimes be replaced with 'Historic', 'Architecture', or 'Landmark', for example.",
		exampleImage: "",
		subTypes: [1, 0]
	},
	HISTORIC: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it has historic significance.\n\nThis has the potential to be used as a Primary Type for any Challenge, but will most often be a Secondary Type. Historic is defined as anything important that happened more than 50 years ago.",
		exampleImage: "",
		subTypes: [2]
	},
	MODERN: {
		details: "This will almost never be used as a Primary Type. It should be used as a Secondary Type if the style of the art or architecture is noticeably modern.",
		exampleImage: "",
		subTypes: [3]
	},
	HOUSE: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a house.\n\nThis can sometimes be replaced with 'Historic' or 'Architecture', for example.",
		exampleImage: "",
		subTypes: [4, 0]
	},
	MURAL: {
		details: "This should be set as a Primary Type if the defining quality about the Challenge is that it's a mural. This will most commonly used for street art.",
		exampleImage: "",
		subTypes: [5, 6, 10]
	},
	STREET_ART: {
		details: "Street Art will almost never be used as a Primary Type.\n\nIt should be used if the Challenge would be described as Street Art but not exactly a mural. This is very rare, so Mural is usually more appropriate.",
		exampleImage: "",
		subTypes: [6, 10]
	},
	STATUE: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a statue. Sometimes though things will be better listed as a Monument rather than a Statue, so use your best judgment",
		exampleImage: "",
		subTypes: [7, 10]
	},
	FOUNTAIN: {
		details: "Fountain is very straight forward: if it's a fountain, mark it as a fountain.\n\nUnlike building and house, it shouldn't be replaced with types like 'Historic' or 'Modern'.",
		exampleImage: "",
		subTypes: [8]
	},
	LANDMARK: {
		details: "This should be set as the Primary Type if the Challenge is recognized by the public as a Landmark, such as a skyscraper, a light house, or large rock formation.",
		exampleImage: "",
		subTypes: [9]
	},
	ART: {
		details: "This should only be used as a Primary Type if the Challenge is a work of art that can't be categorized as 'Street Art', 'Mural', or 'Statue'.\n\nCloud Gate in Chicago is an example of this, since we don't have a specific category for Sculptures. Otherwise this should be a Secondary Type.",
		exampleImage: "",
		subTypes: [10]
	},
	MONUMENT: {
		details: "This should be set as the Primary Type if the most defining quality about the Challenge is that it's a monument. The word 'Monument' will usually be in the name, but not always.\n\nThis one is not so obvious.",
		exampleImage: "",
		subTypes: [11]
	},
	NATURE: {
		details: "This should be a Primary Type for anything that doesn't have a more specific type but involes a lot of nature. Like the Alfred Caldwell Lily Pond in Chicago.\n\nArtwork that depicts nature should not be given a Secondary Type of Nature, this is strictly for real nature.",
		exampleImage: "",
		subTypes: [12]
	},
	VIEW: {
		details: "A Challenge doesn't have to be man-made, it could simply be a stunning view. Set this is a Primary Type if the challenge is just a nice view, or as a Secondary Type if there's a nice view from the challenge.",
		exampleImage: "",
		subTypes: [13]
	},
	WATERFRONT: {
		details: "This will almost always be a Secondary Type since there are more specific types you can use as a Primary Type, like 'River' or 'Waterfall'.\n\nUse this as a Secondary Type for anything that is close to water.",
		exampleImage: "",
		subTypes: [14]
	},
	TRAIL: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a trail. It will usually be marked as such.\n\nTrail here means an active hiking or walking trail, and will usually have the word 'Trail' in the name.",
		exampleImage: "",
		subTypes: [15, 20]
	},
	WATERFALL: {
		details: "A nice and easy one. If it's a waterfall, set 'Waterfall' as the Primary Type. This will only ever be a Secondary Type if the Primary Type is also 'Waterfall'",
		exampleImage: "",
		subTypes: [16, 12, 14]
	},
	BEACH: {
		details: "A nice and easy one. If it's a Beach, set 'Beach' as the Primary Type.\n\nThis can also be a Secondary Type as well, for example if a Trail Challenge is on a beach it can have a Secondary Type of 'Beach'",
		exampleImage: "",
		subTypes: [17, 12, 14, 20]
	},
	PARK: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a park.\n\nThis can be replaced as a Primary Type by types like 'Historic' or 'Nature' (if it is truly full of nature more than it is a park).",
		exampleImage: "",
		subTypes: [18, 20]
	},
	RIVER: {
		details: "If the Challenge is a river, the Primary Type should be set as 'River'. This can be a Secondary Type if something is nearby a river as well, like a riverwalk or a park.",
		exampleImage: "",
		subTypes: [19, 12]
	},
	ACTIVE: {
		details: "This will almost never be a Primary Type. This should really only ever be selected as a Secondary Type for a Primary Type like 'Beach' or 'Trail'.",
		exampleImage: "",
		subTypes: [20]
	},
	MUSEUM: {
		details: "Straightforward: if the Challenge is a museum set the Primary Type to 'Museum'. Museum can sometimes be a Secondary Type without being the Primary Type, but that's very rare.\n\nChallenges should only ever be made inside a museum if the museum is free and you are allowed to take photographs of the exhibits inside.\n\nPlease don't make Challenges out of temporary or paid exhibits.",
		exampleImage: "",
		subTypes: [21]
	},
	ZOO: {
		details: "This is a unique one. There are zoos, and there are Challenges within zoos. Challenges within zoos will only be populated if the zoo itself is free.\n\nIf the zoo is paid-entry, the Challenge should be the zoo itself.\n\nIf the zoo is free, then Challenges within the zoo should be created rather than the zoo itself.\n\nIf a Challenge is within a free zoo, the Primary Type should always be 'Zoo'.",
		exampleImage: "",
		subTypes: [22]
	},
	CONSERVATORY: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a Conservatory. If it's a Conservatory, the Primary Type is Conservatory.",
		exampleImage: "",
		subTypes: [23]
	},
	BRIDGE: {
		details: "This should be set as a Primary Type if the most defining quality about the Challenge is that it's a Bridge. Like with 'Building', this can be replaced by 'Historic' or something similar if its background warrants it.\n\nIf the bridge is over water, it should probably include 'River' or something similar as a Secondary Type.",
		exampleImage: "",
		subTypes: [24]
	},
	MYSTERY: {
		details: "Very mysterious mystery... Mystery should only be used if something just doesn't really fit any of these categories. For example, there is a small stone slide on the side of a flight of stairs in a park in Cincinnati.\n\nWhat is it there for? What's its purpose? Who knows... it's a Mystery...",
		exampleImage: "",
		subTypes: [25]
	},
	NOOK: {
		details: "The opposite of a view, a Nook is a small outdoor space that leaves you with a good vibe. This could be a corner of a park, an alley, or anything really. They might not even have any real significance other than looking nice, and that's okay.",
		exampleImage: "",
		subTypes: [26]
	},
	STADIUM: {
		details: "This should be set as a Primary Type if the structure is a Stadium, whether it be for sports, music, or anything else. This shouldn't ever be a Secondary Type.\n\nThe photo should be of the outside of the Stadium, not the inside (again, all Challenges should be free.)",
		exampleImage: "",
		subTypes: [27, 1, 0]
	},
	FOOD: {
		details: "This should be the Primary Type if the location has some cultural or historic significance about food. An example of this is Restaurant Pizzeria Uno, which is known as the place Deep Dish Pizza was invented.\n\nThough we all have our favorite restaurants, this should only be used for a location that is culturally or historically significant to food.",
		exampleImage: "",
		subTypes: [28]
	},
	MUSIC: {
		details: "This should be the Primary Type if the most defining quality about the Challenge is that it's musical. This will rarely be the case as it will almost always be a Secondary Type.\n\nAn example of this is the Pritzker Pavillion in Chicago, which is set with a Primary Type of Architecture and a Secondary Type of music.",
		exampleImage: "",
		subTypes: [29]
	},
	THEATER: {
		details: "This should be the Primary Type if the location is a theater. This will very rarely be set to as a Secondary Type.\n\nThe only case where this will be a Secondary Type is if the location has multiple functions and there is another that is more appropriate.",
		exampleImage: "",
		subTypes: [30,1,0]
	},
}

export { CHALLENGE_TYPE_DETAILS } 