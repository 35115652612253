/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import useBreakpoints from "hooks/Responsive";

const ChallengePackageCard = (
  {
    topText,
    middleText,
    bottomText,
    gradient,
    icon
  }) => {
  const { isTabletOrMobile } = useBreakpoints();

  if (isTabletOrMobile) {
    return (
      <Card className="card-stats">
        <CardBody>
          <Row>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <p className="mb-0 text-md">
                {bottomText}
              </p>
            </div>
            <Col className="col-auto">
              <div className={`icon icon-shape bg-gradient-${gradient} text-white rounded-circle shadow`}>
                {icon}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              {topText}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {middleText}
            </span>
          </div>
          <Col className="col-auto">
            <div className={`icon icon-shape bg-gradient-${gradient} text-white rounded-circle shadow`}>
              {icon}
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          {bottomText}
        </p>
      </CardBody>
    </Card>
  );
}

ChallengePackageCard.propTypes = {
  topText: PropTypes.string,
  middleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  bottomText: PropTypes.element,
  gradient: PropTypes.string,
  icon: PropTypes.element
};

export default ChallengePackageCard;
