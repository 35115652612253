import { IsEmpty } from 'helpers/Strings';

export const GetCurrentPointValue = (challenge) => {
  let pointValue = challenge.points;
  const currentTime = new Date();
  challenge.pointHistory.forEach((pointHistoryEntry) => {
    if (
      currentTime >= new Date(pointHistoryEntry.startTime)
      && currentTime < new Date(pointHistoryEntry.endTime)
    ) {
      pointValue = pointHistoryEntry.pointValue;
    }
  });
  return pointValue;
}

export const IsChallengeTextAnswer = (challenge) => {
  if (!challenge) return undefined;
  return challenge?.textAnswer?.length > 0 || !IsEmpty(challenge?.textQuestion);
}