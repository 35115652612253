/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react library for routing
import { Link } from "react-router-dom";
import classnames from "classnames";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import { PATH } from "constants/link";
import useBreakpoints from "hooks/Responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ViewNavbar from "./ViewNavbar";

const AuthNavbar = (props) => {
  let { solid, light } = props;
  const { isTabletOrMobile } = useBreakpoints();
  const location = useLocation();

  if (location.pathname.indexOf(PATH.PRICING) > -1 && isTabletOrMobile) solid = true;
  if (location.pathname.indexOf(PATH.VIEW_EVENT) > -1 && isTabletOrMobile) return <ViewNavbar />;
  if (location.pathname.indexOf(PATH.VIEW_EVENT) > -1 && !isTabletOrMobile) return null;
  return (
    <>
      <Navbar
        className={
          classnames(
            "navbar-horizontal navbar-main",
            { "navbar-dark bg-gradient-info": solid && !light },
            { "navbar-transparent": !solid },
            { "navbar-light bg-secondary": solid && light }
          )
        }
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <Row style={{ alignItems: 'center', flexWrap: 'nowrap', marginLeft: 0 }}>
              <img
                className="navbar-brand-img"
                style={{ height: 40, width: 40 }}
                src={require("assets/roamli-logo-white.png").default}
              />
              <h1 className="mb-0 ml-1" style={{ color: 'white', textTransform: 'capitalize' }}>Roamli</h1>
            </Row>
          </NavbarBrand>
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to={PATH.MAIN}>
                    <img
                      alt="..."
                      src={require("assets/roamli-logo.png").default}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                >
                  <Link to={PATH.HOME} style={{ color: isTabletOrMobile ? 'black' : 'white' }}>
                    {isTabletOrMobile && <i class="fa fa-user text-primary" />}
                    <span className="nav-link-inner--text ml-2">
                      Login
                    </span>
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                >
                  <Link to={PATH.SEARCH} style={{ color: isTabletOrMobile ? 'black' : 'white' }}>
                    {isTabletOrMobile && <i class="fa fa-search text-primary" />}
                    <span className="nav-link-inner--text ml-2">
                      Explore
                    </span>
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                >
                  <Link to={PATH.PRICING} style={{ color: isTabletOrMobile ? 'black' : 'white' }}>
                    {isTabletOrMobile && <i class="ni ni-shop text-primary" />}
                    <span className="nav-link-inner--text ml-2">
                      Pricing
                    </span>
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem className="d-lg-none">
                <NavLink
                  className="nav-link-icon"
                  href="https://www.roamli.com/contact-us"
                  target="_blank"
                >
                  {isTabletOrMobile && <i class="far fa-envelope text-primary" />}
                  <span className="nav-link-inner--text ml-2">
                    Contact Us
                  </span>
                </NavLink>
              </NavItem>
              <NavItem className="d-none d-lg-block ml-lg-4">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="https://www.roamli.com/contact-us"
                  target="_blank"
                >
                  <span className="nav-link-inner--text">Contact Us</span>
                </Button>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavbar;
