const CHALLENGE_TYPE_ICON = {
	ARCHITECTURE: "architecture",
	BUILDING: 'building',
	HISTORIC: "historic",
	MODERN: "modern",
	HOUSE: "house",
	MURAL: "mural",
	STREET_ART: "street_art",
	STATUE: "statue",
	FOUNTAIN: "fountain",
	LANDMARK: "landmark",
	ART: "art",
	MONUMENT: "monument",
	NATURE: "nature",
	VIEW: "view",
	WATERFRONT: "waterfront",
	TRAIL: "trail",
	WATERFALL: "waterfall",
	BEACH: "beach",
	PARK: "park",
	RIVER: "river",
	ACTIVE: "active",
	MUSEUM: "museum",
	// Zoo can also be zoo_flamingo, zoo_seal, and zoo_giraffe
	ZOO: "zoo_gorilla",
	CONSERVATORY: "conservatory",
	BRIDGE: "bridge",
	MYSTERY: "mystery",
	NOOK: "nook",
	STADIUM: "stadium",
	FOOD: "food",
	MUSIC: "music",
	THEATER: "theater",
}

export {CHALLENGE_TYPE_ICON} 