/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  UncontrolledTooltip,
  Modal,
  Button,
  Col,
  Row
} from "reactstrap";
import { GetFilterNameFromNumber } from '../../../../helpers/Filters';
import { SanitizeInternalString } from '../../../../helpers/Strings';
import CPACEditModal from "./CPACEditModal";
import Avatar from "../../../Images/RoundedImages/Avatar";
import { COLOR } from "constants/color";
import useBreakpoints from "hooks/Responsive";

const ChallengeViewTableRowV2 = (props) => {
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const { isTabletOrMobile } = useBreakpoints();
  const getCardSize = () => {
    if (isTabletOrMobile) return "sm";
    return "md";
  }
  const formatDate = (timestamp) => {
    if (timestamp === null) {
      return null;
    }

    let date = new Date(timestamp);

    return "" + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + formatTime(date.getMinutes());
  }

  const formatTime = (time) => {
    if (time.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  return (
    <Col style={{
      width: "100%",
      paddingTop: 20,
      paddingBottom: 20,
      backgroundColor: props.order % 2 !== 0 ? "#F5F5F5" : "white",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Col className="order text-muted" style={{ textAlign: 'center', paddingLeft: 0 }} xl={1} xs={1}>
        {props.order}
      </Col>
      <Col>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
          {/* <Avatar imageUrl={props.image} /> */}
          <b className="title" style={{ color: COLOR.ROAMLI_PURPLE_HEX, maxWidth: isTabletOrMobile ? 150 : 300 }}>{props.title}</b>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button
              color="primary"
              size={getCardSize()}
              onClick={(e) => { setEditModalOpen(true) }}>
              Edit/Reorder
            </Button>
            <Button
              size={getCardSize()}
              color="danger"
              onClick={(e) => { props.removeChallengeHandler() }}>
              X
            </Button>
            {
              editModalOpen ?
                <CPACEditModal
                  isOpen={editModalOpen}
                  closeHandler={() => { setEditModalOpen(false) }}
                  challenge={props.challenge}
                  challengePackage={props.challengePackage}
                  selectedChallenges={props.selectedChallenges}
                  updateHandler={props.updateHandler} />
                : null
            }
          </div>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <span className="address text-muted">
            Address: {props.address}
          </span>
        </Row>
        <Row style={{ justifyContent: "space-between" }}>
          <span className="points text-muted">
            Points: {props.points}
          </span>
          <span className="type text-muted">
            Type: {SanitizeInternalString(GetFilterNameFromNumber(props.type))}
          </span>
          <span className="locationBased text-muted">
            Has Location: {!props.noLocation ? "Yes" : "No"}
          </span>
        </Row>
      </Col>
    </Col>
  );
}

export default ChallengeViewTableRowV2;
