/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Input,
  Col,
  Button
} from "reactstrap";
import { IsValidUrl } from "../../helpers/Validations";

const ExternalLinksRow = (props) => {
  return (
    <div className="form-row mb-1" style={{ alignItems: 'flex-end' }}>
      <Col className="mb-3" md={props.labelMd ?? "2"}>
        <label
          className="form-control-label"
          htmlFor="externalLinksLabel"
        >
          Label
        </label>
        <Input
          defaultValue={props.label}
          id="externalLinksLabel"
          placeholder="Label"
          type="text"
          onChange={(e) => {
            props.updateHandler({ label: e.target.value, url: props.url }, props.index)
          }}
        />
        <div className="valid-feedback">Looks good!</div>
      </Col>
      <Col className="mb-3" md={props.urlMd ?? "4"} style={{ position: 'relative' }}>
        <label
          className="form-control-label"
          htmlFor="externalLinksUrl"
        >
          Url
        </label>
        <Input
          defaultValue={props.url}
          id="externalLinksUrl"
          placeholder="Url"
          type="text"
          invalid={!IsValidUrl(props.url)}
          onChange={(e) => {
            props.updateHandler({ label: props.label, url: e.target.value }, props.index)
          }}
        />
        <div className="invalid-feedback" style={{ position: 'absolute' }}>Urls must start with 'http://' or 'https://'</div>
      </Col>
      <Button
        className="btn-icon-only ml-2 mb-3"
        color="primary"
        type="button"
        style={{ height: 46, width: 46 }}
        onClick={() => { props.deleteRowHandler(props.index) }}
      >
        <span className="btn-inner--icon">
          <i className="fas fa-trash-alt" />
        </span>
      </Button>
    </div>
  );
}

ExternalLinksRow.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  index: PropTypes.number,
  deleteRowHandler: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default ExternalLinksRow;
