// import fetch from 'node-fetch';
import axios from 'axios';
import { saveAs } from 'file-saver';
const JSZip = require("jszip");

export function DownloadImagesToZip(imageObjects) {
  // console.log('hey');

  // const files = imageObjects;

  // const zip = new JSZip();

  // const request = async () => {
  //   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //   for (const { file, url } of files) {
  //     const response = await axios.get(url);
  //     console.log(response);
  //     const buffer = await response.buffer();
  //     zip.file(file, buffer);
  //   }
  // }

  // request()
  //   .then((res) => {
  //     // Set the name of the zip file in the download
  //     res.setHeader('Content-Disposition', 'attachment; filename="pictures.zip"')

  //     // Send the zip file
  //     zip.generateNodeStream({ type: 'nodebuffer', streamFiles: true })
  //       .pipe(res).on('finish', function () {
  //         console.log("out.zip written.");
  //       })
  //   })
}

export function DownloadImagesToZipTwo(imageObjects) {
  const url = "https://www.chicagotribune.com/resizer/w-MGsp9_4vlFb0-5gTNhUGKxnFA=/1200x0/top/www.trbimg.com/img-5b51eb4e/turbine/ct-food-best-gyros-chicago-pork-greek-20180713";
  // const JSZipUtils = require('jszip-utils');
  // function urlToPromise(url) {
  //   return new Promise(function (resolve, reject) {
  //     JSZipUtils.getBinaryContent(url, function (err, data) {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         resolve(data);
  //       }
  //     });
  //   });
  // }
  // let zip = new JSZip();
  // zip.file("applesauce.jpg", urlToPromise(url), { binary: true });
  // zip.generateAsync({ type: "blob" })
  //   .then(function callback(blob) {

      // see FileSaver.js
      // saveAs(url, "example.jpg");
      saveAs("https://httpbin.org/image", "image.jpg");
    // });
}