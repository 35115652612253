/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  UncontrolledTooltip,
  Modal,
  Button,
  Col
} from "reactstrap";

import { DownloadImagesToZip, DownloadImagesToZipTwo } from '../../../helpers/Download';

import MasonryGallery from './MasonryGallery';

const IMAGE_SIZE = {
  LARGE: "large",
  SMALL: "small",
}

const MasonryModal = (props) => {
  const [imageSize, setImageSize] = React.useState(IMAGE_SIZE.LARGE);

  const files = props.urls.map((url, idx) => {
    return { url: url, file: idx + ".jpg" }
  })

  const getCardClassName = (imageSizeDefault, imageSizeActual) => {
    if (imageSizeDefault !== imageSizeActual) { return "btn-neutral" }
    return "btn-primary"
  }

  const getCardSize = (imageSizeDefault, imageSizeActual) => {
    return "sm"
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={props.isOpen}
      toggle={props.closeHandler}
      scrollable
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {props.title}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.closeHandler}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <MasonryGallery urls={props.urls} imageSize={imageSize} />
      </div>
      <div className="modal-footer">
        {/* <Button color="primary" type="button" onClick={() => { DownloadImagesToZipTwo(files) }}>
          Download All
        </Button> */}
        <Col className="text-right" lg="6" xs="5" style={{ minHeight: 51 }}>
          <Button
            className={getCardClassName(imageSize, IMAGE_SIZE.SMALL)}
            color="default"
            onClick={() => { setImageSize(IMAGE_SIZE.SMALL) }}
            size={getCardSize(imageSize, IMAGE_SIZE.SMALL)}
          >
            Small
          </Button>
          <Button
            className={getCardClassName(imageSize, IMAGE_SIZE.LARGE)}
            color="default"
            onClick={() => { setImageSize(IMAGE_SIZE.LARGE) }}
            size={getCardSize(imageSize, IMAGE_SIZE.LARGE)}
          >
            Large
          </Button>
        </Col>
      </div>
    </Modal>
  );
}

export default MasonryModal;
