import React from 'react';

const ActivePackageContext = React.createContext(
  {
    activePackage: null,
    submittingChallengeAnswer: {
      challengePackage: null,
      challenge: null,
      submissionProgress: 0
    },
    activatingPackage: false,
    setSubmittingChallengeAnswer: () => { },
    isPackageActivePackage: () => { },
    setActivatingPackage: () => { },
    ActivatePackage: () => { },
    SubmitChallengeAnswer: () => { },
    isChallengeBeingSubmitted: () => { },
  });

export { ActivePackageContext };