import React from 'react';
import GoogleApiWrapper from 'components/GoogleMap/ViewPackageMap/ViewPackageMapDetails';
import useWindowDimensions from 'hooks/Dimensions';
import useBreakpoints from 'hooks/Responsive';
import { UpdateChallengePackageContext } from "contexts/UpdateChallengePackageContext/UpdateChallengePackageContext";

const ViewPackageDetails = (props) => {
  const { height, width } = useWindowDimensions();
  const { isTabletOrMobile } = useBreakpoints();
  const {
    challengePackage,
    removeChallengeHandler,
    hideLine,
    challenges,
    challengePackageType,
    isCreateMode
  } = React.useContext(UpdateChallengePackageContext);

  return (
    <GoogleApiWrapper
      isCreateMode={isCreateMode}
      isTabletOrMobile={isTabletOrMobile}
      userData={props.userData}
      lat={challengePackage?.packageArea?.coordinate?.latitude ?? 41.895081}
      lng={challengePackage?.packageArea?.coordinate?.longitude ?? -87.629879}
      hideLine={hideLine}
      type={challengePackageType}
      challengePackage={challengePackage}
      containerStyle={
        { position: "relative", height: isTabletOrMobile ? height * .6 : height, width: "100%", maxWidth: "100%" }}
      challenges={challenges}
      removeChallengeHandler={removeChallengeHandler}
      setViewMode={props.setViewMode}
    />
  );
}

export default ViewPackageDetails;