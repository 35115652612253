// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Form,
  Button,
  Col
} from "reactstrap";
import ChallengeTypeWithIcon from "./ChallengeTypeWithIcon";
import ChallengeTypeModal from "./ChallengeTypeModal";
import { GetFilterNameFromNumber } from "../../helpers/Filters";

const ChallengeTypeSelection = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  let challengeTypes = Array.isArray(props.challengeTypes) ? props.challengeTypes : [props.challengeTypes];
  const challengeTypeRows =
    challengeTypes
      .sort((a, b) => { return GetFilterNameFromNumber(a) < GetFilterNameFromNumber(b) ? -1 : 1 })
      .map((cst) =>
        <ChallengeTypeWithIcon
          marginTop={true}
          style={{ minWidth: 200 }}
          challengeType={cst}
          isRequired={props.requiredChallengeTypes?.includes(cst)} />);
  return (
    <>
      <Col className="mb-4" md={props.width ?? "6"} style={{ paddingLeft: 0, flexWrap: "wrap" }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <label
            className="form-control-label"
            htmlFor="validationCustom03"
          >
            {props.label ?? ""}
          </label>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap" }}>
          {challengeTypeRows}
        </div>
        <div className="mt-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button
            color="primary"
            type="button"
            style={{ height: 46 }}
            onClick={() => { setModalOpen(true) }}
          >
            Change Types
          </Button>
          {
            props.isResetDisabled ?
              null
              : <Button
                className="btn-icon-only ml-2"
                color="primary"
                type="button"
                disabled={props.isResetDisabled}
                style={{ height: 46, width: 46 }}
                onClick={props.resetHandler}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-undo-alt" />
                </span>
              </Button>
          }
        </div>
      </Col>
      {
        modalOpen ?
          <ChallengeTypeModal
            isOpen={modalOpen}
            closeHandler={() => { setModalOpen(false) }}
            headerLabel={props.label}
            selectedChallengeTypes={props.challengeTypes}
            requiredChallengeTypes={props.requiredChallengeTypes}
            canSelectOnlyOne={props.canSelectOnlyOne}
            onSelectHandler={props.onSelectHandler} />
          : null
      }
    </>
  );
}

ChallengeTypeSelection.propTypes = {
  canSelectOnlyOne: PropTypes.bool,
  challengeTypes: PropTypes.array,
  requiredChallengeTypes: PropTypes.array,
  label: PropTypes.string,
  onSelectHandler: PropTypes.func
};

export default ChallengeTypeSelection;
