import React from 'react';

const UserInfoContext = React.createContext({
  user: {},
  isUserLoaded: false,
  isLoaded: false,
  arePackagesLoaded: false,
  challengePackages: [],
  setChallengePackages: () => { },
  challenges: [],
  updateProfilePicture: () => { },
  updatingProfilePhoto: false,
  deletingAccount: false,
  deleteAccount: () => {},
});

export { UserInfoContext };