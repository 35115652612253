import React from 'react';

const UpdateChallengePackageContext = React.createContext({
  challengePackage: false,
  image: false,
  challengePackageType: false,
  isCreateMode: false,
  lookUpCode: false,
  globalHide: false,
  title: false,
  packageLookUpCode: false,
  isCodeUnique: false,
  checkingLookUpCode: false,
  description: false,
  startTime: false,
  isStartTimeValid: false,
  startTimeErrorMessage: false,
  endTime: false,
  isEndTimeValid: false,
  endTimeErrorMessage: false,
  externalLinks: false,
  youTubeLink: null,
  hideLine: false,
  hideFromList: false,
  challenges: false,
  autoApprove: false,
  authenticatedAdmin: false,
  isOfficial: false,
  isActive: false,
  duplicatePackageLookUpCode: false,
  isDuplicateCodeUnique: false,
  checkingDuplicateLookUpCode: false,
  duplicateUsername: false,
  isDuplicateUsernameValid: false,
  checkingDuplicateUsername: false,
  creatingDuplicate: false,
  addChallengesModalOpen: () => { },
  addChallengeHandler: () => { },
  updatePackage: () => { },
  createDuplicate: () => { },
  setDuplicateUsername: () => { },
  isValidUsername: () => { },
  getDuplicateLookUpCodeErrorText: () => { },
  setDuplicatePackageLookUpCode: () => { },
  setIsActive: () => { },
  setIsOfficial: () => { },
  setAutoApprove: () => { },
  setChallenges: () => { },
  updateCPACHandler: () => { },
  setAddChallengesModalOpen: () => { },
  setHideLine: () => { },
  setHideFromList: () => { },
  removeChallengeHandler: () => { },
  setExternalLinks: () => { },
  setYouTubeLink: () => { },
  setEndTime: () => { },
  setStartTime: () => { },
  formatTime: () => { },
  setDescription: () => { },
  getLookUpCodeErrorText: () => { },
  setPackageLookUpCode: () => { },
  setTitle: () => { },
  setGlobalHide: () => { },
  handleGlobalHideChange: () => { },
  setChallengePackageType: () => { },
  setSelectImageModalOpen: () => { },
  onImageChange: () => { },
  setImage: () => { },
  setUseLocalImage: () => { },
  setImageKey: () => { },
  setAnchorImageUrl: () => { },
  convertChallengesToOtherPackageType: () => { },
  generateChallengePackageChallenge: () => { },
  addChallengePackageChallengesHandler: () => {},
});

export { UpdateChallengePackageContext };