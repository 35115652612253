import axios from 'axios';

const GetProtectedAPIClient = async (baseUrl, accessToken) => {

  let protectedClient = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: 'application/json',
    },
  });
  protectedClient.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : null;
  return protectedClient;
}

export default GetProtectedAPIClient;