import React from "react";
import ReactExport from "react-export-excel";
import { Button } from 'reactstrap';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Download(props) {
  const createSheet = () => {
    if (props.data.length === 0) {
      return <ExcelSheet data={props.data} name="Challenges" />;
    }

    let columns = [];
    Object.keys(props.data[0]).forEach((key) => {
      columns.push(<ExcelColumn key={key} label={key} value={key} />)
    })
    return <ExcelSheet data={props.data} name="Challenges">
      {columns}
    </ExcelSheet>;
  }

  return (
    <ExcelFile
      filename={props.filename}
      element={
        <Button aria-controls="simple-menu" aria-haspopup="true" color="primary" style={{ display: 'flex', alignItems: 'center' }}>
          <i className="ni ni-collection mr-2" />
          Download
        </Button>}>
      {createSheet()}
    </ExcelFile>
  );
}