/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
import classnames from "classnames";
// reactstrap components
import {
  Table,
  Button,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ChallengeViewTableRow from "./ChallengeViewTableRow";
import Download from '../../../Utility/Download';
import { GetFilterNameFromNumber } from '../../../../helpers/Filters';
import { SanitizeInternalString } from '../../../../helpers/Strings';
import { PaginationControls } from '../../../Pagination/PaginationControls';
import { TABLE_PAGE_COUNT } from '../../../../constants';
import ResetChallengesConfirmationModal from "./ResetChallengesConfirmationModal";

const PAGE_COUNT = TABLE_PAGE_COUNT;

const CHALLENGE_SORT_TYPE = {
  NONE: "none",
  ORDER: "order",
  TITLE: "title",
  SUBMISSIONS: "submissions",
  POINTS: "points",
  TYPE: "type",
  ADDRESS: "address",
  PERSONAL: "personal",
  LOCATION_BASED: "locationBased",
}

const ChallengeViewTable = (props) => {
  const firstListRef = React.useRef(null);
  const [pagination, setPagination] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState(1);
  const [sortType, setSortType] = React.useState(CHALLENGE_SORT_TYPE.ORDER);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [resetChallengesConfirmationModalOpen, setResetChallengesConfirmationModalOpen] = React.useState(false);

  const challengesData = props.challenges;

  let challengeSubmissions = {};

  const createRows = (packageChallenges) => {
    let rows = [];
    packageChallenges.forEach((packageChallenge) => {
      let challenge = packageChallenge.challenge;
      let points = packageChallenge.packagePoints ? packageChallenge.packagePoints : 10;
      rows.push(
        <ChallengeViewTableRow
          order={packageChallenge.order + 1}
          title={packageChallenge.title}
          image={challenge?.picChallengeUrl}
          points={points}
          type={packageChallenge.challengeType}
          address={challenge?.address ? challenge?.address?.displayedAddress : ""}
          isPersonal={challenge?.isPersonal}
          noLocation={challenge?.noLocation}
          removeChallengeHandler={() => { props.removeChallengeHandler(packageChallenge) }}
          updateHandler={props.updateHandler}
          challenge={packageChallenge}
          challengePackage={props.challengePackage}
          selectedChallenges={props.challenges}
        />)
    })

    return rows;
  }

  const getSearchResults = (challenges) => {
    if (searchQuery === "" || searchQuery === null) {
      return challenges;
    }

    const filtered = challenges.filter(
      (challenge) => {
        let fullChallenge = challenge.challenge;
        const chalType = SanitizeInternalString(GetFilterNameFromNumber(challenge.challengeType))
        return (challenge.title !== null && challenge.title.toLowerCase().includes(searchQuery.toLowerCase()))
          || (fullChallenge.address?.displayedAddress !== null && fullChallenge.address?.displayedAddress.toLowerCase().includes(searchQuery.toLowerCase()))
          || (chalType !== null && chalType.toLowerCase().includes(searchQuery.toLowerCase()))
      }
    )

    return filtered
  }

  const getPaginatedData = (noPagination) => {
    let challenges = props.challenges;
    if (sortType === CHALLENGE_SORT_TYPE.ORDER) {
      challenges = sortByOrder();
    } else if (sortType === CHALLENGE_SORT_TYPE.TITLE) {
      challenges = sortByTitle();
    } else if (sortType === CHALLENGE_SORT_TYPE.POINTS) {
      challenges = sortByPoints();
    } else if (sortType === CHALLENGE_SORT_TYPE.TYPE) {
      challenges = sortByType();
    } else if (sortType === CHALLENGE_SORT_TYPE.ADDRESS) {
      challenges = sortByAddress();
    } else if (sortType === CHALLENGE_SORT_TYPE.PERSONAL) {
      challenges = sortByIsPersonal();
    } else if (sortType === CHALLENGE_SORT_TYPE.LOCATION_BASED) {
      challenges = sortByIsLocationBased();
    } else if (sortType === CHALLENGE_SORT_TYPE.SUBMISSIONS) {
      challenges = sortByNumberOfSubmissions();
    }

    challenges = getSearchResults(challenges);

    if (noPagination) {
      return challenges;
    }

    return challenges.slice(PAGE_COUNT * pagination, PAGE_COUNT * pagination + PAGE_COUNT);
  }

  const sortByTitle = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let titleA = a.title == null ? "" : a.title.toUpperCase();
        let titleB = b.title == null ? "" : b.title.toUpperCase();
        return (titleA < titleB ? -1 : (titleA > titleB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByNumberOfSubmissions = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = challengeSubmissions[a.challengeId] ? challengeSubmissions[a.challengeId].length : 0;
        let compareB = challengeSubmissions[b.challengeId] ? challengeSubmissions[b.challengeId].length : 0;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByOrder = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.order;
        let compareB = b.order;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByPoints = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.packagePoints == null ? a.packagePoints : 10;
        let compareB = b.packagePoints == null ? b.packagePoints : 10;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByType = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challengeType;
        let compareB = b.challengeType;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByAddress = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challenge.address ? a.challenge.address.displayedAddress : "";
        let compareB = b.challenge.address ? b.challenge.address.displayedAddress : "";
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsPersonal = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challenge.isPersonal;
        let compareB = b.challenge.isPersonal;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsLocationBased = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challenge.noLocation ? 1 : -1;
        let compareB = b.challenge.noLocation ? 1 : -1;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const paginatedData = getPaginatedData();

  React.useEffect(() => {
    setPagination(0);
  }, [sortType, sortDirection, searchQuery])

  const onClickHandler = (newSortType) => {
    setSortDirection(newSortType === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1);
    setSortType(newSortType);
  }

  const challengesHaveChanged = () => {
    return JSON.stringify(props.challengePackage.challenges ?? []) !== JSON.stringify(props.challenges)
  }

  return (
    <>
      <CardHeader className="border-0">
        <Row style={{ alignItems: 'center' }}>
          <Col xs="6">
            <Row style={{ alignItems: 'center' }}>
              <h3 className="mb-0">{"Challenges (" + props.challenges.length + "/100)"}</h3>
              {
                challengesHaveChanged() ?
                  <Button
                    className="ml-2"
                    color="primary"
                    type="button"
                    onClick={() => { setResetChallengesConfirmationModalOpen(true) }}
                  >
                    <span>
                      Undo Changes
                  <i className="fas fa-undo-alt ml-2" />
                    </span>
                  </Button>
                  : null
              }
            </Row>
          </Col>
          <Col className="text-right" xs="6">
            <Row className="text-right mr-1" style={{ justifyContent: 'flex-end' }}>
              <Form
                onSubmit={e => { e.preventDefault(); }}
                style={{ justifyContent: 'flex-end' }}
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                  "navbar-search-dark"
                )}
              >
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      key={"search"}
                      placeholder={"Search Title, Address, or Type"}
                      type="text"
                      onChange={(e) => { setSearchQuery(e.target.value) }} />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Button
                color="primary"
                type="button"
                disabled={props.challenges.length >= 100}
                onClick={props.openAddChallengesModalHandler}
                style={{ minWidth: 160, minHeight: 55 }}
              >
                ADD CHALLENGES
              </Button>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <div className="table-responsive" ref={firstListRef}>
        <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              <th data-sort="order" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ORDER) }}>#</th>
              <th data-sort="title" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TITLE) }}>Title</th>
              {/* <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.SUBMISSIONS) }}># of Submissions</th> */}
              <th data-sort="points" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.POINTS) }}>Point Value</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TYPE) }}>Type</th>
              <th data-sort="address" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ADDRESS) }}>Address</th>
              <th data-sort="personal" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.PERSONAL) }}>Personal</th>
              <th data-sort="locationBased" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.LOCATION_BASED) }}>Location Based</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody className="list">
            {createRows(paginatedData)}
          </tbody>
        </Table>
      </div >
      <PaginationControls
        dataSet={getSearchResults(challengesData)}
        currentPage={pagination}
        setPaginationHandler={setPagination} />
      {
        resetChallengesConfirmationModalOpen ?
          <ResetChallengesConfirmationModal
            isOpen={resetChallengesConfirmationModalOpen}
            closeHandler={() => { setResetChallengesConfirmationModalOpen(false) }}
            resetHandler={props.resetHandler}
          />
          : null
      }
    </>
  );
}

export default ChallengeViewTable;
