/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import Breadcrumbs from './Breadcrumbs';
import { VIEW_MODE } from '../../constants';
import ChallengePackageCard from "./ChallengePackageCard";
import useBreakpoints from "hooks/Responsive";

const ChallengePackageCardsHeader = (
  {
    name,
    parentName,
    subName,
    analytics,
    challengePackageInfoHandler,
    challengesHandler,
    participantsHandler,
    reviewHandler,
    challengePackage,
    viewMode }) => {
  const { isTabletOrMobile } = useBreakpoints();
  const getCardClassName = (viewModeDefault, viewModeActual) => {
    if (viewModeDefault === viewModeActual) { return "btn-neutral" }
    return "btn-primary"
  }

  const getCardSize = (viewModeDefault, viewModeActual) => {
    if (isTabletOrMobile) return "sm";
    if (viewModeDefault === viewModeActual) { return "lg" };
    return "md";
  }

  const navButtons = () => {
    return <>
      <Button
        className={getCardClassName(viewMode, VIEW_MODE.CHALLENGES)}
        color="default"
        onClick={challengesHandler}
        size={getCardSize(viewMode, VIEW_MODE.CHALLENGES)}
      >
        Challenges
      </Button>
      <Button
        className={getCardClassName(viewMode, VIEW_MODE.PARTICIPANTS)}
        color="default"
        onClick={participantsHandler}
        size={getCardSize(viewMode, VIEW_MODE.PARTICIPANTS)}
      >
        Participants
      </Button>
      <Button
        className={getCardClassName(viewMode, VIEW_MODE.CHALLENGE_PACKAGE_INFO)}
        color="default"
        onClick={challengePackageInfoHandler}
        size={getCardSize(viewMode, VIEW_MODE.CHALLENGE_PACKAGE_INFO)}
      >
        Details
      </Button>
      <Button
        className={getCardClassName(viewMode, VIEW_MODE.REVIEW_CHALLENGES)}
        color="default"
        onClick={reviewHandler}
        disabled={challengePackage.autoApproveSubmissions || analytics.submissionsInReview === 0}
        size={getCardSize(viewMode, VIEW_MODE.REVIEW_CHALLENGES)}
      >
        Review
      </Button>
    </>;
  }

  return (
    <div className="header bg-info pb-6">
      <Container fluid>
        <div className="header-body">
          {
            !isTabletOrMobile ?
              <Row className="align-items-center py-4">
                <Breadcrumbs name={name} parentName={parentName} subName={subName ?? name} />
                <Col className="text-right" lg="6" xs="5" style={{ minHeight: 51 }}>
                  {navButtons()}
                </Col>
              </Row> :
              <Col className="align-items-center pt-4 pb-4 pl-0 pr-0">
                <Breadcrumbs name={name} parentName={parentName} subName={subName ?? name} containerStyle={{ paddingLeft: 0, paddingBottom: 10 }} />
              </Col>
          }
          {/* <Row className="align-items-center py-4">
            <Breadcrumbs name={name} parentName={parentName} subName={subName ?? name} />
            {
              !isTabletOrMobile ? <Col className="text-right" lg="6" xs="5" style={{ minHeight: 51 }}>
                {navButtons()}
              </Col>
                : <Row className="text-right">
                  {navButtons()}
                </Row>
            }
          </Row> */}

          <Row>
            <Col md="6" xl="3">
              <ChallengePackageCard
                topText="Lookups"
                middleText={analytics.totalLookups}
                icon={<i className="ni ni-active-40" />}
                gradient="red"
                bottomText={<>
                  <b className="text-success mr-2">
                    {analytics.uniqueLookups}
                  </b>
                  <span className="text-nowrap">{"Unique Lookups"}</span>
                </>}
              />
            </Col>
            <Col md="6" xl="3">
              <ChallengePackageCard
                topText="Total Submissions"
                middleText={analytics.totalSubmissions}
                icon={<i className="ni ni-chart-pie-35" />}
                gradient="orange"
                bottomText={<>
                  <span className={analytics.submissionsInReview == 0 ? "text-success mr-2" : "text-danger mr-2"}>
                    {analytics.submissionsInReview}
                  </span>
                  <span className="text-nowrap">Submissions In Review</span>
                </>}
              />
            </Col>
            <Col md="6" xl="3">
              <ChallengePackageCard
                topText="Challenge Coverage"
                middleText={Math.floor(analytics.challengeCoverage.submitted / analytics.challengeCoverage.total * 100) + "%"}
                icon={<i className="ni ni-chart-bar-32" />}
                gradient="green"
                bottomText={<>
                  <span className="text-success mr-2">
                    {analytics.challengeCoverage.submitted + "/" + analytics.challengeCoverage.total}
                  </span>
                  <span className="text-nowrap">Submitted at least once</span>
                </>}
              />
            </Col>
            <Col md="6" xl="3">
              <ChallengePackageCard
                topText="Most Completions"
                middleText={analytics.mostSubmissions.count ?? "0"}
                icon={<i className="ni ni-check-bold" />}
                gradient="primary"
                bottomText={
                  analytics.mostSubmissions.users ?
                    <>
                      <span className="text-nowrap mr-2">{"Submitted By"}</span>
                      <b className="text-success mr-2">
                        {analytics.mostSubmissions.users}
                      </b>
                      <span className="text-nowrap">
                        {
                          analytics.mostSubmissions.users === 1 ? "User" : "Users"
                        }
                      </span>
                    </>
                    : <span className="text-nowrap mr-2">{"No Users Submissions"}</span>
                }
              />
            </Col>
          </Row>
          {
            isTabletOrMobile && <Row className="text-right pb-3" style={{ margin: 0, justifyContent: 'center' }}>
              {navButtons()}
            </Row>
          }
        </div>
      </Container>
    </div>
  );
}

ChallengePackageCardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  subName: PropTypes.string,
  packageAccesses: PropTypes.array,
  challengesHandler: PropTypes.any,
  participantsHandler: PropTypes.any,
  viewMode: PropTypes.string,
};

export default ChallengePackageCardsHeader;
