/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  UncontrolledTooltip,
  Modal,
  Button,
  Col,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { CHALLENGE_PACKAGE_TYPE } from "../../../../constants";
import { GetPackageTypeName } from "../../../../helpers/ChallengePackage";
import ChallengeFormFieldRow from "../../ChallengeForm/ChallengeFormFieldRow";


const CPACEditModal = (props) => {
  const challenge = props.challenge;
  const [title, setTitle] = React.useState(challenge.title);
  const [extraDescription, setExtraDescription] = React.useState(challenge.extraDescription ?? "");
  const [radius, setRadius] = React.useState(challenge.radius);
  const [packagePoints, setPackagePoints] = React.useState(challenge?.packagePoints ?? challenge?.challenge?.points ?? 10);
  const [overrideHidden, setOverrideHidden] = React.useState(challenge.overrideHidden);
  const [order, setOrder] = React.useState(challenge.order + 1);

  const updateObject = {
    originalCPAC: props.challenge,
    title: title,
    extraDescription: extraDescription,
    radius: radius,
    packagePoints: packagePoints,
    overrideHidden: overrideHidden,
    order: order - 1,
  }

  const updatesAreValid = () => {
    if (title === "" || title === null || title === undefined) {
      return false;
    }

    if (isNaN(packagePoints) || !packagePoints || packagePoints < 0) {
      return false;
    }

    // if (!isValidLatitude()) {
    //   errors.push("Enter Valid Latitude");
    // }

    // if (!isValidLongitude()) {
    //   errors.push("Enter Valid Longitude");
    // }
    if (props.challengePackage.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT) {
      if (!challenge?.challenge?.noLocation && (isNaN(radius) || !radius || radius < 0)) {
        return false
      }
    }

    if (!isOrderValid()) {
      return false
    }

    return true;
  }

  const getNumberErrorMessage = (value) => {
    if (isNaN(value)) {
      return "Must be a number.";
    }

    if (!value) {
      return "Please enter a value.";
    }

    if (value < 0) {
      return "Must be greater than 0.";
    }
  }

  const getOrderErrorMessage = () => {
    if (isNaN(order)) {
      return getNumberErrorMessage(order);
    }

    if (order > props.selectedChallenges.length || order < 1) {
      return "Order must be between 1 and " + props.selectedChallenges.length;
    }
  }

  const isOrderValid = () => {
    if (isNaN(order) || !order || order < 1) {
      return false;
    }

    if (order > props.selectedChallenges.length) {
      return false;
    }

    return true;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={props.isOpen}
      toggle={props.closeHandler}
      scrollable
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {title}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.closeHandler}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <ChallengeFormFieldRow
          resetHandler={() => { setTitle(challenge?.title ?? ""); }}
          isResetDisabled={title === (challenge?.title ?? "")}
          fields={[
            {
              width: "6",
              label: "Title",
              defaultValue: undefined,
              placeholder: "No Title Set",
              isInvalid: title === "" || title === null || title === undefined,
              value: title,
              changeHandler: (value) => { setTitle(value) },
              invalidFeedback: "Enter a Title.",
              validFeedback: null
            }
          ]}
        />
        <div className={"form-row mt-1 mb-4"} style={{ width: '100%' }}>
          <ChallengeFormFieldRow
            resetHandler={() => { setOrder(challenge?.order + 1); }}
            isResetDisabled={order === challenge?.order + 1}
            width="3"
            fields={[
              {
                width: "8",
                type: "number",
                label: "Order",
                defaultValue: undefined,
                disabled: false,
                placeholder: "No Order Set",
                isInvalid: !isOrderValid(),
                value: order,
                changeHandler: (value) => { setOrder(parseInt(value)) },
                invalidFeedback: getOrderErrorMessage(),
                validFeedback: null
              }
            ]}
          />
          <ChallengeFormFieldRow
            resetHandler={() => { setPackagePoints(challenge?.packagePoints ?? challenge?.challenge?.points ?? 10); }}
            isResetDisabled={packagePoints === (challenge?.packagePoints ?? challenge?.challenge?.points ?? 10)}
            width="3"
            fields={[
              {
                width: "8",
                type: "number",
                label: "Points",
                defaultValue: undefined,
                placeholder: "No Points Set",
                isInvalid: isNaN(packagePoints) || !packagePoints || packagePoints < 0,
                value: packagePoints,
                changeHandler: (value) => { setPackagePoints(value) },
                invalidFeedback: getNumberErrorMessage(packagePoints),
                validFeedback: null
              }
            ]}
          />
          {
            props.challengePackage.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
              <ChallengeFormFieldRow
                resetHandler={() => { setRadius(challenge?.radius); }}
                isResetDisabled={radius === challenge?.radius}
                width="3"
                fields={[
                  {
                    width: "8",
                    type: "number",
                    label: "Radius",
                    defaultValue: undefined,
                    disabled: challenge.challenge.noLocation,
                    placeholder: "No Radius Set",
                    isInvalid: !challenge?.challenge?.noLocation && (isNaN(radius) || !radius || radius < 0),
                    value: radius,
                    changeHandler: (value) => { setRadius(parseInt(value)) },
                    invalidFeedback: getNumberErrorMessage(radius),
                    validFeedback: null
                  }
                ]}
              /> : null
          }

        </div>
        <ChallengeFormFieldRow
          resetHandler={() => {}}
          isResetDisabled={true}
          fields={[
            {
              width: "8",
              label: "Main Description",
              type: "textarea",
              inputStyle: { height: 200 },
              defaultValue: undefined,
              placeholder: "No Description Set",
              value: props.challenge.challenge.description,
              disabled: true,
              isInvalid: false,
              changeHandler: (value) => { },
              invalidFeedback: null,
              validFeedback: null,
              tooltipText: "Default description of the Challenge. Can only be changed from the Challenge editor screen if you are the creator of the Challenge.",
              tooltipId: "originalDescription"
            }
          ]}
        />
        <ChallengeFormFieldRow
          resetHandler={() => { setExtraDescription(challenge?.extraDescription ?? ""); }}
          isResetDisabled={extraDescription === (challenge?.extraDescription ?? "")}
          fields={[
            {
              width: "8",
              label: "Extra Description",
              type: "textarea",
              inputStyle: { height: 200 },
              defaultValue: undefined,
              placeholder: "No Extra Description Set",
              value: extraDescription,
              isInvalid: false,
              changeHandler: (value) => { setExtraDescription(value) },
              invalidFeedback: "Enter a Description.",
              validFeedback: null,
              tooltipText: "Extra text that will appear below the default Description of the Challenge when viewed in this " + + GetPackageTypeName(props.challengePackage.type) + ".",
              tooltipId: "extraDefinition"
            }
          ]}
        />
        {
          props.challengePackage.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
            <div className="mb-3" md="2">
              <Col>
                <label
                  className="form-control-label"
                  id="toolTipHideInfo"
                >
                  Hide Info:
                      <i className="far fa-question-circle ml-1" />
                </label>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    defaultChecked
                    id="customRadioHideInfo1"
                    name="custom-radio-hide-info"
                    type="radio"
                    onChange={(e) => { setOverrideHidden(false) }}
                    checked={!overrideHidden}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioHideInfo1"
                  >
                    Hide Info
              </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="customRadioHideInfo2"
                    name="custom-radio-hide-info"
                    type="radio"
                    onChange={(e) => { setOverrideHidden(true) }}
                    checked={overrideHidden}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioHideInfo2"
                  >
                    Show Info
                          </label>
                </div>
              </Col>
              <UncontrolledTooltip delay={0} target="toolTipHideInfo">
                Hide description and other information to make this challenge more difficult to find in the Scavenger Hunt.
          </UncontrolledTooltip>
            </div>
            : null
        }
      </div>
      <div className="modal-footer">
        <div>{
          "*Your changes will only impact the Challenge when it's viewed in "
          + GetPackageTypeName(props.challengePackage.type)
          + ": "
          + props.challengePackage.title
          + "."}
        </div>
        <div>*You need to press "Apply Changes" for your changes to go live.</div>
        <Col className="text-right" lg="12" xs="5" style={{ minHeight: 51 }}>
          <Button
            color="default"
            onClick={props.closeHandler}
          >
            Cancel
          </Button>
          <Button
            color="success"
            disabled={!updatesAreValid()}
            onClick={() => { props.updateHandler(updateObject, props.closeHandler(), () => { }) }}
          >
            Save
          </Button>
        </Col>
      </div>
    </Modal>
  );
}

export default CPACEditModal;
