/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { useAuth0 } from "@auth0/auth0-react";
import auth0 from "auth0-js";
import GetProtectedAPIClient from '../../../api/protectedAPIClient';
import { IsEmpty } from "../../../helpers/Strings";
import ChallengeFormFieldRow from '../../../components/Forms/ChallengeForm/ChallengeFormFieldRow';
import PasswordValidator from 'password-validator';
import "./Login.css";
import { LegalFooter } from "../../../components/Legal/LegalFooter";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";

const usernameRegEx = /^[0-9a-z_.]+$/;
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Login() {
  const [username, setUsername] = React.useState("");
  const [checkingUsername, setCheckingUsername] = React.useState(false);
  const [isUsernameUnique, setIsUsernameUnique] = React.useState(undefined);
  const [usernameTypingTimeout, setUsernameTypingTimeout] = React.useState(null);

  const [email, setEmail] = React.useState("");
  const [checkingEmail, setCheckingEmail] = React.useState(false);
  const [isEmailUnique, setIsEmailUnique] = React.useState(undefined);
  const [emailTypingTimeout, setEmailTypingTimeout] = React.useState(null);

  const [password, setPassword] = React.useState("");
  const [isPasswordValid, setIsPasswordValid] = React.useState(null);
  const [passwordValidations, setPasswordValidations] = React.useState(
    {
      minLength: null,
      hasSymbols: null,
      hasDigits: null,
      hasUpper: null,
      hasLower: null,
    }
  )
  const [passwordIsVisible, setPasswordIsVisible] = React.useState(false);

  const [creatingUser, setCreatingUser] = React.useState(false);

  const [alert, setalert] = React.useState(false);
  const { notify } = React.useContext(NotificationContext);

  const { loginWithRedirect } = useAuth0();

  const successAlert = (createdChallengePackage) => {
    setalert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        closeOnClickOutside={false}
        showConfirm={false}
      >
        {"Signup Successful! Redirecting to login..."}
      </ReactBSAlert>
    );
  };

  const checkEmailTypingTimeout = (text) => {
    if (isValidEmail(text)) {
      setCheckingEmail(true);
      setIsEmailUnique(false);
      if (emailTypingTimeout) {
        clearTimeout(emailTypingTimeout);
        setEmailTypingTimeout(null);
      }

      setEmailTypingTimeout(
        setTimeout(
          async () => { await checkIfEmailIsUnique(text) },
          750
        )
      );
    }
  }

  const checkIfEmailIsUnique = async (code) => {
    try {
      const prodUser = "https://roamlidevusers.azurewebsites.net/api/User";
      var userAPIClient = await GetProtectedAPIClient(prodUser, null);
      await userAPIClient.get('Validate/Email/' + email)
        .then(res => {
          if (res.data) {
            setCheckingEmail(false);
            setIsEmailUnique(true);
          } else {
            setCheckingEmail(false);
            setIsEmailUnique(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCheckingEmail(false);
          setIsEmailUnique(false);
        })
    } catch (error) {
      console.log(error);
      setCheckingEmail(false);
      setIsEmailUnique(false);
    }
  }

  const getEmailErrorText = () => {
    if (!isValidEmail(email) && isEmailUnique !== undefined) {
      return "Enter valid email.";
    }

    if (isEmailUnique === false && !checkingEmail) {
      return "Already in use.";
    }
  }

  const isValidEmail = (text) => {
    return text && emailRegEx.test(text);
  }

  const checkUsernameTypingTimeout = (text) => {
    if (isValidUsername(text)) {
      setCheckingUsername(true);
      setIsUsernameUnique(false);
      if (usernameTypingTimeout) {
        clearTimeout(usernameTypingTimeout);
        setUsernameTypingTimeout(null);
      }

      setUsernameTypingTimeout(
        setTimeout(
          async () => { await checkIfUsernameIsUnique(text) },
          750
        )
      );
    }
  }

  const checkIfUsernameIsUnique = async (text) => {
    try {
      const prodUser = "https://roamlidevusers.azurewebsites.net/api/User";
      var userAPIClient = await GetProtectedAPIClient(prodUser, null);
      await userAPIClient.get('Validate/Username/' + text)
        .then(res => {
          if (res.data) {
            setCheckingUsername(false);
            setIsUsernameUnique(true);
          } else {
            setCheckingUsername(false);
            setIsUsernameUnique(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCheckingUsername(false);
          setIsUsernameUnique(false);
        })
    } catch (error) {
      console.log(error);
      setCheckingUsername(false);
      setIsUsernameUnique(false);
    }
  }

  const isValidUsername = () => {
    return !IsEmpty(username) && username.length > 0 && usernameRegEx.test(username) && username.length <= 30;
  }

  const getUsernameErrorText = () => {
    if (!isValidUsername()) {
      if (!usernameRegEx.test(username)) {
        return "Can only contain numbers and letters."
      }
      if (username?.length > 30) {
        return "Must contain fewer than 30 characters."
      }
      return "Please select a valid username.";
    }

    if (isUsernameUnique === false && !checkingUsername) {
      return "Already in use."
    }
  }

  const validatePassword = (text) => {
    const schema = new PasswordValidator();
    schema
      .is().min(8)                                    // Minimum length 8
      .has().uppercase()                              // Must have uppercase letters
      .has().lowercase()                              // Must have lowercase letters
      .has().digits()
      .has().symbols();
    const failures = schema.validate(text, { list: true });
    setIsPasswordValid(failures.length === 0);
    setPasswordValidations({
      minLength: !failures.includes('min'),
      hasSymbols: !failures.includes('symbols'),
      hasDigits: !failures.includes('digits'),
      hasUpper: !failures.includes('uppercase'),
      hasLower: !failures.includes('lowercase'),
    })
  }

  React.useEffect(() => {
    validatePassword(password);
  }, [password])

  React.useEffect(() => {
    if (isValidEmail(email)) {
      checkEmailTypingTimeout(email);
    }
  }, [email])

  React.useEffect(() => {
    if (isValidUsername()) {
      checkUsernameTypingTimeout(username);
    }
  }, [username])

  const createUser = () => {
    setCreatingUser(true);
    const webAuth = new auth0.WebAuth({
      domain: 'roamli-ly69rb24.auth0.com',
      clientID: 'XaXJu10ilbJlSCcxBuMwuObo2QnfbwJI',
      audience: 'https://roamli.back.auth',
      scope: "read:current_user openid access:users"
    });

    webAuth.signup(
      {
        connection: 'Username-Password-Authentication',
        email: email,
        password: password,
        username: username,
        user_metadata: {
          "name": username,
          "birthday": new Date("1/1/1001"),
          "referralCodeUsed": "",
        },
      }, function (err) {
        setCreatingUser(false);
        if (err) {
          notify("danger", "Invalid Signup", err.code === "user_exists" ? err.description : "Please try again or contact support.")
          return;
        }
        clearForm();
        successAlert();
        setTimeout(() => { setalert(null); loginWithRedirect() }, 2000);
      }
    )
  }

  const clearForm = () => {
    setUsername("");
    setCheckingUsername(false);
    setIsUsernameUnique(undefined);
    setUsernameTypingTimeout(null);

    setEmail("");
    setCheckingEmail(false);
    setIsEmailUnique(undefined);
    setEmailTypingTimeout(null);

    setPassword("");
    setIsPasswordValid(null);
    setPasswordValidations(
      {
        minLength: null,
        hasSymbols: null,
        hasDigits: null,
        hasUpper: null,
        hasLower: null,
      }
    )
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && areFieldsValid()) {
      createUser();
    }
  };

  const areFieldsValid = () => {
    return isEmailUnique && isValidEmail(email) && isUsernameUnique && isValidUsername(username) && isPasswordValid;
  }

  return (
    <>
      <AuthHeader />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pt-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <big>Sign in to Roamli</big>
                </div>
                <div className="btn-wrapper text-center">
                  <Button
                    className="my-2"
                    disabled={creatingUser}
                    style={{ minWidth: '33%' }}
                    color="info"
                    type="button"
                    onClick={() => loginWithRedirect()}>
                    Sign in
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Or create an account</small>
                </div>
                <Form role="form">
                  <ChallengeFormFieldRow
                    resetHandler={() => { setEmail(null); }}
                    isResetDisabled={true}
                    noPadding={true}
                    style={{ margin: 0 }}
                    marginBottom={4}
                    width="12"
                    fields={[
                      {
                        width: "12",
                        label: "Email",
                        defaultValue: undefined,
                        placeholder: "Email",
                        disabled: creatingUser,
                        isInvalid: !isValidEmail(email) || !isEmailUnique,
                        value: email,
                        changeHandler: (value) => {
                          let toLowerCase = value?.toLowerCase();
                          setEmail(toLowerCase);
                        },
                        invalidFeedback: getEmailErrorText(),
                        validFeedback: null,
                        appendStates:
                          { successValue: isEmailUnique === undefined ? undefined : isValidEmail(email) && isEmailUnique, loading: checkingEmail },
                        isAlternative: true,
                        prependIcon: (<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>),
                        noPadding: true,
                        hideLabel: true
                      }
                    ]}
                  />
                  <ChallengeFormFieldRow
                    resetHandler={() => { setUsername(null); }}
                    isResetDisabled={true}
                    width="12"
                    noPadding={true}
                    marginBottom={4}
                    style={{ margin: 0 }}
                    fields={[
                      {
                        width: "12",
                        label: "Username",
                        defaultValue: undefined,
                        placeholder: "Username",
                        disabled: creatingUser,
                        isInvalid: (!isValidUsername() && isUsernameUnique !== undefined) || (isUsernameUnique === false && !checkingUsername),
                        value: username,
                        changeHandler: (value) => {
                          let toLowerCase = value?.toLowerCase();
                          toLowerCase = toLowerCase.replace(" ", "_");
                          setUsername(toLowerCase);
                        },
                        invalidFeedback: getUsernameErrorText(),
                        validFeedback: null,
                        appendStates:
                          { successValue: isUsernameUnique === undefined ? undefined : isValidUsername() && isUsernameUnique, loading: checkingUsername },
                        isAlternative: true,
                        prependIcon: (<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>),
                        noPadding: true,
                        hideLabel: true
                      }
                    ]}
                  />
                  <ChallengeFormFieldRow
                    isResetDisabled={true}
                    noPadding={true}
                    style={{ margin: 0 }}
                    marginBottom={1}
                    width="12"
                    fields={[
                      {
                        width: "12",
                        label: "Password",
                        defaultValue: undefined,
                        placeholder: "Password",
                        disabled: creatingUser,
                        isInvalid: isPasswordValid !== null && isPasswordValid === false,
                        value: password,
                        changeHandler: (value) => {
                          setPassword(value);
                        },
                        type: passwordIsVisible ? "text" : "password",
                        appendStates:
                          { successValue: isPasswordValid === null || IsEmpty(password) ? undefined : isPasswordValid, loading: false },
                        isAlternative: true,
                        prependIcon: (<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>),
                        appendIcon: (<InputGroupAddon addonType="append">
                          <InputGroupText>
                            <div onClick={() => { setPasswordIsVisible(!passwordIsVisible) }} className="password-field-visibility-icon">
                              <i className={passwordIsVisible ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </div>
                          </InputGroupText>
                        </InputGroupAddon>),
                        noPadding: true,
                        hideLabel: true,
                        onKeyPress: handleKeyPress
                      }
                    ]}
                  />
                  {
                    !IsEmpty(password) &&
                    <div className="form-row" style={{ width: "95%", margin: "auto" }}>
                      <PasswordRequirement valid={passwordValidations.hasLower} title={"Lower"} />
                      <PasswordRequirement valid={passwordValidations.hasUpper} title={"Upper"} />
                      <PasswordRequirement valid={passwordValidations.hasSymbols} title={"Symbol"} />
                      <PasswordRequirement valid={passwordValidations.hasDigits} title={"Digit"} />
                      <PasswordRequirement valid={passwordValidations.minLength} title={"Length"} />
                    </div>
                  }
                  <LegalFooter buttonText="Sign Up" />
                  <div className="text-center">
                    <Button
                      style={{ width: 100, height: 50 }}
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={() => createUser()}
                      disabled={!areFieldsValid()}>
                      {creatingUser ? <Spinner color={"secondary"} /> : "Sign Up"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert}
      </Container>
    </>
  );
}

const PasswordRequirement = (props) => {
  if (props.valid) {
    return null;
  }

  return <div style={{ width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {/* {
      props.valid ?
        <i className="fa fa-solid fa-check" style={{ color: "#2dce89", fontSize: 12 }}></i>
        : <i className="fa fa-solid fa-ban" style={{ color: "#f5365c", fontSize: 12 }}></i>
    } */}
    <span style={{ color: props.valid ? "#2dce89" : "#f5365c", fontSize: 12, marginLeft: 2 }}>{props.title}</span>
  </div>

}

export default Login;
