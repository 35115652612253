import React from 'react';
import {
  Button,
  Row,
  Col,
  ListGroupItem,
} from "reactstrap";
import 'react-pro-sidebar/dist/css/styles.css';
import "./ViewPackageUserInfoRow.css";
import YoutubeEmbed from 'components/YouTubePlayer/YouTubePlayer';
import { ExtractYouTubeVideoId } from 'helpers/Validations';

const ViewPackageYouTubeVideo = (props) => {
  const { youTubeLink, softFont } = props;

  if (!youTubeLink?.url) return null;

  return (
    <div>
      {
        youTubeLink?.label && (softFont ? <p className={"viewPackageDetailModalBody title"}>{youTubeLink?.label}</p> : <h5 className="h3 mb-2">{youTubeLink?.label}</h5>)
      }
      {
        youTubeLink?.url && <YoutubeEmbed embedId={ExtractYouTubeVideoId(youTubeLink?.url)} />
      }
      {
        youTubeLink?.description && <div className="mt-2">
          {
            softFont ? <p className={"viewPackageDetailModalBody description"}>
              {youTubeLink?.description}
            </p> : <h4 style={{ fontWeight: 400, color: "black" }}>{youTubeLink?.description}</h4>
          }
        </div>
      }
    </div>
  );
}

export default ViewPackageYouTubeVideo;