import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
} from "reactstrap";
import 'react-pro-sidebar/dist/css/styles.css';
import "./ViewPackageSidebar.css";
import ViewChallengePackageListRow from './ViewPackageChallengeListRow';
import "./ViewChallengePackageList.css"
import { GetEstimatedDistanceAndTime } from 'helpers/ChallengePackage';
import { ShouldShowLineOnMap } from 'helpers/ChallengePackage';
import ViewChallengePackageSearch from './ViewChallengePackageSearch';
import { useAuth0 } from '@auth0/auth0-react';
import { ViewPackageContext, FILTERS } from 'contexts/ViewPackageContext/ViewPackageContext';
import { NoUserSubmissionsCTA } from './NoUserSubmissionsCTA';

const ViewChallengePackageList = ({ challengePackage, challengeClickHandler }) => {
  const { isAuthenticated } = useAuth0();
  const { GetFilteredChallenges, selectedFilter } = React.useContext(ViewPackageContext);
  const cp = challengePackage;
  const getThingsToSeeHeader = () => {
    return `${GetFilteredChallenges().length + " " + (GetFilteredChallenges().length !== 1 ? "Things" : "Thing")} ${getEndText()}`;
  }

  const timeAndDistance = () => {
    const distTime = GetEstimatedDistanceAndTime(cp);
    if (distTime !== null) {
      const walkingDistance = distTime.distance;
      const time = distTime.time;
      const timeString = time.hour > 0 ? time.hour + "-" + (time.hour + 1) + " Hours" : "<1 Hour";
      return { time: timeString, distance: walkingDistance.distance + " " + walkingDistance.unit };
    }
    return null;
  }

  const getCTAText = () => {
    switch (selectedFilter) {
      case FILTERS.ALL:
        return "Nothing to Display";
      case FILTERS.REMAINING:
        return "Nothing left to do";
      case FILTERS.APPROVED:
        return "Nothing Approved Yet";
      case FILTERS.IN_REVIEW:
        return "Nothing In Review";
      case FILTERS.DECLINED:
        return "Nothing Declined";
      default:
        return "";
    }
  }

  const getEndText = () => {
    switch (selectedFilter) {
      case FILTERS.ALL:
        return "to See";
      case FILTERS.REMAINING:
        return "Left To Do";
      case FILTERS.APPROVED:
        return "Approved";
      case FILTERS.IN_REVIEW:
        return "In Review";
      case FILTERS.DECLINED:
        return "Declined";
      default:
        return "";
    }
  }

  return (
    <div className={"viewChallengePackageList"}>
      <Card style={{ marginBottom: 0 }}>
        <CardHeader style={{ padding: '1.25rem 0 0 0', }} id={"viewChallengePackageListTop"}>
          <h5 className="h3 mb-0 mr-3 ml-3">{getThingsToSeeHeader()}</h5>
          {
            (
              ShouldShowLineOnMap(cp) &&
              timeAndDistance()
            )
            && <h5
              className="mb-0 mr-3 ml-3 mb-3">
              {`Time: ${timeAndDistance().time} | Distance: ${timeAndDistance().distance}`}
            </h5>}
          <ViewChallengePackageSearch />
        </CardHeader>
        <CardBody style={{ padding: '1.5rem 15px', }}>
          {
            GetFilteredChallenges().length === 0 ? <NoUserSubmissionsCTA cta={getCTAText()} />
              : <ListGroup className="list my--3" flush>
                {
                  GetFilteredChallenges().map((challenge) => {
                    return (
                      <ViewChallengePackageListRow
                        key={challenge.challenge.id}
                        challenge={challenge}
                        challengeClickHandler={challengeClickHandler} />)
                  })
                }
              </ListGroup>
          }
        </CardBody>
      </Card>
    </div >
  );
}

export default ViewChallengePackageList;