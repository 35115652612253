import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfoContext } from './UserInfoContext';
import GetProtectedAPIClient from '../api/protectedAPIClient';
import { USER_ENDPOINT, CHALLENGE_ENDPOINT } from "constants/api";
import { NotificationContext } from "./NotificationContext/NotificationContext";

const UserInfoProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [user, setUser] = useState({});
  const [challengePackages, setChallengePackages] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [arePackagesLoaded, setArePackagesLoaded] = useState(false);
  const [updatingProfilePhoto, setUpdatingProfilePhoto] = React.useState(false);
  const [deletingAccount, setDeletingAccount] = React.useState(false);
  const { notify } = React.useContext(NotificationContext);

  useEffect(() => {
    const getUser = async () => {
      if (!isAuthenticated) {
        setIsUserLoaded(true);
        setIsLoaded(true);
        setArePackagesLoaded(true);
        return;
      }

      const identifier = "roamli.back.auth";
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${identifier}`,
          scope: "read:current_user openid access:users",
        });
        var userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
        var challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
        userAPIClient.get("/Auth0")
          .then(res => {
            if (res.status === 200 || res.status === 204) {
              res.data.accessToken = accessToken;
              setIsUserLoaded(true);
              setUser(res.data);
              challengeAPIClient.get("/Package/CreatedBy?userId=" + res.data.id)
                .then(cpRes => {
                  setChallengePackages(cpRes.data);
                  setArePackagesLoaded(true);
                  challengeAPIClient.get("/Portal/UserCreated?userId=" + res.data.id)
                    .then(chalRes => {
                      setChallenges(chalRes.data);
                      setIsLoaded(true);
                    })
                    .catch((err) => {
                      setIsLoaded(true);
                      setArePackagesLoaded(true);
                      console.log(err)
                    })
                })
                .catch((err) => {
                  setIsLoaded(true);
                  setArePackagesLoaded(true);
                  console.log(err)
                })

            } else {
              setIsUserLoaded(true);
              setArePackagesLoaded(true);
              setIsLoaded(true);
              console.log("success call but bad res");
            }
          })
          .catch((err) => {
            setIsUserLoaded(true);
            setArePackagesLoaded(true);
            setIsLoaded(true);
            console.log(err);
          });

      } catch (e) {
        setIsUserLoaded(true);
        setArePackagesLoaded(true);
        setIsLoaded(true);
        console.log(e.message);
      }
    };

    getUser();
  }, []);

  const updateProfilePicture = async (photoData, onSuccessCallback) => {
    setUpdatingProfilePhoto(true);
    if (!isAuthenticated) {
      setUpdatingProfilePhoto(false);
      notify("danger", "Update Failed", "Your profile image wasn't updated. Please try again or contact support. (ERR: 7001)");
      return;
    }

    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });
      const userId = user.id;

      const submitImage = {
        mediaBytes: photoData,
        userId: userId,
      };

      const updateObject = {
        userId: userId,
      }

      const storeProfilePictureBase = "/StoreProfilePicture";
      const updateProfilePictureBase = "/UpdateProfilePicture";
      var userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
      userAPIClient
        .post(storeProfilePictureBase, submitImage)
        .then(res => {
          if (res.status === 200) {
            updateObject.pictureUrl = res.data[0];
            userAPIClient
              .post(updateProfilePictureBase, updateObject)
              .then(async (res) => {
                if (res.status === 200 || res.status === 204) {
                  const newUser = JSON.parse(JSON.stringify(user));
                  newUser.pictureUrl = updateObject.pictureUrl;
                  setUser(newUser);
                  if (onSuccessCallback) {
                    onSuccessCallback();
                  }
                  setUpdatingProfilePhoto(false);
                  notify("success", "Profile Image Updated", "Your profile image was updated.");
                } else {
                  notify("danger", "Update Failed", "Your profile image wasn't updated. Please try again or contact support. (ERR: 7002)");
                  setUpdatingProfilePhoto(false);
                }
              })
              .catch(() => {
                notify("danger", "Update Failed", "Your profile image wasn't updated. Please try again or contact support. (ERR: 7003)");
                setUpdatingProfilePhoto(false);
              });
          } else {
            notify("danger", "Update Failed", "Your profile image wasn't updated. Please try again or contact support. (ERR: 7004)");
            setUpdatingProfilePhoto(false);
          }
        })
        .catch(() => {
          notify("danger", "Update Failed", "Your profile image wasn't updated. Please try again or contact support. (ERR: 7005)");
          setUpdatingProfilePhoto(false);
        });
    } catch {
      notify("danger", "Update Failed", "Your profile image wasn't updated. Please try again or contact support. (ERR: 7006)");
      setUpdatingProfilePhoto(false);
    }
  }

  const deleteAccount = async () => {
    setDeletingAccount(true);
    if (!isAuthenticated) {
      setDeletingAccount(false);
      notify("danger", "Could not delete account. Please try again or contact support. (ERR: 9001)");
      return null;
    }

    const identifier = "roamli.back.auth";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const body = {
        userId: user.id,
      };
      const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
      userAPIClient.delete("/DeleteOwnUser", body)
        .then(async (res) => {
          if (res.status === 200 || res.status === 204) {
            logout()
          } else {
            notify("danger", "Could not delete account. Please try again or contact support. (ERR: 9002)");
            setDeletingAccount(false);
            console.log("success call but bad res");
          }
        })
        .catch((err) => {
          notify("danger", "Could not delete account. Please try again or contact support. (ERR: 9003)");
          console.log(err);
          setDeletingAccount(false);
        });
    } catch (e) {
      notify("danger", "Could not delete account. Please try again or contact support. (ERR: 9004)");
      setDeletingAccount(false);
      console.log(e.message);
    }
  }

  return (
    <UserInfoContext.Provider value={{
      user,
      isUserLoaded,
      isLoaded,
      arePackagesLoaded,
      challengePackages,
      setChallengePackages,
      challenges,
      updateProfilePicture,
      updatingProfilePhoto,
      deletingAccount,
      deleteAccount
    }}>
      {
        children
      }
    </UserInfoContext.Provider>
  );
}

export default UserInfoProvider;
