// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Form,
  Button,
  Col
} from "reactstrap";
import { CHALLENGE_ASSETS } from "../../constants";
import { GetFilterNameFromNumber } from "../../helpers/Filters";
import { SanitizeInternalString } from "../../helpers/Strings";

const ChallengeTypeWithIcon = (props) => {
  const challengeTypeName = GetFilterNameFromNumber(props.challengeType);

  const getName = () => {
    const base = SanitizeInternalString(challengeTypeName);
    const addendum = props.isRequired ? " (Required)" : props.isSelected ? " (Selected)" : ""

    return base + addendum;
  }
  let style = { display: 'flex', alignItems: 'center', backgroundColor: 'transparent' };
  if (props.style) {
    Object.keys(props.style).forEach((key) => { style[key] = props.style[key] });
  }
  return <div className={"mr-3" + (props.marginTop ? " mt-1" : "")} style={style}>
    <img
      src={CHALLENGE_ASSETS[challengeTypeName.toUpperCase()].default}
      className="mr-1"
      style={{ backgroundColor: 'transparent', width: 25 }} />
    <b className="title ml-1" style={{ fontSize: 12 }}>{getName()}</b>
  </div>;
}

ChallengeTypeWithIcon.propTypes = {
  challengeType: PropTypes.number,
  isSelected: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default ChallengeTypeWithIcon;
