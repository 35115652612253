/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import ChallengePackage from "views/pages/dashboards/ChallengePackage";
import Main from "views/pages/dashboards/Main";
import CreateChallengeWrapper from "components/Forms/ChallengeForm/CreateChallengeWrapper";
import UpdateChallengeWrapper from "components/Forms/ChallengeForm/UpdateChallengeWrapper";
import CreateChallengePackageWrapper from "components/Forms/ChallengePackageForm/CreateChallengePackageWrapper";
import ViewPackageWrapper from "views/pages/viewPackage/ViewPackageWrapper";
import { PATH } from "constants/link";
import { useAuth0 } from "@auth0/auth0-react";
import SearchPageWrapper from "views/pages/search/SearchPageWrapper";
import { InIFrame } from "helpers/IFrame";
import AuthNavbar from "components/Navbars/AuthNavbar";

function SearchLayout(props) {
  const { isAuthenticated, isLoading } = useAuth0();
  const [sidenavOpen, setSidenavOpen] = React.useState(false);
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === PATH.HOME) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        {/* {!isAuthenticated && !isLoading && !InIFrame() && <AuthNavbar solid />} */}
        <Switch>
          <Route path={PATH.SEARCH + "/:lookUpCode"} component={SearchPageWrapper} />
          <Route path={PATH.SEARCH} component={SearchPageWrapper} />
        </Switch>
        {/* <AdminFooter /> */}
      </div>
    </>
  );
}

export default SearchLayout;
