import React from 'react';
import ViewPackageTimeBar from './ViewPackageTimeBar';
import { COLOR } from 'constants/color';

const ViewPackageDescription = (
  {
    challengePackage,
    updatePackageHandler,
  }
) => {
  const createLinks = () => {
    const links = [];
    challengePackage.externalLinks?.forEach((link, idx) => {
      links.push(
        <div className={"viewPackageDetailModalBody linkRow"} key={idx + "linkKey"}>
          <p className={"viewPackageDetailModalBody linkLabel"}>{`${link.label}: `}</p>
          <a href={link.url} target="_blank" className={"viewPackageDetailModalBody link"}>
            <p className={"viewPackageDetailModalBody linkLabel"}>{link.url}</p>
          </a>
        </div>
      )
    })
    return (
      <div className={"viewPackageDetailModalBody linkContainer"}>
        {links}
      </div>
    );
  }

  return (<div style={{ padding: "1.5rem 15px", backgroundColor: COLOR.WHITE_HEX }}>
    <ViewPackageTimeBar
      onStartHandler={updatePackageHandler}
      challengePackage={challengePackage} />
    <div>
      <p style={{ fontWeight: 400, color: "black" }}>{challengePackage.description}</p>
    </div>
    {createLinks()}
  </div>)
}

export default ViewPackageDescription;