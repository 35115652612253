import { UNITS } from '../constants/units';
import { getDistance } from 'geolib';

export function DistanceBetween(startingPoint, endingPoint, units = UNITS.M_TO_MI, precision = 2) {
	const meters = getDistance(startingPoint, endingPoint, 1);
	return (meters/units).toFixed(precision);
}

// export function IsNearUser(userLocation, challengeLocation, radius = 500) {
// 	if (!global.locationEnabled) {return false};
// 	const metersBetween = DistanceBetween(userLocation, challengeLocation, 1);
// 	return metersBetween <= radius;
// }

export function FormattedDistanceBetween(challenge) {
	if (!global.locationEnabled) {return {distance: "Location Disabled", unit: ""}};

	const userLocation = global.userLocation;
	let unit = "mi";
	let dist = DistanceBetween(userLocation, {latitude: challenge.lat, longitude:challenge.long});
	if (dist*5280 < 1000) {
		dist = DistanceBetween(userLocation, {latitude: challenge.lat, longitude:challenge.long}, UNITS.M_TO_FT, 0);
		unit = "ft";
	}
	return {distance: dist, unit: unit};
}