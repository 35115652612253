/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import { CHALLENGE_PACKAGE_TYPE } from '../../constants';
import Breadcrumbs from './Breadcrumbs';
import { VIEW_MODE } from '../../constants';
import { useLocation } from "react-router-dom";
import ChallengePackageCard from "./ChallengePackageCard";
import useBreakpoints from "hooks/Responsive";

const MainCardsHeader = (
  {
    name,
    parentName,
    subName,
    challengePackages,
    viewMode,
    challengePackagesHandler,
    challengesHandler
  }) => {
  const location = useLocation();
  const { isTabletOrMobile } = useBreakpoints();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // Get the value of a specific query parameter
    const paramValue = queryParams.get('type');

    if (paramValue === "events" && viewMode !== VIEW_MODE.CHALLENGE_PACKAGES) {
      challengePackagesHandler();
    }
    if (paramValue === "challenges" && viewMode !== VIEW_MODE.CHALLENGES) {
      challengesHandler();
    }
  }, [])

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // Get the value of a specific query parameter
    const paramValue = queryParams.get('type');

    if (paramValue === "events" && viewMode !== VIEW_MODE.CHALLENGE_PACKAGES) {
      challengePackagesHandler();
    }
    if (paramValue === "challenges" && viewMode !== VIEW_MODE.CHALLENGES) {
      challengesHandler();
    }
  }, [location.search]);

  const getCardClassName = (viewModeDefault, viewModeActual) => {
    if (viewModeDefault === viewModeActual) { return "btn-neutral" }
    return "btn-primary"
  }

  const getCardSize = (viewModeDefault, viewModeActual) => {
    if (viewModeDefault === viewModeActual) { return "lg" }
    return "md"
  }

  const getDataAnalysis = () => {
    let data = {
      totalLookups: 0,
      huntLookups: 0,
      trackLookups: 0,
      totalTracks: 0,
      totalHunts: 0,
      totalChallenges: 0,
      totalChallengesInHunts: 0,
      totalChallengesInTracks: 0,
      currentlyLive: 0,
      currentlyLiveTracks: 0,
      currentlyLiveHunts: 0,
    }

    let now = new Date();
    challengePackages.forEach((cp) => {
      let live = false;
      if ((!cp.startTime || new Date(cp.startTime) <= now) && (!cp.endTime || new Date(cp.endTime) > now)) {
        live = true;
        data.currentlyLive++;
      }

      data.totalLookups += cp.lookUpCount
      data.totalChallenges += cp.challenges.length
      if (cp.type === CHALLENGE_PACKAGE_TYPE.TRACK) {
        data.trackLookups += (cp.lookUpCount + cp.webLookUpCount + cp.iFrameLookUpCount);
        data.totalTracks++;
        data.totalChallengesInTracks += cp.challenges.length;
        if (live) {
          data.currentlyLiveTracks++;
        }
      }
      if (cp.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT) {
        data.huntLookups += (cp.lookUpCount + cp.webLookUpCount + cp.iFrameLookUpCount);
        data.totalHunts++;
        data.totalChallengesInHunts += cp.challenges.length;
        if (live) {
          data.currentlyLiveHunts++;
        }
      }
    })

    return data;
  }

  const analysis = getDataAnalysis();

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Breadcrumbs name={name} parentName={parentName} subName={subName ?? name} />
              <Col className="text-right" lg="6" xs="12" style={{ minHeight: 51 }}>
                <Button
                  className={getCardClassName(viewMode, VIEW_MODE.CHALLENGE_PACKAGES)}
                  color="default"
                  onClick={challengePackagesHandler}
                  size={getCardSize(viewMode, VIEW_MODE.CHALLENGE_PACKAGES)}
                >
                  Events
                </Button>
                <Button
                  className={getCardClassName(viewMode, VIEW_MODE.CHALLENGES)}
                  color="default"
                  onClick={challengesHandler}
                  size={getCardSize(viewMode, VIEW_MODE.CHALLENGES)}
                >
                  Challenges
                </Button>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <ChallengePackageCard
                  topText="Total Event Lookups"
                  middleText={analysis.totalLookups}
                  icon={<i className="ni ni-active-40" />}
                  gradient="red"
                  bottomText={isTabletOrMobile ? <>
                    <span className="text-nowrap">{"Total Event Lookups: "}</span>
                    <span className="text-success mr-2">
                      {analysis.totalLookups}
                    </span>
                  </>
                    : <>
                      <span className="text-nowrap">{"Tracks: "}</span>
                      <span className="text-success mr-2">
                        {analysis.trackLookups}
                      </span>
                      {" "}
                      <span className="text-nowrap">{"Hunts: "}</span>
                      <span className="text-success mr-2">
                        {analysis.huntLookups}
                      </span>
                    </>}
                />
              </Col>
              <Col md="6" xl="3">
                <ChallengePackageCard
                  topText="Total Events"
                  middleText={challengePackages.length}
                  icon={<i className="ni ni-chart-pie-35" />}
                  gradient="orange"
                  bottomText={isTabletOrMobile ? <>
                    <span className="text-nowrap">{"Total Events: "}</span>
                    <span className="text-success mr-2">
                      {challengePackages.length}
                    </span>
                  </>
                    : <>
                      <span className="text-nowrap">{"Tracks: "}</span>
                      <span className="text-success mr-2">
                        {analysis.totalTracks}
                      </span>
                      {" "}
                      <span className="text-nowrap">{"Hunts: "}</span>
                      <span className="text-success mr-2">
                        {analysis.totalHunts}
                      </span>
                    </>}
                />
              </Col>
              <Col md="6" xl="3">
                <ChallengePackageCard
                  topText="Total Challenges In Events"
                  middleText={analysis.totalChallenges}
                  icon={<i className="ni ni-bullet-list-67" />}
                  gradient="green"
                  bottomText={isTabletOrMobile ? <>
                    <span className="text-nowrap">{"Challenges In Events: "}</span>
                    <span className="text-success mr-2">
                      {analysis.totalChallenges}
                    </span>
                  </>
                    : <>
                      <span className="text-nowrap">{"Tracks: "}</span>
                      <span className="text-success mr-2">
                        {analysis.totalChallengesInTracks}
                      </span>
                      {" "}
                      <span className="text-nowrap">{"Hunts: "}</span>
                      <span className="text-success mr-2">
                        {analysis.totalChallengesInHunts}
                      </span>
                    </>}
                />
              </Col>
              <Col md="6" xl="3">
                <ChallengePackageCard
                  topText="Currently Active Events"
                  middleText={analysis.currentlyLive}
                  icon={<i className="fas fa-hourglass-half" />}
                  gradient="primary"
                  bottomText={isTabletOrMobile ? <>
                    <span className="text-nowrap">{"Currently Active Events: "}</span>
                    <span className="text-success mr-2">
                      {analysis.currentlyLive}
                    </span>
                  </>
                    : <>
                      <span className="text-nowrap">{"Tracks: "}</span>
                      <span className="text-success mr-2">
                        {analysis.currentlyLiveTracks}
                      </span>
                      {" "}
                      <span className="text-nowrap">{"Hunts: "}</span>
                      <span className="text-success mr-2">
                        {analysis.currentlyLiveHunts}
                      </span>
                    </>}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

MainCardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  subName: PropTypes.string,
  challengePackages: PropTypes.array,
  viewMode: PropTypes.string,
  challengePackagesHandler: PropTypes.func,
  challengesHandler: PropTypes.func,
};

export default MainCardsHeader;
