import React from 'react';
import { UserInfoContext } from "contexts/UserInfoContext";
import { useParams, Redirect } from "react-router-dom";
import { PATH } from "constants/link"
import ViewPackageDetails from "views/pages/viewPackage/ViewPackageDetails";
import UpdateChallengePackageProvider from 'contexts/UpdateChallengePackageContext/UpdateChallengePackageProvider';

const UpdateChallengePackageDetailsWrapper = (props) => {
  const data = React.useContext(UserInfoContext);
  const { lookUpCode } = useParams();
  const [challengePackage, setChallengePackage] = React.useState(data.challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode }))

  const resetForm = () => {
    const chalPack = data.challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode });
    setChallengePackage(chalPack);
    props.challengePackageRefreshHandler(chalPack);
  }

  React.useEffect(() => {
    if (lookUpCode) {
      const cp = data.challengePackages.find((cp) => { return cp.lookUpCode == lookUpCode });
      if (cp) {
        setChallengePackage(cp)
      } else {
        return <Redirect to={PATH.MAIN} />
      }
    } else {
      return <Redirect to={PATH.MAIN} />
    }
  }, [lookUpCode])

  if (!data.arePackagesLoaded || !data.isUserLoaded) return null;

  return <UpdateChallengePackageProvider resetFormHandler={resetForm}>
    <ViewPackageDetails setViewMode={props.setViewMode} />
  </UpdateChallengePackageProvider>
}

export default UpdateChallengePackageDetailsWrapper;