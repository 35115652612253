const COLOR = {
	ROAMLI_TEAL_HEX: '#5CA4A9',
	ROAMLI_NAVY_HEX: '#084C61',
	ROAMLI_YELLOW_HEX: '#FFC857',
	ROAMLI_PURPLE_HEX: '#B48BFF',
	ROAMLI_ORANGE_HEX: '#FF8457',
	ROAMLI_BLUE_HEX: "#578CFF",
	WHITE_HEX: '#FFFFFF',
	RED_HEX: '#FF0000',
	GRAY_HEX: '#808080',
	SMOKE_GRAY_HEX: '#f5f5f5',
	BLACK_HEX: '#000000',
	ALERT_RED_HEX: "#FA3E3E",
	SUCCESS_GREEN_HEX: "#4BB543",
	CHAL_ACTIVE_COLOR: "#000000",
	CHAL_ACTIVE_BG: "#76E2F8",
	CHAL_ARCHITECTURE_COLOR: "#474F54",
	CHAL_ARCHITECTURE_BG: "#F4D7AF",
	CHAL_ART_COLOR: "#FFC473",
	CHAL_ART_BG: "#00459F",
	CHAL_BEACH_COLOR: "#F4DA50",
	CHAL_BEACH_BG: "#084C61",
	CHAL_BRIDGE_COLOR: "#FFB229",
	CHAL_BRIDGE_BG: "#0094FF",
	CHAL_BUILDING_COLOR: "#08446D",
	CHAL_BUILDING_BG: "#dda15e",
	CHAL_FOOD_COLOR: "#FFFFFF",
	CHAL_FOOD_BG: "#FF6F00",
	CHAL_FOUNTAIN_COLOR: "#023e8a",
	CHAL_FOUNTAIN_BG: "#69A8FF",
	CHAL_HISTORIC_COLOR: "#084C61",
	CHAL_HISTORIC_BG: "#8fc0a9",
	CHAL_HOUSE_COLOR: "#084C61",
	CHAL_HOUSE_BG: "#FFC857",
	CHAL_LANDMARK_COLOR: "#FF9100",
	CHAL_LANDMARK_BG: "#DEECF1",
	CHAL_MODERN_COLOR: "#444444",
	CHAL_MODERN_BG: "#FFD400",
	CHAL_MONUMENT_COLOR: "#FFCE65",
	CHAL_MONUMENT_BG: "#084C61",
	CHAL_MURAL_COLOR: "#53433F",
	CHAL_MURAL_BG: "#76E2F8",
	CHAL_MUSEUM_COLOR: "#463C4B",
	CHAL_MUSEUM_BG: "#FABE19",
	CHAL_MUSIC_COLOR: "#FFFFFF",
	CHAL_MUSIC_BG: "#FF8700",
	CHAL_MYSTERY_COLOR: "#ffbe0b",
	CHAL_MYSTERY_BG: "#5e548e",
	CHAL_NATURE_COLOR: "#723802",
	CHAL_NATURE_BG: "#79D60D",
	CHAL_NOOK_COLOR: "#FFFFFF",
	CHAL_NOOK_BG: "#084C61",
	CHAL_PARK_COLOR: "#6a040f",
	CHAL_PARK_BG: "#00a896",
	CHAL_RIVER_COLOR: "#FFFFFF",
	CHAL_RIVER_BG: "#8CD96B",
	CHAL_STADIUM_COLOR: "#FFFFFF",
	CHAL_STADIUM_BG: "#5AB267",
	CHAL_STATUE_COLOR: "#FFC857",
	CHAL_STATUE_BG: "#578CFF",
	CHAL_STREET_ART_COLOR: "#084C61",
	CHAL_STREET_ART_BG: "#FF637B",
	CHAL_THEATER_COLOR: "#FFFFFF",
	CHAL_THEATER_BG: "#C9355C",
	CHAL_TRAIL_COLOR: "#FFDA44",
	CHAL_TRAIL_BG: "#70DA40",
	CHAL_VIEW_COLOR: "#78B9EB",
	CHAL_VIEW_BG: "#E4F1FB",
	CHAL_WATERFALL_COLOR: "#DEF5FF",
	CHAL_WATERFALL_BG: "#2F5995",
	CHAL_WATERFRONT_COLOR: "#766E6E",
	CHAL_WATERFRONT_BG: "#A8EBFA",
	CHAL_ZOO_COLOR: "#B48BFF",
	CHAL_ZOO_BG: "#084C61",
}

export { COLOR } 