import { USER_ENDPOINT } from "../constants";
import GetProtectedAPIClient from '../api/protectedAPIClient';


export async function GetUsers(userIds, accessToken, successCallback, errorCallback) {
  const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
  await userAPIClient.get("Selection?idsString=" + userIds.join(","))
    .then((res) => {
      if (res.data) {
        successCallback(res.data);
      } else {
        errorCallback();
      }
    })
    .catch((error) => { console.log(error); errorCallback(error); });
}

export async function GetUnauthenticatedUser(userId, successCallback, errorCallback) {
  const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, null);
  await userAPIClient.get("/Unauthenticated/GetSingleUser?userId=" + userId)
    .then((res) => {
      if (res.data) {
        successCallback(res.data);
      } else {
        errorCallback();
      }
    })
    .catch((error) => {
      console.log(error);
      errorCallback(error);
    });
}