import React, { useState, useEffect } from "react";
import { NotificationContext } from './NotificationContext';
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";

const NotificationProvider = ({ children }) => {
  const [reactBSAlert, setReactBSAlert] = useState(null);
  const notificationAlertRef = React.useRef(null);

  const notify = (type, title, message, detailsList) => {
    let detailsListItems = [];
    if (detailsList) {
      detailsList.forEach((detail, idx) => { detailsListItems.push(<li key={idx}>{detail}</li>) });
    }
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
          {
            detailsList ?
              <ul>
                {
                  detailsListItems
                }
              </ul> :
              null
          }
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notificationAlertRef?.current?.notificationAlert(options);
  };

  const CallSuccessAlert = (
    title,
    onConfirm,
    onCancel,
    cancelBtnBsStyle,
    cancelBtnText,
    confirmBtnBsStyle,
    confirmBtnText,
    closeOnClickOutside,
    showCancel,
    btnSize,
    bodyText,
  ) => {
    setReactBSAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelBtnBsStyle={cancelBtnBsStyle}
        cancelBtnText={cancelBtnText}
        confirmBtnBsStyle={confirmBtnBsStyle}
        confirmBtnText={confirmBtnText}
        closeOnClickOutside={closeOnClickOutside}
        showCancel={showCancel}
        btnSize={btnSize}
      >
        {bodyText}
      </ReactBSAlert>
    );
  };

  return (
    <NotificationContext.Provider value={{
      setReactBSAlert,
      CallSuccessAlert,
      notify
    }}>
      {
        children
      }
      < div className="rna-wrapper" >
        <NotificationAlert ref={notificationAlertRef} />
      </div >
      {reactBSAlert}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
