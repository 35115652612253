/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Table,
  Col,
  CardHeader,
  Row,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";
import ChallengeAddTableRow from "./ChallengeAddTableRow";
import Download from '../../../Utility/Download';
import { SanitizeInternalString } from '../../../../helpers/Strings';
import { GetCurrentPointValue } from '../../../../helpers/Challenge';
import { PaginationControls } from '../../../Pagination/PaginationControls';
import { TABLE_PAGE_COUNT } from '../../../../constants';
import { GetFilterNameFromNumber } from '../../../../helpers/Filters';
import { CHALLENGES_DISPLAYED } from "./ChallengeUpdateModal";
import useBreakpoints from "hooks/Responsive";

const PAGE_COUNT = TABLE_PAGE_COUNT;

const CHALLENGE_SORT_TYPE = {
  NONE: "none",
  ORDER: "order",
  TITLE: "title",
  SUBMISSIONS: "submissions",
  POINTS: "points",
  TYPE: "type",
  ADDRESS: "address",
  PERSONAL: "personal",
  LOCATION_BASED: "locationBased",
  CREATED_ON: "createdOn",
}

const ChallengeAddTable = (props) => {
  const firstListRef = React.useRef(null);
  const [pagination, setPagination] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState(-1);
  const [sortType, setSortType] = React.useState(CHALLENGE_SORT_TYPE.CREATED_ON);
  const [searchQuery, setSearchQuery] = React.useState("");
  const { isTabletOrMobile } = useBreakpoints();

  const challengesData = props.challenges;

  const createRows = (challenges) => {
    let rows = [];
    const selectedChallengeIds = props.selectedChallenges.map(x => x.challengeId);
    challenges.forEach((challenge, idx) => {
      let points = GetCurrentPointValue(challenge);
      rows.push(
        <ChallengeAddTableRow
          index={idx}
          key={challenge.id}
          challenge={challenge}
          title={challenge.title}
          image={challenge.picChallengeUrl}
          points={points}
          type={challenge.challengeType}
          address={challenge.address !== null ? challenge.address.displayedAddress : ""}
          isPersonal={challenge.isPersonal}
          noLocation={challenge.noLocation}
          createdOn={challenge.createdOn}
          showDetailModalHandler={()=>{props.showDetailModalHandler(challenge)}}
          removeChallengeHandler={async () => { await props.removeChallengeHandler(challenge) }}
          addChallengeHandler={() => { props.addChallengeHandler(challenge) }}
          disableAddChallengesHandler={props.selectedChallenges.length >= 100}
          isSelected={selectedChallengeIds.includes(challenge.id)}
        />)
    })

    return rows;
  }

  const getSearchResults = (challenges) => {
    if (searchQuery === "" || searchQuery === null) {
      return challenges;
    }

    const filtered = challenges.filter(
      (challenge) => {
        const type = SanitizeInternalString(GetFilterNameFromNumber(challenge.challengeType))
        return (challenge.title !== null && challenge.title.toLowerCase().includes(searchQuery.toLowerCase()))
          || (challenge.address?.displayedAddress !== null && challenge.address?.displayedAddress.toLowerCase().includes(searchQuery.toLowerCase()))
        // || (type !== null && type.toLowerCase().includes(searchQuery.toLowerCase()))
      }
    )

    return filtered
  }

  const getPaginatedData = (noPagination) => {
    let challenges = props.challenges;
    if (sortType === CHALLENGE_SORT_TYPE.TITLE) {
      challenges = sortByTitle();
    } else if (sortType === CHALLENGE_SORT_TYPE.POINTS) {
      challenges = sortByPoints();
    } else if (sortType === CHALLENGE_SORT_TYPE.TYPE) {
      challenges = sortByType();
    } else if (sortType === CHALLENGE_SORT_TYPE.ADDRESS) {
      challenges = sortByAddress();
    } else if (sortType === CHALLENGE_SORT_TYPE.PERSONAL) {
      challenges = sortByIsPersonal();
    } else if (sortType === CHALLENGE_SORT_TYPE.LOCATION_BASED) {
      challenges = sortByIsLocationBased();
    } else if (sortType === CHALLENGE_SORT_TYPE.CREATED_ON) {
      challenges = sortByCreatedOn();
    }

    challenges = getSearchResults(challenges);

    if (noPagination) {
      return challenges;
    }

    return challenges.slice(PAGE_COUNT * pagination, PAGE_COUNT * pagination + PAGE_COUNT);
  }

  const sortByTitle = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let titleA = a.title == null ? "" : a.title.toUpperCase();
        let titleB = b.title == null ? "" : b.title.toUpperCase();
        return (titleA < titleB ? -1 : (titleA > titleB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByPoints = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = GetCurrentPointValue(a);
        let compareB = GetCurrentPointValue(b);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByType = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.challengeType;
        let compareB = b.challengeType;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByAddress = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.address ? a.address.displayedAddress : "";
        let compareB = b.address ? b.address.displayedAddress : "";
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsPersonal = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = a.isPersonal;
        let compareB = b.isPersonal;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByIsLocationBased = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = !a.noLocation;
        let compareB = !b.noLocation;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const sortByCreatedOn = () => {
    let challenges = challengesData.sort(
      (a, b) => {
        let compareA = new Date(a.createdOn);
        let compareB = new Date(b.createdOn);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challenges
  }

  const paginatedData = getPaginatedData();

  const onClickHandler = (newSortType) => {
    setSortDirection(newSortType === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1);
    setSortType(newSortType);
  }

  // Reset pagination when searchquery gets updated 
  React.useEffect(() => {
    setPagination(0);
  }, [sortType, sortDirection, searchQuery])

  return (
    <Col style={
      {
        height: "100%",
        justifyContent: "flex-start",
        paddingRight: 0,
        paddingLeft: 0,
      }
    }>
      <Row style={
        {
          justifyContent: "space-between",
          width: "100%",
          alignItems: 'center',
          paddingLeft: 20,
        }
      }>
        <h3 className="mb-0">{"Add Challenges (" + props.selectedChallenges.length + "/100)"}</h3>
        <Form
          onSubmit={e => { e.preventDefault(); }}
          style={{ justifyContent: 'flex-end', width: isTabletOrMobile ? "100%" : "50%", display: 'flex', transform: 'none' }}
          className={classnames(
            "navbar-search form-inline",
            "navbar-search-light"
          )}
        >
          <FormGroup className="mb-0">
            <InputGroup className="input-group-alternative input-group-merge">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                key={"search"}
                placeholder={"Search Title or Address"}
                type="text"
                onChange={(e) => { setSearchQuery(e.target.value) }} />
            </InputGroup>
          </FormGroup>
        </Form>
        <div style={{ alignItems: 'center', width: "100%", justifyContent: 'space-between', display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 10, height: 43 }}>
          <Row>
            <div style={{ alignItems: 'center', width: "100%", justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
              {/* <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  id="customRadioListChallenges1"
                  name="custom-radio-list-challenges"
                  type="radio"
                  onChange={(e) => { props.updateChallengesDisplayedHandler(CHALLENGES_DISPLAYED.BOTH) }}
                  checked={props.challengesDisplayed === CHALLENGES_DISPLAYED.BOTH}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioListChallenges1"
                >
                  Both
                </label>
              </div> */}
              <div className="custom-control custom-radio ml-3 mr-3">
                <input
                  className="custom-control-input"
                  id="customRadioListChallenges2"
                  name="custom-radio-list-challenges"
                  type="radio"
                  onChange={(e) => { props.updateChallengesDisplayedHandler(CHALLENGES_DISPLAYED.PERSONAL) }}
                  checked={props.challengesDisplayed === CHALLENGES_DISPLAYED.PERSONAL}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioListChallenges2"
                >
                  My Challenges
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  id="customRadioListChallenges3"
                  name="custom-radio-list-challenges"
                  type="radio"
                  onChange={(e) => { props.updateChallengesDisplayedHandler(CHALLENGES_DISPLAYED.ROAMLI) }}
                  checked={props.challengesDisplayed === CHALLENGES_DISPLAYED.ROAMLI}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioListChallenges3"
                >
                  Roamli Challenges
                </label>
              </div>
            </div>
          </Row>
          {
            props.challengesDisplayed === CHALLENGES_DISPLAYED.ROAMLI && <>
            <Button
              id="toolTipLoadOfficialChallenges"
              color="primary"
              type="button"
              onClick={props.loadRoamliChallengesHandler}
            >
              {props.loadingOfficialChallenges ?
                <Spinner color={"secondary"} />
                : <span className="btn-inner--icon">
                  Load Challenges
                </span>
              }
            </Button>
            <UncontrolledTooltip delay={0} target="toolTipLoadOfficialChallenges">
              Load Official Roamli Challenges within 7 miles of the map center.
            </UncontrolledTooltip>
          </>
          }
        </div>
      </Row>
      <Col
        style={
          {
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
            overflowY: "scroll",
            height: "calc(100% - 173px)"
          }
        }
        ref={firstListRef}>
        {createRows(paginatedData)}
        {/* <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              <th data-sort="order" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ORDER) }}>Order</th>
              <th data-sort="title" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TITLE) }}>Title</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.SUBMISSIONS) }}># of Submissions</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.TYPE) }}>Type</th>
              <th data-sort="address" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.ADDRESS) }}>Address</th>
              <th data-sort="personal" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.PERSONAL) }}>Personal</th>
              <th data-sort="locationBased" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(CHALLENGE_SORT_TYPE.LOCATION_BASED) }}>Location Based</th>
              <th data-sort="createdOn" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(CHALLENGE_SORT_TYPE.CREATED_ON === sortType || sortType === CHALLENGE_SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(CHALLENGE_SORT_TYPE.CREATED_ON); }}>Created On</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody className="list">
          </tbody>
        </Table> */}
      </Col >
      <PaginationControls
        maxPagesShown={5}
        dataSet={getSearchResults(challengesData)}
        currentPage={pagination}
        setPaginationHandler={setPagination} />
    </Col>
  );
}

export default ChallengeAddTable;
