import React from "react";
import "./ViewNavbarAppButtonRow.css";
import { isIOS, isAndroid } from 'react-device-detect';
import { APP_STORE } from 'constants/appStore';

const ViewNavbarAppButtonRow = (props) => {
  return null;
  return (
    <div className="viewNavBarAppButtonContainer">
      <h4 className="mb-2">
        Do more with the App
      </h4>
      <div style={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex" }}>
        <a
          className="sqs-block-image-link"
          target="_blank"
          href={`https://play.google.com/store/apps/details?gl=US&amp;hl=en_US&amp;id=${APP_STORE.ANDROID}`} id="yui_3_17_2_1_1664743731308_67">
          <img className="appShopImage" src="https://images.squarespace-cdn.com/content/v1/611fe31292d5dd79240be7a9/1629483231135-DP1HXC7CCCE7WFVODX52/mevMCdu.png?format=300w" />
        </a>
        <a
          className="sqs-block-image-link"
          target="_blank"
          href={`https://apps.apple.com/us/app/roamli/id${APP_STORE.IOS}`} id="yui_3_17_2_1_1664743731308_67">
          <img className="appShopImage" src="https://images.squarespace-cdn.com/content/v1/611fe31292d5dd79240be7a9/1629483305282-4PWR6V8WQ0M5N8XP9Z1T/GlY5qFm.png?format=300w" />
        </a>
      </div>
    </div>
  );
}



export default ViewNavbarAppButtonRow;
