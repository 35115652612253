import React from 'react';
import {
  UncontrolledAlert,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button
} from 'reactstrap';
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { LINK } from "constants/link";
import ViewPackage from './ViewPackage';
import { LookUpPackage, UnauthenticatedLookUpPackage } from 'helpers/ChallengePackage';
import { useParams } from "react-router-dom";
import { UserInfoContext } from "contexts/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import { GetUsers, GetUnauthenticatedUser } from 'helpers/Users';
import ViewNavbar from 'components/Navbars/ViewNavbar';
import useBreakpoints from 'hooks/Responsive';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { COLOR } from 'constants/color';
import { InIFrame } from 'helpers/IFrame';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import ViewPackageProvider from 'contexts/ViewPackageContext/ViewPackageProvider';
import { ActivePackageProvider } from 'contexts/ActivePackageContext/ActivePackageProvider';
import SearchPage from '../search/SearchPage';

const ViewPackageWrapper = () => {
  return (
    <ViewPackageProvider>
      <ActivePackageProvider>
        <ViewPackageWrapperContent />
      </ActivePackageProvider>
    </ViewPackageProvider>
  );
}

const ViewPackageWrapperContent = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const { lookUpCode } = useParams();
  const { isTabletOrMobile } = useBreakpoints();
  const { challengePackage,
    createdChallengePackages,
    noPackageFound,
    createdByUser,
    newLookUpCode,
    fullyLoaded,
    challengePackageAccess,
    lookUpNewEvent,
    setNewLookUpCode,
    GetPackage,
    resetState } = React.useContext(ViewPackageContext);

  return (<>
    {/* {isTabletOrMobile && <ViewNavbar />}
    {!isTabletOrMobile && noPackageFound && !isAuthenticated && !InIFrame() && <AuthNavbar solid />}
    {!isTabletOrMobile && noPackageFound && isAuthenticated && <AdminNavbar gradient={noPackageFound}/>} */}
    {
      noPackageFound &&
      <SearchPage />
    }
    {
      challengePackage !== null
      && createdByUser !== null
      && <>
        {
          !challengePackage.active &&
          <UncontrolledAlert
            color="warning"
            style={
              {
                marginBottom: 0,
                borderRadius: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
            }
          >
            <span className="alert-icon">
              <i className="fa fa-info-circle" />
            </span>
            <span className="alert-text ml-1">
              <strong>Preview Mode</strong>
              <div>This event is visible only to the creator until it has been activated.</div>
            </span>
          </UncontrolledAlert>
        }
        <ViewPackage />
      </>
    }
  </>)
}

export default ViewPackageWrapper;