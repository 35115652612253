import React from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Container,
  Row,
  Col,
  CardImg,
  CardTitle,
  CardText
} from 'reactstrap';
import styled from 'styled-components';
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { LINK } from "constants/link";
import { LookUpPackage, UnauthenticatedLookUpPackage } from 'helpers/ChallengePackage';
import { useParams } from "react-router-dom";
import { UserInfoContext } from "contexts/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import { GetUsers, GetUnauthenticatedUser } from 'helpers/Users';
import ViewNavbar from 'components/Navbars/ViewNavbar';
import useBreakpoints from 'hooks/Responsive';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { COLOR } from 'constants/color';
import { InIFrame } from 'helpers/IFrame';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import ViewPackageProvider from 'contexts/ViewPackageContext/ViewPackageProvider';
import { ActivePackageProvider } from 'contexts/ActivePackageContext/ActivePackageProvider';
import AuthHeader from "components/Headers/AuthHeader.js";
import { CardFooter } from 'react-bootstrap';
import { IsEmpty } from 'helpers/Strings';

const SearchPage = (props) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const { lookUpCode } = useParams();
  const { isTabletOrMobile } = useBreakpoints();
  const { challengePackage,
    createdChallengePackages,
    noPackageFound,
    createdByUser,
    newLookUpCode,
    fullyLoaded,
    challengePackageAccess,
    lookUpNewEvent,
    setNewLookUpCode,
    GetPackage,
    resetState,
    nearbyChallengeTracks,
    nearbyScavengerHunts,
    nearbyChallengePackagesLoaded,
  } = React.useContext(ViewPackageContext);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      GetPackage(true, newLookUpCode);
    }
  };

  return (
    <>
      <MainContainer>
        <AuthHeader />
        <Container className="pb-5" style={{ marginTop: isTabletOrMobile ? -200 : -350 }}>
          <Row className="justify-content-center">
            <Col lg="12" md="12">
              <CardBody style={{ display: "flex", justifyContent: "center" }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: isTabletOrMobile ? "100%" : "80%",
                  padding: isTabletOrMobile ? 0 : 20,
                  textAlign: 'center'
                }
                }>
                  {
                    noPackageFound && !IsEmpty(lookUpCode) ? <>
                      <h2 style={{ color: COLOR.WHITE_HEX }}>No Event found for</h2>
                      <h1 key={lookUpCode}><strong style={{ color: COLOR.WHITE_HEX }}>{lookUpCode}</strong></h1>
                      <div style={{ color: COLOR.WHITE_HEX }}>Looks like we couldn't find the event you were looking for. {!InIFrame() ? "If you have another code, try entering it below." : ""}</div>
                    </>
                      : <h2
                        className="display-4 text-white font-weight-light">
                        Search for an Event
                      </h2>
                  }
                  {
                    !InIFrame() &&
                    <Row style={{
                      justifyContent: 'space-between',
                      marginTop: 20,
                      width: "100%",
                      flexDirection: isTabletOrMobile ? "column" : "row"
                    }}>
                      <Form
                        onSubmit={e => { e.preventDefault(); }}
                        style={{ justifyContent: 'flex-end', marginTop: 20, width: isTabletOrMobile ? "100%" : "calc(100% - 100px)" }}
                        className={classnames(
                          "form-inline",
                          "navbar-search-light"
                        )}
                      >
                        <FormGroup className="mb-0" style={{ width: "100%" }}>
                          <InputGroup className="input-group-alternative input-group-merge" style={{ width: "100%" }}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-search" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              onKeyDown={handleKeyDown}
                              value={newLookUpCode}
                              placeholder={"Enter a Look Up Code"}
                              type="text"
                              onChange={(e) => { setNewLookUpCode(e.target.value.toUpperCase()) }} />
                          </InputGroup>
                        </FormGroup>
                      </Form>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color="info"
                        style={{ marginTop: 20 }}
                        onClick={() => { GetPackage(true, newLookUpCode) }}
                        disabled={!newLookUpCode}>
                        Search
                      </Button>
                    </Row>
                  }
                </div>
              </CardBody>
            </Col>
          </Row>
        </Container>
        <h2 className="display-4 text-white font-weight-light" style={{ textAlign: "center", position: "relative" }}>More Events</h2>
        <StyledContainer>
          {
            nearbyScavengerHunts?.concat(nearbyChallengeTracks).map((cp) => {
              return (
                <StyledCard
                  key={cp?.lookUpCode}
                  onClick={() => { lookUpNewEvent(cp?.lookUpCode) }}
                  $isTabletOrMobile={isTabletOrMobile}>
                  <StyledCardImgContainer>
                    <StyledCardImg
                      alt="..."
                      src={cp?.anchorImage}
                      top
                    />
                  </StyledCardImgContainer>
                  <StyledCardBody>
                    <div>
                      <CardTitle className="h3 mb-0">
                        {cp?.title}
                      </CardTitle>
                      {
                        cp?.anchorChallenge?.address?.displayedAddress &&
                        <small className="text-muted">
                          {cp?.anchorChallenge?.address?.displayedAddress}
                        </small>
                      }
                    </div>
                    {/* <Button
                      className="px-0"
                      color="link"
                      href="#pablo"
                      style={{ marginTop: 10 }}
                      onClick={(e) => e.preventDefault()}
                    >
                      Explore
                    </Button> */}
                  </StyledCardBody>
                </StyledCard>
              )
            })
          }
        </StyledContainer>
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div({
  backgroundColor: "#172b4d !important",
  minHeight: "100vh",
})

const StyledCard = styled(Card)(({ $isTabletOrMobile }) => ({
  margin: $isTabletOrMobile ? "20px 0" : 20,
  width: $isTabletOrMobile ? "100%" : 300,
  minHeight: $isTabletOrMobile ? 0 : 295,
  cursor: "pointer",
  transition: 'transform 0.2s ease-in-out',
  ':hover': {
    transform: 'scale(1.03)',
  },
}));

const StyledCardImgContainer = styled.div({
  height: 200,
  position: "relative",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: "100%",
  overflow: "hidden",
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10
})

const StyledCardImg = styled(CardImg)({
  minWidth: "130%",
  minHeight: "100%",
  position: "absolute"
})

const StyledCardBody = styled(CardBody)({
  display: "flex",
  flexDirection: 'column',
  justifyContent: "space-between",
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 15,
  paddingRight: 15,
})

const StyledContainer = styled(Container)({
  width: "100%",
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

export default SearchPage;
