export function AbbreviateNumber(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) {
    var numArray = (n / 1e3).toString().split(".");
    if (numArray.length === 1 || numArray[1].charAt(0) === "0") {
      return numArray[0] + "K"
    }
    return numArray[0] + "." + numArray[1].charAt(0) + "K";
  };
  if (n >= 1e6 && n < 1e9) {
    var numArray = (n / 1e6).toString().split(".");
    if (numArray.length === 1 || numArray[1].charAt(0) === "0") {
      return numArray[0] + "M"
    }
    return numArray[0] + "." + numArray[1].charAt(0) + "M";
  };
  if (n >= 1e9 && n < 1e12) {
    var numArray = (n / 1e9).toString().split(".");
    if (numArray.length === 1 || numArray[1].charAt(0) === "0") {
      return numArray[0] + "B"
    }
    return numArray[0] + "." + numArray[1].charAt(0) + "B";
  };
  if (n >= 1e12) {
    var numArray = (n / 1e12).toString().split(".");
    if (numArray.length === 1 || numArray[1].charAt(0) === "0") {
      return numArray[0] + "T"
    }
    return numArray[0] + "." + numArray[1].charAt(0) + "T";
  };
}

export function GetRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}