/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { UserInfoContext } from "contexts/UserInfoContext";
import { PaymentContext } from 'contexts/PaymentContext';
import { STRIPE_PRICES } from 'constants/stripe';
import { COLOR } from 'constants/color';
import { FormatDate } from 'helpers/Date';
import { HEX_OPACITY } from 'constants/hexOpacity';
import CreatorCard from 'components/Subscription/CreatorCard';
import ExplorerCard from 'components/Subscription/ExplorerCard';

const Profile = () => {
  const {
    user,
    isUserLoaded,
    updatingProfilePhoto,
    updateProfilePicture,
    deletingAccount,
    deleteAccount
  } = React.useContext(UserInfoContext);
  const [alert, setalert] = React.useState(false);
  const [newProfileImage, setNewProfileImage] = React.useState(false);
  const {
    isActiveSubscription,
    cancelSubscription,
    cancellingSubscription,
    getActiveSubscription
  } = React.useContext(PaymentContext);
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  const imagePickerRef = React.useRef(null);

  const openImagePicker = () => {
    imagePickerRef?.current?.click();
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setNewProfileImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const onConfirmImageUpdate = async () => {
    const mediaBytes = newProfileImage ? newProfileImage?.split("base64,")[1] : [];
    await updateProfilePicture(mediaBytes, () => { setNewProfileImage(null) });
  }

  const cancelSubscriptionAlert = () => {
    setalert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancel Subscription"
        onConfirm={() => { cancelSubscription(getActiveSubscription()?.id, true); setalert(null); }}
        onCancel={() => setalert(null)}
        cancelBtnBsStyle="info"
        cancelBtnText="Close"
        confirmBtnBsStyle="danger"
        confirmBtnText={cancellingSubscription ? <Spinner /> : "Cancel Subscription"}
        btnSize=""
        showCancel
      >
        {`Are you sure you want to cancel your subscription? Your events will stay active until ${FormatDate(user?.subscriptionInfo?.currentPeriodEnd)}.`}
      </ReactBSAlert>
    );
  };

  const deleteAccountAlert = () => {
    const deleteAccountText = ["Deleting your account is permanent."]

    if (user?.subscriptionInfo?.status === "active") {
      // deleteAccountText.push(`Your subscription and events will stay active until ${FormatDate(user?.subscriptionInfo?.currentPeriodEnd)}. You will not be charged again.`);
      deleteAccountText.push(`Your subscription and events will deactivate immediately. You will not be charged again.`);
    }

    deleteAccountText.push("Are you sure you want to delete?")

    setalert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Delete Account"
        onConfirm={deleteAccount}
        onCancel={() => setalert(null)}
        cancelBtnBsStyle="info"
        cancelBtnText="Cancel"
        confirmBtnBsStyle="danger"
        confirmBtnText={deletingAccount ? <Spinner /> : "Delete Account"}
        btnSize=""
        showCancel
      >
        {
          deleteAccountText.map(
            (text, idx) => {
              return <div
                style={
                  {
                    marginBottom: 10,
                    fontWeight: idx === 0 || idx === (deleteAccountText.length - 1) ? 600 : 100
                  }
                }>
                {text}
              </div>
            })
        }
      </ReactBSAlert>
    );
  };

  const reactivateSubscriptionAlert = () => {
    setalert(
      <ReactBSAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title="Reactivate Subscription"
        onConfirm={() => { cancelSubscription(getActiveSubscription()?.id, false); setalert(null); }}
        onCancel={() => setalert(null)}
        cancelBtnBsStyle="info"
        cancelBtnText="Close"
        confirmBtnBsStyle="success"
        confirmBtnText={cancellingSubscription ? <Spinner /> : "Reactivate Subscription"}
        btnSize=""
        showCancel
      >
        {`Reactivate your subscription for $49/month? Your next cycle will start on ${FormatDate(user?.subscriptionInfo?.currentPeriodEnd)}.`}
      </ReactBSAlert>
    );
  };

  const renewInformation = () => {
    if (cancellingSubscription || !isUserLoaded) return null;
    if (user?.subscriptionInfo?.status !== "active") return null;
    if (user?.subscriptionInfo?.cancelAtPeriodEnd) {
      return <h6 className="heading-small text-danger">
        {`Subscription Ends on ${FormatDate(user?.subscriptionInfo?.currentPeriodEnd)}`}
      </h6>
    }
    return <h6 className="heading-small text-muted">
      {`Subscription Renews on ${FormatDate(user?.subscriptionInfo?.currentPeriodEnd)}`}
    </h6>
  }

  const cancelSubsctiptionButton = () => {
    if (cancellingSubscription || !isUserLoaded) return null;
    const isCreator = isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY);
    if (!isCreator) return null;
    if (user?.subscriptionInfo?.status !== "active") return null;
    if (user?.subscriptionInfo?.cancelAtPeriodEnd) {
      return <Button
        style={{ padding: 0 }}
        className="px-0"
        color="link"
        onClick={(e) => reactivateSubscriptionAlert()}
      >
        Reactivate Subscription
      </Button>
    };
    return <Button
      style={{ padding: 0 }}
      className="px-0"
      color="link"
      onClick={(e) => cancelSubscriptionAlert()}
    >
      Cancel Subscription
    </Button>
  }

  const subscriptionCard = () => {
    if (cancellingSubscription || !isUserLoaded) {
      return <Spinner />
    }
    const isCreator = isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY);
    if (isCreator) {
      return <CreatorCard />;
    };

    return <ExplorerCard />;
  }


  return (
    <>
      {alert}
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-2" xl="4">
            <Card className="card-profile" style={{ position: "relative" }}>
              <CardImg
                alt="..."
                src={require("assets/img/theme/img-1-1000x600.jpg").default}
                top
              />
              <Row className="justify-content-center">
                <Col style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                  <span
                    className="avatar avatar-sm rounded-circle"
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 175,
                      width: 175,
                      overflow: 'hidden',
                      transform: "translate(0%, -50%)",
                      border: "2px solid white"
                    }}>
                    <img
                      alt="..."
                      src={newProfileImage ? newProfileImage : (user?.pictureUrl ?? require("../../assets/placeholder_assets/profile_image_placeholder.png").default)}
                      style={{ position: 'absolute', width: '100%' }}
                    />
                  </span>
                  {
                    newProfileImage ?
                      <Row style={{ marginTop: "-50px", marginBottom: "50px" }}>
                        <Button
                          color="danger"
                          disabled={updatingProfilePhoto}
                          onClick={(e) => { e.preventDefault(); setNewProfileImage(null) }}
                          size="md"
                        >
                          Cancel
                        </Button>
                        <Button
                          color="success"
                          disabled={updatingProfilePhoto}
                          onClick={async (e) => { e.preventDefault(); await onConfirmImageUpdate(); }}
                          size="md"
                        >
                          Confirm
                        </Button>
                      </Row> :
                      <Button
                        style={{ marginTop: "-50px", marginBottom: "50px" }}
                        color="info"
                        disabled={updatingProfilePhoto}
                        onClick={(e) => { e.preventDefault(); openImagePicker() }}
                        size="md"
                      >
                        Change Photo
                      </Button>
                  }
                  <input
                    type='file'
                    id='file'
                    ref={imagePickerRef}
                    onChange={(event) => (onImageChange(event))}
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }} />
                </Col>
              </Row>
              {
                updatingProfilePhoto && <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  backgroundColor: COLOR.BLACK_HEX + HEX_OPACITY["50"]
                }}>
                  <Spinner color="secondary" />
                </div>
              }
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Account Info</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Subscription information
                  </h6>
                  <div>
                    <Row>
                      <Col md="12">
                        {subscriptionCard()}
                        {renewInformation()}
                        {cancelSubsctiptionButton()}
                      </Col>
                    </Row>
                    <Row>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            defaultValue={user?.username}
                            disabled
                            id="input-username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            defaultValue={user?.email}
                            id="input-email"
                            disabled
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Name
                          </label>
                          <Input
                            defaultValue={user?.name}
                            id="input-first-name"
                            disabled
                            placeholder="Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <div style={{ cursor: "pointer", display: "inline-block" }} onClick={() => { deleteAccountAlert() }}>
                    <h6 className="heading-small text-danger">
                      Delete Account
                    </h6>
                  </div>
                  {/* <div>
                    <FormGroup>
                      <label className="form-control-label">About Me</label>
                      <Input
                        placeholder="A few words about you ..."
                        rows="4"
                        disabled
                        type="textarea"
                        defaultValue={user?.bio}
                      />
                    </FormGroup>
                  </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
