/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Col,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";

const ChallengeFormFieldRow = (props) => {
  const getLookUpFieldAppendIcon = (appendStates) => {
    if (appendStates.loading) {
      return (<div style={{ height: 46, width: 46, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner color="primary" />
      </div>);
    } else if (appendStates.successValue !== undefined) {
      if (appendStates.successValue) {
        return <div style={{ height: 46, width: 46, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="fa fa-solid fa-check" style={{ color: "#2dce89" }}></i>
        </div>
      } else {
        return <div style={{ height: 46, width: 46, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="fa fa-solid fa-ban" style={{ color: "#f5365c" }}></i>
        </div>
      }
    }
  }

  const createFields = () => {
    let fields = [];
    props.fields.forEach((field, idx) => {
      const input = field.appendStates || field.prependIcon ?
        <InputGroup className={field.isAlternative ? "input-group-merge input-group-alternative" : undefined}>
          {field.prependIcon && field.prependIcon}
          <Input
            defaultValue={field.defaultValue}
            disabled={field.disabled}
            id={"validationCustom" + field.label}
            placeholder={field.placeholder}
            style={field.inputStyle ? field.inputStyle : {}}
            type={field.type}
            invalid={field.isInvalid}
            value={field.value}
            onKeyPress={field.onKeyPress}
            onChange={(e) => {
              field.changeHandler(e.target.value);
            }}
          />
          {field.appendIcon && field.appendIcon}
          <InputGroupAddon addonType="append">
            {getLookUpFieldAppendIcon(field.appendStates)}
          </InputGroupAddon>
        </InputGroup>
        : <Input
          defaultValue={field.defaultValue}
          disabled={field.disabled}
          id={"validationCustom" + field.label}
          placeholder={field.placeholder}
          style={field.inputStyle ? field.inputStyle : {}}
          type={field.type}
          invalid={field.isInvalid}
          value={field.value}
          onChange={(e) => {
            field.changeHandler(e.target.value);
          }}
        />;
      fields.push(
        <Col
          className={props.marginBottom ? "mb-" + props.marginBottom : "mb-3"}
          md={field.width ?? "2"}
          style={field.noPadding ? { padding: 0 } : undefined}>
          {
            field.hideLabel ?
              null
              : <label
                className="form-control-label"
                htmlFor={"validationCustom" + fields.label + (field.tooltipId ?? "")}
                id={"tooltip" + (field.tooltipId ?? "")}
              >
                {field.label}
                {
                  field.tooltipId ?
                    <i className="far fa-question-circle ml-1" />
                    : null
                }
              </label>
          }
          {input}
          <div className="invalid-feedback" style={{ position: 'absolute', display: field.isInvalid ? "block" : "none" }}>{field.invalidFeedback}</div>
          <div className="valid-feedback" style={{ position: 'absolute', display: !field.isInvalid ? "block" : "none" }}>{field.validFeedback}</div>
          {
            field.tooltipId ?
              <UncontrolledTooltip delay={0} target={"tooltip" + (field.tooltipId ?? "")}>
                {field.tooltipText}
              </UncontrolledTooltip>
              : null
          }
        </Col>
      )
    })

    return fields;
  }
  return (
    <div
      className={"form-row" + (props.marginTop ? " mt-" + props.marginTop : " mt-1") + (props.width ? " col-md-" + props.width : "")}
      style={{ alignItems: 'flex-end', padding: (props.noPadding ? 0 : undefined), ...props.style }}>
      {createFields()}
      {
        props.isResetDisabled ?
          null
          : <Button
            className="btn-icon-only ml-2 mb-3"
            color="primary"
            type="button"
            disabled={props.isResetDisabled}
            style={{ height: 46, width: 46 }}
            onClick={props.resetHandler}
          >
            <span className="btn-inner--icon">
              <i className="fas fa-undo-alt" />
            </span>
          </Button>
      }
    </div>
  );
}

ChallengeFormFieldRow.propTypes = {
  resetHandler: PropTypes.func,
  isResetDisabled: PropTypes.bool,
  fields: PropTypes.array,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default ChallengeFormFieldRow;
