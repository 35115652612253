const IMAGE = {
	ADD_CHALLENGE_ICON: require('../assets/misc_icons/add-challenge.png'),
	ANNOUNCEMENT_ICON: require('../assets/misc_icons/announcement.png'),
	CAMERA_ICON: require('../assets/misc_icons/camera.png'),
	CHALLENGE_PLACEHOLDER: require('../assets/placeholder_assets/challenge_placeholder.jpg'),
	COMMUNITY_ICON: require('../assets/misc_icons/community.png'),
	COMPASS_ICON: require('../assets/misc_icons/compass.png'),
	DESTINATION_ICON: require('../assets/misc_icons/path.png'),
	FIREFLY_ICON: require('../assets/misc_icons/firefly.png'),
	FIREFLY_OFF_ICON: require('../assets/misc_icons/fireflyOff.png'),
	INSPECT_ICON: require('../assets/misc_icons/inspect.png'),
	LEADERBOARD_ICON: require('../assets/misc_icons/leaderboard.png'),
	LIST_ICON: require('../assets/misc_icons/list.png'),
	LOCK_AND_KEY_ICON: require('../assets/misc_icons/lockAndKey.png'),
	LOGOUT_ICON: require('../assets/misc_icons/logout.png'),
	MAP_ICON: require('../assets/misc_icons/map.png'),
	NO_ICON: require('../assets/misc_icons/no.png'),
	PASSWORD_ICON: require('../assets/misc_icons/password.png'),
	PLACEHOLDER_PROFILE_IMAGE: require('../assets/placeholder_assets/profile_image_placeholder.png'),
	REVIEW_ICON: require('../assets/misc_icons/review.png'),
	REWARD_ICON: require('../assets/misc_icons/reward.png'),
	ROAMLI_LOGO: require('../assets/roamli-logo.png'),
	SCAVENGER_HUNT_ICON: require('../assets/misc_icons/scavengerHunt.png'),
	SEARCH_ICON: require('../assets/misc_icons/search.png'),
	SIGN_UP_ICON: require('../assets/misc_icons/sign-up.png'),
	SMARTPHONE_ICON: require('../assets/misc_icons/smartphone.png'),
	STAR_BLUE_ICON: require('../assets/misc_icons/starBlue.png'),
	STAR_NAVY_ICON: require('../assets/misc_icons/starNavy.png'),
	STAR_ORANGE_ICON: require('../assets/misc_icons/starOrange.png'),
	STAR_PURPLE_ICON: require('../assets/misc_icons/starPurple.png'),
	STAR_TEAL_ICON: require('../assets/misc_icons/starTeal.png'),
	STAR_YELLOW_ICON: require('../assets/misc_icons/starYellow.png'),
	STOPWATCH_ICON: require('../assets/misc_icons/stopwatch.png'),
	TO_DO_ICON: require('../assets/misc_icons/to-do.png'),
	UPDATE_ICON: require('../assets/misc_icons/update.png'),
	VERIFY_EMAIL: require('../assets/misc_icons/verify-email.png'),
	WARNING_ICON: require('../assets/misc_icons/warning.png'),
	YES_ICON: require('../assets/misc_icons/yes.png'),
}

export {IMAGE} 