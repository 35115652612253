import { CHALLENGE_ANSWER_STATUS } from 'constants/challengeAnswerStatus';
import * as React from 'react';
import "./ReviewSection.css";

const ReviewSectionGalleryInfoModalImage = ({
  challenge,
  submission,
}) => {
  const getReviewStatus = () => {
    if (submission.status === CHALLENGE_ANSWER_STATUS.IN_REVIEW) {
      return { text: "In Review", image: require('../../assets/misc_icons/review.png').default };
    }
    if (submission.status === CHALLENGE_ANSWER_STATUS.APPROVED) {
      return { text: "Approved", image: require('../../assets/misc_icons/yes.png').default };
    }
    if (submission.status === CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT) {
      return { text: "Rejected", image: require('../../assets/misc_icons/no.png').default };
    }
    if (submission.status === CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE) {
      return { text: "Rejected", image: require('../../assets/misc_icons/no.png').default };
    }
  }

  return (
    <div
      className="review-section-image-root">
      <img
        src={submission.picUrl}
        style={{ width: '130%', objectFit: "cover" }} />
      <div className="review-section-image-gallery-title-overlay">
        {"#" + (challenge?.order + 1) + ": " + challenge?.title}
      </div>
      <div
        className="review-section-gallery-info-modal-image-review-status">
        <img
          src={getReviewStatus().image}
          className="review-section-gallery-info-modal-image-review-status-icon"
        />
        {getReviewStatus().text}
      </div>
    </div>
  )
}

export default ReviewSectionGalleryInfoModalImage;