/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";
import ChallengePackage from "views/pages/dashboards/ChallengePackage";
import Main from "views/pages/dashboards/Main";
import CreateChallengeWrapper from "components/Forms/ChallengeForm/CreateChallengeWrapper";
import UpdateChallengeWrapper from "components/Forms/ChallengeForm/UpdateChallengeWrapper";
import CreateChallengePackageWrapper from "components/Forms/ChallengePackageForm/CreateChallengePackageWrapper";
import ViewPackageWrapper from "views/pages/viewPackage/ViewPackageWrapper";
import Pricing from "pricing/Pricing";
import Profile from "components/Profile/Profile";
import { PATH } from "constants/link";

function Admin(props) {
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {/* {getRoutes(routes)} */}
          <Route path={PATH.PROFILE} component={Profile} />
          <Route path={PATH.PRICING} component={Pricing} />
          <Route path={PATH.MAIN_CREATE_PACKAGE} exact component={CreateChallengePackageWrapper} />
          <Route path={PATH.MAIN_CREATE_CHALLENGE} exact component={CreateChallengeWrapper} />
          <Route path={PATH.MAIN_CHALLENGE + "/:challengeId"} component={UpdateChallengeWrapper} />
          {/* <Route path={PATH.MAIN_PACKAGE + "/:lookUpCode" + PATH.PACKAGE_DETAILS} component={UpdateChallengePackageDetailsWrapper} /> */}
          <Route path={PATH.MAIN_PACKAGE + "/:lookUpCode"} component={ChallengePackage} />
          <Route path={PATH.VIEW_EVENT + "/:lookUpCode"} component={ViewPackageWrapper} />
          <Route path={PATH.MAIN} component={Main} />
          <Redirect from="*" to={PATH.MAIN} />
        </Switch>
        <AdminFooter />
      </div>
    </>
  );
}

export default Admin;
