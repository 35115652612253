const CHALLENGE_ASSETS = {
	ACTIVE: require('../assets/challenge_assets/active.png'),
	ARCHITECTURE: require('../assets/challenge_assets/architecture.png'),
	ART: require('../assets/challenge_assets/art.png'),
	BEACH: require('../assets/challenge_assets/beach.png'),
	BRIDGE: require('../assets/challenge_assets/bridge.png'),
	BUILDING: require('../assets/challenge_assets/building.png'),
	CONSERVATORY: require('../assets/challenge_assets/museum.png'),
	FOUNTAIN: require('../assets/challenge_assets/fountain.png'),
	HISTORIC: require('../assets/challenge_assets/historic.png'),
	HOUSE: require('../assets/challenge_assets/house.png'),
	LANDMARK: require('../assets/challenge_assets/landmark.png'),
	MODERN: require('../assets/challenge_assets/modern.png'),
	MONUMENT: require('../assets/challenge_assets/monument.png'),
	MURAL: require('../assets/challenge_assets/mural.png'),
	MUSEUM: require('../assets/challenge_assets/museum.png'),
	MYSTERY: require('../assets/challenge_assets/mystery.png'),
	NATURE: require('../assets/challenge_assets/nature.png'),
	PARK: require('../assets/challenge_assets/park.png'),
	RIVER: require('../assets/challenge_assets/river.png'),
	STATUE: require('../assets/challenge_assets/statue.png'),
	STREET_ART: require('../assets/challenge_assets/street_art.png'),
	TRAIL: require('../assets/challenge_assets/trail.png'),
	VIEW: require('../assets/challenge_assets/view.png'),
	WATERFALL: require('../assets/challenge_assets/waterfall.png'),
	WATERFRONT: require('../assets/challenge_assets/waterfront.png'),
	// Zoo can also be zoo_flamingo, zoo_seal, and zoo_giraffe
	ZOO: require('../assets/challenge_assets/zoo_gorilla.png'),
	NOOK: require('../assets/challenge_assets/nook.png'),
	STADIUM: require('../assets/challenge_assets/stadium.png'),
	FOOD: require('../assets/challenge_assets/food.png'),
	MUSIC: require('../assets/challenge_assets/music.png'),
	THEATER: require('../assets/challenge_assets/theater.png'),
}

export {CHALLENGE_ASSETS} 