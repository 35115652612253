// Any time you add a new challengeType make sure you add a new challenge asset, a new challengeTypeDetials, and go to the filters helper to update it there as well.
// Also CHALLENGE_TYPE_ICON. It's a process

const CHALLENGE_TYPE = {
	ARCHITECTURE: 0,
	BUILDING: 1,
	HISTORIC: 2,
	MODERN: 3,
	HOUSE: 4,
	MURAL: 5,
	STREET_ART: 6,
	STATUE: 7,
	FOUNTAIN: 8,
	LANDMARK: 9,
	ART: 10,
	MONUMENT: 11,
	NATURE: 12,
	VIEW: 13,
	WATERFRONT: 14,
	TRAIL: 15,
	WATERFALL: 16,
	BEACH: 17,
	PARK: 18,
	RIVER: 19,
	ACTIVE: 20,
	MUSEUM: 21,
	ZOO: 22,
	CONSERVATORY: 23,
	BRIDGE: 24,
	MYSTERY: 25,
	NOOK: 26,
	STADIUM: 27,
	FOOD: 28,
	MUSIC: 29,
	THEATER: 30,
}

export {CHALLENGE_TYPE} 