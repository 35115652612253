/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner,
  Container
} from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
// core components
import { CHALLENGE_PACKAGE_TYPE, DISTANCE, ANSWER_TYPE, LINK } from '../../../constants';
import GetProtectedAPIClient from '../../../api/protectedAPIClient';
import { UserInfoContext } from "contexts/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import ExternalLinks from '../../ExternalLinks/ExternalLinks';
import './ChallengePackageForm.css';
import { IsEmpty } from "../../../helpers/Strings";
import { GetPackageTypeName, IsValidPackageLookUpCode } from "../../../helpers/ChallengePackage";
import ChallengeImageField from "../ChallengeForm/ChallengeImageField";
import GoogleApiWrapper from '../../GoogleMap/ChallengePackageMap/ChallengePackageMap';
import ChallengeFormFieldRow from '../ChallengeForm/ChallengeFormFieldRow';
import ChallengeUpdateModal from "./ChallengeSelection/ChallengeUpdateModal";
import ChallengeViewTableV2 from "./ChallengeSelection/ChallengeViewTableV2";
import SelectChallengePackageImageModal from "../../Modals/SelectChallengePackageImageModal/SelectChallengePackageImageModal";
import useBreakpoints from "hooks/Responsive";
import Breadcrumbs from "components/Headers/Breadcrumbs";
import { UpdateChallengePackageContext } from "contexts/UpdateChallengePackageContext/UpdateChallengePackageContext";
import YouTubeLinkRow from "components/ExternalLinks/YouTubeLinkRow";

const ChallengePackageFormV2 = (props) => {
  const {
    imageKey,
    challengePackage,
    image,
    challengePackageType,
    isCreateMode,
    lookUpCode,
    globalHide,
    title,
    packageLookUpCode,
    isCodeUnique,
    checkingLookUpCode,
    description,
    startTime,
    isStartTimeValid,
    startTimeErrorMessage,
    endTime,
    isEndTimeValid,
    endTimeErrorMessage,
    externalLinks,
    youTubeLink,
    hideLine,
    challenges,
    autoApprove,
    authenticatedAdmin,
    isOfficial,
    isActive,
    duplicatePackageLookUpCode,
    isDuplicateCodeUnique,
    checkingDuplicateLookUpCode,
    duplicateUsername,
    isDuplicateUsernameValid,
    checkingDuplicateUsername,
    creatingDuplicate,
    addChallengesModalOpen,
    addChallengeHandler,
    createDuplicate,
    setDuplicateUsername,
    isValidUsername,
    getDuplicateLookUpCodeErrorText,
    setDuplicatePackageLookUpCode,
    setIsActive,
    setIsOfficial,
    setAutoApprove,
    setChallenges,
    updateCPACHandler,
    setAddChallengesModalOpen,
    setHideLine,
    removeChallengeHandler,
    setExternalLinks,
    setYouTubeLink,
    setEndTime,
    setStartTime,
    formatTime,
    setDescription,
    getLookUpCodeErrorText,
    setPackageLookUpCode,
    setTitle,
    setGlobalHide,
    handleGlobalHideChange,
    setChallengePackageType,
    setSelectImageModalOpen,
    onImageChange,
    setImage,
    setUseLocalImage,
    setImageKey,
    setAnchorImageUrl,
    convertChallengesToOtherPackageType,
    selectImageModalOpen,
    anchorImageUrl,
    useLocalImage,
    alert,
    setHideFromList,
    hideFromList
  } = React.useContext(UpdateChallengePackageContext);
  const { isTabletOrMobile } = useBreakpoints();

  let source = anchorImageUrl ? anchorImageUrl : null;
  source = !IsEmpty(source) && !useLocalImage ? source : image;

  const content = () => {
    return <>
      <Card style={{ marginBottom: 0 }}>
        <CardBody style={{ paddingBottom: 0 }}>
          <Form className="needs-validation" noValidate>
            <h4 className={"mb-4"}>Header Photo Details</h4>
            <Col className={"mt-1"} style={
              {
                width: '100%',
                marginLeft: 0,
                marginRight: 0,
                display: 'flex',
                flexDirection: 'column'
              }
            }>
              <ChallengeImageField
                key={imageKey}
                source={source}
                onChangeImageHandler={onImageChange}
                resetHandler={() => { setImage(null); setUseLocalImage(false); setImageKey(imageKey + 1); setAnchorImageUrl(challengePackage?.anchorImage) }}
                isResetDisabled={image === null && anchorImageUrl === challengePackage?.anchorImage} />
              <h3 style={{ minWidth: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>OR</h3>
              <Button
                type="button"
                color="primary"
                onClick={() => { setSelectImageModalOpen(true) }}
                style={{ minWidth: 160, minHeight: 55 }}
              >
                SELECT IMAGE FROM CHALLENGES
              </Button>
            </Col>
            <h4 className={"mt-4 pt-4 mb-4"} style={{ borderTop: "1px solid black" }}>{GetPackageTypeName(challengePackageType) + " Details"}</h4>
            <div className="form-column" style={{ marginLeft: 0, marginRight: 0, width: "100%" }}>
              {
                isCreateMode ?
                  <>
                    <label
                      className="form-control-label"
                      id="toolTipChallengeType"
                    >
                      Event Type:
                      <i className="far fa-question-circle ml-1" />
                    </label>
                    <Row>
                      <div className="custom-control custom-radio mb-3 ml-3">
                        <input
                          className="custom-control-input"
                          id="customRadioPackageType1"
                          name="custom-radio-package-type"
                          type="radio"
                          onChange={
                            (e) => {
                              if (challengePackageType !== CHALLENGE_PACKAGE_TYPE.TRACK) {
                                setChallengePackageType(CHALLENGE_PACKAGE_TYPE.TRACK);
                                challengePackage.type = CHALLENGE_PACKAGE_TYPE.TRACK;
                                convertChallengesToOtherPackageType(CHALLENGE_PACKAGE_TYPE.TRACK)
                              }
                            }
                          }
                          checked={challengePackageType === CHALLENGE_PACKAGE_TYPE.TRACK}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadioPackageType1"
                        >
                          Challenge Track
                        </label>
                      </div>
                      <div className="custom-control custom-radio mb-3 ml-3">
                        <input
                          className="custom-control-input"
                          id="customRadioPackageType2"
                          name="custom-radio-package-type"
                          type="radio"
                          onChange={
                            (e) => {
                              if (challengePackageType !== CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT) {
                                setChallengePackageType(CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT);
                                challengePackage.type = CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT;
                                convertChallengesToOtherPackageType(CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT)
                              }
                            }
                          }
                          checked={challengePackageType === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadioPackageType2"
                        >
                          Scavenger Hunt
                        </label>
                      </div>
                    </Row>
                    <UncontrolledTooltip delay={0} target="toolTipChallengeType">
                      Scavenger Hunts are better for games and competitions while Challenge Tracks are better for longer lasting events, like walking tours.
                    </UncontrolledTooltip>
                  </>
                  : <>
                    <ChallengeFormFieldRow
                      isResetDisabled={true}
                      style={{ marginLeft: -5 }}
                      fields={[
                        {
                          disabled: true,
                          width: "12",
                          label: "Event Type",
                          value: GetPackageTypeName(challengePackage.type),
                        }
                      ]}
                    />
                    <Col
                      style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                      <label
                        className="form-control-label mr-1 mb-0"
                        htmlFor={"validationCustomLink"}
                      >
                        Web View Link
                      </label>
                      <a
                        href={`${LINK.FULL_VIEW_BASE}/${lookUpCode}`}
                        target="_blank"
                      >
                        {`${LINK.FULL_VIEW_BASE}/${lookUpCode}`}
                      </a>
                    </Col>
                  </>
              }
            </div>
            {
              challengePackage.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT &&
              <div className="form-column" style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                <label
                  className="form-control-label"
                  id="toolTipGlobalHide"
                >
                  Hide Challenge Details:
                  <i className="far fa-question-circle ml-1" />
                </label>
                <div className="form-row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <div className="custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="customRadioGlobalHideType1"
                      name="custom-radio-global-hide"
                      type="radio"
                      onChange={
                        (e) => {
                          setGlobalHide(false);
                          handleGlobalHideChange(false)
                        }
                      }
                      checked={globalHide === false}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioGlobalHideType1"
                    >
                      Show Details
                    </label>
                  </div>
                  <div className="custom-control custom-radio mb-3 ml-3">
                    <input
                      className="custom-control-input"
                      id="customRadioGlobalHide2"
                      name="custom-radio-global-hide"
                      type="radio"
                      onChange={
                        (e) => {
                          setGlobalHide(true);
                          handleGlobalHideChange(true);
                        }
                      }
                      checked={globalHide === true}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioGlobalHide2"
                    >
                      Hide Details
                    </label>
                  </div>
                </div>
                <UncontrolledTooltip delay={0} target="toolTipGlobalHide">
                  Hide descriptions and other information to make all challenges more difficult to find in the Scavenger Hunt. You can also set it on a per-challenge basis in the Challenge Details section.
                </UncontrolledTooltip>
              </div>
            }
            <div className="form-column" style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
              <label
                className="form-control-label"
                id="tooltip124750201123"
              >
                Show Event in Your Lists:
                <i className="far fa-question-circle ml-1" />
              </label>
              <div className="form-row" style={{ marginLeft: 0 }}>
                <div className="custom-control custom-radio mb-3 mr-4">
                  <input
                    className="custom-control-input"
                    id="customRadioHideLists2"
                    name="custom-radio-hide-lists-2"
                    type="radio"
                    onChange={(e) => { setHideFromList(false) }}
                    checked={!hideFromList}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioHideLists2"
                  >
                    Enabled
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    defaultChecked
                    id="customRadioHideLists1"
                    name="custom-radio-hide-lists-1"
                    type="radio"
                    onChange={(e) => { setHideFromList(true) }}
                    checked={hideFromList}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioHideLists1"
                  >
                    Disabled
                  </label>
                </div>
                <UncontrolledTooltip delay={0} target="tooltip124750201123" style={{ width: 300 }}>
                  Enabling will include this event in "Created by You" lists for other users on mobile and web, making it easier for users to find your other events. When disabled, users will still be able to find the event by its Lookup Code. Enabling is a good idea for year-round events, while one-off events are better kept disabled.
                </UncontrolledTooltip>
              </div>
            </div>
            <div className="form-column" style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
              <label
                className="form-control-label"
                id="toolTipAutoApprove"
              >
                Auto Approve Submissions:
                <i className="far fa-question-circle ml-1" />
              </label>
              <div className="form-row" style={{ marginLeft: 0 }}>
                <div className="custom-control custom-radio mb-3 mr-4">
                  <input
                    className="custom-control-input"
                    id="customRadioAutoApprove1"
                    name="custom-radio-auto-approve"
                    type="radio"
                    onChange={(e) => { setAutoApprove(true) }}
                    checked={autoApprove}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioAutoApprove1"
                  >
                    Enabled
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="customRadioAutoApprove2"
                    name="custom-radio-auto-approve"
                    type="radio"
                    onChange={(e) => { setAutoApprove(false) }}
                    checked={!autoApprove}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioAutoApprove2"
                  >
                    Disabled
                  </label>
                </div>
              </div>
              <UncontrolledTooltip delay={0} target="toolTipAutoApprove">
                Enable this to auto-approve user submissions. You should disable this if this Event tallies points based on correct submissions (i.e. a competition with prizes).
              </UncontrolledTooltip>
            </div>
            <div className="form-column">
              <ChallengeFormFieldRow
                resetHandler={() => { setTitle(challengePackage?.title); }}
                isResetDisabled={title === challengePackage?.title}
                fields={[
                  {
                    width: title === challengePackage?.title ? "12" : "10",
                    label: "Title",
                    defaultValue: undefined,
                    placeholder: "No Title Set",
                    isInvalid: title === "" || title === null || title === undefined,
                    value: title,
                    changeHandler: (value) => { setTitle(value) },
                    invalidFeedback: "Enter a Title.",
                    validFeedback: null
                  }
                ]}
              />
              <ChallengeFormFieldRow
                resetHandler={() => { setPackageLookUpCode(challengePackage?.lookUpCode); }}
                isResetDisabled={packageLookUpCode === challengePackage?.lookUpCode}
                fields={[
                  {
                    width: packageLookUpCode === challengePackage?.lookUpCode ? "12" : "10",
                    label: "Lookup Code",
                    defaultValue: undefined,
                    placeholder: "No Lookup Code Set",
                    disabled: !isCreateMode,
                    isInvalid: isCreateMode && (!IsValidPackageLookUpCode(packageLookUpCode) || !isCodeUnique),
                    value: packageLookUpCode,
                    changeHandler: isCreateMode ? (value) => {
                      const toUpperCase = value?.toUpperCase();
                      setPackageLookUpCode(toUpperCase);
                    } : () => { },
                    invalidFeedback: getLookUpCodeErrorText(),
                    validFeedback: isCreateMode ? "Lookup Code is Valid!" : undefined,
                    appendStates:
                      (isCreateMode ?
                        { successValue: IsValidPackageLookUpCode(packageLookUpCode) && isCodeUnique, loading: checkingLookUpCode }
                        : undefined),
                  }
                ]}
              />
            </div>
            <ChallengeFormFieldRow
              resetHandler={() => { setDescription(challengePackage?.description); }}
              isResetDisabled={description === challengePackage?.description}
              fields={[
                {
                  width: "12",
                  label: "Description",
                  type: "textarea",
                  inputStyle: { height: 200 },
                  defaultValue: undefined,
                  placeholder: "No Description Set",
                  isInvalid: description === "" || description === null || description === undefined,
                  value: description,
                  changeHandler: (value) => { setDescription(value) },
                  invalidFeedback: "Enter a Description.",
                  validFeedback: null
                }
              ]}
            />
            <Row>
              <Col className="mb-3" style={{ width: "100%" }}>
                <label
                  className="form-control-label"
                  id="tooltiptimezone1"
                >
                  Start Time ({"Timezone: " + Intl.DateTimeFormat().resolvedOptions().timeZone})
                  <i className="far fa-question-circle ml-1" />
                </label>
                <div style={{ display: 'flex', position: 'relative' }}>
                  <Input
                    style={{ width: '80%' }}
                    defaultValue={
                      formatTime(startTime)
                    }
                    id="example-datetime-local-input-start-time"
                    invalid={!isStartTimeValid}
                    type="datetime-local"
                    onChange={(e) => { setStartTime(new Date(e.target.value)) }}
                    value={formatTime(startTime)}
                  />
                  <Button
                    className="btn-icon-only ml-2 mb-3"
                    color="primary"
                    type="button"
                    style={{ height: 46, width: 46 }}
                    onClick={() => { setStartTime("") }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-trash-alt" />
                    </span>
                  </Button>
                  {
                    (challengePackage.startTime === null ? "" : new Date(challengePackage.startTime)) !== startTime ?
                      <Button
                        className="btn-icon-only ml-2 mb-3"
                        color="primary"
                        type="button"
                        disabled={props.isResetDisabled}
                        style={{ height: 46, width: 46 }}
                        onClick={() => { setStartTime(challengePackage.startTime === null ? "" : new Date(challengePackage.startTime)) }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-undo-alt" />
                        </span>
                      </Button>
                      : null
                  }
                  <div className="invalid-feedback" style={{ position: 'absolute', bottom: -5 }}>{startTimeErrorMessage}</div>
                </div>
                <UncontrolledTooltip delay={0} target="tooltiptimezone1">
                  {"Users cannot start before this time."}
                </UncontrolledTooltip>
              </Col>
              <Col className="mb-3" xl={12} xs={12}>
                <label
                  className="form-control-label"
                  id="tooltiptimezone2"
                >
                  End Time ({"Timezone: " + Intl.DateTimeFormat().resolvedOptions().timeZone})
                  <i className="far fa-question-circle ml-1" />
                </label>
                <div style={{ display: 'flex', position: 'relative' }}>
                  <Input
                    style={{ width: '80%' }}
                    defaultValue={
                      formatTime(endTime)
                    }
                    id="example-datetime-local-input-end-time"
                    invalid={!isEndTimeValid}
                    type="datetime-local"
                    onChange={(e) => { setEndTime(new Date(e.target.value)) }}
                    value={formatTime(endTime)}
                  />
                  <Button
                    className="btn-icon-only ml-2 mb-3"
                    color="primary"
                    type="button"
                    style={{ height: 46, width: 46 }}
                    onClick={() => { setEndTime("") }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-trash-alt" />
                    </span>
                  </Button>
                  {
                    (challengePackage.endTime === null ? "" : new Date(challengePackage.endTime)) !== endTime ?
                      <Button
                        className="btn-icon-only ml-2 mb-3"
                        color="primary"
                        type="button"
                        disabled={props.isResetDisabled}
                        style={{ height: 46, width: 46 }}
                        onClick={() => { setEndTime(challengePackage.endTime === null ? "" : new Date(challengePackage.endTime)) }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-undo-alt" />
                        </span>
                      </Button>
                      : null
                  }
                  <div className="invalid-feedback" style={{ position: 'absolute', bottom: -5 }}>{endTimeErrorMessage}</div>
                </div>
                <UncontrolledTooltip delay={0} target="tooltiptimezone2">
                  {"The event ends at this time and no more submissions can be made."}
                </UncontrolledTooltip>
              </Col>
            </Row>
            <div className="pb-4 mb-4 ml-0" style={{ borderTop: "1px solid black" }}>
              <ExternalLinks
                isResetDisabled={JSON.stringify(externalLinks) === JSON.stringify(challengePackage?.externalLinks)}
                resetHandler={() => { setExternalLinks(challengePackage?.externalLinks); }}
                externalLinks={externalLinks}
                externalLinksHandler={setExternalLinks}
                urlMd={"6"}
                labelMd={"4"} />
            </div>
            <div className="pb-4 mb-4 ml-0" style={{ borderTop: "1px solid black" }}>
              <>
                <h3 className="mb-0 mt-3">{"YouTube Link (Optional)"}</h3>
                <YouTubeLinkRow
                  youTubeLink={youTubeLink}
                  isResetDisabled={JSON.stringify(challengePackage?.youTubeLink) === JSON.stringify(youTubeLink)}
                  updateHandler={(value) => { setYouTubeLink(value) }}
                  resetHandler={() => { setYouTubeLink(challengePackage?.youTubeLink) }}
                />
              </>
            </div>
            {
              challengePackageType === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
                null
                : <>
                  <h4 className={"mt-4 pt-4 mb-4"} style={{ borderTop: "1px solid black" }}>Challenge Details</h4>
                  <div className={"mt-1 pb-2"} style={{ width: '100%', borderBottom: "1px solid black" }}>
                    <div md="12">
                      <Col style={{ paddingLeft: 0 }}>
                        <label
                          className="form-control-label"
                          id="tooltip124750201"
                        >
                          Hide Line:
                          <i className="far fa-question-circle ml-1" />
                        </label>
                        <div className="form-row" style={{ marginLeft: 0 }}>
                          <div className="custom-control custom-radio mb-3 mr-4">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id="customRadio6"
                              name="custom-radio-1"
                              type="radio"
                              onChange={(e) => { setHideLine(true) }}
                              checked={hideLine}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio6"
                            >
                              Enabled
                            </label>
                          </div>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="customRadio5"
                              name="custom-radio-1"
                              type="radio"
                              onChange={(e) => { setHideLine(false) }}
                              checked={!hideLine}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio5"
                            >
                              Disabled
                            </label>
                          </div>
                        </div>
                      </Col>
                      <UncontrolledTooltip delay={0} target="tooltip124750201">
                        Removes Lines between Challenges in a Challenge Track
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </>
            }
            <div className="form-row mt-2">
              {/* <Col md="2">
          <label
            className="form-control-label"
            id="tooltip12475020112"
          >
            Active:
                <i className="far fa-question-circle ml-1" />
          </label>
          <div className="custom-control custom-radio mb-3">
            <input
              className="custom-control-input"
              id="customRadioActive1"
              name="custom-radio-active"
              type="radio"
              onChange={(e) => { setIsActive(true) }}
              checked={isActive}
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioActive1"
            >
              Active
            </label>
          </div>
          <div className="custom-control custom-radio mb-3">
            <input
              className="custom-control-input"
              defaultChecked
              id="customRadioActive2"
              name="custom-radio-active"
              type="radio"
              onChange={(e) => { setIsActive(false) }}
              checked={!isActive}
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioActive2"
            >
              Inactive
            </label>
          </div>
        </Col>
        <UncontrolledTooltip delay={0} target="tooltip12475020112">
          If disabled, hides the event from all users.
        </UncontrolledTooltip> */}
            </div>
            {
              authenticatedAdmin ?
                <>
                  <h4 className={"mt-4 mb-3"} >Admin Controls</h4>
                  <div className="form-column ml-0">
                    <Col className="ml-0 pl-0">
                      <label
                        className="form-control-label"
                        id="toolTipIsPersonal"
                      >
                        Official Status:
                        <i className="far fa-question-circle ml-1" />
                      </label>
                      <div className="form-row" style={{ marginLeft: 0 }}>
                        <div className="custom-control custom-radio mb-3 mr-4">
                          <input
                            className="custom-control-input"
                            id="customRadioIsPersonal1"
                            name="custom-radio-is-personal"
                            type="radio"
                            onChange={(e) => { setIsOfficial(false) }}
                            checked={!isOfficial}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioIsPersonal1"
                          >
                            Personal Event
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioIsPersonal2"
                            name="custom-radio-is-personal"
                            type="radio"
                            onChange={(e) => { setIsOfficial(true) }}
                            checked={isOfficial}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioIsPersonal2"
                          >
                            Official Roamli Package
                          </label>
                        </div>
                      </div>
                    </Col>
                    <UncontrolledTooltip delay={0} target="toolTipIsPersonal">
                      "Official Roamli Package" should only be chosen if this is being made into an official Roamli Package that will appear on the map for everyone. Everything else should be "Personal Event".
                    </UncontrolledTooltip>
                    <Col className="ml-0 pl-0" >
                      <label
                        className="form-control-label"
                        id="tooltip12475020112"
                      >
                        Active:
                        <i className="far fa-question-circle ml-1" />
                      </label>
                      <div className="form-row" style={{ marginLeft: 0 }}>
                        <div className="custom-control custom-radio mb-3 mr-4">
                          <input
                            className="custom-control-input"
                            id="customRadioActive1"
                            name="custom-radio-active"
                            type="radio"
                            onChange={(e) => { setIsActive(true) }}
                            checked={isActive}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioActive1"
                          >
                            Active
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            id="customRadioActive2"
                            name="custom-radio-active"
                            type="radio"
                            onChange={(e) => { setIsActive(false) }}
                            checked={!isActive}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioActive2"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </Col>
                    <UncontrolledTooltip delay={0} target="tooltip12475020112">
                      If Inactive, hides the event from all users.
                    </UncontrolledTooltip>
                    {
                      !isCreateMode &&
                      <Col className="ml-0 pl-0" >
                        <div className="form-row">
                          <ChallengeFormFieldRow
                            resetHandler={() => { }}
                            isResetDisabled={true}
                            width="12"
                            fields={[
                              {
                                width: "12",
                                label: "Lookup Code For Duplicate",
                                defaultValue: undefined,
                                placeholder: "No Lookup Code Set",
                                disabled: !authenticatedAdmin,
                                isInvalid: authenticatedAdmin && (!IsValidPackageLookUpCode(duplicatePackageLookUpCode) || !isDuplicateCodeUnique),
                                value: duplicatePackageLookUpCode,
                                changeHandler: authenticatedAdmin ? (value) => {
                                  const toUpperCase = value?.toUpperCase();
                                  setDuplicatePackageLookUpCode(toUpperCase);
                                } : () => { },
                                invalidFeedback: getDuplicateLookUpCodeErrorText(),
                                validFeedback: authenticatedAdmin ? "Lookup Code is Valid!" : undefined,
                                appendStates:
                                  (authenticatedAdmin ?
                                    { successValue: IsValidPackageLookUpCode(duplicatePackageLookUpCode) && isDuplicateCodeUnique, loading: checkingDuplicateLookUpCode }
                                    : undefined),
                              }
                            ]}
                          />
                        </div>
                        <div className="form-row mb-3">
                          <ChallengeFormFieldRow
                            resetHandler={() => { }}
                            isResetDisabled={true}
                            width="12"
                            fields={[
                              {
                                width: "12",
                                label: "Username For Duplicate",
                                defaultValue: undefined,
                                placeholder: "No Username Set",
                                disabled: !authenticatedAdmin,
                                isInvalid: authenticatedAdmin && (!isValidUsername(duplicateUsername) || !isDuplicateUsernameValid),
                                value: duplicateUsername,
                                changeHandler: authenticatedAdmin ? (value) => {
                                  const toLowerCase = value?.toLowerCase();
                                  setDuplicateUsername(toLowerCase);
                                } : () => { },
                                invalidFeedback: "Username is not valid",
                                validFeedback: authenticatedAdmin ? "Username is Valid!" : undefined,
                                appendStates:
                                  (authenticatedAdmin ?
                                    { successValue: isDuplicateUsernameValid, loading: checkingDuplicateUsername }
                                    : undefined),
                              }
                            ]}
                          />
                        </div>
                        <Button
                          color="success"
                          type="button"
                          onClick={async () => { await createDuplicate() }}
                          disabled={
                            !duplicateUsername
                            || !duplicatePackageLookUpCode
                            || !isDuplicateUsernameValid
                            || !isDuplicateCodeUnique
                            || !IsValidPackageLookUpCode(duplicatePackageLookUpCode)
                          }
                          style={{ minWidth: 160, height: 55, marginBottom: 10 }}
                        >
                          {creatingDuplicate ? <Spinner color={"secondary"} /> : "Create Duplicate"}
                        </Button>
                      </Col>
                    }

                    {/* <Col md="3" className="ml-0 pl-0" >
                <label
                  className="form-control-label"
                  id="toolTipBlockDeactivate"
                >
                  Block Deactivation:
                <i className="far fa-question-circle ml-1" />
                </label>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="customRadioBlockDeactivate1"
                    name="custom-radio-block-deactivate"
                    type="radio"
                    onChange={(e) => { setBlockDeactivate(true) }}
                    checked={blockDeactivate}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioBlockDeactivate1"
                  >
                    Block Deactivation
                </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    id="customRadioBlockDeactivate2"
                    name="custom-radio-block-deactivate"
                    type="radio"
                    onChange={(e) => { setBlockDeactivate(false) }}
                    checked={!blockDeactivate}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioBlockDeactivate2"
                  >
                    Don't Block Deactivation
              </label>
                </div>
              </Col>
              <UncontrolledTooltip delay={0} target="toolTipBlockDeactivate">
                If true, users who start this package will not be able to interact with any features in the app until the Event finishes. Should only be used by Roamli Admins for Roamli Events that use official Roamli Challenges that need to be obscured to prevent cheating. SHOULD NOT BE USED IF THE EVENT LASTS LONGER THAN A FEW HOURS.
              </UncontrolledTooltip> */}
                  </div>
                </>
                : null
            }
          </Form>
        </CardBody>
      </Card>
      {alert}
      <SelectChallengePackageImageModal
        isOpen={selectImageModalOpen}
        closeHandler={() => { setSelectImageModalOpen(false) }}
        urls={challenges.map((chal) => { return { url: chal.picChallengeUrl, hoverText: "yo" } })}
        title={"Select an Image"}
        setImageHandler={(url) => { setImage(null); setUseLocalImage(false); setImageKey(imageKey + 1); setAnchorImageUrl(url); setSelectImageModalOpen(false); }}
        currentImage={anchorImageUrl} />
    </>;
  }

  return (
    <>
      {
        isCreateMode ?
          <div className="header bg-info pb-6">
            <div className={"ml-3 mb-3 pt-3"}>
              <Breadcrumbs name={"Event"} parentName={"Home"} subName={isCreateMode ? "Create Event" : "Update Event"} />
            </div>
          </div>
          : null
      }
      {
        isCreateMode ?
          <Container className="mt--6" fluid style={isTabletOrMobile ? { padding: 0 } : null}>
            {content()}
          </Container >
          : <div className="card-wrapper">
            {content()}
          </div>
      }

    </>
  );
}

export default ChallengePackageFormV2;
