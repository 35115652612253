import React from 'react';
import useBreakpoints from '../../../hooks/Responsive';
import {
  Button,
  Row,
  Spinner
} from "reactstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import "./ViewPackageDetailsSidebar.css";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { COLOR } from 'constants/color';
import { PATH } from 'constants/link';
import ChallengePackageFormV2 from 'components/Forms/ChallengePackageForm/ChallengePackageFormV2';
import { UpdateChallengePackageContext } from "contexts/UpdateChallengePackageContext/UpdateChallengePackageContext";
import { GetPackageTypeName } from "helpers/ChallengePackage";
import ChallengeViewTableV2 from 'components/Forms/ChallengePackageForm/ChallengeSelection/ChallengeViewTableV2';

const MODE = {
  DETAILS_MODE: "details_mode",
  CHALLENGES_MODE: "challenges_mode"
}

const ViewPackageDetailsSidebar = ({ setViewMode }) => {
  const {
    challengePackage,
    challengePackageType,
    isCreateMode,
    updatePackage,
    isLoading,
    setAddChallengesModalOpen,
    removeChallengeHandler,
    updateCPACHandler,
    challenges,
    setChallenges
  } = React.useContext(UpdateChallengePackageContext);
  const [collapsed, setCollapsed] = React.useState(false);
  const { isAuthenticated } = useAuth0();
  const { isTabletOrMobile } = useBreakpoints();
  const [mode, setMode] = React.useState(isCreateMode ? MODE.CHALLENGES_MODE : MODE.DETAILS_MODE);

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  return (
    <div className={"viewPackageDetailsSidebarRoot"}>
      <ProSidebar
        collapsed={isTabletOrMobile ? false : collapsed}
        collapsedWidth={60}
        transitionDuration={100}
        width={isTabletOrMobile ? "100vw" : 600}
        style={{ height: "calc(100%-95px)" }}
      >
        <SidebarHeader style={{ backgroundColor: "white" }}>
          <div
            className="viewPackageSidebarCollapseSection"
            onClick={collapsed ? () => { setCollapsed(!collapsed) } : undefined}
            style={collapsed ? { cursor: "pointer" } : undefined}>
            {
              !collapsed ?
                isAuthenticated ?
                  <Menu iconShape="square">
                    <MenuItem
                      icon={<i className="fas fa-arrow-left" />}
                      onClick={() => { isCreateMode ? history.goBack() : setViewMode() }}
                    >
                      {isCreateMode ? "Back to Dashboard" : "Back to Event Dashboard"}
                    </MenuItem>
                  </Menu>
                  : <Button
                    className="my-2 ml-2"
                    color="info"
                    type="button"
                    onClick={() => { routeChange(PATH.HOME) }}>
                    Log In / Sign Up
                  </Button>
                : null
            }
            {
              !collapsed
              && !isTabletOrMobile
              && <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                <img
                  className="navbar-brand-img"
                  style={{ height: 40, width: 40 }}
                  src={require("assets/roamli-logo.png").default}
                />
                <h1 className="mb-0 ml-1" style={{ color: COLOR.ROAMLI_NAVY_HEX, textTransform: 'capitalize' }}>Roamli</h1>
              </Row>
            }
            {!isTabletOrMobile && <div
              onClick={!collapsed ? () => { setCollapsed(!collapsed) } : undefined}
              className="sidebarHeaderCollapse">
              <div
                className={classnames("sidenav-toggler d-none d-xl-block", {
                  active: !collapsed,
                })}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line collapseIcon" />
                  <i className="sidenav-toggler-line collapseIcon" />
                  <i className="sidenav-toggler-line collapseIcon" />
                </div>
              </div>
            </div>}
          </div>
        </SidebarHeader>
        <SidebarContent
          style={{ backgroundColor: "white", position: "relative" }}>
          <div
            onClick={() => { setCollapsed(false) }}
            className={`viewPackageSidebarCollapseCover${collapsed ? " show" : ""}`} />
          {
            mode === MODE.DETAILS_MODE ?
              <ChallengePackageFormV2
                challengePackage={challengePackage}
                resetFormHandler={() => { }} />
              : <ChallengeViewTableV2
                openAddChallengesModalHandler={() => { setAddChallengesModalOpen(true) }}
                removeChallengeHandler={removeChallengeHandler}
                updateHandler={updateCPACHandler}
                resetHandler={() => { setChallenges(challengePackage?.challenges ? challengePackage.challenges : []) }}
                challenges={challenges}
                challengePackage={challengePackage} />
          }
        </SidebarContent>
      </ProSidebar>
      <div
        className='updateChallengePackageSubmitButtonContainer'
        style={
          {
            padding: 20,
            position: "absolute",
            bottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: "100%",
            backgroundColor: COLOR.WHITE_HEX,
            zIndex: 1000,
            borderTop: "2px solid rgb(75, 181, 67)",
          }
        }>
        <Button
          color="warning"
          type="button"
          onClick={() => {
            setMode(mode === MODE.DETAILS_MODE ? MODE.CHALLENGES_MODE : MODE.DETAILS_MODE);
          }}
          disabled={isLoading}
        >
          {mode === MODE.DETAILS_MODE ?
            "MANAGE CHALLENGES"
            : "MANAGE DETAILS"
          }
        </Button>
        <Button
          color="success"
          type="button"
          onClick={async () => { await updatePackage() }}
          disabled={isLoading}
        >
          {isLoading ?
            <Spinner color={"secondary"} />
            : isCreateMode ?
              "CREATE " + GetPackageTypeName(challengePackageType).toUpperCase()
              : "APPLY CHANGES"}
        </Button>
      </div>
    </div>
  );
}

export default ViewPackageDetailsSidebar;