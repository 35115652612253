import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfoContext } from '../UserInfoContext';
import { ChallengeContext } from './ChallengeContext';
import GetProtectedAPIClient from '../../api/protectedAPIClient';
import { CHALLENGE_ENDPOINT } from "constants/api";
import { NotificationContext } from "../NotificationContext/NotificationContext";

const ChallengeProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { user, challengePackages, setChallengePackages } = React.useContext(UserInfoContext);
  const [activeStatusLoading, setActiveStatusLoading] = React.useState(false);
  const { notify } = React.useContext(NotificationContext);

  const updateChallengePackageActiveStatus = async (challengePackageId, setActiveStatus) => {
    setActiveStatusLoading(true);
    if (!isAuthenticated) {
      notify("danger", (setActiveStatus ? "Activation Failed" : "Deactivation Failed"), "Your event couldn't be " + (setActiveStatus ? "activated" : "deactivated") + ". Please try again or contact support. (ERR: 6001)");
      setActiveStatusLoading(false);
      return;
    }

    const identifier = "roamli.back.auth";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });
      var challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
      const body = {
        ChallengePackageId: challengePackageId,
        UserId: user.id,
        SetActiveStatus: setActiveStatus
      };
      challengeAPIClient.put("/Package/ActiveStatus", body)
        .then(res => {
          setTimeout(() => {
            if (res.data) {
              const updatedChallengePackages = [];
              challengePackages.forEach(
                (challengePackage) => {
                  if (challengePackage.id === res.data.id) { updatedChallengePackages.push(res.data) }
                  else { updatedChallengePackages.push(challengePackage) }
                });
              // Update challengePackages list without needing to reload all challengepackages
              setChallengePackages(JSON.parse(JSON.stringify(updatedChallengePackages)));
              notify("success", (setActiveStatus ? "Your event is Live!" : "Your event is Hidden!"), (setActiveStatus ? "Users can now see your event online!" : "Users won't be able to interact with your event. Reactivate any time."));
            } else {
              notify("danger", (setActiveStatus ? "Activation Failed" : "Deactivation Failed"), "Your event couldn't be " + (setActiveStatus ? "activated" : "deactivated") + ". Please try again or contact support. (ERR: 6002)");
            }
            setActiveStatusLoading(false);
          }, 1000);
        })
        .catch((err) => {
          notify("danger", (setActiveStatus ? "Activation Failed" : "Deactivation Failed"), "Your event couldn't be " + (setActiveStatus ? "activated" : "deactivated") + ". Please try again or contact support. (ERR: 6003)");
          setActiveStatusLoading(false);
          console.log(err)
        })
    } catch (e) {
      notify("danger", (setActiveStatus ? "Activation Failed" : "Deactivation Failed"), "Your event couldn't be " + (setActiveStatus ? "activated" : "deactivated") + ". Please try again or contact support. (ERR: 6004)");
      setActiveStatusLoading(false);
      console.log(e.message);
    }
  }

  return (
    <ChallengeContext.Provider value={{
      updateChallengePackageActiveStatus,
      activeStatusLoading,
      setActiveStatusLoading
    }}>
      {
        children
      }
    </ChallengeContext.Provider>
  );
}

export default ChallengeProvider;
