import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import GetProtectedAPIClient from '../api/protectedAPIClient';
import { USER_ENDPOINT } from "constants/api";
import { PaymentContext } from "./PaymentContext";
import { UserInfoContext } from "./UserInfoContext";
import { STRIPE_PRICES, VALID_MONTHLY_PRICES } from "constants/stripe";
import { NotificationContext } from "./NotificationContext/NotificationContext";
import { PATH } from "constants/link";

const PaymentProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { user } = React.useContext(UserInfoContext);
  const [creatorSubscriptionPaymentLink, setCreatorSubscriptionPaymentLink] = React.useState(null);
  const [userStripeInfo, setUserStripeInfo] = React.useState(null);
  const [loadingPaymentLink, setLoadingPaymentLink] = React.useState(false);
  const [loadingStripeInfo, setLoadingStripeInfo] = React.useState(false);
  const [cancellingSubscription, setCancellingSubscription] = React.useState(false);
  const { notify } = React.useContext(NotificationContext);

  const isCreatedBeforeSubscriptionOriginDate = (createdOn) => {
    var originDate = new Date(2023, 5, 4);
    return originDate > createdOn;
  }

  const getActiveSubscription = () => {
    if (user?.subscriptionInfo?.status === "active") {
      return user?.subscriptionInfo;
    }

    return null;
  }

  const handleSubscription = async (
    paymentMethodId,
    priceId
  ) => {
    if (!isAuthenticated) {
      return null;
    }

    const identifier = "roamli.back.auth";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const body = {
        userId: user.id,
        paymentMethodId,
        priceId,
      };
      const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
      userAPIClient.post("/Subscribe", body)
        .then(res => {
          if (res.status === 200 || res.status === 204) {

          } else {
            console.log("success call but bad res");
          }
        })
        .catch((err) => { console.log(err); });
    } catch (e) {
      console.log(e.message);
    }
  }

  const getPaymentLink = async (
    priceId,
    successUrl,
    cancelUrl
  ) => {
    if (!isAuthenticated) {
      return null;
    }

    const identifier = "roamli.back.auth";

    try {
      setLoadingPaymentLink(true);
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const body = {
        userId: user.id,
        priceId,
        successUrl,
        cancelUrl
      };
      const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
      userAPIClient.post("/GetPaymentLink", body)
        .then(res => {
          setLoadingPaymentLink(false);
          if (res.status === 200 || res.status === 204) {
            setCreatorSubscriptionPaymentLink(res.data);
          } else {
            console.log("success call but bad res");
            setCreatorSubscriptionPaymentLink(null);
          }
        })
        .catch((err) => {
          setLoadingPaymentLink(false);
          console.log(err);
          setCreatorSubscriptionPaymentLink(null);
        });
    } catch (e) {
      setLoadingPaymentLink(false);
      console.log(e.message);
      setCreatorSubscriptionPaymentLink(null);
    }
  }

  const getStripeInfo = async () => {
    if (!isAuthenticated) {
      return null;
    }

    const identifier = "roamli.back.auth";

    try {
      setLoadingStripeInfo(true);
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const body = {
        userId: user.id,
      };
      const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
      userAPIClient.post("/StripeInfo", body)
        .then(res => {
          setLoadingStripeInfo(false);
          setCancellingSubscription(false);
          if (res.status === 200 || res.status === 204) {
            setUserStripeInfo(res.data);
            return res.data;
          } else {
            console.log("success call but bad res");
            setUserStripeInfo(null);
          }
        })
        .catch((err) => {
          console.log(err);
          setCancellingSubscription(false);
          setLoadingStripeInfo(false);
          setUserStripeInfo(null);
        });
    } catch (e) {
      setCancellingSubscription(false);
      setLoadingStripeInfo(false);
      console.log(e.message);
      setUserStripeInfo(null);
    }
  }

  const cancelSubscription = async (subscriptionId, endSubscription) => {
    if (!isAuthenticated) {
      notify("danger", (endSubscription ? "Cancellation Failed" : "Renewal Failed"), "Your subscription couldn't be " + (endSubscription ? "cancelled" : "renewed") + ". Please try again or contact support. (ERR: 5001)");
      return null;
    }

    const identifier = "roamli.back.auth";

    try {
      setCancellingSubscription(true);
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      const body = {
        userId: user.id,
        subscriptionId,
        endSubscription
      };
      const userAPIClient = await GetProtectedAPIClient(USER_ENDPOINT, accessToken);
      userAPIClient.post("/CancelSubscription", body)
        .then(async (res) => {
          if (res.status === 200 || res.status === 204) {
            window.location.reload(true);
          } else {
            notify("danger", (endSubscription ? "Cancellation Failed" : "Renewal Failed"), "Your subscription couldn't be " + (endSubscription ? "cancelled" : "renewed") + ". Please try again or contact support. (ERR: 5002)");
            setCancellingSubscription(false);
            console.log("success call but bad res");
          }
        })
        .catch((err) => {
          notify("danger", (endSubscription ? "Cancellation Failed" : "Renewal Failed"), "Your subscription couldn't be " + (endSubscription ? "cancelled" : "renewed") + ". Please try again or contact support. (ERR: 5003)");
          console.log(err);
          setCancellingSubscription(false);
        });
    } catch (e) {
      notify("danger", (endSubscription ? "Cancellation Failed" : "Renewal Failed"), "Your subscription couldn't be " + (endSubscription ? "cancelled" : "renewed") + ". Please try again or contact support. (ERR: 5004)");
      setCancellingSubscription(false);
      console.log(e.message);
    }
  }

  const isActiveSubscription = (priceId) => {
    let isActive = false;
    user?.subscriptionInfo?.subscriptionItems?.forEach((info) => {
      if (VALID_MONTHLY_PRICES.includes(info?.priceId) && user?.subscriptionInfo?.status === "active") {
        isActive = true;
      }
    })

    return isActive;
  }

  const subscriptionHasPrice = (subscriptionInfo, priceId) => {
    return subscriptionInfo?.subscriptionItems.find((sub)=>{return sub.priceId === priceId}) !== null;
  }

  const subscriptionHas2999Price = (subscriptionInfo) => {
    return subscriptionHasPrice(subscriptionInfo, STRIPE_PRICES.CREATOR_MONTHLY_2999);
  }

  const subscriptionHas4999Price = (subscriptionInfo) => {
    return subscriptionHasPrice(subscriptionInfo, STRIPE_PRICES.CREATOR_MONTHLY_4999);
  }

  React.useEffect(() => {
    if (user !== null) {
      const activeSubscription = getActiveSubscription();
      let priceId = STRIPE_PRICES.CREATOR_MONTHLY_4999;
      if (activeSubscription){
        if (subscriptionHas2999Price(activeSubscription)) priceId = STRIPE_PRICES.CREATOR_MONTHLY_2999;
      }
      getPaymentLink(
        priceId,
        PATH.BASE + PATH.MAIN,
        PATH.BASE + PATH.PRICING
      );
      // getStripeInfo();
    }
  }, [user])

  return (
    <PaymentContext.Provider value={{
      userStripeInfo,
      creatorSubscriptionPaymentLink,
      loadingPaymentLink,
      loadingStripeInfo,
      cancellingSubscription,
      handleSubscription,
      getPaymentLink,
      isActiveSubscription,
      cancelSubscription,
      getActiveSubscription,
      isCreatedBeforeSubscriptionOriginDate
    }}>
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentProvider;
