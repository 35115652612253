import React from 'react';
import ChallengePackageForm from './ChallengePackageForm';
import { UserInfoContext } from "contexts/UserInfoContext";
import { CHALLENGE_PACKAGE_TYPE } from '../../../constants';
import UpdateChallengePackageProvider from 'contexts/UpdateChallengePackageContext/UpdateChallengePackageProvider';
import ViewPackageDetails from 'views/pages/viewPackage/ViewPackageDetails';

const CreateChallengePackageWrapper = () => {
  const data = React.useContext(UserInfoContext);
  const userId = data.user.id;

  const [resetCount, setResetCount] = React.useState(0);

  const resetForm = () => {
    setResetCount(resetCount + 1);
  }

  return <UpdateChallengePackageProvider resetFormHandler={resetForm}>
    <ViewPackageDetails />
  </UpdateChallengePackageProvider>
}

export default CreateChallengePackageWrapper;