import React from 'react';

const PaymentContext = React.createContext(
  {
    userStripeInfo: null,
    creatorSubscriptionPaymentLink: null,
    loadingStripeInfo: false,
    loadingPaymentLink: false,
    cancellingSubscription: false,
    handleSubscription: () => { },
    getPaymentLink: () => { },
    isActiveSubscription: () => { },
    cancelSubscription: () => { },
    getActiveSubscription: () => { },
    isCreatedBeforeSubscriptionOriginDate: () => { },
  }
);

export { PaymentContext };