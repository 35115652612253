import {
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from "reactstrap";
import { TABLE_PAGE_COUNT } from '../../constants';
import useBreakpoints from "hooks/Responsive";

export const PaginationControls = ({ dataSet, currentPage, setPaginationHandler, maxPagesShown }) => {
  const { isTabletOrMobile } = useBreakpoints();
  const ROWS_PER_PAGE = TABLE_PAGE_COUNT;
  const MAX_PAGES_SHOWN = maxPagesShown ? maxPagesShown : isTabletOrMobile ? 3 : 15
  const OFFSET = (Math.floor(MAX_PAGES_SHOWN - 1) / 2);


  if (dataSet.length <= ROWS_PER_PAGE) {
    return null;
  }
  let paginationLinks = [];

  let numPages = (dataSet.length - 1) / ROWS_PER_PAGE;
  let firstPage = numPages > MAX_PAGES_SHOWN ? currentPage - OFFSET < 0 ? 0 : currentPage - OFFSET : 0;
  let lastPage =
    numPages > MAX_PAGES_SHOWN ?
      currentPage + OFFSET >= numPages ?
        numPages
        : currentPage + OFFSET
      : numPages;

  if (currentPage <= OFFSET) {
    lastPage = MAX_PAGES_SHOWN;
  }

  if (currentPage + OFFSET >= Math.floor(numPages)) {
    firstPage = Math.floor(numPages) - MAX_PAGES_SHOWN;
  }

  if (firstPage < 0) {
    firstPage = 0;
  }

  if (lastPage > Math.floor(numPages)) {
    lastPage = Math.floor(numPages);
  }

  for (let x = firstPage; x <= lastPage; x++) {
    paginationLinks.push(
      <PaginationItem className={currentPage === x ? "active" : null} key={x}>
        <PaginationLink
          onClick={(e) => { e.preventDefault(); setPaginationHandler(x); }}
        >
          {(x + 1).toString()}
        </PaginationLink>
      </PaginationItem>)
  }

  return (<CardFooter className="py-4">
    <nav aria-label="...">
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={currentPage === 0 ? "disabled" : null} key={"a"}>
          <PaginationLink
            onClick={(e) => { e.preventDefault(); setPaginationHandler(currentPage - 1) }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {
          firstPage > 0 ?
            <>
              <PaginationItem key={"b"}>
                <PaginationLink
                  onClick={(e) => { e.preventDefault(); setPaginationHandler(0) }}
                >
                  {"1"}
                </PaginationLink>
              </PaginationItem>
              {/* <span>{". . ."}</span> */}
            </>
            : null
        }
        {paginationLinks}
        {
          lastPage < Math.floor(numPages) ?
            <>
              {/* <span>{". . ."}</span> */}
              <PaginationItem key={"c"}>
                <PaginationLink
                  onClick={(e) => { e.preventDefault(); setPaginationHandler(Math.floor(numPages)) }}
                >
                  {Math.floor(numPages + 1).toString()}
                </PaginationLink>
              </PaginationItem>
            </>
            : null
        }

        <PaginationItem key={"d"} className={currentPage >= dataSet.length / ROWS_PER_PAGE - 1 ? "disabled" : null}>
          <PaginationLink
            onClick={(e) => { e.preventDefault(); setPaginationHandler(currentPage + 1) }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  </CardFooter>);
}