/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Spinner,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row
} from "reactstrap";
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
import { STRIPE_PRICES } from 'constants/stripe';
import { useHistory } from "react-router-dom";
import { UserInfoContext } from "contexts/UserInfoContext";
import { PaymentContext } from 'contexts/PaymentContext';
import useBreakpoints from 'hooks/Responsive';
import { PATH, LINK } from "constants/link";

const Sidebar = ({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) => {
  const { isTabletOrMobile } = useBreakpoints();
  const [state, setState] = React.useState({});
  const {
    user,
    isUserLoaded,
    updatingProfilePhoto,
    updateProfilePicture
  } = React.useContext(UserInfoContext);
  const {
    isActiveSubscription,
    cancelSubscription,
    cancellingSubscription,
    getActiveSubscription
  } = React.useContext(PaymentContext);
  const location = useLocation();
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }
  React.useEffect(() => {
    setState(getCollapseStates(routes));
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-hidden");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
    // if (isTabletOrMobile) {
    //   toggleSidenav();
    // }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    console.log("in here");
    if (!sidenavOpen) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
  }, [sidenavOpen])

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };


  const creatorCard = () => {
    return <Col className='pl-0 pr-0' style={{ flexGrow: 0, position: "absolute", bottom: 0, width: 250, maxWidth: 250, right: 0 }}>
      <Card
        className="bg-gradient-success border-0"
        tag="h5"
        style={{ cursor: "pointer", marginBottom: 0, borderRadius: 0 }}
        onClick={() => {
          closeSidenav();
          routeChange(PATH.MAIN);
        }}>
        <CardBody>
          <Row>
            <div className="col" style={{ paddingRight: 0 }}>
              <CardTitle
                className="text-uppercase text-muted mb-0 text-white"
                tag="h5"
              >
                You're a Creator!
              </CardTitle>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-white mr-2">
                  Go to Dashboard
                </span>
              </p>
            </div>
            <p className="mt-3 mb-0 text-sm">
              <span className="text-white mr-2">
                <i className="fa fa-arrow-right ml-2" />
              </span>
            </p>
          </Row>
        </CardBody>
      </Card>
    </Col>
  }

  const explorerCard = () => {
    return <Col className='pl-0 pr-0' style={{ flexGrow: 0, position: "absolute", bottom: 0, width: 250, maxWidth: 250, right: 0 }}>
      <Card
        className="bg-gradient-info border-0"
        tag="h5"
        style={{ cursor: "pointer", marginBottom: 0, borderRadius: 0 }}
        onClick={() => {
          closeSidenav();
          routeChange(PATH.PRICING);
        }}>
        <CardBody>
          <Row>
            <div className="col" style={{ paddingRight: 0 }}>
              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                Upgrade
              </CardTitle>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-white mr-2">
                  Become a Roamli Creator
                </span>
              </p>
            </div>
            <p className="mt-3 mb-0 text-sm">
              <span className="text-white mr-2">
                <i className="fa fa-arrow-right ml-2" />
              </span>
            </p>
          </Row>
        </CardBody>
      </Card>
    </Col>
  }

  const subscriptionCard = () => {
    if (cancellingSubscription || !isUserLoaded) {
      return null
    }
    const isCreator = isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY);
    if (!isCreator) {
      return explorerCard();
    };

    return null
  }

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div>
      <div className="scrollbar-inner" style={{ position: "relative" }}>
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
                <h2 className="mb-0 ml-1">Roamli</h2>
              </Row>
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: sidenavOpen,
              })}
              onClick={toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>{createLinks(routes)}</Nav>
            <hr className="my-3" />
            <h6 className="navbar-heading p-0 text-muted">
              <span className="docs-normal">Support</span>
              <span className="docs-mini">{isTabletOrMobile ? "Support" : "S"}</span>
            </h6>
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink
                  href={LINK.NOTION_FAQ}
                  target="_blank"
                >
                  <i className="fas fa-question"></i>
                  <span className="nav-link-text">FAQ</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={LINK.CONTACT_US}
                  target="_blank"
                >
                  <i className="far fa-envelope"></i>
                  <span className="nav-link-text">Contact Us</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </div>
      {subscriptionCard()}
    </div>
  );

  if (location.pathname.indexOf(PATH.VIEW_EVENT) > -1 && !isTabletOrMobile) return null;
  if (location.pathname.indexOf(PATH.PACKAGE_DETAILS) > -1) return null;
  if (location.pathname.indexOf(PATH.MAIN_CREATE_PACKAGE) > -1) return null;

  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      style={{ paddingBottom: 0 }}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
