import { COLOR } from 'constants/color';
export const getMarkerIcon = (color) => {
  var pinColor = color ?? COLOR.ROAMLI_BLUE_HEX;
  var pinLabel = "A";

  // Pick your pin (hole or no hole)
  var pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
  var labelOriginHole = new window.google.maps.Point(12, 15);
  var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
  var labelOriginFilled = new window.google.maps.Point(12, 9);


  return {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
    path: pinSVGFilled,
    anchor: new window.google.maps.Point(12, 21),
    fillOpacity: 1,
    fillColor: pinColor,
    strokeWeight: 2,
    strokeColor: "white",
    scale: 2,
    labelOrigin: labelOriginFilled
  };
}

export const getUserLocationIcon = () => {
  var pinSVGHole = "M0,0m-8,0a8,8 0 1,1 16,0a8,8 0 1,1 -16,0Z";
  return {
    fillColor: "#4285F4",
    fillOpacity: 1,
    path: pinSVGHole,
    scale: 1,
    strokeColor: "rgb(255,255,255)",
    strokeWeight: 2,
  };
}

export const getUserLocationCircle = () => {
  var pinSVGHole = "M0,0m-8,0a8,8 0 1,1 16,0a8,8 0 1,1 -16,0Z";
  return {
    fillColor: "#4285F4",
    fillOpacity: 0.3,
    path: pinSVGHole,
    scale: 2,
    strokeColor: "rgb(255,255,255)",
    strokeWeight: 2,
    strokeOpacity: 0,
  };
}