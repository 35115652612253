import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import ReviewSectionChallengeSelect from "./ReviewSectionChallengeSelect";
import ReviewSectionGallery from './ReviewSectionGallery';
import { CHALLENGE_ANSWER_STATUS } from "constants/challengeAnswerStatus";
import GetProtectedAPIClient from '../../api/protectedAPIClient';
import { CHALLENGE_ENDPOINT } from "constants/api";
import { UserInfoContext } from "contexts/UserInfoContext";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";

const ReviewSection = ({
  packageAccesses,
  challengePackage,
  reloadPackageAccessesHandler
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [selectedSubmissions, setSelectedSubmissions] = React.useState([]);
  const [selectedChallenge, setSelectedChallenge] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { notify } = React.useContext(NotificationContext);

  const userInfo = React.useContext(UserInfoContext);
  const userId = userInfo.user.id;

  const setSelectedSubmissionsHandler = (submission) => {
    let newSubmissions = [];
    let removed = false;
    selectedSubmissions.forEach((selSub) => {
      if (selSub.id !== submission.id) {
        newSubmissions.push(selSub);
      } else {
        removed = true;
      }
    })
    if (!removed) {
      newSubmissions.push(submission);
    }
    setSelectedSubmissions(newSubmissions);
  }

  const calculateChallengeSubmissions = () => {
    let newChallengeSubmissions = {};
    packageAccesses.forEach((pa) => {
      let access = pa.packageAccess;
      access.submittedChallengeAnswers.forEach((ca) => {
        if (ca.status !== CHALLENGE_ANSWER_STATUS.IN_REVIEW) {
          return;
        }

        const challenge = challengePackage.challenges.find((chal) => { return chal.challengeId === ca.challengeId });
        if (!challenge) {
          return;
        }
        ca.challenge = challenge;

        if (newChallengeSubmissions[ca.challengeId]) {
          let newChallengeInfo = newChallengeSubmissions[ca.challengeId];
          newChallengeInfo.push(ca);
          newChallengeSubmissions[ca.challengeId] = newChallengeInfo;
        } else {
          newChallengeSubmissions[ca.challengeId] = [ca];
        }
      })
    })

    return newChallengeSubmissions;
  }

  const challengeSubmissions = calculateChallengeSubmissions();

  const getButtonText = (text) => {
    return text + (selectedSubmissions?.length > 0 ? ` (${selectedSubmissions?.length})` : "")
  }

  const getSubmissionsForSelectedChallenge = () => {
    if (selectedChallenge === null) {
      return Object.values(challengeSubmissions).flat() ?? [];
    }

    return challengeSubmissions[selectedChallenge.challengeId];
  }

  const submitReview = async (reviewAction) => {
    if (!isAuthenticated || !challengePackage) {
      notify("danger", "Review Failed", "Your review couldn't be applied. Please try again or contact support. (ERR: 3001)")
      return;
    }
    setIsLoading(true);
    const identifier = "roamli.back.auth";
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${identifier}`,
        scope: "read:current_user openid access:users",
      });

      var challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
      let body = {
        challengeAnswers: selectedSubmissions.map(
          (selectedSubmission) => {
            return {
              userId: selectedSubmission.userId,
              challengeId: selectedSubmission.challengeId,
              challengeAnswerId: selectedSubmission.id,
              approved: reviewAction === CHALLENGE_ANSWER_STATUS.APPROVED,
              inappropriate: reviewAction === CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE,
              incorrect: reviewAction === CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT,
            }
          }
        ),
        reviewerUserId: userId,
      }

      await challengeAPIClient.post("/Portal/Review", body)
        .then(res => {
          reloadPackageAccessesHandler(() => {
            notify("success", "Review Complete!", "Your review was successful.");
            setSelectedSubmissions([]);
            setIsLoading(false);
          });
        })
        .catch(err => {
          reloadPackageAccessesHandler(() => {
            notify("danger", "Review Failed", "Your review couldn't be applied. Please try again or contact support. (Err: 3002)");
            setSelectedSubmissions([]);
            setIsLoading(false);
          });
        })
    } catch (e) {
      reloadPackageAccessesHandler(() => {
        console.log(e.message);
        notify("danger", "Review Failed", "Your review couldn't be applied. Please try again or contact support. (Err: 3003)");
        setSelectedSubmissions([]);
        setIsLoading(false);
      });
    }
  }

  return (
    <>
      <div className="card-wrapper">
        <Card>
          <CardHeader>
            <StyledHeader>
              <ReviewSectionChallengeSelect
                disabled={isLoading}
                selectedChallenge={selectedChallenge}
                setSelectedChallengeHandler={
                  (challenge) => {
                    setSelectedChallenge(challenge);
                    setSelectedSubmissions([])
                  }
                }
                challengeSubmissions={challengeSubmissions}
              />
              <StyledButtonContainer>
                <Button
                  type="button"
                  color="primary"
                  disabled={selectedSubmissions?.length === getSubmissionsForSelectedChallenge()?.length || isLoading}
                  onClick={() => { setSelectedSubmissions(getSubmissionsForSelectedChallenge()) }}
                  style={{ minWidth: 80, minHeight: 30 }}
                >
                  {`Select All (${getSubmissionsForSelectedChallenge()?.length})`}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  disabled={selectedSubmissions?.length === 0 || isLoading}
                  onClick={() => { setSelectedSubmissions([]) }}
                  style={{ minWidth: 80, minHeight: 30 }}
                >
                  {getButtonText("Deselect All")}
                </Button>
                <UncontrolledButtonDropdown
                  style={{ marginRight: "0.5rem" }}
                  disabled={selectedSubmissions?.length === 0 || isLoading}>
                  <DropdownToggle caret color={"danger"} disabled={selectedSubmissions?.length === 0 || isLoading}>
                    {getButtonText("Reject")}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={async () => { await submitReview(CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT) }}>
                      Incorrect
                    </DropdownItem>
                    <DropdownItem
                      onClick={async () => { await submitReview(CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE) }}>
                      Inappropriate
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <Button
                  type="button"
                  color="success"
                  disabled={selectedSubmissions?.length === 0 || isLoading}
                  onClick={async () => { await submitReview(CHALLENGE_ANSWER_STATUS.APPROVED) }}
                  style={{ minWidth: 80, minHeight: 30 }}
                >
                  {getButtonText("Approve")}
                </Button>
              </StyledButtonContainer>
            </StyledHeader>
          </CardHeader>
          <CardBody>
            <ReviewSectionGallery
              challengePackage={challengePackage}
              packageAccesses={packageAccesses}
              submissions={getSubmissionsForSelectedChallenge()}
              selectedSubmissions={selectedSubmissions}
              setSelectedSubmissionsHandler={setSelectedSubmissionsHandler} />
          </CardBody>
        </Card>
      </div>
      {
        isLoading && <div className="review-section-loading-overlay">
          <div style={{ height: 100, width: 100, display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: "white", borderRadius: "10px" }}>
            <CircularProgress />
          </div>
        </div>
      }
    </>
  );
}

const StyledHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: "wrap",
});

const StyledButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: "wrap",
});

export default ReviewSection;
