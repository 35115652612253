import React from 'react';
import {
  UncontrolledAlert,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button
} from 'reactstrap';
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import { LINK } from "constants/link";
import { LookUpPackage, UnauthenticatedLookUpPackage } from 'helpers/ChallengePackage';
import { UserInfoContext } from "contexts/UserInfoContext";
import { useAuth0 } from "@auth0/auth0-react";
import { GetUsers, GetUnauthenticatedUser } from 'helpers/Users';
import ViewNavbar from 'components/Navbars/ViewNavbar';
import useBreakpoints from 'hooks/Responsive';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { COLOR } from 'constants/color';
import { InIFrame } from 'helpers/IFrame';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import ViewPackageProvider from 'contexts/ViewPackageContext/ViewPackageProvider';
import { ActivePackageProvider } from 'contexts/ActivePackageContext/ActivePackageProvider';
import SearchPage from '../search/SearchPage';

const SearchPageWrapper = () => {
  return (
    <ViewPackageProvider>
      <ActivePackageProvider>
        <SearchPage />
      </ActivePackageProvider>
    </ViewPackageProvider>
  );
}

export default SearchPageWrapper;