/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from "reactstrap";
import ParticipantTableRow from "./ParticipantTableRow";
import { CHALLENGE_ANSWER_STATUS } from "../../../constants";
import Download from '../../../components/Utility/Download';
import { GetChallengeById } from "../../../helpers/ChallengePackage";
import { PaginationControls } from '../../Pagination/PaginationControls';
import { TABLE_PAGE_COUNT } from '../../../constants';
import { PaymentContext } from 'contexts/PaymentContext';
import ExplorerCard from 'components/Subscription/ExplorerCard';
import { STRIPE_PRICES } from "../../../constants/stripe";

const PAGE_COUNT = TABLE_PAGE_COUNT;

const PARTICIPANT_SORT_TYPE = {
  NONE: "none",
  USERNAME: "username",
  NAME: "name",
  SUBMISSIONS: "submissions",
  LAST_SUBMISSION: "lastSubmission",
  POINTS: "points",
  IN_REVIEW: "inReview",
  APPROVED: "approved",
  INCORRECT: "incorrect",
  DEVICES: "devices",
}

const ParticipantTable = (props) => {
  const firstListRef = React.useRef(null);
  const [pagination, setPagination] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState(-1);
  const [sortType, setSortType] = React.useState(PARTICIPANT_SORT_TYPE.POINTS);
  const { isActiveSubscription, isCreatedBeforeSubscriptionOriginDate } = React.useContext(PaymentContext);
  const isCreator = isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY);

  const challengesData = props.challengePackage.challenges;
  const packageAccesses = props.packageAccesses.filter((pa) => { return pa?.userInfo?.id !== null && pa?.userInfo?.id !== undefined });

  let userAnalyses = {};
  packageAccesses.forEach((accessUser) => {
    let pa = accessUser.packageAccess;
    let userInfo = accessUser.userInfo;
    if (!userInfo) {
      return;
    }

    let analytics = {
      approved: 0,
      inReview: 0,
      incorrect: 0,
      devices: {},
      points: 0,
    };

    pa.submittedChallengeAnswers.forEach((sca) => {
      let challenge = challengesData.find((c) => { return c.challengeId === sca.challengeId });
      if (!challenge) {
        return;
      }
      sca.challengeInfo = challenge;

      if (sca.status == CHALLENGE_ANSWER_STATUS.APPROVED) {
        analytics.approved++;

        if (challenge.packagePoints !== null) {
          analytics.points += challenge.packagePoints
        } else {
          analytics.points += 10
        }
      }

      if (sca.status == CHALLENGE_ANSWER_STATUS.IN_REVIEW) {
        analytics.inReview++
      }

      if (sca.status == CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE || sca.status == CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT) {
        analytics.incorrect++
      }

      if (!analytics.devices[sca.deviceId]) {
        analytics.devices[sca.deviceId] = true
      }
    })

    if (userInfo?.id) {
      userAnalyses[userInfo?.id] = analytics;
    }
  })

  const createRows = (accessUsers) => {
    if (Object.keys(userAnalyses).length === 0) {
      return [];
    }
    let rows = [];
    accessUsers.forEach((accessUser) => {
      if (!accessUser || !accessUser?.userInfo?.id) return;
      let pa = accessUser.packageAccess;
      let userInfo = accessUser.userInfo;
      let analytics = userAnalyses[userInfo?.id];
      let submissions = pa.submittedChallengeAnswers ?? [];

      rows.push(
        <ParticipantTableRow
          image={userInfo.pictureUrl}
          username={userInfo.username}
          name={userInfo.name}
          totalSubmissions={submissions}
          inReview={analytics.inReview}
          approved={analytics.approved}
          incorrect={analytics.incorrect}
          devices={Object.keys(analytics.devices).length}
          points={analytics.points}
        />)
    })

    return rows;
  }

  const getSearchResults = (accessUsers) => {
    if (props.searchQuery === "" || props.searchQuery === null) {
      return accessUsers;
    }
    const filtered = accessUsers.filter(
      (au) => {
        return (
          au.userInfo.username !== null && au.userInfo.username.toLowerCase().includes(props.searchQuery.toLowerCase())
          || au.userInfo.name !== null && au.userInfo.name.toLowerCase().includes(props.searchQuery.toLowerCase())
        )
      }
    )

    return filtered
  }

  const getPaginatedData = (noPagination) => {
    let pas = props.packageAccesses;
    if (sortType === PARTICIPANT_SORT_TYPE.USERNAME) {
      pas = sortByUsername();
    } else if (sortType === PARTICIPANT_SORT_TYPE.NAME) {
      pas = sortByName();
    } else if (sortType === PARTICIPANT_SORT_TYPE.POINTS) {
      pas = sortByPoints();
    } else if (sortType === PARTICIPANT_SORT_TYPE.SUBMISSIONS) {
      pas = sortBySubmissions();
    } else if (sortType === PARTICIPANT_SORT_TYPE.LAST_SUBMISSION) {
      pas = sortByLastApprovedSubmission();
    } else if (sortType === PARTICIPANT_SORT_TYPE.APPROVED) {
      pas = sortByApproved();
    } else if (sortType === PARTICIPANT_SORT_TYPE.INCORRECT) {
      pas = sortByIncorrect();
    } else if (sortType === PARTICIPANT_SORT_TYPE.IN_REVIEW) {
      pas = sortByInReview();
    } else if (sortType === PARTICIPANT_SORT_TYPE.DEVICES) {
      pas = sortByDevices();
    }

    pas = getSearchResults(pas);

    if (noPagination) {
      return pas;
    }

    return pas.slice(PAGE_COUNT * pagination, PAGE_COUNT * pagination + PAGE_COUNT);
  }

  const sortByUsername = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = a.userInfo.username == null ? "" : a.userInfo.username.toUpperCase();
        let compareB = b.userInfo.username == null ? "" : b.userInfo.username.toUpperCase();
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortByName = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = a.userInfo.name == null ? "" : a.userInfo.name.toUpperCase();
        let compareB = b.userInfo.name == null ? "" : b.userInfo.name.toUpperCase();
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortBySubmissions = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = a.packageAccess.submittedChallengeAnswers.length;
        let compareB = b.packageAccess.submittedChallengeAnswers.length;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortByLastApprovedSubmission = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let aAnswers = a.packageAccess.submittedChallengeAnswers.filter((sca) => { return sca.status === CHALLENGE_ANSWER_STATUS.APPROVED });
        let bAnswers = b.packageAccess.submittedChallengeAnswers.filter((sca) => { return sca.status === CHALLENGE_ANSWER_STATUS.APPROVED });
        let aAnswersLength = aAnswers.length;
        let bAnswersLength = bAnswers.length;
        if (aAnswersLength === 0 && bAnswersLength === 0) return 0;
        if (aAnswersLength > 0 && bAnswersLength === 0) return 1 * sortDirection;
        if (aAnswersLength === 0 && bAnswersLength > 0) return -1 * sortDirection;
        if (aAnswers[aAnswersLength - 1].submissionDate < bAnswers[bAnswersLength - 1].submissionDate) return 1 * sortDirection;
        if (aAnswers[aAnswersLength - 1].submissionDate > bAnswers[bAnswersLength - 1].submissionDate) return -1 * sortDirection;
        return 0;
      }
    );
    return pas
  }

  const sortByPoints = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = userAnalyses[a.userInfo?.id]?.points;
        let compareB = userAnalyses[b.userInfo?.id]?.points;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortByInReview = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = userAnalyses[a.userInfo?.id]?.inReview;
        let compareB = userAnalyses[b.userInfo?.id]?.inReview;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortByApproved = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = userAnalyses[a.userInfo?.id]?.approved;
        let compareB = userAnalyses[b.userInfo?.id]?.approved;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortByIncorrect = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = userAnalyses[a.userInfo?.id]?.incorrect;
        let compareB = userAnalyses[b.userInfo?.id]?.incorrect;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const sortByDevices = () => {
    let pas = packageAccesses.sort(
      (a, b) => {
        let compareA = Object.keys(userAnalyses[a.userInfo?.id]?.devices).length;
        let compareB = Object.keys(userAnalyses[b.userInfo?.id]?.devices).length;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return pas
  }

  const paginatedData = getPaginatedData();

  const getDownloadButton = (accessUsers) => {
    let downloadData = [];
    accessUsers.forEach(
      (accessUser) => {
        if (!accessUser?.userInfo?.id) return;
        let pa = accessUser.packageAccess;
        let userInfo = accessUser.userInfo;
        let analytics = userAnalyses[userInfo?.id];
        let submissions = pa.submittedChallengeAnswers ?? [];
        let dataObject = {};

        dataObject.Username = userInfo.username;
        dataObject.Name = userInfo.name;
        dataObject.Points = analytics.points;
        dataObject.TotalSubmissions = submissions.length;
        dataObject.Approved = analytics.approved;
        dataObject.InReview = analytics.inReview;
        dataObject.Incorrect = analytics.incorrect;
        dataObject.DevicesUsed = Object.keys(analytics.devices).length;

        downloadData.push(dataObject);
      }
    )

    return <Download
      filename={props.challengePackage.title + "-Participants"}
      data={downloadData} />;
  }

  const onClickHandler = (newSortType) => {
    setSortDirection(newSortType === sortType || sortType === PARTICIPANT_SORT_TYPE.NONE ? sortDirection * -1 : 1);
    setSortType(newSortType);
  }

  React.useEffect(() => {
    setPagination(0);
    props.setDownloadButtonHandler(getDownloadButton(getPaginatedData(true)));
  }, [sortType, sortDirection, props.searchQuery])

  if (!isCreator && !isCreatedBeforeSubscriptionOriginDate(new Date(props.challengePackage.createdOn))) {
    return <div className="mt-3 mb-3 ml-3 mr-3">
      <ExplorerCard ctaText={"Access Participant Analytics!"} />
    </div>;
  }

  return (
    <>
      <div className="table-responsive" ref={firstListRef}>
        <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              <th data-sort="username" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.USERNAME) }}>Username</th>
              <th data-sort="name" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.NAME) }}>Name</th>
              <th data-sort="points" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.POINTS) }}>Points</th>
              <th data-sort="submissions" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.SUBMISSIONS) }}># of Submissions</th>
              <th title="Time of Last Correct Submission" data-sort="lastSubmission" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.LAST_SUBMISSION) }}>Last Approved Time</th>
              <th data-sort="approved" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.APPROVED) }}># Approved</th>
              <th data-sort="inReview" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.IN_REVIEW) }}># In Review</th>
              <th data-sort="incorrect" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.INCORRECT) }}># Incorrect</th>
              <th data-sort="devices" scope="col" onClick={(e) => { e.preventDefault(); onClickHandler(PARTICIPANT_SORT_TYPE.DEVICES) }}># of Devices</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody className="list">
            {createRows(paginatedData)}
          </tbody>
        </Table>
      </div >
      <PaginationControls
        dataSet={getSearchResults(packageAccesses)}
        currentPage={pagination}
        setPaginationHandler={setPagination} />
    </>
  );
}

export default ParticipantTable;
