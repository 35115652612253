/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import { PATH } from "constants/link";

const Breadcrumbs = ({ name, parentName, subName, containerStyle }) => {
  const history = useHistory();
  return (
    <Col lg="6" xs="7" style={containerStyle}>
      <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
      <Breadcrumb
        className="d-none d-md-inline-block ml-md-4"
        listClassName="breadcrumb-links breadcrumb-dark"
      >
        <BreadcrumbItem>
          <a href={PATH.MAIN} onClick={(e) => { e.preventDefault(); history.push(PATH.MAIN) }}>
            <i className="fas fa-home" />
          </a>
        </BreadcrumbItem>
        {
          parentName ?
            <BreadcrumbItem>
              <a href={PATH.MAIN} onClick={(e) => { e.preventDefault(); history.push(PATH.MAIN) }}>
                {parentName}
              </a>
            </BreadcrumbItem>
            : null
        }
        <BreadcrumbItem aria-current="page" className="active">
          <span style={{ color: "white" }}>
            {subName}
          </span>
        </BreadcrumbItem>
      </Breadcrumb>
    </Col>
  );
}

Breadcrumbs.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  subName: PropTypes.string,
};

export default Breadcrumbs;
