import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfoContext } from '../UserInfoContext';
import { ViewPackageContext, FILTERS } from './ViewPackageContext';
import { useParams, useHistory } from "react-router-dom";
import { LookUpPackage, UnauthenticatedLookUpPackage } from 'helpers/ChallengePackage';
import { GetUsers, GetUnauthenticatedUser } from 'helpers/Users';
import { LINK } from "constants/link";
import { GetSubmissionForChallenge } from "helpers/ChallengePackage";
import { CHALLENGE_ANSWER_STATUS } from "constants/challengeAnswerStatus";
import GetProtectedAPIClient from "api/protectedAPIClient";
import { CHALLENGE_ENDPOINT } from "constants/api";
import { COORDINATES } from "constants/coordinates";
import { CHALLENGE_PACKAGE_TYPE } from "constants/challengePackageType";
import { DISTANCE } from "constants/distance";
import { IsEmpty } from "helpers/Strings";
import { PATH } from "constants/link";
const defaultFiltered = {
  [FILTERS.ALL]: [],
  [FILTERS.APPROVED]: [],
  [FILTERS.IN_REVIEW]: [],
  [FILTERS.REMAINING]: [],
  [FILTERS.DECLINED]: [],
};

const ViewPackageProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const data = React.useContext(UserInfoContext);
  const history = useHistory();
  const { lookUpCode } = useParams();

  const [challengePackage, setChallengePackage] = React.useState(null);
  const [createdChallengePackages, setCreatedChallengePackages] = React.useState([]);
  const [noPackageFound, setNoPackageFound] = React.useState(false);
  const [createdByUser, setCreatedByUser] = React.useState(null);
  const [newLookUpCode, setNewLookUpCode] = React.useState(null);
  const [fullyLoaded, setFullyLoaded] = React.useState(false);
  const [challengePackageAccess, setChallengePackageAccess] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState(FILTERS.ALL);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [filteredChallenges, setFilteredChallenges] = React.useState(defaultFiltered);
  const [nearbyChallengeTracks, setNearbyChallengeTracks] = React.useState([]);
  const [nearbyScavengerHunts, setNearbyScavengerHunts] = React.useState([]);
  const [nearbyChallengePackagesLoaded, setNearbyChallengePackagesLoaded] = React.useState(false);

  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  const resetState = () => {
    setNoPackageFound(false);
    setCreatedByUser(null)
    setNewLookUpCode(null);
    setChallengePackage(null);
    setSelectedFilter(FILTERS.ALL);
    setSearchQuery(null);
  }

  const updateFilteredChallenges = () => {
    const newFilteredChallenges = JSON.parse(JSON.stringify(defaultFiltered));

    if (!isAuthenticated) {
      challengePackage?.challenges.forEach((challenge, idx) => {
        if (searchQuery === null || challenge?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
          newFilteredChallenges[FILTERS.ALL].push(challenge);
        }
      })
      setFilteredChallenges(newFilteredChallenges)
    }
    else {
      challengePackage?.challenges.forEach((challenge, idx) => {
        const submission = GetSubmissionForChallenge(challenge.challenge, challengePackageAccess);
        if (searchQuery === null || challenge?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
          newFilteredChallenges[FILTERS.ALL].push(challenge);
        }
        if (!submission) {
          if (searchQuery === null || challenge?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
            newFilteredChallenges[FILTERS.REMAINING].push(challenge);
          }
        } else {
          switch (submission.status) {
            case CHALLENGE_ANSWER_STATUS.APPROVED:
              if (searchQuery === null || challenge?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
                newFilteredChallenges[FILTERS.APPROVED].push(challenge);
              }
              break;
            case CHALLENGE_ANSWER_STATUS.IN_REVIEW:
              if (searchQuery === null || challenge?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
                newFilteredChallenges[FILTERS.IN_REVIEW].push(challenge);
              }
              break;
            case CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE:
            case CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT:
              if (searchQuery === null || challenge?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
                newFilteredChallenges[FILTERS.DECLINED].push(challenge);
              }
              break;
          }
        };
      })

      setFilteredChallenges(newFilteredChallenges);
    };
  }

  React.useEffect(() => {
    updateFilteredChallenges()
  }, [JSON.stringify(challengePackage), JSON.stringify(challengePackageAccess), searchQuery])

  const GetFilteredChallenges = React.useCallback(() => { return filteredChallenges[selectedFilter] }, [JSON.stringify(filteredChallenges), selectedFilter, searchQuery])

  const GetPackage = async (redirectOnSuccess, code) => {
    const searchCode = IsEmpty(code) ? lookUpCode : code;
    if (fullyLoaded) { return; }
    if (isLoading) {
      return;
    }
    if (IsEmpty(searchCode)) {
      resetState();
      setNoPackageFound(true);
      return;
    }
    if (!isAuthenticated) {
      try {
        await UnauthenticatedLookUpPackage(
          searchCode,
          async (cp) => {
            if (cp === null || cp === "NO PACKAGE OR INACTIVE" || !cp?.challengePackage) {
              setNoPackageFound(true);
              history.push(`${PATH.SEARCH}/${searchCode}`);
            } else {
              if (redirectOnSuccess) {
                lookUpNewEvent(searchCode)
              } else {
                setNoPackageFound(false);
                setChallengePackage(cp.challengePackage);
                setCreatedChallengePackages(cp.createdChallengePackages);
                await GetUnauthenticatedUser(
                  cp.challengePackage.createdBy,
                  (userData) => {
                    setCreatedByUser(userData);
                    setFullyLoaded(true)
                  },
                  (error) => {
                    setNoPackageFound(true);
                    history.push(`${PATH.SEARCH}/${searchCode}`);
                    console.log("Something Broke", error)
                  }
                )
              }
            }
          },
          (error) => {
            setNoPackageFound(true);
            history.push(`${PATH.SEARCH}/${searchCode}`);
            console.log("Something Broke", error)
          }
        )
      } catch (e) {
        setNoPackageFound(true);
        history.push(`${PATH.SEARCH}/${searchCode}`);
        console.log(e.message);
      }
    } else {
      const identifier = "roamli.back.auth";
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${identifier}`,
          scope: "read:current_user openid access:users",
        });
        await LookUpPackage(
          searchCode,
          data?.user?.id,
          accessToken,
          async (cp) => {
            if (cp === null || cp === "NO PACKAGE OR INACTIVE" || !cp?.challengePackage) {
              setNoPackageFound(true);
              history.push(`${PATH.SEARCH}/${searchCode}`);
            } else {
              if (redirectOnSuccess) {
                lookUpNewEvent(searchCode)
              } else {
                setNoPackageFound(false);
                setChallengePackage(cp.challengePackage);
                setCreatedChallengePackages(cp.createdChallengePackages);
                setChallengePackageAccess(cp.challengePackageAccess);
                await GetUsers(
                  [cp.challengePackage.createdBy],
                  accessToken,
                  (userData) => {
                    setCreatedByUser(userData[0]);
                    setFullyLoaded(true)
                  },
                  (error) => {
                    setNoPackageFound(true);
                    history.push(`${PATH.SEARCH}/${searchCode}`);
                    console.log("Something Broke", error)
                  }
                )
              }
            }
          },
          (error) => {
            setNoPackageFound(true);
            history.push(`${PATH.SEARCH}/${searchCode}`);
            console.log("Something Broke", error)
          }
        )
      } catch (e) {
        setNoPackageFound(true);
        history.push(`${PATH.SEARCH}/${searchCode}`);
        console.log(e.message);
      }
    }
  }

  const lookUpNewEvent = (code) => {
    const newCode = code ?? newLookUpCode;
    if (IsEmpty(newCode)) {
      return;
    }
    resetState();
    history.push(`${LINK.VIEW_BASE}/${newCode}`);
  }

  React.useEffect(() => {
    if (!isLoading && (!isAuthenticated || (isAuthenticated && data?.user?.id))) {
      GetPackage();
    }
  }, [lookUpCode, isLoading, data])

  React.useEffect(() => {
    const fetchLocation = () => {
      if (!navigator.geolocation) {
        setError('Geolocation is not supported by your browser');
        return;
      }

      const success = (position) => {
        setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
      };

      const failure = (error) => {
        setError(`Error ${error.code}: ${error.message}`);
      };

      navigator.geolocation.getCurrentPosition(success, failure);
    };

    fetchLocation();

    // Clean up
    return () => {
      // Cleanup logic if needed
    };
  }, []);

  const GetPoints = () => {
    let statuses = {
      toDo: 0,
      inReview: 0,
      approved: 0,
      declined: 0,
      pointsEarned: 0,
      pointsInReview: 0,
    };

    if (!challengePackageAccess || !challengePackage) {
      return statuses;
    }

    const challenges = challengePackage?.challenges;
    const submissions = challengePackageAccess?.submittedChallengeAnswers;
    let submissionsMap = {}
    let remainingChallenges = [];

    submissions?.forEach((submission) => {
      let cpacChal = challenges.find((challenge) => { return challenge?.challengeId === submission?.challengeId });
      submissionsMap[submission?.challengeId] = submission;
      switch (submission?.status) {
        case CHALLENGE_ANSWER_STATUS.IN_REVIEW:
          statuses.inReview += 1;
          if (cpacChal) {
            statuses.pointsInReview += (cpacChal?.packagePoints ?? cpacChal?.challenge?.points);
          }
          break;
        case CHALLENGE_ANSWER_STATUS.APPROVED:
          statuses.approved += 1;
          if (cpacChal) {
            statuses.pointsEarned += (cpacChal?.packagePoints ?? cpacChal?.challenge?.points);
          }
          break;
        case CHALLENGE_ANSWER_STATUS.DECLINED_INCORRECT:
        case CHALLENGE_ANSWER_STATUS.DECLINED_INAPPROPRIATE:
          statuses.declined += 1;
          break;
      }
    })

    challenges.forEach((packageChallenge) => {
      if (!submissionsMap[packageChallenge?.challengeId]) {
        remainingChallenges.push(packageChallenge);
      }
    })

    statuses.toDo = remainingChallenges.length;

    return statuses;
  }

  const getNearbyChallengePackages = async () => {
    const challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT);
    let scavengerHunts = [];
    let challengeTracks = [];
    const locationToUse = userLocation ? { latitude: userLocation?.lat, longitude: userLocation?.lng } : COORDINATES.LOCATION_DISABLED;
    if (!locationToUse) {
      setNearbyScavengerHunts(scavengerHunts);
      setNearbyChallengeTracks(challengeTracks);
      setNearbyChallengePackagesLoaded(true);
    } else {
      challengeAPIClient.get("/Package?latitude=" + locationToUse.latitude + "&longitude=" + locationToUse.longitude + "&radius=" + DISTANCE.REGION_RADIUS_M + "&activeOnly=true")
        .then((res) => {
          res.data.forEach((challengePackage) => {
            if (challengePackage.type === CHALLENGE_PACKAGE_TYPE.TRACK) {
              challengeTracks.push(challengePackage);
            } else {
              scavengerHunts.push(challengePackage);
            }
          })
          setNearbyScavengerHunts(scavengerHunts);
          setNearbyChallengeTracks(challengeTracks);
          setNearbyChallengePackagesLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setNearbyScavengerHunts(scavengerHunts);
          setNearbyChallengeTracks(challengeTracks);
          setNearbyChallengePackagesLoaded(true);
        }
        );
    }
  }

  React.useEffect(() => {
    getNearbyChallengePackages()
  }, [])

  return (
    <ViewPackageContext.Provider value={{
      challengePackage,
      createdChallengePackages,
      noPackageFound,
      createdByUser,
      newLookUpCode,
      fullyLoaded,
      challengePackageAccess,
      selectedFilter,
      filteredChallenges,
      lookUpNewEvent,
      setNewLookUpCode,
      GetPackage,
      resetState,
      userLocation,
      setChallengePackageAccess,
      setChallengePackage,
      setSelectedFilter,
      GetFilteredChallenges,
      nearbyChallengeTracks,
      nearbyScavengerHunts,
      nearbyChallengePackagesLoaded,
      searchQuery,
      setSearchQuery,
      GetPoints
    }}>
      {
        children
      }
    </ViewPackageContext.Provider>
  );
}

export default ViewPackageProvider;
