import React from 'react';
import { LINK } from '../constants';

export function GenCommunityStandardsLink(child) {
  return (<a href={LINK.COMMUNITY_STANDARDS} target="_blank">
    {child}
  </a>)
}

export function GenPrivacyPolicyLink(child) {
  return (<a href={LINK.PRIVACY_POLICY} target="_blank">
    {child}
  </a>)
}

export function GenTermsOfUseLink(child) {
  return (<a href={LINK.TERMS_OF_USE} target="_blank">
    {child}
  </a>)
}