import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserInfoProvider from './contexts/UserInfoProvider';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ViewLayout from "layouts/View.js";
import SearchLayout from "layouts/Search.js";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import PaymentProvider from "contexts/PaymentProvider";
import ChallengeProvider from "contexts/ChallengeContext/ChallengeProvider";
import NotificationProvider from "contexts/NotificationContext/NotificationProvider";
import { PATH } from "constants/link";
import MainAppLoader from "components/Loaders/MainAppLoader";
import AdminNavbar from "components/Navbars/AdminNavbar";
import routes from "routes.js";
import Sidebar from "components/Sidebar/Sidebar";
import AuthNavbar from "components/Navbars/AuthNavbar";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [sidenavOpen, setSidenavOpen] = React.useState(false);
  const mainContentRef = React.useRef(null);

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    setSidenavOpen(!sidenavOpen);
  };

  const getRoutes = () => {
    const authenticatedRoutes =
      <>
        <NotificationProvider>
          <UserInfoProvider>
            <ChallengeProvider>
              <PaymentProvider>
                <BrowserRouter>
                  <>
                    <Sidebar
                      routes={routes}
                      toggleSidenav={toggleSidenav}
                      sidenavOpen={sidenavOpen}
                      logo={{
                        // innerLink: "/",
                        imgSrc: require("assets/roamli-logo.png").default,
                        imgAlt: "...",
                      }}
                    />
                    <div className="main-content" ref={mainContentRef}>
                      <AdminNavbar
                        theme={"dark"}
                        toggleSidenav={toggleSidenav}
                        sidenavOpen={sidenavOpen}
                        brandText={""}
                        gradient
                      />
                      <Switch>
                        <Route path={PATH.VIEW} render={(props) => <ViewLayout {...props} />} />
                        <Route path={PATH.SEARCH} render={(props) => <SearchLayout {...props} />} />
                        {/* <Route path="/pricing" render={(props) => <AdminLayout {...props} />} /> */}
                        <Route path={PATH.HOME} render={(props) => <AdminLayout {...props} />} />
                        <Redirect from="*" to={PATH.HOME} />
                      </Switch>
                    </div>
                    {sidenavOpen && isAuthenticated ? (
                      <div className="backdrop d-xl-none" onClick={toggleSidenav} />
                    ) : null}
                  </>
                </BrowserRouter>
              </PaymentProvider>
            </ChallengeProvider>
          </UserInfoProvider>
        </NotificationProvider>
      </>;

    const inauthenticatedRoutes = <BrowserRouter>
      <AuthNavbar />
      <Switch>
        <Route path={PATH.VIEW} render={(props) => <ViewLayout {...props} />} />
        <Route path={PATH.SEARCH} render={(props) => <SearchLayout {...props} />} />
        <Route path={PATH.HOME} render={(props) => <AuthLayout {...props} />} />
        {
          isLoading ?
            null
            : <Redirect from="*" to={PATH.HOME} />
        }
      </Switch>
    </BrowserRouter>;

    if (isLoading) {
      return <MainAppLoader />
    }

    return isAuthenticated ? authenticatedRoutes : inauthenticatedRoutes;
  }

  return getRoutes();
}

export default App;
