import React from "react";
import styled from "styled-components";

const Avatar = (props) => {
  return (
    <AvatarV2 src={props.imageUrl} alt={props.alt} margin="mr-3" style={props.style}/>
    // <div
    //   className="avatar rounded-circle mr-3"
    //   style={{ backgroundImage: "url(" + props.imageUrl + ")", backgroundSize: 'cover', backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
    // />
  );
}

export const AvatarV2 = (props) => {
  const classes = "avatar" + (props.margin ? " " + props.margin : "");
  return (
    <AvatarRoot className={classes} style={props.style}>
      <AvatarInner src={props.src} alt={props.alt} />
    </AvatarRoot>
  )
}

const AvatarRoot = styled('div')({
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const AvatarInner = styled('img')({
  width: 'unset !important',
  height: '130% !important',
  position: 'absolute',
});




export default Avatar;
