import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker, Circle } from 'google-maps-react';
import './GoogleMap.css';
import { GetIconColorPackage } from '../../helpers/Category';
import { CHALLENGE_ASSETS } from "../../constants";

const mapStyles = {
  width: '500px',
  height: '500px'
};

export class UpdateMap extends Component {
  state = {
    showingInfoWindow: false,  //Hides or the shows the infoWindow
    activeMarker: {},          //Shows the active marker upon click
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  constructor(props) {
    super(props);
  }

  render() {
    const position = { lat: this.props.lat, lng: this.props.lng };
    let colorPackage = GetIconColorPackage(this.props.challengeType ?? 25);
    let name = colorPackage.name === "zoo_gorilla" ? "zoo" : colorPackage.name;
    let iconMarker = new window.google.maps.MarkerImage(
      CHALLENGE_ASSETS[name.toUpperCase()].default,
      null, /* size is determined at runtime */
      null, /* origin is 0,0 */
      new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
      new window.google.maps.Size(30, 30)
    );
    return (
      <Map
        className={"googleMap"}
        google={this.props.google}
        containerStyle={this.props.containerStyle}
        zoom={18}
        style={{
          position: "relative",
          width: 400,
          height: 400
        }}
        initialCenter={position}
        center={position}
        onClick={
          (t, map, c) => {
            this.props.onClickHandler(
              {
                lat: c.latLng.lat(),
                lng: c.latLng.lng()
              })
          }
        }
      >
        <Circle
          center={position}
          radius={this.props.radius}
          strokeColor={colorPackage.color}
          fillColor={colorPackage.backgroundColor}
          onClick={(t, map, c) => {
            this.props.onClickHandler(
              {
                lat: c.latLng.lat(),
                lng: c.latLng.lng()
              })
          }} />
        <Marker
          onClick={this.onMarkerClick}
          icon={iconMarker}
          name={this.props.name}
          position={position} />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <h4 style={{ textAlign: 'center' }}>{this.state.selectedPlace.name}</h4>
              <img src={this.props.picUrl} style={{ height: 100 }} />
            </div>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBVA1mMwDKj4H4Vr1xtyeGrYDFXrkZfBeo'
})(UpdateMap);