import * as React from 'react';
import Masonry from 'react-masonry-component';
import "./SCPIMGallery.css";

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const SCPIMGallery = (props) => {
  const childElements = props.urls.map((url) => {
    return (
      <div
        onClick={() => { props.selectImageHandler(url.url) }}
        className="scpim-image-root"
        style={
          {
            width: 200,
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 2.5,
            position: 'relative',
            overflow: 'hidden',
            borderRadius: 15,
            cursor: 'pointer',
            position: 'relative',
          }
        }>
        <img src={url.url} style={{ minWidth: '100%', height: '100%', objectFit: 'cover' }} />
        {
          url?.url && props.selectedImage && props.selectedImage === url?.url ?
            <>
              <div className="scpim-image-overlay">
                <img src={require('../../../assets/misc_icons/yes.png').default} style={{ height: 50, width: 50, position: 'absolute', bottom: 20, right: 20 }} />
              </div>
            </>
            : null
        }
      </div >

    );
  });

  return (
    <Masonry
      className={'my-gallery-class'} // default ''
      elementType={'div'} // default 'div'
      options={masonryOptions} // default {}
      disableImagesLoaded={false} // default false
      updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
      imagesLoadedOptions={imagesLoadedOptions} // default {}
    >
      {
        props.urls.length !== 0 ?
          childElements
          : <h3>Add Challenges First</h3>
      }
    </Masonry>
  );
}

export default SCPIMGallery;