/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Input,
  Col,
  Button
} from "reactstrap";
import { IsValidYouTubeUrl, ExtractYouTubeVideoId } from "helpers/Validations";
import ChallengeFormFieldRow from "components/Forms/ChallengeForm/ChallengeFormFieldRow";
import { IsEmpty } from "helpers/Strings";
import YoutubeEmbed from "components/YouTubePlayer/YouTubePlayer";

const YouTubeLinkRow = (props) => {
  const [keyUpdate, setKeyUpdate] = React.useState(0);
  const { label, url, description } = props.youTubeLink ?? { label: null, url: null, description: null };
  return (
    <>
      <div key={keyUpdate} className="form-row mb-1" style={{ alignItems: 'flex-end' }}>
        <Col className="mb-3" md={props.labelMd ?? "4"}>
          <label
            className="form-control-label"
            htmlFor="externalLinksLabel"
          >
            Label
          </label>
          <Input
            value={label}
            id="youTubeLinkLabel"
            placeholder="Label (Optional)"
            type="text"
            onChange={(e) => {
              props.updateHandler(IsEmpty(url) && IsEmpty(e.target.value) && IsEmpty(description) ? null : { label: e.target.value, url: url, description: description })
            }}
          />
          <div className="valid-feedback">Looks good!</div>
        </Col>
        <Col className="mb-3" md={props.urlMd ?? (props.isResetDisabled ? "8" : "6")} style={{ position: 'relative' }}>
          <label
            className="form-control-label"
            htmlFor="externalLinksUrl"
          >
            Url
          </label>
          <Input
            value={url}
            id="youTubeLinkURL"
            placeholder="YouTube URL"
            type="text"
            invalid={!IsValidYouTubeUrl(url) && !IsEmpty(url)}
            onChange={(e) => {
              props.updateHandler(IsEmpty(label) && IsEmpty(e.target.value) && IsEmpty(description) ? null : { label: label, url: e.target.value, description: description })
            }}
          />
          <div className="invalid-feedback" style={{ position: 'absolute' }}>Must be a valid YouTube Link</div>
        </Col>
        {
          props.isResetDisabled ?
            null
            : <Button
              className="btn-icon-only ml-2 mb-3"
              color="primary"
              type="button"
              disabled={props.isResetDisabled}
              style={{ height: 46, width: 46 }}
              onClick={() => { props.resetHandler(); setKeyUpdate(keyUpdate + 1) }}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-undo-alt" />
              </span>
            </Button>
        }
      </div>
      <ChallengeFormFieldRow
        key={"description" + keyUpdate}
        isResetDisabled={true}
        fields={[
          {
            width: "12",
            label: "Additional Text",
            type: "textarea",
            inputStyle: { height: 150 },
            defaultValue: undefined,
            placeholder: "Enter Additional Text (Optional)",
            value: description,
            changeHandler: (value) => { props.updateHandler(IsEmpty(label) && IsEmpty(value) && IsEmpty(url) ? null : { label: label, url: url, description: value }) },
            invalidFeedback: null,
            validFeedback: null
          }
        ]}
      />
      {url && IsValidYouTubeUrl(url) && <YoutubeEmbed embedId={ExtractYouTubeVideoId(url)} maxWidth={props.maxVideoWidth}/>}
    </>
  );
}

YouTubeLinkRow.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  updateHandler: PropTypes.func,
  resetHandler: PropTypes.func,
  isResetDisabled: PropTypes.bool,
};

export default YouTubeLinkRow;
