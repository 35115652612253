import { IsEmpty } from "./Strings";

export function IsValidUrl(url) {
  if (IsEmpty(url)) {
    return false;
  }
  let splitUrl = url.split("://");
  return splitUrl.length > 1 && (splitUrl[0] === "https" || splitUrl[0] === "http");
}

export function IsValidYouTubeUrl(url) {
  if (IsEmpty(url)) {
    return false;
  }

  const regex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;

  return regex.test(url);
}

export function ExtractYouTubeVideoId(url) {
  let regex = /[?&]v=([^&]+)/;
  let match = url.match(regex);
  return match && match[1];
}