/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React from "react";
import { COLOR } from "constants/color";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import useBreakpoints from 'hooks/Responsive';
import { PaymentContext } from "contexts/PaymentContext";
import { STRIPE_PRICES } from "constants/stripe";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PATH } from "constants/link";
import "./Pricing.css";

const Pricing = () => {
  const { isTabletOrMobile } = useBreakpoints();
  const { isAuthenticated, isLoading } = useAuth0();
  const {
    creatorSubscriptionPaymentLink,
    loadingPaymentLink,
    loadingStripeInfo,
    isActiveSubscription
  } = React.useContext(PaymentContext);

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  const getMainClass = () => {
    return isTabletOrMobile ? "justify-content-center" : "justify-content-center pt-6";
  }
  const subscriptionButtonInfo = () => {
    if (!isAuthenticated) {
      return {
        clickHandler: () => { routeChange(PATH.HOME) },
        disabled: false,
        innerContent: "Try it out!"
      }
    }

    if (loadingPaymentLink) {
      return {
        clickHandler: () => { },
        disabled: true,
        innerContent: <Spinner color={"secondary"} />
      }
    }

    if (isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY)) {
      return {
        clickHandler: () => { },
        disabled: true,
        innerContent: "Your Current Plan"
      }
    }

    if (!creatorSubscriptionPaymentLink) {
      return {
        clickHandler: () => { },
        disabled: true,
        innerContent: "Error"
      }
    }

    return {
      clickHandler: () => { window.location.href = creatorSubscriptionPaymentLink.url },
      disabled: false,
      innerContent: "Upgrade"
    }
  }

  const buttonInfo = subscriptionButtonInfo();

  return (
    <Container className={`pb-5 ${!isAuthenticated && 'bg-gradient-secondary'}`} style={{ width: "100%", maxWidth: "100%" }}>
      {
        <Container style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
          <div className="header-body text-center">
            <Row className="justify-content-center">
              <Col className="px-5" lg="10" md="10" xl="10">
                <h1
                  className="ls-1 py-4"
                  style={{ fontSize: isTabletOrMobile ? 30 : 42, fontWeight: 400, color: COLOR.ROAMLI_NAVY_HEX }}
                >
                  {isAuthenticated ? "Select the plan that's right for you" : ""}
                </h1>
              </Col>
            </Row>
          </div>
        </Container>
      }
      <Row className={getMainClass()} style={{ maxWidth: 1400, margin: "auto" }}>
        <Col lg="10">
          <div className="pricing card-group flex-md-row mb-3" style={isTabletOrMobile ? { display: 'flex', flexDirection: "column-reverse" } : null}>
            <Card className="card-pricing border-0 text-center mb-4">
              <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                  Explorer
                </h4>
              </CardHeader>
              <CardBody className="px-lg-7" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                <div className="display-2">Free</div>
                <ul className="list-unstyled my-4">
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon icon-shape bg-gradient-info shadow rounded-circle text-white">
                          <i className="ni ni-square-pin" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2">Explore Activities Near You</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon icon-shape bg-gradient-info shadow rounded-circle text-white">
                          <i className="ni ni-trophy" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2">
                          Earn points for Completing Events
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon icon-shape bg-gradient-info shadow rounded-circle text-white">
                          <i className="ni ni-user-run" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2">Compete with other Roamers</span>
                      </div>
                    </div>
                  </li>
                </ul>
                {
                  !isActiveSubscription(STRIPE_PRICES.CREATOR_MONTHLY) &&
                  isAuthenticated &&
                  <Button className="mb-3" color="info" type="button" disabled>
                    Your Current Plan
                  </Button>
                }
                {
                  !isAuthenticated &&
                  <Button className="mb-3" color="info" type="button" onClick={() => { routeChange(PATH.HOME) }} >
                    Create Free Account!
                  </Button>
                }
              </CardBody>
              <CardFooter>
                {/* <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                > */}
                Explore in the Roamli App
                {/* </a> */}
              </CardFooter>
            </Card>
            <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">
              <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                  Creator
                </h4>
              </CardHeader>
              <CardBody className="px-lg-7" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                <div className="display-1 text-white">$49/month</div>
                {/* <span className="text-white">or $299.99 annually</span> */}
                <ul className="list-unstyled my-4">
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon icon-shape bg-white shadow rounded-circle text-muted">
                          <i className="ni ni-spaceship" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2 text-white">
                          Unlimited Interactive Maps & Experiences
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon icon-shape bg-white shadow rounded-circle text-muted">
                          <i className="ni ni-chart-bar-32" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2 text-white">
                          Advanced Analytics & Leaderboards
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="icon icon icon-shape bg-white shadow rounded-circle text-muted">
                          <i className="ni ni-single-02" />
                        </div>
                      </div>
                      <div>
                        <span className="pl-2 text-white">
                          1:1 Dedicated Customer Success Manager
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <Button disabled={buttonInfo.disabled} className="mb-3" color="primary" type="button" onClick={buttonInfo.clickHandler}>
                  {
                    buttonInfo.innerContent
                  }
                </Button>
              </CardBody>
              <CardFooter className="bg-transparent">
                <a
                  className="text-white"
                  target="_blank"
                  href="https://www.roamli.com/demo"
                >
                  Request a Demo
                </a>
              </CardFooter>
            </Card>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-lg-center px-3 mt-5">
        <div>
          <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
            <img style={{ height: 40 }} src={require('assets/roamli-logo.png').default} />
          </div>
        </div>
        <Col lg="6">
          <p className="text-black">
            <strong>Roamli Creators</strong> can create their own events from walking tours,
            corporate outings, conferences, and more. These events are available to anyone
            via Roamli's website or as Interactive Maps embedded in your own site!
          </p>
        </Col>
      </div>
      <Row className="row-grid justify-content-center">
        <Col lg="10">
          <Table className="mt-5" responsive style={{ maxWidth: 1000, margin: "auto" }}>
            <thead>
              <tr>
                <th className="px-0 bg-transparent" scope="col">
                  <span className="text-dark font-weight-700 featureListHeader">Features</span>
                </th>
                <th className="text-center bg-transparent featureListHeader" scope="col">
                  Explorer
                </th>
                <th className="text-center bg-transparent featureListHeader" scope="col">
                  Creator
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-0 featureListText">Explore Experiences & Activities Near You</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Compete in Special Events on Roamli</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Create Unlimited Interactive Maps & Experiences</td>
                <td className="text-center featureListText">Preview</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Share via Roamli's Web Portal</td>
                <td className="text-center featureListText">Preview</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Embed Interactive Maps in Your Site</td>
                <td className="text-center featureListText">-</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Advanced Analytics & Leaderboards</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Live Onboarding Training</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">1:1 Dedicated Customer Success Manager</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
              <tr>
                <td className="px-0 featureListText">Full Event Creation Support & Buildout</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <i className="fas fa-check text-success featureListText" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Pricing;
