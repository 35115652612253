import { COLOR } from './color';

const FONT = {
	PRIMARY_PROFILE: {
	  	color: COLOR.ROAMLI_NAVY_HEX,
  		fontSize: 24,
	},

	SECONDARY_PROFILE: {
	  	fontSize: 18,
	},
}

export {FONT};