import React from 'react';
import './ViewPackageImageHeader.css';
import useBreakpoints from '../../../hooks/Responsive';

const ViewPackageImageHeader = ({ challengePackage, showArrow }) => {
  const { isTabletOrMobile } = useBreakpoints();
  return (<div className={`viewPackageMapMobileTitleCard${!isTabletOrMobile ? " desktopVersion" : ""}`}>
    <div className="viewPackageMapMobileTitleCardOverlay" />
    <img src={challengePackage.anchorImage} className="viewPackageMapMobileTitleCardBackground" />
    <div className="viewPackageMapMobileTitleCardInner">
      <h1 className="display-1 mb-0 viewPackageMapMobileTitleCardHeader" >
        {challengePackage?.title?.toUpperCase()}
      </h1>
      <h2 style={{ color: "white" }}>
        {challengePackage?.lookUpCode}
      </h2>
    </div>
    {
      showArrow && <div className="viewPackageMapMobileTitleCardArrow" onClick={() => {
        document.getElementById("viewPackageUserRow").scrollIntoView(
          { behavior: "smooth" }
        );
      }} />
    }
  </div>)
}

export default ViewPackageImageHeader;