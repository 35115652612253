import React from "react";
import styled from "styled-components";
import { COLOR } from "constants/color";
import { FILTERS } from "contexts/ViewPackageContext/ViewPackageContext";
import classnames from "classnames";
import { ViewPackageContext } from "contexts/ViewPackageContext/ViewPackageContext";
import {
  Row,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

const ViewChallengePackageSearch = props => {
  const { isAuthenticated } = useAuth0();
  const defaultItem = { to: "1", color: COLOR.ROAMLI_BLUE_HEX, component: <div style={{ color: COLOR.ROAMLI_BLUE_HEX, fontWeight: "bold" }}>All</ div>, filter: FILTERS.ALL };
  const activeRef = React.createRef();
  const none = React.createRef();
  const [offset, setOffset] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [selected, setSelected] = React.useState(defaultItem);
  const { setSelectedFilter, setSearchQuery } = React.useContext(ViewPackageContext);

  React.useEffect(() => {
    const activeElement = activeRef.current;
    setOffset(activeElement?.offsetLeft);
    setWidth(activeElement?.clientWidth);
  }, [selected, activeRef]);

  const items =
    [
      defaultItem,
      { to: "2", color: COLOR.ROAMLI_PURPLE_HEX, component: <div style={{ color: COLOR.ROAMLI_PURPLE_HEX, fontWeight: "bold" }}>To-Do</ div>, filter: FILTERS.REMAINING },
      { to: "3", color: COLOR.SUCCESS_GREEN_HEX, component: <i className='fa fa-check' style={{ color: COLOR.SUCCESS_GREEN_HEX, fontSize: 24 }} />, filter: FILTERS.APPROVED },
      { to: "4", color: COLOR.ROAMLI_YELLOW_HEX, component: <i className='fa fa-gavel' style={{ color: COLOR.ROAMLI_YELLOW_HEX, fontSize: 24 }} />, filter: FILTERS.IN_REVIEW },
      { to: "5", color: COLOR.ALERT_RED_HEX, component: <i className='fa fa-ban' style={{ color: COLOR.ALERT_RED_HEX, fontSize: 24 }} />, filter: FILTERS.DECLINED },
    ];

  return (
    <>
      <Row
        className={`text-right ml-3 mt-3`}
        style={{ display: "flex", justifyContent: "center", width: 'calc(100% - 2rem)' }}>
        <Form
          onSubmit={e => { e.preventDefault(); }}
          style={{ justifyContent: 'center', display: 'flex', transform: "translateX(0%)", width: "100%" }}
          className={classnames(
            "navbar-search form-inline",
            "navbar-search-light"
          )}
        >
          <FormGroup className="mb-0" style={{ width: "100%" }}>
            <InputGroup className="input-group-alternative input-group-merge" style={{ width: "100%" }}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={"Search..."}
                type="text"
                onChange={(e) => { setSearchQuery(e.target.value) }} />
            </InputGroup>
          </FormGroup>
        </Form>
      </Row>
      {isAuthenticated && <TabsWrapper>
        <TabList>
          {items?.map(item => {
            return (
              <TabItem
                key={item.to}
                ref={selected?.to === item.to ? activeRef : none}
                className={selected?.to === item.to ? "is-active" : ""}
                onClick={() => {
                  setSelected(item);
                  setSelectedFilter(item.filter);
                }}
              >
                <StyledTab>
                  {item.component}
                </StyledTab>
              </TabItem>
            );
          })}
        </TabList>
        <ActiveLine width={width} offset={offset} color={selected?.color} />
      </TabsWrapper >}
    </>
  );
};

const TabsWrapper = styled.div({
  display: 'grid',
  width: "100%"
});

const ActiveLine = styled.div(props => ({
  height: '3px',
  width: `${props.width}px`,
  transform: `translateX(${props.offset}px)`,
  background: props.color,
  transition: 'transform 350ms cubic-bezier(0.15, 0.3, 0.25, 1)'
}));

const TabList = styled.ul({
  listStyle: 'none',
  padding: '0',
  margin: '0',
  position: 'relative'
});

const TabItem = styled.li({
  display: 'inline-block',
  cursor: 'pointer',
  '&.is-active': {},
  width: '20%',
});

const StyledTab = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
});

export default ViewChallengePackageSearch;
