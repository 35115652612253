// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Form,
  Button,
  Col,
  Modal
} from "reactstrap";
import { GetFilterNameFromNumber, GetFilters } from "../../helpers/Filters";
import ChallengeTypeSelectionRow from "./ChallengeTypeSelectionRow";
import { CHALLENGE_TYPE, COLOR } from "../../constants/";
import ChallengeTypeWithIcon from "./ChallengeTypeWithIcon";

const ChallengeTypeModal = (props) => {
  const [selectedTypes, setSelectedTypes] = React.useState(props.selectedChallengeTypes ?? []);

  const onSelectHandler = (challengeType, isSelected) => {
    if (props.requiredChallengeTypes?.includes(challengeType)) {
      return;
    } else if (props.canSelectOnlyOne) {
      if (isSelected) {
        setSelectedTypes([challengeType]);
      } else {
        setSelectedTypes([]);
      }
    } else {
      const newSelectedTypes = selectedTypes.map(st => st);
      if (isSelected) {
        newSelectedTypes.push(challengeType);
      } else {
        const index = newSelectedTypes.indexOf(challengeType);
        if (index > -1) {
          newSelectedTypes.splice(index, 1);
        }
      }
      setSelectedTypes(newSelectedTypes);
    }
  }
  const activeChallengeTypes = GetFilters(true);
  const challengeTypeNames = (Object.keys(activeChallengeTypes)).map(act => act.toUpperCase()).sort((a, b) => { return a > b ? 1 : -1 });
  const challengeTypeRows =
    challengeTypeNames.map(
      ctn =>
        <ChallengeTypeSelectionRow
          challengeType={CHALLENGE_TYPE[ctn]}
          isSelected={selectedTypes.includes(CHALLENGE_TYPE[ctn])}
          isRequired={props.requiredChallengeTypes?.includes(CHALLENGE_TYPE[ctn])}
          onSelectHandler={onSelectHandler}
        />
    );

  return (
    <Modal
      className="modal-dialog-centered"
      size="m"
      isOpen={props.isOpen}
      toggle={props.closeHandler}
      scrollable
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {"Select " + props.headerLabel}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.closeHandler}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body" style={{ padding: 0 }}>
        <Col style={{ padding: 0 }}>
          {challengeTypeRows}
        </Col>
      </div>
      <div
        className="modal-footer challengeTypeModalFooter"
        style={
          {
            backgroundColor: COLOR.ROAMLI_ORANGE_HEX,
            border: "1px solid " + COLOR.ROAMLI_ORANGE_HEX,
            pointerEvents: selectedTypes.length === 0 ? "none" : "all",
            opacity: selectedTypes.length === 0 ? .7 : 1,
          }
        }
        disabled={selectedTypes.length === 0}
        onClick={() => { props.canSelectOnlyOne ? props.onSelectHandler(selectedTypes[0]) : props.onSelectHandler(selectedTypes); props.closeHandler() }}
      >
        <div className="modal-footer challengeTypeModalFooterText">
          {"Set " + props.headerLabel}
        </div>
      </div>
    </Modal >
  )
}

ChallengeTypeModal.propTypes = {
  canSelectOnlyOne: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSelectHandler: PropTypes.func,
  closeHandler: PropTypes.func,
  selectedChallengeTypes: PropTypes.array,
  requiredChallengeTypes: PropTypes.array,
};

export default ChallengeTypeModal;
