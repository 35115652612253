import React from 'react';
import {
  Button,
  Row,
  Col,
  ListGroupItem,
} from "reactstrap";
import 'react-pro-sidebar/dist/css/styles.css';
import "./ViewPackageUserInfoRow.css";
import { AvatarV2 } from 'components/Images/RoundedImages/Avatar';

const ViewPackageUserInfoRow = (props) => {
  const { userData, challengePackage } = props;
  return (
    <ListGroupItem className="px-0 viewPackageUserRow" id="viewPackageUserRow">
      <Row className="align-items-center viewPackageUserInfoRowRoot">
        <Col className="col-auto">
          <AvatarV2 src={userData.pictureUrl} alt={userData.name} />
        </Col>
        {
          <div className="col ml--2">
            <h4 className="mb-0">
              <div>
                {userData.name}
              </div>
            </h4>
            <span className="text-success">●</span>{" "}
            <small>{userData.username}</small>
          </div>
        }
        {/* <Col className="col-auto">
          <div className="col ml--2">
            <h4 className="mb-0">
              <div>
                {challengePackage.lookUpCode}
              </div>
            </h4>
          </div>
        </Col> */}
      </Row>
    </ListGroupItem>
  );
}

export default ViewPackageUserInfoRow;