import React from "react";
import {
  Modal,
  Button,
} from "reactstrap";


const ResetChallengesConfirmationModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={props.isOpen}
        toggle={props.closeHandler}
      >
        <div className="modal-header">
          <h6
            className="modal-title"
            id="modal-title-notification"
          >
            Confirm Reset
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.closeHandler}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x mb-5" />
            <p>
              Clicking Reset will undo all changes you've made to Challenges, including adding/removing Challenges and Challenge-specific changes.
          </p>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <Button
            color="default"
            type="button"
            onClick={props.closeHandler}
          >
            Cancel
        </Button>
          <Button
            className="btn-white"
            color="default"
            type="button"
            onClick={() => { props.resetHandler(); props.closeHandler(); }}
          >
            Reset
        </Button>
        </div>
      </Modal >
    </>
  );
}

export default ResetChallengesConfirmationModal;