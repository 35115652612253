import React, { Component } from 'react';
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Circle,
  Polyline,
} from 'google-maps-react';
import {
  Button
} from "reactstrap";
import '../../GoogleMap/ViewPackageMap/ViewPackageMap.css';
import ViewPackageDetailsSidebar from 'views/pages/viewPackage/ViewPackageDetailsSidebar';
import 'views/pages/viewPackage/ViewPackage.css';
import { COLOR, DISTANCE, CHALLENGE_PACKAGE_TYPE, MAP_STYLE } from '../../../constants';
import { GetIconColorPackage } from '../../../helpers/Category';
import ViewPackageMapInfoWindow from './ViewPackageMapInfoWindow';
import ViewPackageDetailModal from 'views/pages/viewPackage/ViewPackageDetailModal';
import ViewPackageChallengeList from 'views/pages/viewPackage/ViewPackageChallengeList';
import ViewPackageUserInfoRow from 'views/pages/viewPackage/ViewPackageUserInfoRow';
import ShowOnly from 'components/Transitions/ShowOnly';
import ViewPackageImageHeader from 'views/pages/viewPackage/ViewPackageImageHeader';
import ViewPackageDescription from 'views/pages/viewPackage/ViewPackageDescription';
import { computeDestinationPoint } from 'geolib';
import { getMarkerIcon } from 'helpers/Marker';

class MarkersList extends React.Component {
  constructor(props) {
    super(props);
    this.markersRendered = false;
    this.props.challenges.forEach((challenge) => {
      this[challenge.challengeId] = React.createRef();
    })
    this.getMarkers = this.getMarkers.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.hideLine !== nextProps.hideLine) {
      return true;
    }

    if (this.props.type !== nextProps.type) {
      return true;
    }

    if (JSON.stringify(this.props.challenges) === JSON.stringify(nextProps.challenges) && this.markersRendered) {
      return false;
    }
    this.markersRendered = true;
    return true;
  }

  getScavengerHuntMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starPurple.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );
        const marker =
          <Marker
            ref={this[challenge.challengeId]}
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon()}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                fontSize: "12px",
                className: "markerLabel",
              }}
          >
          </Marker>
        challengeMarkers.push(marker);
      })
    }
    return challengeMarkers;
  };

  getChallengeTrackMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starOrange.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );

        const marker =
          <Marker
            ref={this[challenge.challengeId]}
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId + "markers"}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon()}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                // fontSize: "12px",
                // className: "markerLabel",
              }}
          />
        challengeMarkers.push(marker);
      })
    }
    if (!this.props.hideLine) {
      challengeMarkers.push(<Polyline
        map={this.props.map}
        google={this.props.google} Î
        onClick={() => { }}
        path={
          this.props.challenges.map(
            (c) => { return { lat: c.centerLat, lng: c.centerLong } })
        }
        options={{ strokeColor: COLOR.ROAMLI_PURPLE_HEX, strokeWeight: 5 }} />);
    }

    return challengeMarkers;
  }

  getMarkers() {
    const challengeMarkers =
      this.props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
        this.getScavengerHuntMarkers()
        : this.getChallengeTrackMarkers();
    return challengeMarkers
  }

  render() {
    const challengeMarkers = this.getMarkers()

    return (
      <>
        {challengeMarkers}
      </>
    )
  }
}

class ScavengerHuntRadiusMarker extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const centerPosition = {
      lat: this.props.activeChallenge?.centerLat,
      lng: this.props.activeChallenge?.centerLong
    };

    let colorPackage = GetIconColorPackage(this.props.activeChallenge?.challengeType ?? 25);
    return (
      <>
        {
          this.props.activeChallenge &&
          <Circle
            map={this.props.map}
            google={this.props.google}
            onClick={() => { }}
            center={centerPosition}
            radius={this.props.activeChallenge.radius ?? DISTANCE.SCAVENGER_HUNT_RADIUS}
            strokeColor={colorPackage.color}
            fillColor={colorPackage.backgroundColor} />
        }
      </>
    )
  }
}

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    const bounds = new window.google.maps.LatLngBounds();
    const position = { lat: this.props.lat, lng: this.props.lng };
    this.state = {
      showInfoWindow: false,
      showDetailModal: false,
      activeMarker: {},
      activeChallenge: null,
      bounds: bounds,
      zoom: undefined,
      center: position,
    };
    this.markersList = React.createRef();
    this.map = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.lat !== nextProps.lat || this.props.lng !== nextProps.lng) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    const bounds = new window.google.maps.LatLngBounds();
    this.props.challenges.forEach((challenge) => {
      const location = new window.google.maps.LatLng(
        challenge.centerLat,
        challenge.centerLong
      )

      bounds.extend(location);
    })

    if (!this.eventHasStarted()
      && this.props.challengePackage.challenges[0]) {
      const lat = this.props.challengePackage.challenges[0]?.centerLat;
      const lng = this.props.challengePackage.challenges[0]?.centerLong;
      const radius = this.props.challengePackage.challenges[0]?.radius;
      const radNorth = computeDestinationPoint(
        {
          latitude: lat,
          longitude: lng
        },
        radius,
        0
      );
      const radSouth = computeDestinationPoint(
        {
          latitude: lat,
          longitude: lng
        },
        radius,
        180
      );
      const radEast = computeDestinationPoint(
        {
          latitude: lat,
          longitude: lng
        },
        radius,
        90
      );
      const radWest = computeDestinationPoint(
        {
          latitude: lat,
          longitude: lng
        },
        radius,
        270
      );

      bounds.extend(new window.google.maps.LatLng(
        radNorth.latitude,
        radNorth.longitude
      ));
      bounds.extend(new window.google.maps.LatLng(
        radSouth.latitude,
        radSouth.longitude
      ));
      bounds.extend(new window.google.maps.LatLng(
        radEast.latitude,
        radEast.longitude
      ));
      bounds.extend(new window.google.maps.LatLng(
        radWest.latitude,
        radWest.longitude
      ));
    }

    this.setState({ bounds: bounds });
  }

  handleCenterChanged = (map) => {
    if (!map?.center || !this.props.updateCoordinatesHandler) { return false };
    this.props.updateCoordinatesHandler({ latitude: map.center.lat(), longitude: map.center.lng() })
  };

  onMarkerClick = (props, marker, e, challenge) => {
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
      activeChallenge: challenge,
    });
  }

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        activeChallenge: null,
      });
    }
  };

  setActiveChallengeFromOutsideOfMap = challenge => {
    if (challenge) {
      const marker = this.markersList.current[challenge.challengeId].current.marker;
      if (marker) {
        this.onMarkerClick({}, marker, null, challenge)
      }
    }
  }

  eventHasStarted = () => {
    return !this.props.challengePackage.startTime
      || (
        this.props.challengePackage.startTime
        && new Date().getTime() >= new Date(this.props.challengePackage.startTime).getTime()
      );
  }

  render() {
    return (
      <div className="viewPackageRoot">
        <div
          className={`map-container viewPackageMap`}
          style={{
            flexDirection: this.props.isTabletOrMobile ? "column" : 'row'
          }}
        >
          <ViewPackageDetailsSidebar
            setViewMode={this.props.setViewMode}
            onSideMenuClickHandler={this.setActiveChallengeFromOutsideOfMap}
            updatePackageHandler={this.props.updatePackageHandler}
            userData={this.props.userData}
          />
          {!this.props.isTabletOrMobile && <Map
            ref={this.map}
            className={"googleMap"}
            google={this.props.google}
            containerStyle={this.props.containerStyle}
            zoom={this.state.zoom}
            center={this.state.center}
            bounds={this.props.isCreateMode ? undefined : this.state.bounds}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            onClick={this.onClose}
            onCenterChanged={(t, map, coord) => { this.handleCenterChanged(map) }}
            styles={
              [
                {
                  "featureType": "poi.business",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                }
              ]
            }
          >
            <MarkersList
              ref={this.markersList}
              google={this.props.google}
              onClick={this.onMarkerClick}
              challengePackage={this.props.challengePackage}
              type={this.props.type}
              challenges={this.props.challenges}
              hideLine={this.props.hideLine} />

            {this.props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT
              && <ScavengerHuntRadiusMarker
                google={this.props.google}
                activeChallenge={this.state.activeChallenge}
              />}
            {
              // When not started, show radius of the event
              (
                !this.eventHasStarted()
              ) && !this.state.activeChallenge
              && <ScavengerHuntRadiusMarker
                google={this.props.google}
                activeChallenge={this.props.challengePackage.challenges[0]} />
            }
            <ViewPackageMapInfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}>
              <div style={
                {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  minHeight: 200,
                  minWidth: 200
                }
              }>
                <div style={
                  {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: "column"
                  }
                }>
                  <div style={{ height: 150, width: 250, overflow: 'hidden' }}>
                    {
                      this.state.activeChallenge?.picChallengeUrl ?
                        <img src={this.state.activeChallenge?.picChallengeUrl} style={{ width: '100%' }} />
                        : <h6 style={{ textAlign: 'center' }}>No Image</h6>
                    }
                  </div>
                  <h4 style={{ textAlign: 'left', padding: 10, width: '100%', paddingBottom: 0, marginBottom: 0 }}>{this.state.activeChallenge?.title}</h4>
                  {this.state.activeChallenge?.challenge?.address?.displayedAddress
                    &&
                    <small style={{ textAlign: 'left', paddingLeft: 10, paddingRight: 10, width: '100%' }}>
                      {this.state.activeChallenge?.challenge?.address?.displayedAddress}
                    </small>
                  }
                  <div className={"viewPackageInfoWindowButtonContainer"}>
                    <Button
                      style={{ width: '50%', marginBottom: 0, marginTop: 10, marginRight: 0, marginLeft: 0, borderRadius: 0 }}
                      color="primary"
                      type="button"
                      onClick={
                        () => {
                          this.setState({ showDetailModal: true });
                        }
                      }>
                      View
                    </Button>
                    <Button
                      style={{ width: '50%', marginBottom: 0, marginTop: 10, marginRight: 0, marginLeft: 0, borderRadius: 0 }}
                      color="danger"
                      type="button"
                      onClick={
                        () => {
                          this.props.removeChallengeHandler(this.state.activeChallenge)
                        }
                      }>
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </ViewPackageMapInfoWindow>
          </Map>
          }
          <ViewPackageDetailModal
            show={this.state.showDetailModal}
            showHandler={(value) => { this.setState({ showDetailModal: value }) }}
            activeChallengeHandler={this.setActiveChallengeFromOutsideOfMap}
            challenges={this.props.challenges}
            challenge={this.state.activeChallenge} />
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBVA1mMwDKj4H4Vr1xtyeGrYDFXrkZfBeo'
})(MapContainer);