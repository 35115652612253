/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Spinner,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// core components
import MainCardsHeader from "components/Headers/MainCardsHeader.js";
import { UserInfoContext } from "contexts/UserInfoContext";
import ChallengePackageTable from "components/Tables/ChallengePackageTable/ChallengePackageTable";
import ChallengeTable from "components/Tables/ChallengeTable/ChallengeTable"
import { VIEW_MODE } from '../../../constants';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { useHistory, useLocation } from "react-router-dom";
import useBreakpoints from "hooks/Responsive";
import LoadingCardsHeader from "components/Headers/LoadingCardsHeader";
import { PATH } from "constants/link";

const Main = () => {
  const { isTabletOrMobile } = useBreakpoints();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [viewMode, setViewMode] = React.useState(VIEW_MODE.CHALLENGE_PACKAGES);
  const [downloadButton, setDownloadButton] = React.useState(null);
  const data = React.useContext(UserInfoContext);

  const location = useLocation();
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  const renderContent = () => {
    if (!data.arePackagesLoaded) {
      return <Container className="mt--6" fluid style={isTabletOrMobile ? { padding: 0 } : null}>
        <Card>
          <CardHeader className="border-0">
            <Row style={{ alignItems: 'center' }}>
              <h3 className="mb-0 ml-3 mr-3">{"Loading Events"}</h3>
              <Spinner color="primary" />
            </Row>
          </CardHeader>
        </Card>
      </Container>
    } else {
      return (viewMode === VIEW_MODE.CHALLENGE_PACKAGES &&
        data.challengePackages !== null &&
        data.challengePackages.length > 0) ||
        (viewMode === VIEW_MODE.CHALLENGES &&
          data.challenges !== null &&
          data.challenges.length > 0) ?
        <Container className="mt--6" fluid style={isTabletOrMobile ? { padding: 0 } : null}>
          <Card>
            <CardHeader className="border-0">
              <Row style={{ alignItems: 'center' }}>
                <Col md="6">
                  <Row md="12" style={{ display: 'flex', alignItems: 'center', marginLeft: 0, justifyContent: isTabletOrMobile ? "space-between" : null }}>
                    <h3 className="mb-0 mr-3">{viewMode === VIEW_MODE.CHALLENGES ? "Challenges" : "Events"}</h3>
                    {
                      viewMode === VIEW_MODE.CHALLENGES ?
                        <PrimaryButton
                          icon={"fas fa-solid fa-plus"}
                          onClickHandler={() => { routeChange(PATH.MAIN_CREATE_CHALLENGE) }}
                          label={"Create Challenge"} />
                        : <PrimaryButton
                          icon={"fas fa-solid fa-plus"}
                          onClickHandler={() => { routeChange(PATH.MAIN_CREATE_PACKAGE) }}
                          label={"Create Event"} />
                    }
                  </Row>
                </Col>
                <Col className="text-right" xl="6" xs="12">
                  <Row
                    className={`text-right${isTabletOrMobile ? " mt-3" : " mr-1"}`}
                    style={{ display: "flex", justifyContent: isTabletOrMobile ? "center" : 'flex-end' }}>
                    <Form
                      onSubmit={e => { e.preventDefault(); }}
                      style={{ justifyContent: 'flex-end', display: 'flex', transform: "translateX(0%)" }}
                      className={classnames(
                        "navbar-search form-inline mr-sm-3",
                        "navbar-search-light"
                      )}
                    >
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative input-group-merge">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            key={viewMode}
                            placeholder={"Search " + (viewMode === VIEW_MODE.CHALLENGES ? "Title, Address, or Type" : "Title or LookUp Code")}
                            type="text"
                            onChange={(e) => { setSearchQuery(e.target.value) }} />
                        </InputGroup>
                      </FormGroup>
                    </Form>
                    {!isTabletOrMobile && downloadButton}
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            {
              viewMode === VIEW_MODE.CHALLENGE_PACKAGES ?
                <ChallengePackageTable
                  challengePackages={data.challengePackages}
                  searchQuery={searchQuery}
                  setDownloadButtonHandler={setDownloadButton} />
                : null
            }
            {
              viewMode === VIEW_MODE.CHALLENGES ?
                <ChallengeTable
                  challenges={data.challenges}
                  searchQuery={searchQuery}
                  setDownloadButtonHandler={setDownloadButton} />
                : null
            }
          </Card>
        </Container>
        : <Container className="mt--6" fluid style={isTabletOrMobile ? { padding: 0 } : null}>
          <Card>
            <CardHeader className="border-0">
              <Row style={{ alignItems: 'center' }}>
                <h3 className="mb-0 ml-3 mr-3">{viewMode === VIEW_MODE.CHALLENGES ? "Create Your First Challenge" : "Create Your First Event"}</h3>
                <PrimaryButton
                  icon={"fas fa-solid fa-plus"}
                  label={viewMode === VIEW_MODE.CHALLENGES ? "Create Challenge" : "Create Event"}
                  onClickHandler={
                    () => {
                      let route = viewMode === VIEW_MODE.CHALLENGES ? PATH.MAIN_CREATE_CHALLENGE : PATH.MAIN_CREATE_PACKAGE;
                      routeChange(route);
                    }
                  } />
              </Row>
            </CardHeader>
          </Card>
        </Container>
    }
  }

  return (
    <>
      {
        data.isLoaded ? <MainCardsHeader
          name="Home"
          challengePackages={data.challengePackages}
          challengePackagesHandler={() => {
            const queryParams = new URLSearchParams(location.search);
            // Get the value of a specific query parameter
            let queryString = "?type=events";
            Object.keys(queryParams).forEach((key, index) => {
              if (key !== "type") {
                queryString += "&";
                queryString += `${key}=${queryParams[key]}`;
              }
            })

            setViewMode(VIEW_MODE.CHALLENGE_PACKAGES);
            setSearchQuery("");
            routeChange(`${PATH.MAIN}${queryString}`)
          }}
          challengesHandler={() => {
            const queryParams = new URLSearchParams(location.search);
            // Get the value of a specific query parameter
            let queryString = "?type=challenges";
            Object.keys(queryParams).forEach((key, index) => {
              if (key !== "type") {
                queryString += "&";
                queryString += `${key}=${queryParams[key]}`;
              }
            })
            setViewMode(VIEW_MODE.CHALLENGES);
            setSearchQuery("");
            routeChange(`${PATH.MAIN}${queryString}`)
          }}
          viewMode={viewMode}
        /> : <LoadingCardsHeader name="Home" />
      }
      {
        renderContent()
      }
    </>
  );
}

export default Main;
