/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,

  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfoContext } from '../../contexts/UserInfoContext';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PATH } from "constants/link";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import useBreakpoints from "hooks/Responsive";

const AdminNavbar = ({ theme, sidenavOpen, toggleSidenav, gradient }) => {
  const data = React.useContext(UserInfoContext);
  const { isTabletOrMobile } = useBreakpoints();

  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const location = useLocation();
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  }

  if (location.pathname.indexOf(PATH.MAIN) > -1) gradient = false;
  if (location.pathname.indexOf(PATH.VIEW_EVENT) > -1 && !isTabletOrMobile) return null;
  if (location.pathname.indexOf(PATH.VIEW_EVENT) > -1 && isTabletOrMobile) gradient = false;
  if (location.pathname.indexOf(PATH.PACKAGE_DETAILS) > -1) return null;
  if (location.pathname.indexOf(PATH.MAIN_CREATE_PACKAGE) > -1) return null;

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand",
          { "border-bottom": !gradient },
          { "navbar-dark bg-gradient-info": gradient },
          { "navbar-dark bg-info": theme === "dark" && !gradient },
          { "navbar-light bg-secondary": theme === "light" && !gradient }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span
                      className="avatar avatar-sm rounded-circle"
                      style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 40, width: 40, overflow: 'hidden' }}>
                      <img
                        alt="..."
                        src={data.user.pictureUrl ?? require("../../assets/placeholder_assets/profile_image_placeholder.png").default}
                        style={{ position: 'absolute', width: '100%' }}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {data.user.username}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    href="#pablo"
                    style={{ alignItems: 'center', display: 'flex' }}
                    onClick={(e) => { e.preventDefault(); routeChange(PATH.SEARCH); }}
                  >
                    <i class="fa fa-search text-primary" />
                    <span>Explore</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    style={{ alignItems: 'center', display: 'flex' }}
                    onClick={(e) => { e.preventDefault(); routeChange(PATH.MAIN); }}
                  >
                    <i class="ni ni-spaceship text-primary" />
                    <span>Dashboard</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    style={{ alignItems: 'center', display: 'flex' }}
                    onClick={(e) => { e.preventDefault(); routeChange(PATH.PROFILE); }}
                  >
                    <i class="fa fa-user text-primary" />
                    <span>Profile</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    style={{ alignItems: 'center', display: 'flex' }}
                    onClick={(e) => { e.preventDefault(); logout(); }}
                  >
                    <i class="fas fa-door-open text-primary" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
