/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Table,
} from "reactstrap";
import ChallengePackageTableRow from "./ChallengePackageTableRow";
import { CHALLENGE_PACKAGE_TYPE } from "../../../constants";
import Download from '../../Utility/Download';
import { PaginationControls } from '../../Pagination/PaginationControls';
import { TABLE_PAGE_COUNT } from '../../../constants';

const PAGE_COUNT = TABLE_PAGE_COUNT;

const SORT_TYPE = {
  NONE: "none",
  TITLE: "title",
  LOOK_UP_CODE: "lookUpCode",
  TYPE: "type",
  CHALLENGES: "challenges",
  CREATED_ON: "createdOn",
  MODIFIED_ON: "modifiedOn",
  START_TIME: "startTime",
  END_TIME: "endTime",
  LOOKUP_COUNT: "lookUpCount",
  ACTIVE: "active"
}

const ChallengePackageTable = (props) => {
  const firstListRef = React.useRef(null);
  const [pagination, setPagination] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState(-1);
  const [sortType, setSortType] = React.useState(SORT_TYPE.CREATED_ON);

  const createRows = (data) => {
    let rows = [];
    data.forEach((data) => {
      rows.push(
        <ChallengePackageTableRow
          key={data.lookUpCode}
          image={data.anchorImage || data.anchorChallenge.picChallengeUrl}
          title={data.title}
          type={data.type}
          lookUpCode={data.lookUpCode}
          challenges={data.challenges}
          createdOn={data.createdOn}
          modifiedOn={data.modifiedOn === "0001-01-01T00:00:00Z" ? data.createdOn : data.modifiedOn}
          startTime={data.startTime}
          endTime={data.endTime}
          lookUpCount={(data.lookUpCount + data.webLookUpCount + data.iFrameLookUpCount)}
          isActive={data.active}
        />)
    })

    return rows;
  }

  const getSearchResults = (packages) => {
    if (props.searchQuery === "" || props.searchQuery === null) {
      return packages;
    }

    const filtered = packages.filter(
      (cp) => {
        return (cp.title !== null && cp.title.toLowerCase().includes(props.searchQuery.toLowerCase()))
          || cp.lookUpCode.toLowerCase().includes(props.searchQuery.toLowerCase())
      }
    )

    return filtered
  }

  const getPaginatedData = (noPagination) => {
    let challengePackages = props.challengePackages;
    if (sortType === SORT_TYPE.TITLE) {
      challengePackages = sortByTitle();
    } else if (sortType === SORT_TYPE.TYPE) {
      challengePackages = sortByType();
    } else if (sortType === SORT_TYPE.LOOK_UP_CODE) {
      challengePackages = sortByLookUpCode();
    } else if (sortType === SORT_TYPE.CHALLENGES) {
      challengePackages = sortByChallenges();
    } else if (sortType === SORT_TYPE.CREATED_ON) {
      challengePackages = sortByCreatedOn();
    } else if (sortType === SORT_TYPE.MODIFIED_ON) {
      challengePackages = sortByModifiedOn();
    } else if (sortType === SORT_TYPE.START_TIME) {
      challengePackages = sortByStartTime();
    } else if (sortType === SORT_TYPE.END_TIME) {
      challengePackages = sortByEndTime();
    } else if (sortType === SORT_TYPE.LOOKUP_COUNT) {
      challengePackages = sortByLookUpCount();
    } else if (sortType === SORT_TYPE.ACTIVE) {
      challengePackages = sortByActive();
    }

    challengePackages = getSearchResults(challengePackages);

    if (noPagination) {
      return challengePackages;
    }

    return challengePackages.slice(PAGE_COUNT * pagination, PAGE_COUNT * pagination + PAGE_COUNT);
  }

  const sortByTitle = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let titleA = a.title == null ? "" : a.title.toUpperCase();
        let titleB = b.title == null ? "" : b.title.toUpperCase();
        return (titleA < titleB ? -1 : (titleA > titleB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByLookUpCode = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = a.lookUpCode == null ? "" : a.lookUpCode.toUpperCase();
        let compareB = b.lookUpCode == null ? "" : b.lookUpCode.toUpperCase();
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByType = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = a.type == null ? "" : a.type;
        let compareB = b.type == null ? "" : b.type;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByChallenges = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = a.challenges.length == null ? "" : a.challenges.length;
        let compareB = b.challenges.length == null ? "" : b.challenges.length;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByCreatedOn = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = new Date(a.createdOn);
        let compareB = new Date(b.createdOn);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByModifiedOn = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = new Date(a.modifiedOn);
        let compareB = new Date(b.modifiedOn);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByStartTime = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = a.startTime === null ? new Date(0) : new Date(a.startTime);
        let compareB = b.startTime === null ? new Date(0) : new Date(b.startTime);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByEndTime = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = a.endTime === null ? new Date(0) : new Date(a.endTime);
        let compareB = b.endTime === null ? new Date(0) : new Date(b.endTime);
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByLookUpCount = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        let compareA = a.lookUpCount + a.webLookUpCount + a.iFrameLookUpCount;
        let compareB = b.lookUpCount + b.webLookUpCount + b.iFrameLookUpCount;
        return (compareA < compareB ? -1 : (compareA > compareB) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const sortByActive = () => {
    let challengePackages = props.challengePackages.sort(
      (a, b) => {
        return (a.active < b.active ? -1 : (a.active > b.active) ? 1 : 0) * sortDirection;
      }
    );
    return challengePackages
  }

  const paginatedData = getPaginatedData();

  const getDownloadButton = (challengePackages) => {
    let downloadData = [];
    challengePackages.forEach(
      (challengePackage) => {
        let dataObject = {};

        dataObject.Title = challengePackage.title;
        dataObject.Type = challengePackage.type === CHALLENGE_PACKAGE_TYPE.TRACK ? "Track" : "Hunt";
        dataObject.LookUpCode = challengePackage.lookUpCode;
        dataObject.NumberOfChallenges = challengePackage.challenges.length
        dataObject.CreatedOn = challengePackage.createdOn;
        dataObject.StartTime = challengePackage.startTime;
        dataObject.EndTime = challengePackage.endTime;
        dataObject.LookUpCount = challengePackage.lookUpCount + challengePackage.webLookUpCount + challengePackage.iFrameLookUpCount;

        downloadData.push(dataObject);
      }
    )

    return <Download
      filename={"Roamli Events"}
      data={downloadData} />;
  }

  // Reset pagination when searchquery gets updated 
  React.useEffect(() => {
    setPagination(0);
    props.setDownloadButtonHandler(getDownloadButton(getPaginatedData(true)));
  }, [props.searchQuery, sortType, sortDirection])

  return (
    <>
      <div className="table-responsive" ref={firstListRef}>
        <Table className="align-items-center table-flush" responsive striped>
          <thead className="thead-light">
            <tr>
              <th data-sort="title" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.TITLE === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.TITLE); }}>Title</th>
              <th data-sort="link" scope="col" >Link</th>
              <th data-sort="active" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.ACTIVE === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.ACTIVE); }}>Active</th>
              <th data-sort="lookUpCount" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.LOOKUP_COUNT === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.LOOKUP_COUNT); }}>Lookup Count</th>
              <th data-sort="lookUpCode" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.LOOK_UP_CODE === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.LOOK_UP_CODE); }}>Lookup Code</th>
              <th data-sort="challenges" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.CHALLENGES === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.CHALLENGES); }}>Challenges</th>
              <th data-sort="startTime" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.START_TIME === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.START_TIME); }}>Start Time</th>
              <th data-sort="endTime" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.END_TIME === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.END_TIME); }}>End Time</th>
              <th data-sort="type" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.TYPE === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.TYPE); }}>Type</th>
              <th data-sort="createdOn" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.CREATED_ON === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.CREATED_ON); }}>Created On</th>
              <th data-sort="modifiedOn" scope="col" onClick={(e) => { e.preventDefault(); setSortDirection(SORT_TYPE.MODIFIED_ON === sortType || sortType === SORT_TYPE.NONE ? sortDirection * -1 : 1); setSortType(SORT_TYPE.MODIFIED_ON); }}>Last Updated</th>
              {/* <th scope="col" /> */}
            </tr>
          </thead>
          <tbody className="list">
            {createRows(paginatedData)}
          </tbody>
        </Table>
      </div >
      <PaginationControls
        dataSet={getSearchResults(props.challengePackages)}
        currentPage={pagination}
        setPaginationHandler={setPagination} />
    </>
  );
}

export default ChallengePackageTable;
