import React from "react";
import {
  UncontrolledTooltip,
  Modal,
  Button,
  Col
} from "reactstrap";

import SCPIMGallery from './SCPIMGallery';

const SelectChallengePackageImageModal = (props) => {
  const [selectedImage, setSelectedImage] = React.useState(props.currentImage);

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={props.isOpen}
      toggle={props.closeHandler}
      scrollable
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {props.title}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.closeHandler}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <SCPIMGallery urls={props.urls} selectedImage={selectedImage} selectImageHandler={setSelectedImage} />
      </div>
      <div className="modal-footer">
        <Col className="text-right" lg="6" xs="5" style={{ minHeight: 51 }}>
          <Button
            type="button"
            onClick={props.closeHandler}
            style={{ minWidth: 80, minHeight: 30 }}
          >
            CANCEL
          </Button>
          <Button
            disabled={!selectedImage}
            type="button"
            color="primary"
            onClick={() => { props.setImageHandler(selectedImage) }}
            style={{ minWidth: 80, minHeight: 30 }}
          >
            SELECT
          </Button>
        </Col>
      </div>
    </Modal>
  );
}

export default SelectChallengePackageImageModal;
