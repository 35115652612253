import React from "react";
import "./ShowOnly.css"

const ShowOnly = (props) => {
  const showClass = "showOnlyVisible";
  const hideClass = "showOnlyHidden";

  const handleScroll = () => {
    const element = document.getElementById(props.targetId);
    const selfElement = document.getElementById(props.selfId);
    if (element && selfElement) {
      const rect = element.getBoundingClientRect();
      const isOutOfSight = rect.bottom < 0;
      if (isOutOfSight) {
        selfElement.classList.add(showClass)
        selfElement.classList.remove(hideClass)
      } else {
        selfElement.classList.add(hideClass)
        selfElement.classList.remove(showClass)
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => { window.removeEventListener("scroll", handleScroll) }
  }, [])

  return props.children;
}

export default ShowOnly;
