/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Form,
  Button,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import TextAnswerRow from './TextAnswerRow';
import ChallengeFormFieldRow from "components/Forms/ChallengeForm/ChallengeFormFieldRow";

const TextAnswers = ({
  textQuestion,
  textQuestionHandler,
  textQuestionResetHandler,
  isTextQuestionResetDisabled,
  textAnswers,
  textAnswersHandler,
  textAnswerResetHandler,
  isTextAnswerResetDisabled,
  labelMd,
}) => {
  const [questionKey, setQuestionKey] = React.useState(0);
  const updateAnswers = (answers) => {
    textAnswersHandler(answers);
  }

  const addRow = () => {
    let newTextAnswers = [];
    textAnswers.forEach((ta) => {
      newTextAnswers.push(ta);
    })
    newTextAnswers.push("");
    updateAnswers(newTextAnswers);
  }

  const deleteRow = (deleteIndex) => {
    let newTextAnswers = [];
    textAnswers.forEach((ta, idx) => {
      if (deleteIndex !== idx) {
        newTextAnswers.push(ta);
      }
    })
    updateAnswers(newTextAnswers);
  }

  const updateRow = (updatedAnswer, updateIndex) => {
    let newTextAnswers = [];
    textAnswers.forEach((ta, idx) => {
      if (updateIndex === idx) {
        newTextAnswers.push(updatedAnswer);
      } else {
        newTextAnswers.push(ta);
      }
    })
    updateAnswers(newTextAnswers);
  }

  const createRows = () => {
    let rows = [];
    if (textAnswers === null || textAnswers.length === 0) {
      return [];
    }

    textAnswers.forEach((textAnswer, idx) => {
      rows.push(
        <TextAnswerRow
          textAnswer={textAnswer}
          deleteRowHandler={deleteRow}
          index={idx}
          key={idx + textAnswers.length.toString()}
          updateHandler={updateRow}
          labelMd={labelMd}
        />)
    })
    return rows;
  }
  return (
    <>
      <div className={"form-row mt-1"} style={{ width: '100%' }}>
        <ChallengeFormFieldRow
          key={questionKey}
          resetHandler={() => { textQuestionResetHandler(); setQuestionKey(questionKey + 1);}}
          isResetDisabled={isTextQuestionResetDisabled}
          width="10"
          fields={[
            {
              width: "10",
              label: "Enter a Question",
              defaultValue: undefined,
              placeholder: "Enter your question here",
              isInvalid: textQuestion === "" || textQuestion === null || textQuestion === undefined,
              value: textQuestion,
              changeHandler: (value) => { textQuestionHandler(value); },
              invalidFeedback: "Must include a Question",
              validFeedback: null
            }
          ]}
        />
      </div>
      <h4
        className="mb-0 mt-3 mb-1"
        style={{ width: 300 }}
        id="tooltipValidAnswers">
        Answers
        <i className="far fa-question-circle ml-1" />
      </h4>
      <Form className="needs-validation" noValidate>
        {createRows()}
      </Form>
      <div className="mt-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button
          color="primary"
          type="button"
          onClick={addRow}
          style={{ height: 46 }}
        >
          Add Valid Answer
        </Button>
        {
          isTextAnswerResetDisabled ?
            null
            : <Button
              className="btn-icon-only ml-2"
              color="primary"
              type="button"
              disabled={isTextAnswerResetDisabled}
              style={{ height: 46, width: 46 }}
              onClick={textAnswerResetHandler}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-undo-alt" />
              </span>
            </Button>
        }
        <UncontrolledTooltip delay={0} target="tooltipValidAnswers">
          Include multiple versions of an answer to make a better experience for your users.
        </UncontrolledTooltip>
      </div>
      <h4
        className="mb-0 mt-3 mb-1"
        style={{ width: 300 }}>
        Tips
      </h4>
      <ul>
        <li>It's a good idea to include the question in your description as well.</li>
        <li>Enter answers to your prompt or question here as Valid Answers.</li>
        <li>You can have multiple valid answers.</li>
        <li>Try to keep answers simple to avoid players getting errors from typos.</li>
        <li>You should create several versions of an answer to make it easier for the player to guess (e.g. if your question is "How many apples do you see", your answers could be "Three Apples", "3 apples", "3", "three").</li>
        <li>Answers are not case-sensitive and ignore punctuation.</li>
      </ul>
    </>
  );
}

TextAnswers.propTypes = {
  textAnswers: PropTypes.array,
  textAnswersHandler: PropTypes.func,
};

export default TextAnswers;
